import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobal } from "reactn";
import { getRoom } from "../../../../../chat/slices/IOSlice";
import { messagesRemoveRoomUnread } from "../../../../../chat/slices/MessageSlice";
import MessageList from "./MessageList";

const Conversation = (props) => {
  /// MARK: - Initials;
  const room = useSelector((state) => state.ios.room);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const setOver = useGlobal("over")[1];
  // const {id} = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const joinRoomAsync = async () => {
      // join room id
      if (room) {
        const rs = await dispatch(getRoom({ id: room._id }));
        await dispatch(messagesRemoveRoomUnread({ roomID: room._id }));
      }
      console.log("call conversation");
    };
    joinRoomAsync();
  }, [room && room._id]);

  const Loading = () => <div>Loading...</div>;

  const NotFound = () => (
    <div className="chat-box scroll-gray">Room Not Found</div>
  );

  const Error = () => <div className="chat-box scroll-gray">Network Error</div>;

  const Content = () => <MessageList />;

  return (
    <>
      {!room && <NotFound />}
      {room && <Content />}
    </>
  );
};

export default Conversation;
