import React from "react";
import MyProfileAbout from "./components/MyProfileAbout";
import MyProfileHead from "./components/MyProfileHead";
import MyProfileStats from "./components/MyProfileStats";
import MyProfileTab from "./components/MyProfileTab";

const MyProfile = (props) => {
  return (
    <>
      <div className="row d-flex justify-content-center wrapper__profile__container">
        <div className="col-lg-10">
          <MyProfileHead />
          <MyProfileTab />
          <MyProfileAbout />
          <MyProfileStats />
          {/* <MyProfileActivities /> */}
          {/* <MyProfileTeamChart /> */}
        </div>
      </div>
    </>
  );
};

MyProfile.propTypes = {};

export default MyProfile;
