import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import roomApi from "../api/roomApi";

export const getRooms = createAsyncThunk("/api/rooms/list", async (payload) => {
  const res = await roomApi.getRooms(payload);
  return res;
});

export const createRoom = createAsyncThunk(
  "/api/room/create",
  async (payload) => {
    const res = await roomApi.createRoom(payload);
    return res;
  }
);

export const getRoom = createAsyncThunk("/api/room/join", async (payload) => {
  const res = await roomApi.getRoom(payload);
  return res;
});

export const getOnlineUser = createAsyncThunk(
  "/api/room/onlineUser",
  async (payload) => {
    const res = await payload;
    return res;
  }
);

export const deleteConversation = createAsyncThunk(
  "/api/room/delete",
  async (payload) => {
    const res = await roomApi.deleteRoom(payload);
    return res;
  }
);

const ioSlice = createSlice({
  name: "IoSlice",
  initialState: {
    io: null,
    room: null,
    messages: [],
    rooms: [],
    id: null,
    onlineUsers: [],
    refreshMeetings: null,
  },
  reducers: {
    initializeIO: (state, action) => {
      state.io = action.payload.io;
      state.id = action.payload.io.id;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload.rooms;
    },
    setRoom: (state, action) => {
      state.room = action.payload.room;
    },
    setMessages: (state, action) => {
      state.messages = action.messages;
    },
    moreMessages: (state, action) => {
      state.messages = [...action.payload.messages, ...state.messages];
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload.message);
    },
    onlineUsers: (state, action) => {
      state.onlineUsers = action.data;
    },
    refreshMeetings: (state, action) => {
      state.refreshMeetings = action.timestamp;
    },
  },
  extraReducers: {
    [createRoom.fulfilled]: (state, action) => {
      state.room = action.payload.data.room;
      state.messages = action.payload.data.room.messages;
      // state.searchResults = action.payload.data.users;
    },
    [getRooms.fulfilled]: (state, action) => {
      state.rooms = action.payload.data.rooms;
    },
    [getRoom.fulfilled]: (state, action) => {
      state.room = action.payload.data.room;
      state.messages = action.payload.data.room.messages;
    },
    [getOnlineUser.fulfilled]: (state, action) => {
      state.onlineUsers = action.payload;
    },
  },
});

const { reducer, actions } = ioSlice;
export const {
  initializeIO,
  setRooms,
  setRoom,
  refreshMeetings,
  onlineUsers,
  addMessage,
  moreMessages,
  setMessages,
} = actions;
export default reducer;
