import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPhone, FiPhoneOff, FiVideo } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import ringSound from "../../../../../assets/audio/ring.mp3";
import logo from "../../../../../assets/icons/logo.png";
import chatApi from "../../../../../chat/api/chatApi";
import { rtcLeave, rtcParams } from "../../../../../chat/slices/RTCSlice";

const Ringing = ({ onJoin, incoming, meetingID }) => {
  /// MARK: - Initials;
  const counterpart = useSelector((state) => state.rtc.counterpart) || {};
  const [isAudio, setAudio] = useGlobal("audio");
  const [isVideo, setVideo] = useGlobal("video");
  const [audioStream, setAudioStream] = useGlobal("audioStream");
  const [videoStream, setVideoStream] = useGlobal("videoStream");
  const [accepted, setAccepted] = useGlobal("accepted");
  const callData = useSelector((state) => state.rtc.callData) || {};
  const [acquireError, setAcquireError] = useState(false);
  const closingState = useSelector((state) => state.rtc.closingState);
  const closed = useSelector((state) => state.rtc.closed);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const room = useSelector((state) => state.ios.room);

  const getAudio = async () => {
    setAcquireError(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      await setAudioStream(stream);
    } catch (e) {
      setAcquireError(true);
      enqueueSnackbar(t("failed_to_acquire_audio"), {
        variant: "error",
      });
    }
  };

  const getVideo = async () => {
    setAcquireError(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      await setVideoStream(stream);
    } catch (e) {
      setAcquireError(true);
      enqueueSnackbar(t("failed_to_acquire_video"), {
        variant: "error",
      });
    }
  };

  const close = async (closingState) => {
    if (isVideo && videoStream) videoStream.getVideoTracks()[0].stop();
    if (isAudio && audioStream) audioStream.getAudioTracks()[0].stop();
    dispatch(rtcLeave());
    if (closingState)
      await chatApi.postClose({
        meetingID,
        userID: counterpart._id,
        isGroup: (room && room.isGroup) || false,
      });
    dispatch(rtcParams({ params: null }));
    // history.push("/messenger");
    console.log("close action ringing");
  };

  const join = async () => {
    await setAudio(true);
    await setVideo(false);
    await getAudio();
    if (acquireError) return;
    setAccepted(true);
    await chatApi.postAnswer({ userID: callData.caller, meetingID });
  };

  const joinWithVideo = async () => {
    await setAudio(true);
    await setVideo(true);
    await getVideo();
    if (acquireError) return;
    await getAudio();
    if (acquireError) return;
    setAccepted(true);
    await chatApi.postAnswer({ userID: callData.caller, meetingID });
  };

  // const Picture = () => {
  //     if (!counterpart.firstName) counterpart.firstName = 'Anonymous';
  //     if (!counterpart.lastName) counterpart.lastName = 'User';
  //     if (counterpart.picture)
  //         return <img src={`${Config.url || ''}/api/images/${counterpart.picture.shieldedID}/256`} alt="Picture" className="picture" />;
  //     else
  //         return (
  //             <div className="img-wrapper">
  //                 <div className="img">{counterpart.firstName.substr(0, 1) || 'D'}{counterpart.lastName.substr(0, 1)|| 'U'}</div>
  //             </div>
  //         );
  // };

  const getTitle = () => {
    if (incoming) {
      if (callData.added) return t("adding_you_to_meeting");
      else return t("incoming_call");
    } else {
      return t("outgoing_call");
    }
  };

  useEffect(() => {
    if (isAudio) getAudio();
    if (isVideo) getVideo();

    const audio = document.createElement("audio");
    audio.style.display = "none";
    audio.src = ringSound;
    audio.autoplay = true;
    audio.loop = true;

    return () => {
      if (audio) {
        audio.pause();
        audio.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (closingState && !closed) close(true);
  }, [closingState, closed]);

  return (
    <div className="join uk-flex uk-flex-middle uk-flex-center uk-flex-column">
      <img className="logo-little" src={logo} alt="Logo" />
      <p className="title">{getTitle()}</p>
      <p className="name">Roamie</p>
      <div className="picture uk-margin-small">{/* <Picture /> */}</div>
      <div className="uk-flex" hidden={!incoming}>
        <div className="rounded-button close" onClick={close}>
          <FiPhoneOff className="button-icon" />
        </div>
        <div className="rounded-button" onClick={join}>
          <FiPhone className="button-icon" />
        </div>
        <div className="rounded-button" onClick={joinWithVideo}>
          <FiVideo className="button-icon" />
        </div>
      </div>
      <div className="uk-flex" hidden={incoming}>
        <div className="rounded-button close" onClick={close}>
          <FiPhoneOff className="button-icon" />
        </div>
      </div>
    </div>
  );
};

export default Ringing;
