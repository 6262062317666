import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import premiumQuantity from "../../../../assets/icons/premium_quality.png";
import star from "../../../../assets/icons/star.png";
import AddButton from "../../../components/Button/AddButton";
import MyProfileTag from "./MyProfileTag";
import { useSelector } from "react-redux";
import avatarHolder from "../../../../assets/images/user.png";
import coverHolder from "../../../../assets/images/cover_image.png";
import moment from "moment";

const MyProfileHead = (props) => {
  /// Hooks
  const { t, i18n } = useTranslation();
  const { initData } = useSelector((state) => state.auth);
  return (
    <div className="my-profile-head-container">
      <div className="profile">
        <div className="profile-head">
          <div className="photo-content">
            <div
              className="cover-photo"
              style={{
                backgroundImage: `url('${
                  initData && initData.profile_cover
                    ? initData.profile_cover
                    : coverHolder
                }')`,
              }}
            ></div>
          </div>
          <div className="profile-info">
            <div className="row profile-details col-lg-2">
              <div className="profile-photo">
                <img
                  src={
                    initData && initData.profile_image
                      ? initData.profile_image
                      : avatarHolder
                  }
                  className="rounded-circle my-avatar"
                  alt="profile"
                />
              </div>
              <div className="d-flex justify-content-start col-lg-7">
                <div className="profile-name px-3">
                  <span className="mb-0 fs-23 font-w500 roamie-text-color-primary">
                    {initData && (initData.first_name + " " + initData.last_name)}
                  </span>
                  <span className="fs-18 font-w400 roamie-text-color-primary">
                    {initData && initData.user_role === 3 ? t("leader") : t("seller")}
                  </span>
                  <span>{t('expiry')}: {moment(initData && initData.package_expire_date).format('DD/MM/YYYY')}</span>
                </div>
                <div className="px-3 d-flex flex-column align-items-start justify-content-start">
                  <div>
                    {/* <img
                      src={premiumQuantity}
                      className="rounded-circle px-1"
                    />
                    <img
                      src={premiumQuantity}
                      className="rounded-circle px-1"
                    />
                    <img
                      src={premiumQuantity}
                      className="rounded-circle px-1"
                    /> */}
                  </div>
                  <div className="d-flex align-items-center pt-1">
                    {/* <img src={star} className="rounded-circle px-1" />
                    <img src={star} className="rounded-circle px-1" />
                    <img src={star} className="rounded-circle px-1" />
                    <img src={star} className="rounded-circle px-1" />
                    <img src={star} className="rounded-circle px-1" />
                    <span className="fs-12 font-w600 roamie-text-color-primary">
                      {t("excellent")}
                    </span> */}
                  </div>
                </div>
              </div>
              {/* <div className="profile-name px-3 col-lg-2">
                <AddButton text={t("send_message")} />
              </div> */}
              <div className="my-profile-tag-container d-flex pt-2">
                {/* <MyProfileTag text={t("sale")} />
                <MyProfileTag text={t("business_development")} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MyProfileHead.propTypes = {};

export default MyProfileHead;
