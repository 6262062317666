const StatusType = {
    active: {
      value: 'active',
      name: "active",
    },
    inactive: {
      value: 'inactive',
      name: "inactive",
    }
  };
  
  export const statusType = [
    StatusType.active,
    StatusType.inactive
  ];
  
  export default StatusType
  