import { faEnvelope, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Field, Formik } from "formik";
import parsePhoneNumber from "libphonenumber-js";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import * as Yup from "yup";
import avatar from "../../../../../assets/icons/avatar.png";
import { CurrentCountry } from "../../../../../utils/language_util";
import LeadSelected from "../../../../components/Lead/LeadSelected";
import { useLoading } from "../../../../components/Loading/LoadingContext";
import {
  addLeadCompany,
  getLeadsManagement,
  getLeadsPeople,
  updateLeadModel,
  uploadLeadAvatar,
} from "../../LeadSlice";

const LeadCompany = (props) => {
  /// MARK: - Initials
  const [hashTags, setTags] = useState([]);
  const [leadsPerson, setLeadsPerson] = useState([]);
  const [leadsSelected, setLeadsSelected] = useState([]);
  const [formData, setFormData] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const { lead } = props;
  const [myContact, setMyContact] = useState(null);

  /// MARK: - Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef();

  /// UseEffect Load Data
  useEffect(() => {
    if (lead) {
      setTags(lead.tags);
      const foundContact = lead.contacts.find((contact) => {
        return (
          typeof contact.objectId === "undefined" || contact.objectId === null
        );
      });
      if (typeof foundContact !== "undefined") {
        setMyContact(foundContact);
      }
    }
  }, []);

  useEffect(() => {
    formikRef.current.setFieldValue(
      "email",
      (myContact &&
        myContact.emails &&
        myContact.emails.length > 0 &&
        myContact.emails[0].email) ||
        ""
    );

    /// Validate Phone
    const number =
      (myContact &&
        myContact.numbers &&
        myContact.numbers.length > 0 &&
        myContact.numbers[0].number) ||
      "";
    const country =
      (myContact &&
        myContact.numbers &&
        myContact.numbers.length > 0 &&
        myContact.numbers[0].country_phone_code) ||
      "";
    const phoneNumber = parsePhoneNumber(`${country}${number}`);
    formikRef.current.setFieldValue(
      "number",
      `${(phoneNumber && phoneNumber.number) || ""}`
    );
    formikRef.current.setFieldValue(
      "country_phone_code",
      `${(phoneNumber && phoneNumber.countryCallingCode) || ""}`
    );
  }, [myContact]);

  /// UseEffect Get Lead Person
  useEffect(() => {
    const getLeadsPeopleAsync = async () => {
      try {
        const action = getLeadsPeople({ type: 2 });
        const resultAction = await dispatch(action);
        const leads = unwrapResult(resultAction);
        setLeadsPerson(leads);
      } catch (error) {
        console.log("Get Lead Person Error: ", error);
      }
    };
    getLeadsPeopleAsync();
  }, []);

  /// Handle On Change Lead
  const handleOnChangeLead = (event) => {
    let value = event.target.value;
    if (value == -1) {
      return;
    }
    const foundLeadSelected = leadsSelected.find((element) => {
      return element.objectId == value;
    });
    if (typeof foundLeadSelected === "undefined") {
      const lead = leadsPerson.find((element) => {
        return element.objectId == value;
      });
      leadsSelected.push(lead);
      setLeadsSelected([...leadsSelected]);
    }
  };

  /// Handle On Delete Lead
  const handleOnDeleteLead = (lead) => {
    const objectId = lead.objectId;
    const leadsSelectedClone = [...leadsSelected];
    const newLeads = leadsSelectedClone.filter((element) => {
      return element.objectId != objectId;
    });
    setLeadsSelected(newLeads);
  };

  /// Handle Picker Image
  const handlePickerImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let formData = new FormData();
      formData.append("fileToUpload", e.target.files[0]);
      setFormData(formData);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  /// Handle Submit Add New Lead
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const { company, email, address, country_phone_code, number } = values;
      const contacts = [];
      const phoneNumber = parsePhoneNumber(
        number.includes("+") ? number : `+${number}`
      );
      contacts.push({
        objectId: null,
        name: null,
        numbers: [
          {
            number: (phoneNumber && phoneNumber.nationalNumber) || "",
            country_phone_code:
              phoneNumber && `+${phoneNumber.countryCallingCode || ""}`,
            type: "mobile",
          },
        ],
        emails: [
          {
            email: email || "",
            type: "personal",
          },
        ],
      });
      leadsSelected.forEach((lead) => {
        (lead.contacts || []).forEach((element) => {
          element.objectId = lead.objectId;
        });
        contacts.push(...(lead.contacts || []));
      });

      /// Upload Lead Avatar
      var url = (lead && lead.url) || "";
      if (formData) {
        const result = await dispatch(uploadLeadAvatar(formData));
        const response = unwrapResult(result);
        url = response.img || "";
      }

      const request = {
        args: [
          {
            name: company,
            type: 1,
            source: null,
            isCustomer: true,
            url: url,
            contacts: contacts,
            gender: null,
            birthday: null,
            category: null,
            status: null,
            tags: hashTags || [],
            addressList: [
              {
                address: address,
                lat: 0,
                long: 0,
              },
            ],
            selection_record: [],
            first_name: null,
            last_name: null,
          },
        ],
      };
      /// Request action
      const action = addLeadCompany(request);
      const updateRequest = { ...request.args[0] };
      updateRequest.status = (lead && lead.status) || null;
      updateRequest.category = (lead && lead.category) || null;
      updateRequest.selection_record = (lead && lead.selection_record) || [];
      const update = updateLeadModel({
        leadId: lead && lead.objectId,
        request: updateRequest,
      });
      const result = await dispatch(lead && lead.objectId ? update : action);
      const addLeadResponse = unwrapResult(result);
      if (addLeadResponse.status === 200) {
        await dispatch(getLeadsManagement());
        enqueueSnackbar(t("add_leads_success"), {
          persist: false,
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
        if (props.onAddSuccess) {
          props.onAddSuccess();
        }
      } else {
        enqueueSnackbar(t(addLeadResponse.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Faile to add lead", error);
    } finally {
      setLoading(false);
    }
  };

  //handle phone number

  const handleNumber = (phone, country, setNumber) => {
    setNumber("number", phone);
    setNumber("country_phone_code", country.dialCode);
  };

  //handle tags
  const handleTags = (tags, setFormTags) => {
    setTags(tags);
    setFormTags("tags", tags);
  };

  /// Schema Validate
  const validateSchema = Yup.object({
    company: Yup.string().required(t("company_is_required")),
    address: Yup.string().required(t("address_is_required")),
    email: Yup.string().email(t("email_is_invalid")),
  });

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={validateSchema}
      initialValues={{
        company: (lead && lead.name) || "",
        email:
          (myContact &&
            myContact.emails &&
            myContact.emails.length > 0 &&
            myContact.emails[0].email) ||
          "",
        address:
          (lead &&
            lead.addressList &&
            lead.addressList.length > 0 &&
            lead.addressList[0].address) ||
          "",
        tags: (lead && lead.tags && lead.tags.length > 0 && lead.tags) || [],
        number:
          (myContact &&
            myContact.numbers &&
            myContact.numbers.length > 0 &&
            myContact.numbers[0].number) ||
          "",
        country_phone_code:
          (myContact &&
            myContact.numbers &&
            myContact.numbers.length > 0 &&
            myContact.numbers[0].country_phone_code) ||
          "",
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          {/* Avatar */}
          <div className="d-flex justify-content-center">
            <label htmlFor="lead_company_avatar">
              <div className="avatar pointer">
                <img
                  className="rounded-circle"
                  src={previewImage || (lead && lead.url) || avatar}
                  width="100"
                  alt="avatar"
                  style={{
                    minWidth: "100px",
                    minHeight: "100px",
                    maxWidth: "100px",
                    maxHeight: "100px",
                    objectFit: "cover",
                  }}
                />
                <input
                  hidden={true}
                  type="file"
                  id="lead_company_avatar"
                  accept="image/*"
                  onChange={handlePickerImage}
                />
              </div>
            </label>
          </div>
          {/* Company Name */}
          <div className="input-group mb-3 border-text-input d-flex align-items-center">
            <Field
              type="text"
              className="form-control roamie-text-color-primary fs-16 font-w400"
              placeholder={t("company_name")}
              name="company"
            />
            <ErrorMessage component="div" className="error" name="company" />
          </div>

          {/* Email */}
          <div className="input-group mb-3 border-text-input d-flex align-items-center">
            <Field
              type="email"
              className="form-control roamie-text-color-primary fs-16 font-w400"
              placeholder={t("email")}
              name="email"
            />
            <div className="px-3">
              <FontAwesomeIcon icon={faEnvelope} size="1x" color="#B5BBDE" />
            </div>
            <ErrorMessage component="div" className="error" name="email" />
          </div>

          {/* Address */}
          <div className="input-group mb-3 border-text-input d-flex align-items-center">
            <Field
              type="text"
              className="form-control roamie-text-color-primary fs-16 font-w400"
              placeholder={t("address")}
              name="address"
            />
            <div className="px-3">
              <FontAwesomeIcon
                icon={faLocationArrow}
                size="1x"
                color="#B5BBDE"
              />
            </div>
            <ErrorMessage component="div" className="error" name="address" />
          </div>

          {/* Tags */}
          <TagsInput
            value={hashTags}
            onChange={(tags) => handleTags(tags, setFieldValue)}
            className="mb-2 tags-input input-field border-text-input placeholder-color"
            inputProps={{ placeholder: `${t("hash_tags")}` }}
          />

          {/*  Phone Input */}
          <PhoneInput
            className=""
            country={CurrentCountry()}
            value={lead ? values.number : ""}
            type="text"
            onChange={(phone, country) =>
              handleNumber(phone, country, setFieldValue)
            }
            inputClass="input-phone placeholder-color"
            aria-describedby="basic-addon2"
          />

          <div className="line" />

          <div className="select-title">{t("add_people_to_this_company")}</div>

          {/* Leads */}
          <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
            <select
              onChange={handleOnChangeLead}
              className="placeholder-color form-select form-select-lg bg-transparent"
            >
              <option defaultValue={-1} value={-1}>
                {t("add_person")}
              </option>
              {leadsPerson &&
                leadsPerson.length > 0 &&
                leadsPerson.map((lead, index) => (
                  <option value={lead.objectId} key={index}>
                    {(lead.first_name || "") + " " + (lead.last_name || "")}
                  </option>
                ))}
            </select>
          </div>

          {/* Leads Selected */}
          {leadsSelected &&
            leadsSelected.length > 0 &&
            leadsSelected.map((lead) => (
              <LeadSelected
                key={lead && lead.objectId}
                lead={lead}
                onDelete={handleOnDeleteLead}
              />
            ))}

          {/* Save */}
          <div className="d-grid gap-2 col-6 mx-auto mb-5">
            <button
              className="btn btn-success roamie-text-white-color"
              type="submit"
            >
              {lead ? t("update") : t("add")}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

LeadCompany.propTypes = {
  onAddSuccess: PropTypes.func,
};

export default LeadCompany;
