import axiosClient from "./axiosClient";

const calendarApi = {
  getAppointments: (params) => {
    const { firstDayFormatter, lastDayFormatter, salePersonId } = params;
    const url = `api/getAppointmentByDate?start_time=${firstDayFormatter}&end_time=${lastDayFormatter}&sale_person=${
      salePersonId || ""
    }`;
    return axiosClient.get(url);
  },
  submitAppointment: (params) => {
    const url = "api/submitAppointment";
    return axiosClient.post(url, params);
  },
  updateAppointment: (params, objectId) => {
    const url = `/api/updateAppointment/${objectId}`;
    return axiosClient.put(url, params);
  },
};

export default calendarApi;
