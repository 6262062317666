import { faChevronRight, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Textfit } from "react-textfit";
import Empty from "../../../components/Empty/Empty";
import { checkMetrics } from "../../../components/Metrics/checkMetrics";
import { getDealByTarget } from "../TargetSlice";
import DealTabs from "./DealTabs";

export default function TargetView(props) {
  /// Initials
  const { target, onClickTargetUpdate } = props;
  const [deal, setDeal] = useState([]);
  /// Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /// Calculate percent
  const calcPercent = () => {
    const targetValue = (target && target.value) || 0;
    const dealValue =
      (deal &&
        deal
          .filter((item) => item.deal_type === 2)
          .reduce((pre, current) => pre + current.value, 0)) ||
      0;
    return Intl.NumberFormat().format(
      ((dealValue / targetValue) * 100).toFixed(2)
    );
  };

  /// Calculate value closing && closed
  const calcValue = (type) => {
    return Intl.NumberFormat().format(
      deal &&
        deal
          .filter((item) => item.deal_type === type)
          .reduce((pre, current) => pre + current.value, 0)
    );
  };
  /// Use Effect Get Deal
  useEffect(() => {
    const getDealAsync = async () => {
      if (target) {
        const request = {
          target_id: (target && target.goal_id) || null,
        };
        try {
          const action = getDealByTarget(request);
          const result = await dispatch(action);
          const response = unwrapResult(result);
          if (response.status === 200) {
            setDeal(response.deals);
          }
        } catch (error) {
          console.log("Failed to get deals", error);
        }
      }
    };
    getDealAsync();
  }, []);

  return (
    <div className="padding-20">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <button onClick={props.onClickCloseDrawer}>
          <FontAwesomeIcon
            icon={faChevronRight}
            color="#CDD2F3"
            style={{ fontSize: "1.25rem" }}
          />
        </button>
        <span className="fs-16 font-w600 roamie-text-color-primary">
          {t("target")}
        </span>
        <button onClick={() => onClickTargetUpdate(target)}>
          <FontAwesomeIcon icon={faPen} size="1x" color="#CDD2F3" />
        </button>
      </div>
      {/* Body */}
      <div>
        <input
          readOnly
          type={"text"}
          className="form-control text-light placeholder--white-color task-title fs-18 font-w400 border-text-input mt-3"
          placeholder={target && target.subject}
          style={{ backgroundColor: "#41539f" }}
        />
      </div>
      <div>
        <p className="text-center mt-3">
          {target &&
            Moment(target.start_date).locale("en").format("MM/DD/YYYY") +
              " - " +
              Moment(target.end_date).locale("en").format("MM/DD/YYYY")}
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <div style={{ width: "39%" }}>
          <CircularProgressbarWithChildren
            value={calcPercent()}
            styles={buildStyles({
              pathColor: `#43D133`,
              textColor: "#000000",
              trailColor: "#d6d6d6",
            })}
          >
            <div style={{ fontSize: 13, marginTop: -5, textAlign: "center" }}>
              <Textfit mode="single">
                <h3>{calcPercent()}%</h3>
              </Textfit>
              <span>
                {t(`metrics`)}: {checkMetrics(target && target.metrics)}
              </span>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>

      {/* Deal */}
      {deal && deal.length > 0 ? (
        <div className="d-flex justify-content-around mt-3">
          <div
            className="p-5 bg-danger rounded text-center"
            style={{ width: "17vw" }}
          >
            <p className="fb-500">
              {checkMetrics(target && target.metrics)} {calcValue(1)}
              <sup>/{Intl.NumberFormat().format(target && target.value)}</sup>
            </p>
            <span>{t("closing")}</span>
          </div>
          <div
            className="p-5 text-light bg-success rounded text-center"
            style={{ width: "17vw" }}
          >
            <p className="fb-500">
              {checkMetrics(target && target.metrics)} {calcValue(2)}
              <sup>/{Intl.NumberFormat().format(target && target.value)}</sup>
            </p>
            <span>{t("closed")}</span>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-around mt-3">
          <Empty text={t("do_not_have_any_deal")} />
        </div>
      )}
      {deal && deal.length > 0 ? (
        <div className="mt-3">
          <DealTabs target={target} deal={deal} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
