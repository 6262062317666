import React from "react";
import PropTypes from "prop-types";

const RankNo = (props) => {
  return (
    <div className="rankno" style={{ backgroundColor: props.color }}>
      <span className={`fs-15 font-w700 ${props.text === '2.'|| props.text === '3.'?'text-light': 'roamie-text-color-primary'} d-flex justify-content-center`}>
        {props.text}
      </span>
    </div>
  );
};

RankNo.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

RankNo.defaultProps = {
  color: null,
  text: null,
};

export default RankNo;
