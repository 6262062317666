import React from "react";
import { useTranslation } from "react-i18next";

export default function SignInDescription(props) {
  /// MARK: - Initials;
  const { t } = useTranslation();
  return (
    <div className="description-container mt-4">
      <div>
        <a
          style={{ cursor: "pointer" }}
          onClick={props.register}
          className="fw-normal fs-14 font-w500 roamie-text-color-primary"
        >
          {t("do_not_have_an_account")}
        </a>
      </div>
      <div className="mt-0">
        <a
          style={{ cursor: "pointer" }}
          onClick={props.register}
          className="text-primary fs-15 font-w500"
        >
          {t("register")}
        </a>
      </div>
      <div className="mt-0">
        <a className="fw-light description">
          {t("by_logging_in_you_agree_to_our_terms_and_privacy_policy")}
        </a>
      </div>
    </div>
  );
}
