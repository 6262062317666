const LeadCategoryType = {
  unknow: {
    value: 0,
    name: "unknow",
  },
  cold: {
    value: 1,
    name: "cold",
  },
  naturalMarket: {
    value: 2,
    name: "natural_market",
  },
  referral: {
    value: 3,
    name: "referral",
  },
  noResponse: {
    value: 4,
    name: "no_response",
  },
  callLater: {
    value: 5,
    name: "call_later",
  },
  apptMake: {
    value: 6,
    name: "appt_make",
  },
  factFind: {
    value: 7,
    name: "fact_find",
  },
  closing: {
    value: 8,
    name: "closing",
  },
  closed: {
    value: 9,
    name: "closed",
  },
  renewal: {
    value: 10,
    name: "renewal",
  },
  testimonial: {
    value: 11,
    name: "testimonial",
  },
  upCrossSelling: {
    value: 12,
    name: "up_cross_selling",
  },
  notNow: {
    value: 13,
    name: "not_now",
  },
  warm: {
    value: 14,
    name: "warm",
  },
  hot: {
    value: 15,
    name: "hot",
  },
  supperHot: {
    value: 16,
    name: "supper_hot",
  },
  emailLater: {
    value: 17,
    name: "email_latter",
  },
  appointment: {
    value: 18,
    name: "appointment",
  },
  upSell: {
    value: 19,
    name: "up_sell",
  },
};

export const getLeadCategoryType = (value) => {
  switch (value) {
    case 0:
      return LeadCategoryType.unknow;
    case 1:
      return LeadCategoryType.cold;
    case 2:
      return LeadCategoryType.naturalMarket;
    case 3:
      return LeadCategoryType.referral;
    case 4:
      return LeadCategoryType.noResponse;
    case 5:
      return LeadCategoryType.callLater;
    case 6:
      return LeadCategoryType.apptMake;
    case 7:
      return LeadCategoryType.factFind;
    case 8:
      return LeadCategoryType.closing;
    case 9:
      return LeadCategoryType.closed;
    case 10:
      return LeadCategoryType.renewal;
    case 11:
      return LeadCategoryType.testimonial;
    case 12:
      return LeadCategoryType.upCrossSelling;
    case 13:
      return LeadCategoryType.notNow;
    case 14:
      return LeadCategoryType.warm;
    case 15:
      return LeadCategoryType.hot;
    case 16:
      return LeadCategoryType.supperHot;
    case 17:
      return LeadCategoryType.emailLater;
    case 18:
      return LeadCategoryType.appointment;
    case 19:
      return LeadCategoryType.upSell;
    default:
      break;
  }
};

export default LeadCategoryType;
