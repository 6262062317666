import { unwrapResult } from "@reduxjs/toolkit";
import React, { useRef, useState } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGlobal } from "reactn";
import chatApi from "../../../../../chat/api/chatApi";
import { search } from "../../../../../chat/slices/ChatSlice";
import User from "./User";

export default function AddPeers({ onClose }) {
  const searchInput = useRef();
  const setSearchResults = useGlobal("searchResults")[1];
  const { searchResults } = useSelector((state) => state.chat);
  const [searchText, setSearch] = useGlobal("search");
  const [meeting, setMeeting] = useGlobal("meeting");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  // const { addToast } = useToasts();
  const params = useParams();
  const roomID = params.id;

  const onChange = async (e) => {
    const rs = await dispatch(search({ search: e.target.value }));
    const final = unwrapResult(rs);
    setSearchResults(final.data.users);
    setSearch(e.target.value);
  };

  // const errorToast = content => {
  //     addToast(content, {
  //         appearance: 'error',
  //         autoDismiss: true,
  //     })
  // };

  const call = async (user) => {
    setSelected([...selected, user._id]);
    console.warn(roomID);
    try {
      await chatApi.postAdd({ userID: user._id, meetingID: roomID });
    } catch (e) {
      console.log("Server error. Unable to initiate call.");
    }
  };

  const searchResultsList =
    searchResults &&
    searchResults.length > 0 &&
    searchResults.map((user) => (
      <User
        key={user._id}
        user={user}
        onSelect={() => !selected.includes(user._id) && call(user)}
        selected={selected.includes(user._id)}
      />
    ));

  const Notice = ({ text }) => <div className="notice">{text}</div>;

  return (
    <div className="add-peers-overlay">
      <div className="box">
        <div className="top-controls">
          <div className="title">Add Peers</div>
          <div className="close" onClick={onClose}>
            <FiX />
          </div>
        </div>
        <div className="search-bar uk-flex uk-flex-center uk-flex-middle">
          <div className="icon" onClick={() => searchInput.current.focus()}>
            <FiSearch />
          </div>
          <div className="uk-inline search">
            <input
              className="uk-input uk-border-pill"
              placeholder="Search"
              ref={searchInput}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="users">
          {searchResultsList}
          {searchResults.length === 0 && (
            <Notice text={`There are no search results for "${searchText}"`} />
          )}
        </div>
      </div>
    </div>
  );
}
