import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CurrentLanguage } from "../../../utils/language_util";
import ArrowCalendarButton from "../../components/Button/ArrowCalendarButton";
import { useLoading } from "../../components/Loading/LoadingContext";
import { getMyTeam } from "../SalesTeam/SalesTeamSlice";
import AddTask from "../Tasks/AddTask";
import { getTasks } from "../Tasks/TasksSlice";
import AddNewAppointment from "./AddNewAppointment";
import { getAppointments } from "./CalendarSlice";
import CalendarEvents from "./components/CalendarEvents";
import CalendarHead from "./components/CalendarHead";

const CalendarPage = (props) => {
  /// MARK: - Initials;
  const { t } = useTranslation();
  const [appointments, setAppointments] = useState([]);
  const [appointmentsFilterd, setAppointmentsFiltered] = useState([]);
  const [appointmentSelected, setAppointmentSelected] = useState({});
  const [appointmentReload, setAppointmentReload] = useState(false);
  const [tasksReload, setTasksReload] = useState(false);
  const [taskSelected, setTaskSelected] = useState({});
  const [taskFillter, setTaskFillter] = useState([]);
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const [saleTeams, setSaleTeams] = useState([]);
  const [teamSelect, setTeamSelect] = useState({
    value: -1,
    user_name: t("all"),
  });
  const { current } = useSelector((state) => state.auth);

  /// Day change
  const [date, setDate] = useState(new Date());
  const onDateChange = (newDate) => {
    setDate(newDate);
  };

  /// Moth change
  const [month, setMonth] = useState(new Date());
  const onMonthChange = (newDate) => {
    const date = new Date(newDate);
    setMonth(date);
  };

  /// Check appointments dates
  const checkAppointmentsDates = (dates) => {
    const datesCheck = Moment(dates).format("YYYY-MM-DD");
    const isEvent = appointments.some(
      (item) => Moment(item.start_time).format("YYYY-MM-DD") === datesCheck
    );
    return isEvent;
  };

  /// Check task dates
  const checkTasksDates = (dates) => {
    const datesCheck = Moment(dates).format("YYYY-MM-DD");
    const isTask = taskFillter.some(
      (item) => Moment(item.end_date).format("YYYY-MM-DD") === datesCheck
    );
    return isTask;
  };

  /// Drawer appoint ment
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawerAddNewAppointment = () => {
    setIsOpen((prevState) => !prevState);
  };

  const [taskOpen, setTaskOpen] = useState(false);
  const toggleDrawerTask = () => {
    setTaskOpen((prevState) => !prevState);
  };

  /// Handle On Click Calendar Event
  const handleOnClickCalendarEvent = (appointment) => {
    setAppointmentSelected(appointment);
    toggleDrawerAddNewAppointment();
  };

  /// Handle on click task
  const handleOnclickTask = (task) => {
    setTaskSelected(task);
    toggleDrawerTask();
  };

  /// Handle Open Drawer Add Appointment
  const handleOpenDrawerAddAppointment = () => {
    setAppointmentSelected(null);
    toggleDrawerAddNewAppointment();
  };

  /// Handle reload appointment
  const handleReloadApointment = () => {
    setAppointmentReload((preState) => !preState);
  };

  /// Handle reload tasks
  const handleReloadTasks = () => {
    setTasksReload((preState) => !preState);
  };

  /// Get Teams
  useEffect(() => {
    const getSalesTeamAsync = async () => {
      try {
        const action = getMyTeam();
        const resultActioin = await dispatch(action);
        const response = unwrapResult(resultActioin);
        if (response) {
          let salesTeam = (response.data || []).filter(
            (team) => team.status === "1"
          );
          if (current) {
            salesTeam.push({
              value: current.user_id,
              user_name: current.user_name,
            });
          }
          setSaleTeams(salesTeam);
        }
      } catch (error) {
        console.log("Get sale teams failed", error);
      }
    };
    if (current.level == 3) {
      getSalesTeamAsync();
    }
  }, []);

  /// UseEffect For Change Month
  useEffect(() => {
    const changeMonthAsync = async () => {
      try {
        setLoading(true);
        const firstDay = new Date(
          month.getFullYear(),
          month.getMonth(),
          1
        ).toLocaleDateString();
        const lastDay = new Date(
          month.getFullYear(),
          month.getMonth() + 1,
          1
        ).toLocaleDateString();
        const firstDayFormatter = Moment(firstDay).format("YYYY-MM-DD");
        const lastDayFormatter = Moment(lastDay).format("YYYY-MM-DD");
        const salePersonId = current.level == 3 ? teamSelect.value : null;
        const action = getAppointments({
          firstDayFormatter,
          lastDayFormatter,
          salePersonId,
        });
        const resultAction = await dispatch(action);
        const appointments = unwrapResult(resultAction);
        setAppointments(appointments);
      } catch (error) {
        console.log("Get Appointments Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    changeMonthAsync();
  }, [month]);

  /// UseEffect For Change Date
  useEffect(() => {
    const changeDateAsync = async () => {
      try {
        const dateSelectedFormatted = Moment(Date.parse(date)).format(
          "YYYY-MM-DD"
        );
        const appointmentsClone = [...appointments];
        const appointmentsFiltered = appointmentsClone.filter((appointment) => {
          const startDateAppointmentFormatted = Moment(
            Date.parse(appointment.start_time)
          ).format("YYYY-MM-DD");
          return Moment(dateSelectedFormatted).isSame(
            startDateAppointmentFormatted
          );
        });
        setAppointmentsFiltered(appointmentsFiltered);
      } catch (error) {
        console.log("Get Appointments Error: ", error);
      }
    };
    changeDateAsync();
  }, [date]);

  ///Effetc load appointments current day
  useEffect(() => {
    const loadAppoinmentsCurrentDay = async () => {
      try {
        setLoading(true);
        const firstDay = new Date(
          month.getFullYear(),
          month.getMonth(),
          1
        ).toLocaleDateString();
        const lastDay = new Date(
          month.getFullYear(),
          month.getMonth() + 1,
          1
        ).toLocaleDateString();
        const firstDayFormatter = Moment(firstDay).format("YYYY-MM-DD");
        const lastDayFormatter = Moment(lastDay).format("YYYY-MM-DD");
        const salePersonId = current.level == 3 ? teamSelect.value : null;
        const action = getAppointments({
          firstDayFormatter,
          lastDayFormatter,
          salePersonId,
        });
        const resultAction = await dispatch(action);
        const appointments = unwrapResult(resultAction);
        const today = new Date();
        const apptToday = Moment(today).format("YYYY-MM-DD");
        setAppointments(appointments);
        setAppointmentsFiltered(
          appointments.filter((appointment) => {
            const startDateAppointmentFormatted = Moment(
              Date.parse(appointment.start_time)
            ).format("YYYY-MM-DD");
            return Moment(apptToday).isSame(startDateAppointmentFormatted);
          })
        );
      } catch (error) {
        console.log("Get Appointments Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    loadAppoinmentsCurrentDay();
  }, [appointmentReload, teamSelect]);

  /// Effect load task
  useEffect(() => {
    const loadTask = async () => {
      try {
        setLoading(true);
        const salePersonId = current.level == 3 ? teamSelect.value : null;
        const action = getTasks({ salePersonId });
        const result = await dispatch(action);
        const taskResponse = unwrapResult(result);
        if (taskResponse) {
          setTaskFillter(taskResponse);
        }
      } catch (error) {
        console.log("Get Tasks Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    loadTask();
  }, [tasksReload, teamSelect]);

  return (
    <div className="row wrapper__calendar__container">
      {/* Header */}
      <CalendarHead addNewAppointmentClick={handleOpenDrawerAddAppointment} />

      {/* Calendar Filter */}
      <div>
        {current.level == 3 ? (
          <div className="col col-md-4 col-lg-4">
            <span className="fs-13 font-w400 op9 roamie-text-color-primary text-truncate">
              {t("saleperson")}
            </span>
            <Dropdown className="roamie-drop-down mg-top-14 h-50">
              <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
                <span className="fs-12 font-w400 roamie-text-color-primary text-dropdown-select text-truncate">
                  {teamSelect.user_name || ""}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="fs-12"
                  onClick={() => {
                    setTeamSelect({
                      value: -1,
                      user_name: t("all"),
                    });
                  }}
                >
                  {t("all")}
                </Dropdown.Item>
                {saleTeams &&
                  saleTeams.length > 0 &&
                  saleTeams.map((team) => (
                    <Dropdown.Item
                      onClick={() => {
                        setTeamSelect({
                          value: team.user_id || team.value,
                          user_name: team.user_name,
                        });
                      }}
                      key={team.user_id}
                    >
                      {team.user_name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
      </div>

      {/* Body */}
      <div className="row calendar-container d-flex justify-content-around">
        <div className="col-md-12 col-lg-12 col-xl-6 mg-top-40">
          <div>
            <h1 className="text-center fs-16 font-w500 roamie-text-color-primary">
              {t("my_calendar_and_tasks")}
            </h1>
            <Calendar
              className="roamie-calendar fs-22 font-w600 hide-current"
              nextLabel={<ArrowCalendarButton direction="right" />}
              next2Label={null}
              prevLabel={<ArrowCalendarButton direction="left" />}
              prev2Label={null}
              onChange={onDateChange}
              value={date}
              onActiveStartDateChange={({ activeStartDate }) =>
                onMonthChange(activeStartDate)
              }
              locale={CurrentLanguage()}
              tileClassName={({ date, view }) => {
                if (
                  view === "month" &&
                  checkAppointmentsDates(date) &&
                  checkTasksDates(date)
                ) {
                  return "title-event title-task";
                } else if (view === "month" && checkAppointmentsDates(date)) {
                  return "title-event-only";
                } else if (view === "month" && checkTasksDates(date)) {
                  return "title-task-only";
                }
              }}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-4 mg-top-40">
          <CalendarEvents
            date={date}
            appointments={appointmentsFilterd}
            tasks={taskFillter}
            onClick={handleOnClickCalendarEvent}
            taskClick={handleOnclickTask}
          />
        </div>
      </div>

      {/* Drawer */}
      <Drawer
        className="lead-drawer"
        variant="temporary"
        open={isOpen}
        anchor={"right"}
        onBackdropClick={toggleDrawerAddNewAppointment}
      >
        <AddNewAppointment
          onClickCloseDrawer={toggleDrawerAddNewAppointment}
          appointment={appointmentSelected}
          reload={handleReloadApointment}
        />
      </Drawer>
      <Drawer
        className="lead-drawer"
        variant="temporary"
        open={taskOpen}
        anchor={"right"}
        onBackdropClick={toggleDrawerTask}
      >
        <AddTask
          onClickCloseDrawer={toggleDrawerTask}
          task={taskSelected}
          reload={handleReloadTasks}
        />
      </Drawer>
    </div>
  );
};

CalendarPage.propTypes = {};

export default CalendarPage;
