import axiosClient from "./axiosClient";

const paymentApi = {
  getAmount: () => {
    const url = "/api/setting/amount-update-role";
    return axiosClient.get(url);
  },
  paymentRequest: (params) => {
    const url = "/api/users/update-role/braintree";
    return axiosClient.post(url, params);
  },
  getPackage: () => {
    const url = "/api/package/get-all";
    return axiosClient.get(url);
  },
  paymentExpried: (params) => {
    const url = "/api/braintree/get-transaction";
    return axiosClient.post(url, params);
  },
};

export default paymentApi;
