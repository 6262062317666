import moment from "moment";
import Tabs, { TabPane } from "rc-tabs";
import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { checkMetrics } from "../../../components/Metrics/checkMetrics";

export default function DealTabs(props) {
  /// Initials
  const { deal, target } = props;

  /// Hooks
  const { t } = useTranslation();

  const calRemain = (item, type) => {
    const targetValue = target && target.value;
    const totalDealsValue = deal
      .filter((d) => d.deal_type === type)
      .filter((d) => d.objectId <= item.objectId)
      .map((d) => d.value)
      .reduce((prev, current) => prev + current, 0);
    const remain = targetValue - totalDealsValue;
    if (remain < 0) {
      return `+${Intl.NumberFormat().format(Math.abs(remain))}`;
    }
    return Intl.NumberFormat().format(Math.abs(remain));
  };

  return (
    <Tabs className="tab-header" style={{ width: "100%" }}>
      <TabPane className="ms-3" tab={t("closing")} key="1">
        <Table responsive>
          <thead>
            <tr>
              <th>{t("deal")}</th>
              <th>{t("value")}</th>
              <th>{t("remain")}</th>
            </tr>
          </thead>
          <tbody>
            {deal &&
              deal
                .filter((item) => item.deal_type === 1)
                .map((item) => (
                  <React.Fragment key={item.objectId}>
                    <tr key={item.objectId}>
                      <td>
                        <h4>{item.lead_name}</h4>
                      </td>
                      <td>
                        <h4 className="text-danger">
                          {checkMetrics(target && target.metrics)}{" "}
                          {Intl.NumberFormat().format(item.value)}
                        </h4>
                      </td>
                      <td>
                        <h4 className="text-primary">
                          {checkMetrics(target && target.metrics)}{" "}
                          {calRemain(item, 1)}
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td>{item.description}</td>
                      <td></td>
                      <td>{moment(item.close_date).format("MM/DD/YYYY")}</td>
                    </tr>
                  </React.Fragment>
                ))}
          </tbody>
        </Table>
      </TabPane>
      <TabPane className="ms-3 " tab={t("closed")} key="2">
        <Table responsive>
          <thead>
            <tr>
              <th>{t("deal")}</th>
              <th>{t("value")}</th>
              <th>{t("remain")}</th>
            </tr>
          </thead>
          <tbody>
            {deal &&
              deal
                .filter((item) => item.deal_type === 2)
                .map((item) => (
                  <React.Fragment key={item.objectId}>
                    <tr>
                      <td>
                        <h4>{item.lead_name}</h4>
                      </td>
                      <td>
                        <h4 className="text-danger">
                          {checkMetrics(target && target.metrics)}{" "}
                          {Intl.NumberFormat().format(item.value)}
                        </h4>
                      </td>
                      <td>
                        <h4 className="text-primary">
                          {checkMetrics(target && target.metrics)}{" "}
                          {calRemain(item, 2)}
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td>{item.description}</td>
                      <td></td>
                      <td>{moment(item.close_date).format("MM/DD/YYYY")}</td>
                    </tr>
                  </React.Fragment>
                ))}
          </tbody>
        </Table>
      </TabPane>
    </Tabs>
  );
}
