import React from "react";

export default function ChatReceiveAudio() {
  return (
    <div className="chat-receive-item-container ">
      <div className="chat-send-item p-0 bg-transparent align-items-center">
        <audio controlsList="nodownload" controls>
          <source
            src="https://r5---sn-8pxuuxa-nbosd.googlevideo.com/videoplayback?expire=1638197514&ei=qpSkYa-VFsCD6dsPob2u-A4&ip=216.131.88.219&id=o-AIztq_OYC4xUX7uketpt4ugbGO0MtJTa8hMpUqmHt0H4&itag=251&source=youtube&requiressl=yes&gcr=nl&vprv=1&mime=audio%2Fwebm&gir=yes&clen=2788286&dur=161.681&lmt=1633636071435245&keepalive=yes&fexp=24001373%2C24007246&c=ANDROID&txp=5511222&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cgcr%2Cvprv%2Cmime%2Cgir%2Cclen%2Cdur%2Clmt&sig=AOq0QJ8wRgIhAJJbr2zv4Iyul_ugHbIeby9yrxLmgBz6_ZUohbq4DTYpAiEAqtchLtGS0rHsLaUwbTpxfh_bBl9kdOt4psK-kIWaONs%3D&title=Post+Malone%2C+Swae+Lee+-+Sunflower+%28Spider-Man%3A+Into+the+Spider-Verse%29&cms_redirect=yes&mh=5Q&mip=27.71.108.133&mm=31&mn=sn-8pxuuxa-nbosd&ms=au&mt=1638175504&mv=m&mvi=5&pcm2cms=yes&pl=24&lsparams=mh,mip,mm,mn,ms,mv,mvi,pcm2cms,pl&lsig=AG3C_xAwRQIgK_bM5570AxBd9i2bdB0olIMHisMe-PEM-0p__1AgK5ACIQDMtgBFeOQmnwSPNowL5moP0kPjQ1OhSpqgtuVaPx79kw%3D%3D"
            type="audio/mpeg"
          />
        </audio>
      </div>
    </div>
  );
}
