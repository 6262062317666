import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { object } from "prop-types";
import calendarApi from "../../../api/calendarApi";

export const getAppointments = createAsyncThunk(
  "api/getAppointmentByDate",
  async (payload) => {
    const appointmentsResponse = await calendarApi.getAppointments(payload);
    return appointmentsResponse.appointments || [];
  }
);

export const submitAppointment = createAsyncThunk(
  "/api/submitAppointment",
  async (payload) => {
    const submitAppointmentResponse = await calendarApi.submitAppointment(
      payload
    );
    return submitAppointmentResponse;
  }
);

export const updateAppointment = createAsyncThunk(
  "/api/updateAppointment",
  async (payload) => {
    const { apmt_id } = payload;
    const appointmentResponse = await calendarApi.updateAppointment(
      payload,
      apmt_id
    );
    return appointmentResponse;
  }
);

const calendarSlice = createSlice({
  name: "CalendarSlice",
  initialState: {
    appointments: [],
  },
  reducers: {},
  extraReducers: {
    [getAppointments.fulfilled]: (state, action) => {
      state.appointments = action.payload;
    },
  },
});

const { reducer, actions } = calendarSlice;
export default reducer;
