import axiosClient from "./axiosClient";

const taskApi = {
  getTasks: (payload) => {
    const { salePersonId } = payload;
    const url = `/api/getAllTasks?sale_person=${salePersonId || ""}`;
    return axiosClient.get(url);
  },
  submitTask: (params) => {
    const url = "/api/submitTasks";
    return axiosClient.post(url, params);
  },
  updateTask: (params) => {
    const url = "/api/updateTasks";
    return axiosClient.put(url, params);
  },
  deleteTask: (params) => {
    const url = "/api/deleteTask";
    return axiosClient.delete(url, { data: params });
  },
};

export default taskApi;
