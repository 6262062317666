import PropTypes from "prop-types";
import React from "react";
import { Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TargetItem from "./TargetItem";

const TargetTable = (props) => {
  /// Initials
  const { targets } = props;

  /// Hooks
  const { t } = useTranslation();

  return (
    <div className="targets-table mg-top-20">
      <Col lg={12}>
        <Table responsive striped>
          <thead className="table-head">
            <tr>
              <th></th>
              <th>
                <span>{t("name")}</span>
              </th>
              <th>
                <span>{t("start_date")}</span>
              </th>
              <th>
                <span>{t("end_date")}</span>
              </th>
              <th>
                <span>{t("metrics")}</span>
              </th>
              <th>
                <span>{t("value")}</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody id="targets">
            {targets &&
              targets.length > 0 &&
              targets.map((target) => (
                <TargetItem
                  key={target.goal_id}
                  target={target}
                  onClickTargetItem={(target) =>
                    props.onClickTargetItem(target)
                  }
                  reload={props.reload}
                />
              ))}
          </tbody>
        </Table>
      </Col>
    </div>
  );
};

TargetTable.propTypes = {
  targets: PropTypes.array.isRequired,
  onClickTargetItem: PropTypes.func,
};

TargetTable.defaultProps = {
  targets: [],
};

export default TargetTable;
