import { unwrapResult } from "@reduxjs/toolkit";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useGlobal } from "reactn";
import { search } from "../../../../../chat/slices/ChatSlice";

const MessagesSearch = (props) => {
  /// MARK: - Initials;
  const { t } = useTranslation();

  const setSearchResults = useGlobal("searchResults")[1];
  const setSearch = useGlobal("search")[1];
  const [nav, setNav] = useGlobal("nav");
  const dispatch = useDispatch();

  const onSearchChange = async (e) => {
    const rs = await dispatch(search({ search: e.target.value }));
    const final = unwrapResult(rs);
    setSearchResults(final.data.users);
    setSearch(e.target.value);
    if (nav === 'createGroup') return
    setNav("search");
  };

  return (
    <div className="mg-top-20 d-flex justify-content-between align-items-center messages-search2">
      <input
        type="text"
        className="form-control"
        placeholder={t("search_member")}
        onChange={onSearchChange}
      />
      <i className="fa fa-search" style={{ width: "40px" }}></i>
    </div>
  );
};

MessagesSearch.propTypes = {};

export default MessagesSearch;
