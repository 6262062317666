const ReminderType = {
  atTime: {
    value: 1,
    title: "at_time",
    reminderMinutes: 0,
  },
  fiveMinBefore: {
    value: 2,
    title: "five_min_before",
    reminderMinutes: 5,
  },
  fifteenMinBefore: {
    value: 3,
    title: "fifteen_min_before",
    reminderMinutes: 15,
  },
  thirtyMinBefire: {
    value: 4,
    title: "thirty_min_before",
    reminderMinutes: 30,
  },
  oneHoursBefore: {
    value: 5,
    title: "one_hours_before",
    reminderMinutes: 60,
  },
  twoHoursBefore: {
    value: 6,
    title: "two_hours_before",
    reminderMinutes: 120,
  },
  sixHoursBefore: {
    value: 7,
    title: "six_hours_before",
    reminderMinutes: 360,
  },
  twelveHoursBefore: {
    value: 8,
    title: "twelve_hours_before",
    reminderMinutes: 720,
  },
  oneDayBefore: {
    value: 9,
    title: "one_day_before",
    reminderMinutes: 1440,
  },
  twoDaysBefore: {
    value: 10,
    title: "two_days_before",
    reminderMinutes: 2880,
  },
};

export const reminderTypes = [
  ReminderType.atTime,
  ReminderType.fiveMinBefore,
  ReminderType.fifteenMinBefore,
  ReminderType.thirtyMinBefire,
  ReminderType.oneHoursBefore,
  ReminderType.twoHoursBefore,
  ReminderType.sixHoursBefore,
  ReminderType.twelveHoursBefore,
  ReminderType.oneDayBefore,
  ReminderType.twoDaysBefore,
];

export default ReminderType;
