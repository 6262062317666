import axiosClient from "./axiosClient";

const serviceApi = {
  getService: () => {
    const url = "/api/getService";
    return axiosClient.get(url);
  },
};

export default serviceApi;
