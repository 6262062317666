import { unwrapResult } from "@reduxjs/toolkit";
import dropin from "braintree-web-drop-in";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAmount,
  getPackage,
  payForMonth,
  payRequest,
} from "../../pages/Settings/SettingSlice";
import { useLoading } from "../Loading/LoadingContext";

export default function PayMentForm(props) {
  /// 1. up to lead, 2. pay for month
  const { type } = props;
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const token = "sandbox_w3b739kk_6hsm86wzhh5j8m4r";

  /// Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const [amount, setAmount] = useState();
  const history = useHistory();
  /// Effect get amout
  useEffect(() => {
    const fetchAmout = async () => {
      try {
        const action = type === 1 ? getAmount() : getPackage();
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response.status === 200) {
          return type === 1
            ? setAmount(response.update_role)
            : setAmount(response.data[0]);
        } else {
          enqueueSnackbar(t(response.message || ""), {
            variant: "error",
          });
        }
      } catch (error) {
        console.log("Failed to get payment", error);
      }
    };
    fetchAmout();
  }, [type, dispatch]);
  /// Effect authentication
  useEffect(() => {
    const initializeBraintree = () =>
      dropin.create(
        {
          authorization: token, /// insert tokenization
          container: "#braintree-drop-in-div",
          paypal: {
            flow: "checkout",
            amount: amount && amount.amount,
            currency: amount && amount.currency,
          },
        },
        function (error, instance) {
          if (error) console.error(error);
          else setBraintreeInstance(instance);
        }
      );

    if (braintreeInstance) {
      braintreeInstance.teardown().then(() => {
        initializeBraintree();
      });
    } else {
      initializeBraintree();
    }
  }, [amount]);

  return (
    <div style={{ display: "block" }}>
      <div id={"braintree-drop-in-div"} />
      <div className="d-flex justify-content-center">
        <button className="btn btn-outline-danger" onClick={props.cancel}>
          {t("close")}
        </button>
        <button
          className={"btn btn-primary ms-2"}
          disabled={!braintreeInstance}
          onClick={() => {
            if (braintreeInstance) {
              braintreeInstance.requestPaymentMethod(async (error, payload) => {
                if (error) {
                  console.error(error);
                } else {
                  const paymentMethodNonce = payload.nonce;
                  console.log("payment method nonce", payload.nonce);

                  ///  call server and complete the payment here
                  if (
                    (paymentMethodNonce && amount && amount.amount) ||
                    (paymentMethodNonce && amount && amount.price_usd)
                  ) {
                    try {
                      setLoading(true);
                      const request =
                        type === 1
                          ? {
                              amount: amount && amount.amount,
                              payment_method_nonce: paymentMethodNonce,
                              device_data: "",
                              description: "upgrade to leader",
                              user_role: 3,
                            }
                          : {
                              amount: amount.price_usd,
                              payment_method_nonce: paymentMethodNonce,
                              device_data: "",
                              description: "pay for month",
                              package_type: 1,
                            };
                      const action =
                        type === 1 ? payRequest(request) : payForMonth(request);
                      const resultAction = await dispatch(action);
                      const response = unwrapResult(resultAction);
                      if (response.status === 200) {
                        enqueueSnackbar(t("payment_successful"), {
                          variant: "success",
                        });
                        if (type === 1) {
                          if (props.reload) {
                            props.reload();
                          }
                        }
                        props.cancel();
                        if (type === 2) {
                          history.go(0);
                        }
                      } else {
                        enqueueSnackbar(t("something_went_wrong"), {
                          variant: "error",
                        });
                      }
                    } catch (error) {
                      console.log("Payment failed", error);
                    } finally {
                      setLoading(false);
                    }
                  }
                }
              });
            }
          }}
        >
          {t("pay")}
        </button>
      </div>
    </div>
  );
}
