import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InviteImage from "../../../assets/images/invite.png";
import RoleType, { rolesType } from "../../../enum/RoleType";
import AddButton from "../../components/Button/AddButton";
import { useLoading } from "../../components/Loading/LoadingContext";
import { inviteTeam } from "./SalesTeamSlice";

const InviteTeam = (props) => {
  /// MARK: - Initials;
  const { t, i18n } = useTranslation();
  const [roleTypeSelected, setRoleTypeSelected] = useState(RoleType.salePerson);

  /// MARK: - Hooks
  const { loading, setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /// Handle On Change Role
  const handleOnChangeRole = (e) => {
    const foundRole = rolesType.find((roleType) => {
      return roleType.value == e.target.value;
    });
    if (typeof foundRole !== "undefined") {
      setRoleTypeSelected(foundRole);
    }
  };

  /// Validate
  const validateSchema = Yup.object({
    first_name: Yup.string().required(t("first_name_is_required")),
    last_name: Yup.string().required(t("last_name_is_required")),
    email: Yup.string()
      .email(t("email_is_invalid"))
      .required(t("email_required")),
  });

  /// Submit Invite
  const handleSubmitInvite = async (values) => {
    try {
      setLoading(true);
      const { first_name, last_name, email } = values;
      const request = {
        name: `${first_name} ${last_name}`,
        email: email,
        role: roleTypeSelected.value,
      };
      const action = inviteTeam(request);
      const resultAction = await dispatch(action);
      const response = unwrapResult(resultAction);
      if (response.status === 200) {
        enqueueSnackbar(t("invite_team_successfully"), {
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
        if (props.onClickCloseDrawer) {
          props.onClickCloseDrawer();
        }
      } else {
        enqueueSnackbar(t(response.message || "this_user_already_exists"), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Submit Invite Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sale_wrapper__container padding-20">
      {/* Header */}
      <div className="wrapper__container-header">
        <button onClick={props.onClickCloseDrawer}>
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ fontSize: "1.25rem" }}
            color="#CDD2F3"
          />
        </button>
        <span className="fs-16 font-w500 roamie-text-color-primary">
          {t("invite_new_team_member")}
        </span>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <img src={InviteImage} style={{ width: "70%" }} />
      </div>

      {/* Body */}
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
        }}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          handleSubmitInvite(values);
        }}
      >
        {(formik) => (
          <div className="wrapper__container-form">
            <Form className="col-10 mx-auto">
              {/* First name, Last Name  */}
              <div className="wrapper__first-last-name d-flex justify-content-between">
                <div className="input-group mb-3 border-text-input">
                  <Field
                    type="text"
                    className="form-control placeholder-color"
                    placeholder={t("first_name")}
                    name="first_name"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="first_name"
                  />
                </div>
                <div className="input-group mb-3 border-text-input">
                  <Field
                    type="text"
                    className="form-control placeholder-color"
                    placeholder={t("last_name")}
                    name="last_name"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="last_name"
                  />
                </div>
              </div>

              {/* EMail */}
              <div className="input-group mb-3 border-text-input">
                <Field
                  type="text"
                  className="form-control placeholder-color"
                  placeholder={t("email")}
                  name="email"
                />
                <ErrorMessage className="error" component="div" name="email" />
              </div>

              {/* Role */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <select
                  onChange={handleOnChangeRole}
                  className="placeholder-color form-select form-select-lg bg-transparent"
                >
                  {rolesType &&
                    rolesType.length > 0 &&
                    rolesType.map((roleType, index) => (
                      <option
                        defaultValue={
                          roleTypeSelected &&
                          roleTypeSelected.value == roleType.value
                        }
                        value={roleType.value}
                        key={index}
                      >
                        {t(roleType.name || "")}
                      </option>
                    ))}
                </select>
              </div>

              {/* Save */}
              <div className="d-grid gap-2 col-6 mx-auto mb-5">
                <button type="submit">
                  <AddButton text={t("invite")} />
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

InviteTeam.propTypes = {
  onClickCloseDrawer: PropTypes.func,
};

InviteTeam.defaultProps = {
  onClickCloseDrawer: null,
};

export default InviteTeam;
