import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import LeadProfileJournalItem from "./LeadProfileJournalItem";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getActivities } from "../../LeadSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Empty from "../../../../components/Empty/Empty";

const LeadProfileJournal = (props) => {
  /// Initials
  const { lead } = props;
  const [activities, setActivities] = useState([]);

  /// Hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  /// UseEffect Get Journal
  useEffect(() => {
    const getActivitiesAsync = async () => {
      try {
        const action = getActivities({ leadId: lead.objectId });
        const resultAction = await dispatch(action);
        const activities = unwrapResult(resultAction);
        setActivities(activities);
      } catch (error) {
        console.log("Get Activities Error: ", error);
      }
    };
    getActivitiesAsync();
  }, []);

  return (
    <div className="journal-container">
      <div className="mg-top-20">
        {activities && activities.length == 0 ? (
          <Empty text={t("do_not_have_any_journal")} />
        ) : null}
        {activities &&
          activities.length > 0 &&
          activities.map((activity) => (
            <LeadProfileJournalItem
              key={activity.activity_id}
              activity={activity}
            />
          ))}
      </div>
    </div>
  );
};

LeadProfileJournal.propTypes = {
  lead: PropTypes.object.isRequired,
};

export default LeadProfileJournal;
