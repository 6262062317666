import { unwrapResult } from "@reduxjs/toolkit";
import "font-awesome/css/font-awesome.min.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withResizeDetector } from "react-resize-detector";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import ExpiredRegistration from "./jsx/pages/ExpiredRegistration/ExpiredRegistration";
import "../node_modules/uikit/dist/css/uikit.css";
import InitIO from "./chat/initIO";
import setAuthToken from "./chat/setAuthToken";
import { setCurrentUser } from "./chat/slices/ChatSlice";
import StorageKeys from "./constants/StorageKeys";
import "./css/style.css";
/// Components
import Markup from "./jsx";
import Auth from "./jsx/pages/Auth/Auth";
import { fetch } from "./jsx/pages/Auth/AuthSlide";
import "./scss/main.scss";
import { CurrentLanguage } from "./utils/language_util";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

const App = ({ width }) => {
  /// MARK: - Initials
  const [loggedIn, setLoggedIn] = useState(false);
  const [encryptCode, setEncryptCode] = useState(null);
  const { initData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [checked, setChecked] = useState(false);

  /// Effect get user info
  useEffect(() => {
    const userResponse = JSON.parse(localStorage.getItem(StorageKeys.USER));
    const loadUserInfo = () => {
      setLoggedIn(userResponse.user_id !== null);
      setChecked(true);
      const resultAction = dispatch(fetch(userResponse.user_id));
      const response = unwrapResult(resultAction);
      i18n.changeLanguage(CurrentLanguage());
    };
    if (userResponse !== null) {
      loadUserInfo();
    } else {
      setChecked(true);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const token = params.get("token");
      if (!loggedIn && token) {
        setEncryptCode(token);
      }
    }
    const nodeToken = localStorage.getItem(StorageKeys.NODE_TOKEN);
    if (nodeToken !== null) {
      setAuthToken(nodeToken);
      dispatch(InitIO(nodeToken));
    }

    const nodeUser = localStorage.getItem(StorageKeys.NODE_USER);
    if (nodeUser !== null) {
      const user = JSON.parse(nodeUser);
      dispatch(setCurrentUser(user));
    }
  }, []);

  /// Load Language
  useEffect(() => {
    i18n.changeLanguage(CurrentLanguage());
  }, [initData]);

  /// Support UI
  const body = document.querySelector("body");
  body.setAttribute("data-typography", "poppins");
  body.setAttribute("data-theme-version", "light");
  body.setAttribute("data-layout", "vertical");
  body.setAttribute("data-nav-headerbg", "color_1");
  body.setAttribute("data-headerbg", "color_1");
  body.setAttribute("data-sidebar-style", "compact");
  body.setAttribute("data-sibebarbg", "color_1");
  body.setAttribute("data-primary", "color_1");
  body.setAttribute("data-sidebar-position", "fixed");
  body.setAttribute("data-header-position", "fixed");
  body.setAttribute("data-container", "wide");
  body.setAttribute("direction", "ltr");

  width >= 768 && width < 1300
    ? body.setAttribute("data-sidebar-style", "mini")
    : width <= 768
    ? body.setAttribute("data-sidebar-style", "overlay")
    : body.setAttribute("data-sidebar-style", "full");

  if (!checked) return <></>;

  return (
    <>
      <BrowserRouter>
        <Switch>
          {loggedIn ? (
            <Route path={"/"} component={Markup} />
          ) : (
            <Route
              path={"/"}
              component={() => <Auth encrypt={encryptCode}></Auth>}
            />
          )}
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default withResizeDetector(App);
