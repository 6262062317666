import React from "react";
import PropTypes from "prop-types";

const AvatarChat = (props) => {
  return (
    <div className={`wrapper__avatar__chat__container ${props.avatarType}`}>
      <img
        src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
        className="avatar__chat-img"
      />
      <div className="status"></div>
    </div>
  );
};

AvatarChat.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  urlImageAvatar: PropTypes.string.isRequired,
  avatarType:PropTypes.string,
};

AvatarChat.defaultProps = {
  isOnline: false,
  avatarType: ''
};

export default AvatarChat;
