import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@material-ui/core";
import React from "react";

const SliderDrawer = (props) => {
  /// MARK: - Initials;
  const { open, children, onClose, title } = props;

  return (
    <Drawer
      className="lead-drawer"
      variant="temporary"
      open={open || false}
      anchor={"right"}
      transitionDuration={500}
    >
      <div className="add-lead__header">
        <button onClick={onClose}>
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ fontSize: "1.25rem" }}
            color="#CDD2F3"
          />
        </button>
        <span className="fs-16 font-w500 roamie-text-color-primary">
          {title || ""}
        </span>
        <span className="fs-16 font-w500 roamie-text-color-primary"></span>
      </div>
      {children}
    </Drawer>
  );
};

SliderDrawer.propTypes = {};

export default SliderDrawer;
