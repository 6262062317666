import PropTypes from "prop-types";
import React, { useEffect } from "react";
import avatar from "../../../../assets/images/user.png";
import RankNo from "./RankNo";

const RankingItem = (props) => {
  /// MARK: - Initials;
  const { member, target } = props;

  /// Get Rank Color
  const getRankColor = () => {
    if (member && member.rank == 1) {
      return "#F4D63C";
    } else if (member && member.rank == 2) {
      return "#F4863C";
    } else if (member && member.rank == 3) {
      return "#3E4391";
    }
    return "";
  };

  /// Cal target hit
  const calTargetHit = () => {
    if (target <= 0) {
      return 0;
    }
    const targetHit = (
      ((member && member.deal_closed_value) / target) *
      100
    ).toFixed(2);
    return Intl.NumberFormat().format(targetHit || 0);
  };

  return (
    <>
      <tr>
        <td>
          <RankNo
            text={`${member && member.rank.toString()}.`}
            color={getRankColor()}
          />
        </td>
        <td>
          <div className="d-flex align-items-center">
            <img
              src={
                member && member.avatar_url !== null
                  ? member.avatar_url
                  : avatar
              }
              className="rounded-circle mr-2 rank_avatar"
              width="60"
              alt=""
            />{" "}
            <span className="name text-truncate">{member && member.name}</span>
          </div>
        </td>
        <td className="data">{member && member.number_of_leads}</td>
        <td className="data">{member && member.number_of_appointments}</td>
        <td className="data">{member && member.number_of_tasks}</td>
        <td className="data">{member && member.number_of_deals}</td>
        <td className="deals-closing">
          <span style={{ marginRight: "5.5vw" }}>
            ${member && Intl.NumberFormat().format(member.deal_closing_value)}
          </span>
        </td>
        <td className="deals-closed">
          <span style={{ marginRight: "5.5vw" }}>
            ${member && Intl.NumberFormat().format(member.deal_closed_value)}
          </span>
        </td>
        <td>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "3vw" }}
          >
            <span className="px-3 fs-13 font-w600 roamie-text-color-primary ">
              %{calTargetHit()}
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

RankingItem.propTypes = {
  member: PropTypes.object,
};

export default RankingItem;
