import React from "react";
import PropTypes from "prop-types";
import ChatDetailPeopleItem from "./ChatDetailPeopleItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSortDown, faUserPlus} from "@fortawesome/free-solid-svg-icons";

const ChatDetailPeople = (props) => {
  /// Initials
  const {isMinimize} = props
  return (
    <div className="mg-top-20">
      <span className={`d-flex justify-content-${isMinimize? 'center':'between'}`} style={{ color: "#4B5155" }}>
        <span className="fs-14 font-w500">People</span>
        <div className={isMinimize? 'd-none': ''}>
          <FontAwesomeIcon className="p-2 rounded-1 chat-feature-icon" icon={faUserPlus} style={{backgroundColor:"#DBE5ED"}} />
          <FontAwesomeIcon className="p-2 rounded-1 chat-feature-icon ms-2" icon={faSortDown} style={{backgroundColor:"#DBE5ED"}} />
        </div>
      </span>
      <div className={`chat-detail-people-container ${isMinimize? 'scroll-hide':'scroll-gray'} `}>
        <div className="chat-detail-peoples mg-top-25 ">
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
          <ChatDetailPeopleItem isMinimize={isMinimize}/>
        </div>
      </div>
    </div>
  );
};

ChatDetailPeople.propTypes = {};

export default ChatDetailPeople;
