import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useLoading } from "../../../components/Loading/LoadingContext";
import PayMentForm from "../../../components/PayMent/PayMentForm";
import { deleteMyAccount, fetch, logout } from "../../Auth/AuthSlide";
import { update } from "../SettingSlice";
import PhoneUser from "./PhoneUser";

export default function ProfileForm(props) {
  /// MARK: - Initials
  const current = JSON.parse(localStorage.getItem("USER"));
  const [userCurrent, setUserCurrent] = useState(current);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  /// Handle modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /// Handle reload
  const handle1Reload = () => {
    setReload(!reload);
  };
  //update schema
  const updateSchema = Yup.object({
    email: Yup.string().email(t("email_is_invalid")),
  });

  const handleUpdate = async (values) => {
    try {
      const data = {
        userId: current?.user_id,
        body: { ...values },
      };
      setLoading(true);
      const action = update(data);
      const resultAction = await dispatch(action);
      const updateResponse = unwrapResult(resultAction);
      if (updateResponse.status === 200) {
        await dispatch(fetch(current?.user_id));
        handle1Reload();
        enqueueSnackbar(t("update_profile_successful"), {
          persist: false,
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(updateResponse.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update profile fail", error);
    } finally {
      setLoading(false);
    }
  };

  /// Get user
  useEffect(() => {
    const getUserAsync = async () => {
      try {
        const id = current && current.user_id;
        const action = fetch(id);
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response) {
          setUserCurrent(response);
        }
      } catch (error) {
        console.log("Fetch user failed", error);
      }
    };
    getUserAsync();
  }, [reload]);

  /// Handle Delete Account
  const handleDeleteAccount = () => {
    const deleteMyAccountAsync = async () => {
      try {
        setLoading(true);
        const id = current && current.user_id;
        const action = deleteMyAccount({
          objectId: id,
          password: "",
        });
        const resultAction = await dispatch(action);
        const updateResponse = unwrapResult(resultAction);
        if (updateResponse.status === 200) {
          await dispatch(logout());
          await history.push("/");
          history.go(0);
        } else {
          enqueueSnackbar(t(updateResponse.message || ""), {
            variant: "error",
          });
        }
      } catch (error) {
        console.log("Delete my account error", error);
      } finally {
        setLoading(false);
      }
    };
    let callback = window.confirm(t("are_you_sure_delete_this_account"));
    if (callback) {
      deleteMyAccountAsync();
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: userCurrent?.first_name || "",
        last_name: userCurrent?.last_name || "",
        company_name: userCurrent?.company_name || "",
        email: userCurrent?.email || "",
        address: userCurrent?.address || "",
        phone_number: "",
        country_phone_code: "",
      }}
      validationSchema={updateSchema}
      onSubmit={handleUpdate}
    >
      {(formik) => (
        <Form className="col-7 mx-auto ">
          <div className="row">
            <div className="col input-group mb-3 ">
              <Field
                type="text"
                className="fs-16 form-control border-text-input"
                placeholder={t("first_name")}
                name="first_name"
              />
            </div>
            <div className="col input-group mb-3">
              <Field
                type="text"
                className="fs-16 form-control border-text-input"
                placeholder={t("last_name")}
                name="last_name"
              />
            </div>
          </div>
          <div className=" input-group mb-3 border-text-input">
            <Field
              type="text"
              className="fs-16 form-control "
              placeholder={t("company_name")}
              name="company_name"
            />
          </div>
          <div className=" input-group mb-3 border-text-input">
            <Field
              type="text"
              className="fs-16 form-control"
              placeholder={t("email_office")}
              name="email"
            />
            <span className="input-icon input-group-text bg-transparent">
              <i className="fa fa-envelope"></i>
            </span>
            <ErrorMessage className="error" component="div" name="email" />
          </div>
          <div className=" input-group mb-3 border-text-input">
            <Field
              type="text"
              className="fs-16 form-control "
              placeholder={t("address")}
              name="address"
            />
            <span className=" input-icon input-group-text bg-transparent">
              <i className="fa fa-map-marker"></i>
            </span>
          </div>
          <div className="mb-5 d-flex border-text-input">
            <PhoneUser user={userCurrent} form={formik} />
            <span className="input-icon input-group-text bg-transparent">
              <i className="fa fa-phone"></i>
            </span>
          </div>
          <div className="d-grid gap-2 col-6 mx-auto mb-5">
            <button
              className="btn btn-success roamie-text-white-color"
              type="submit"
            >
              {t("save")}
            </button>
            {userCurrent && userCurrent.user_role != 3 ? (
              <button className="btn btn-info" type="button" onClick={() => {}}>
                <span>{t("upgrade_to_leader")}</span>
                <br />
                <span>{t("coming_soon")}</span>
              </button>
            ) : (
              ""
            )}
            {/* Delete My Account */}
            {/* <button
              className="btn btn-outline-danger"
              type="button"
              onClick={() => handleDeleteAccount()}
            >
              {t("delete_my_account")}
            </button> */}
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{t("upgrade_to_leader")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PayMentForm
                reload={handle1Reload}
                cancel={handleClose}
                type={1}
              />
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
