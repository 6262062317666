import nodeAxiosClient from "../nodeAxiosClient";

const groupApi = {
  createGroup: (params) => {
    const url = "/api/group/create";
    return nodeAxiosClient.post(url, params);
  },
};

export default groupApi;
