import PropTypes from "prop-types";
import React from "react";
import avatarHolder from "../../../assets/icons/avatar.png";

const LeadSelected = (props) => {
  /// MARK: - Initials
  const { lead } = props;

  return (
    <div className="mb-3 lead-selected">
      <div className="d-flex justify-content-center align-items-center">
        <img
          className="lead-avatar"
          src={(lead && lead.url) || avatarHolder}
          alt="avatar"
        />
        <span>
          {lead && lead.type === 1
            ? (lead && lead.name) || ""
            : (lead && lead.first_name + " " + lead && lead.last_name) || ""}
        </span>
      </div>
      <button onClick={() => props.onDelete(lead)} type="button">
        <span>
          <i className="fa fa-times-circle"></i>
        </span>
      </button>
    </div>
  );
};

LeadSelected.propTypes = {
  lead: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
};

export default LeadSelected;
