import nodeAxiosClient from "../nodeAxiosClient";

const fileApi = {
  uploadFile: (file, token, onProgress = () => {}) => {
    const url = "/api/upload/file";
    const data = new FormData();

    data.append("file", file, file.name);

    const config = {
      onUploadProgress: onProgress,
    };

    return nodeAxiosClient.post(url, data, config);
  },
};

export default fileApi;
