import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetch } from "../Auth/AuthSlide";
import SettingsHeader from "./components/SettingsHeader";
import SettingsTabs from "./components/SettingsTabs";

const Settings = (props) => {
  /// MARK: - Intials;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.auth);
  const [initData, setInitData] = useState({});

  /// Effect get user
  useEffect(() => {
    const getUsersAsync = async () => {
      try {
        const action = fetch(current?.user_id);
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response) {
          setInitData(response);
        }
      } catch (error) {
        console.log("Failed to get user", error);
      }
    };
    getUsersAsync();
  }, [current]);

  return (
    <div className="row">
      <div className="settings-container col-12 mx-auto scroll-gray">
        <SettingsHeader
          className="col-12 mx-auto"
          coverImage={initData && initData.profile_cover}
          avatar={initData && initData.profile_image}
        />
        <div className="col-2 mx-auto d-flex justify-content-center">
          <div>
            <div className="fs-16 font-w400 roamie-text-color-primary ">
              {initData.email || ""}
            </div>
            <div className="fs-20 font-w500 roamie-text-color-primary">
              {initData && initData.first_name
                ? (initData && initData.first_name) ||
                  "" + " " + initData.last_name ||
                  ""
                : t("update_your_name")}
            </div>
          </div>
        </div>
        <SettingsTabs user={initData} />
      </div>
    </div>
  );
};

Settings.propTypes = {};

export default Settings;
