import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddButton from "../../../../components/Button/AddButton";
import Empty from "../../../../components/Empty/Empty";
import { getDeals } from "../../LeadSlice";
import AddDeal from "../Deals/AddDeal";
import LeadProfileDealItem from "./LeadProfileDealItem";

const LeadProfileDeals = (props) => {
  /// MARK: - Initials
  const [change, setChange] = useState(true);
  const [deals, setDeals] = useState([]);
  const [dealSelected, setDealSelected] = useState(null);
  const { lead, active } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [needReload, setNeedReload] = useState(false);
  const [radio, setRadio] = useState(null);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  /// Get Deals
  useEffect(() => {
    const getDealsAsync = async () => {
      const type = change ? 2 : 1;
      try {
        const action = getDeals({
          type: type,
          lead_id: lead.objectId,
        });
        const resultAction = await dispatch(action);
        const deals = unwrapResult(resultAction);
        setDeals(deals);
      } catch (error) {
        console.log("Get Deals Error: ", error);
      }
    };
    getDealsAsync();
  }, [change, needReload]);

  /// Handle Change Tab
  const handleChange = () => {
    setChange(!change);
  };

  /// Handle Open Drawer
  const toggleDrawerAddNote = () => {
    setIsOpen((prevState) => !prevState);
  };

  /// Handle On Click Add Note
  const handleOnClickAddDeal = () => {
    setDealSelected(null);
    toggleDrawerAddNote();
  };

  /// Handle On Click Deal
  const handleOnClickDeal = (deal) => {
    setDealSelected(deal);
    toggleDrawerAddNote();
  };

  /// Handel lead profile status
  useEffect(() => {
    if (active.name === "closed") {
      handleOnClickAddDeal();
      setRadio(2);
    } else if (active.name === "closing") {
      handleOnClickAddDeal();
      setRadio(1);
    }
  }, [active]);

  /// Hadle radio
  useEffect(() => {
    if (change) {
      setRadio(2);
    } else {
      setRadio(1);
    }
  }, [change]);
  return (
    <div className="deals-container mg-top-20">
      {/* Tabs */}
      <div className="d-flex btn-changes">
        <div className="d-flex justify-content-end col-lg-6 p-0">
          <div className="d-flex align-items-center">
            <a
              onClick={handleChange}
              className={`btn ${change ? "btn-light active" : "btn-primary"}`}
            >
              {t("closing")}
            </a>
          </div>
        </div>
        <div className="d-flex justify-content-start col-lg-6 p-0">
          <div className="d-flex align-items-center">
            <a
              onClick={handleChange}
              className={`btn ${change ? "btn-primary" : "btn-light active"}`}
            >
              {t("closed")}
            </a>
          </div>
        </div>
      </div>

      {/* Add Deal Button */}
      <div className="d-flex justify-content-end mg-top-10">
        <AddButton text={t("add_deal")} onClick={handleOnClickAddDeal} />
      </div>

      {/* Deals */}
      <div className="mg-top-10">
        {deals && deals.length == 0 ? (
          <Empty text={t("do_not_have_any_deal")} />
        ) : null}
        {deals &&
          deals.length > 0 &&
          deals.map((deal, index) => (
            <LeadProfileDealItem
              key={deal.objectId}
              deal={deal}
              index={index}
              type={change ? t("closed") : t("closing")}
              onClick={handleOnClickDeal}
            />
          ))}
      </div>

      {/* Drawer Add Deal */}
      <Drawer
        className="lead-drawer"
        variant="temporary"
        {...props}
        open={isOpen}
        anchor={"right"}
        transitionDuration={500}
        onBackdropClick={toggleDrawerAddNote}
      >
        <AddDeal
          radio={radio}
          onClose={toggleDrawerAddNote}
          onClick={handleOnClickDeal}
          deal={dealSelected}
          lead={lead}
          onReload={() => setNeedReload((preState) => !preState)}
        />
      </Drawer>
    </div>
  );
};

LeadProfileDeals.propTypes = {
  lead: PropTypes.object.isRequired,
};

export default LeadProfileDeals;
