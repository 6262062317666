import PropTypes from "prop-types";
import React from "react";
import ActivitiesChart from "./ActivitiesChart";
import DealsChart from "./DealsChart";

const HomeSummary = (props) => {
  /// Initials;
  const { summaryDeal, tabType, summaryActivities } = props;

  return (
    <>
      <div className="mg-top-40">
        {tabType == 0 ? (
          <DealsChart summaryDeal={summaryDeal} />
        ) : (
          <ActivitiesChart activities={summaryActivities} />
        )}
      </div>
    </>
  );
};

HomeSummary.propTypes = {
  summaryDeal: PropTypes.object,
  tabType: PropTypes.number,
  summaryActivities: PropTypes.array,
};

export default HomeSummary;
