import React from "react";
import { useTranslation } from "react-i18next";

export default function SignUpDescription(props) {
  const { t } = useTranslation();

  return (
    <div className="description-container mt-4">
      <div>
        <a
          style={{ cursor: "pointer" }}
          onClick={props.login}
          className="fw-normal fs-14 font-w500 roamie-text-color-primary"
         
        >
          {t("already_have_an_account")}
        </a>
      </div>
      <div className="mt-0">
        <a
          style={{ cursor: "pointer" }}
          onClick={props.login}
          className="text-primary fs-15 font-w500"
         
        >
          {t("login")}
        </a>
      </div>
      <div className="mt-0">
        <a className="fw-light description">
          {t("by_signing_up_you_agree_to_our_terms_and_privacy_policy")}
        </a>
      </div>
    </div>
  );
}
