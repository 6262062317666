const SaleType = {
  revenue: {
    value: 1,
    name: "revenue",
  },
  profit: {
    value: 2,
    name: "profit",
  },
  commissions: {
    value: 3,
    name: "commissions",
  },
};

export const salesType = [
  SaleType.revenue,
  SaleType.profit,
  SaleType.commissions,
];

export default SaleType;
