import { unwrapResult } from "@reduxjs/toolkit";
import Tabs, { TabPane } from "rc-tabs";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLoading } from "../../components/Loading/LoadingContext";
import ServiceDealsTable from "./components/ServiceDealsTable";
import ServiceLeadItem from "./components/ServiceLeadItem";
import ServiceTasksTable from "./components/ServiceTasksTable";
import { getService } from "./ServiceSlice";

export default function Service() {
  /// Initials
  const [service, setService] = useState(null);
  const [leadsSelected, setLeadsSelected] = useState([]);
  const [reload, setReload] = useState(false);
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  /// Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, setLoading } = useLoading();

  /// Effect get service
  useEffect(() => {
    const getServicesAsync = async () => {
      try {
        setLoading(true);
        const action = getService();
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response.status === 200) {
          setService(response.services);
        }
      } catch (error) {
        console.log("Failed to get service", error);
      } finally {
        setLoading(false);
      }
    };
    getServicesAsync();
  }, [reload]);

  return (
    <>
      <div className="row service-wrapper">
        <Tabs defaultActiveKey="1" tabBarGutter={53}>
          {/* Leads */}
          <TabPane className="mt-3 " tab={t("leads")} key="1">
            <Table responsive striped>
              <thead className="table-head">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {alphabets.split("").map((char) => {
                  return (
                    service &&
                    service.length > 0 &&
                    service
                      .filter((lead) =>
                        lead.name.toLowerCase().startsWith(char.toLowerCase())
                      )
                      .map((lead, index) => (
                        <React.Fragment key={index}>
                          {index === 0 ? (
                            <tr className="spacer">
                              <td className="alphabet-sort">{char}</td>
                            </tr>
                          ) : null}
                          <ServiceLeadItem
                            key={lead.objectId}
                            lead={lead}
                            select={setLeadsSelected}
                            leadsSelected={leadsSelected}
                          />
                        </React.Fragment>
                      ))
                  );
                })}
              </tbody>
            </Table>
          </TabPane>

          {/* Deals */}
          <TabPane className="mt-3 " tab={t("deals")} key="2">
            <ServiceDealsTable
              reload={setReload}
              leadsSelected={leadsSelected}
              service={service && service.length > 0 ? service : []}
            />
          </TabPane>

          {/* Task */}
          <TabPane className="mt-3 " tab={t("tasks")} key="3">
            <ServiceTasksTable
              reload={setReload}
              leadsSelected={leadsSelected}
              service={service && service.length > 0 ? service : []}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
