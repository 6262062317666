import {
  faCalendarAlt,
  faChevronRight,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import en from "date-fns/locale/en-US";
import vi from "date-fns/locale/vi";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CurrentCountry } from "../../../../../utils/language_util";
import { MomentUtil } from "../../../../../utils/moment_util";
import { useLoading } from "../../../../components/Loading/LoadingContext";
import { getTargets } from "../../../Target/TargetSlice";
import {
  getLeadsManagement,
  submitDeal,
  updateLeadDeal,
} from "../../LeadSlice";
import TaskModal from "./TaskModal";

const AddDeal = (props) => {
  /// MARK: - Initials
  const [closeDate, setCloseDate] = useState(new Date());
  const [dealType, setDealType] = useState(1);
  const [leads, setLeads] = useState([]);
  const [leadSelected, setLeadSelected] = useState(null);
  const [targets, setTargets] = useState([]);
  const [targetSelected, setTargetSelected] = useState(null);
  const { deal, lead, radio } = props;
  const [showTask, setShow] = useState(false);

  /// MARK: - Hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { loading, setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  /// UseEffect Load Data
  useEffect(() => {
    setCloseDate(deal ? new Date(deal && deal.close_date) : new Date());
    setDealType((deal && deal.deal_type) || 1);
  }, []);

  /// UseEffect Default Radio
  useEffect(() => {
    if (radio !== null) {
      setDealType(radio);
    }
  }, [radio]);

  /// UseEffect Get Leads
  useEffect(() => {
    const getLeadsAsync = async () => {
      try {
        const action = getLeadsManagement();
        const result = await dispatch(action);
        const leads = unwrapResult(result);
        setLeads(leads);
        /// Check Lead Selected
        if (deal) {
          const foundLeadSelected = leads.find((element) => {
            return element.objectId == deal.lead_id;
          });
          if (typeof foundLeadSelected !== "undefined") {
            setLeadSelected(foundLeadSelected);
          }
        } else {
          const foundLeadSelected = leads.find((element) => {
            return element.objectId == lead.objectId;
          });
          if (typeof foundLeadSelected !== "undefined") {
            setLeadSelected(foundLeadSelected);
          }
        }
      } catch (error) {
        console.log("Get Leads Error: ", error);
      }
    };
    getLeadsAsync();
  }, []);

  /// UseEffect Get Target
  useEffect(() => {
    const getTargetsAsync = async () => {
      try {
        const action = getTargets({});
        const result = await dispatch(action);
        const targets = unwrapResult(result);
        setTargets(targets);
        /// Check Target Selected
        if (deal) {
          const foundTargetSelected = targets.find((element) => {
            return element.goal_id == deal.target_id;
          });
          setTargetSelected(foundTargetSelected);
        }
      } catch (error) {
        console.log("Get Target Error: ", error);
      }
    };
    getTargetsAsync();
  }, []);

  /// Handle On Change Deal Type (1: Closing, 2: Closed)
  const handleOnChangeDealType = (e) => {
    const value = e.target.value;
    setDealType(value);
  };

  /// Handle On Change Lead
  const handleOnChangeLead = (event) => {
    let value = event.target.value;
    if (value == -1) {
      return;
    }
    const foundLeadSelected = leads.find((element) => {
      return element.objectId == value;
    });
    if (typeof foundLeadSelected !== "undefined") {
      setLeadSelected(foundLeadSelected);
    }
  };

  /// Handle On Change Target
  const handleOnChangeTarget = (event) => {
    let value = event.target.value;
    if (value == -1) {
      return;
    }
    const foundTargetSelected = targets.find((element) => {
      return element.goal_id == value;
    });
    if (typeof foundTargetSelected !== "undefined") {
      setTargetSelected(foundTargetSelected);
    }
  };

  /// Handle Add Deal
  const handleAddDeal = async (values) => {
    try {
      setLoading(true);
      const { description_deal, hashtags, value } = values;
      const request = {
        deals: [
          {
            description: description_deal,
            lead_id: leadSelected.objectId,
            value: value,
            close_date: closeDate.toISOString(),
            target_id: targetSelected.goal_id,
            deal_type: dealType,
            hastag: hashtags.split(","),
            category: 1,
          },
        ],
      };
      const action = submitDeal(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message) || "", {
          variant: "success",
        });
        props.onReload();
        if (radio === 2) {
          setShow(true);
        } else {
          props.onClose();
        }
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Add Deal Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  /// Hanle Update Deal
  const handleUpdateDeal = async (values) => {
    try {
      setLoading(true);
      const { description_deal, hashtags, value } = values;
      const request = {
        deal_id: deal.objectId,
        args: {
          deals: [
            {
              description: description_deal,
              lead_id: leadSelected.objectId,
              value: value,
              close_date: closeDate.toISOString(),
              target_id: targetSelected.goal_id,
              deal_type: dealType,
              hastag: hashtags.split(","),
              category: 1,
            },
          ],
        },
      };
      const action = updateLeadDeal(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message) || "", {
          variant: "success",
        });
        props.onReload();
        props.onClose();
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update Deal Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="padding-20">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <button onClick={props.onClose}>
          <FontAwesomeIcon icon={faChevronRight} size="1x" color="#CDD2F3" />
        </button>
        <span className="fs-16 font-w600 roamie-text-color-primary">
          {deal ? t("update_deal") : t("add_deal")}
        </span>
        <button></button>
      </div>
      {/* Form */}
      <div className="row mg-top-40">
        <Formik
          initialValues={{
            description_deal: (deal && deal.description) || "",
            value: (deal && deal.value) || "",
            hashtags: ((deal && deal.hastag) || []).join(",") || "",
          }}
          onSubmit={(values) => {
            deal ? handleUpdateDeal(values) : handleAddDeal(values);
          }}
        >
          {(formik) => (
            <Form className="col-10 mx-auto">
              {/* Description Deal */}
              <div className="input-group mb-3 border-text-input">
                <Field
                  type="text"
                  className="form-control placeholder-color roamie-text-color-primary fs-20 font-w400"
                  placeholder={t("description_deal")}
                  name="description_deal"
                />
              </div>

              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <select
                  value={leadSelected && leadSelected.objectId}
                  onChange={handleOnChangeLead}
                  className="placeholder-color form-select form-select-lg bg-transparent"
                >
                  <option defaultValue={-1} value={-1}>
                    {t("lead")}
                  </option>
                  {leads &&
                    leads.length > 0 &&
                    leads.map((lead, index) => (
                      <option
                        defaultValue={
                          leadSelected
                            ? lead.objectId == leadSelected.objectId
                            : false
                        }
                        value={lead.objectId}
                        key={index}
                      >
                        {lead && lead.type == 1
                          ? lead.name
                          : (lead.first_name || "") +
                            " " +
                            (lead.last_name || "")}
                      </option>
                    ))}
                </select>
              </div>

              {/* Value */}
              <div className="input-group mb-3 border-text-input d-flex align-items-center justify-content-center">
                <Field
                  type="text"
                  className="form-control placeholder-color roamie-text-color-primary fs-20 font-w400"
                  placeholder={t("value")}
                  name="value"
                />
                <div className="px-3">
                  <FontAwesomeIcon icon={faPen} size="1x" color="#B5BBDE" />
                </div>
              </div>

              {/* Close Date */}
              <DatePicker
                className="custom__date__picker px-3 mb-3"
                selected={closeDate}
                onChange={(date) => setCloseDate(date)}
                showTimeInput
                locale={CurrentCountry().includes("vn") ? vi : en}
                customInput={
                  <div className="input-group border-text-input d-flex justify-content-between align-items-center">
                    <span>
                      {closeDate && MomentUtil(closeDate, "DD MMM yyyy, HH:mm")}
                    </span>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="1x"
                      color="#B5BBDE"
                    />
                  </div>
                }
              />

              {/* Deal Type */}
              <div className="d-flex justify-content-center mb-3">
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="deal_type"
                      value="1"
                      checked={dealType == 1}
                      onChange={handleOnChangeDealType}
                    />
                    {t("closing")}
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="deal_type"
                      value="2"
                      checked={dealType == 2}
                      onChange={handleOnChangeDealType}
                    />
                    {t("closed")}
                  </label>
                </div>
              </div>

              {/* Targets */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <select
                  value={targetSelected && targetSelected.goal_id}
                  onChange={handleOnChangeTarget}
                  className="placeholder-color form-select form-select-lg bg-transparent"
                >
                  <option defaultValue={-1} value={-1}>
                    {t("target")}
                  </option>
                  {targets &&
                    targets.length > 0 &&
                    targets.map((target, index) => (
                      <option
                        defaultValue={
                          targetSelected &&
                          targetSelected.target_id == target.target_id
                        }
                        value={target.goal_id}
                        key={index}
                      >
                        {target.subject || ""}
                      </option>
                    ))}
                </select>
              </div>

              {/* HashTags */}
              <div className="input-group mb-3 border-text-input d-flex align-items-center justify-content-center">
                <Field
                  type="text"
                  className="form-control placeholder-color roamie-text-color-primary fs-20 font-w400"
                  placeholder={t("hash_tags")}
                  name="hashtags"
                />
                <div className="px-3">
                  <FontAwesomeIcon icon={faPen} size="1x" color="#B5BBDE" />
                </div>
              </div>

              {/* Save */}
              <div className="d-grid gap-2 col-6 mx-auto mb-5">
                <button
                  className="btn btn-success roamie-text-white-color"
                  type="submit"
                >
                  {t("save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <TaskModal
          lead={lead}
          show={showTask}
          set={setShow}
          close={props.onClose}
        />
      </div>
    </div>
  );
};

AddDeal.propTypes = {
  onClose: PropTypes.func.isRequired,
  deal: PropTypes.object,
  lead: PropTypes.object,
  onReload: PropTypes.func,
};

export default AddDeal;
