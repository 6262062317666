import axios from "axios";
import queryString from "query-string";
import StorageKeys from "../constants/StorageKeys";
require("dotenv").config();

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

let count = 0;
const checkInvalidToken = async (message) => {
  if (count === 0) {
    count = count + 1;
    await window.localStorage.removeItem("USER");
    window.location.href = "/";
    alert(message);
    return false;
  }
  return true;
};

axiosClient.interceptors.request.use(async (config) => {
  const userLocal = localStorage.getItem(StorageKeys.USER);
  if (userLocal !== null) {
    const user = JSON.parse(userLocal);
    config.headers.Authorization = user.session_token || "";
    config.headers.put["Content-Type"] = "application/json";
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (
      response &&
      response.data &&
      (response.data.status === 403 || response.data.status === 406)
    ) {
      checkInvalidToken(response.data.message);
      return;
    }
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    throw error;
  }
);

export default axiosClient;
