import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import targetApi from "../../../api/targetApi";

export const getTargets = createAsyncThunk(
  "api/classes/Target",
  async (payload) => {
    const targetsResponse = await targetApi.getTargets(payload);
    return targetsResponse.goals || [];
  }
);

export const submitTarget = createAsyncThunk(
  "api/classes/Target",
  async (payload) => {
    const targetResponse = await targetApi.submitTarget(payload);
    return targetResponse;
  }
);

export const updateTarget = createAsyncThunk(
  "/api/classes/Target/Update",
  async (payload) => {
    const { targetId, request } = payload;
    const targetResponse = await targetApi.updateTarget(request, targetId);
    return targetResponse;
  }
);

export const deleteTarget = createAsyncThunk(
  "/api/classes/Target",
  async (payload) => {
    const { targetId } = payload;
    const response = await targetApi.deleteTarget(targetId);
    return response;
  }
);

export const getDealByTarget = createAsyncThunk(
  "api/classes/getDeals",
  async (payload) => {
    const dealsResponse = await targetApi.getDeal(payload);
    return dealsResponse;
  }
);

const targetSlice = createSlice({
  name: "TargetSlice",
  initialState: {},
  reducers: {},
  extraReducers: {},
});

const { reducer, actions } = targetSlice;
export default reducer;
