import React from "react";
import Config from "../../../../../chat/config";

export default function ChatSendAudio(props) {
  const { src } = props;
  return (
    <div className="chat-send-item-container">
      <div className="chat-send-item p-0 bg-transparent align-items-center">
        <audio controlsList="nodownload" controls>
          <source
            src={`${Config.url || ""}/api/files/${src.content}`}
            type="audio/mpeg"
          />
        </audio>
      </div>
    </div>
  );
}
