import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Table } from "react-bootstrap";
import HeaderRanking from "../components/HeaderRanking";
import { useTranslation } from "react-i18next";
import RankingItem from "../components/RankingItem";
import { getTargets } from "../../Target/TargetSlice";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const TeamMemberRanking = (props) => {
  /// Initials
  const { members, target } = props;
  const [targetSum, setSum] = useState(0);
  /// Hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const getTargetsAsync = async () => {
      if (
        (target && target.ranking === undefined) ||
        (target && target.ranking.value === -1)
      ) {
        try {
          const action = getTargets({});
          const result = await dispatch(action);
          const response = unwrapResult(result);
          if (response) {
            const value = response
              .map((item) => item.value)
              .reduce((curent, pre) => curent + pre, 0);
            setSum(value);
          }
        } catch (error) {
          console.log("Failed to get targets", error);
        }
      } else {
        setSum(target && target.ranking.value);
      }
    };
    getTargetsAsync();
  }, [target]);

  if (members.length === 0) {
    return <div></div>;
  }

  return (
    <Col lg={12}>
      <Card.Header>
        <Card.Title className="home-title">
          {t("team_members_ranking")}
        </Card.Title>
      </Card.Header>
      <Table responsive>
        <HeaderRanking />
        <tbody>
          {members &&
            members.length > 0 &&
            members.map((member, index) => (
              <RankingItem member={member} key={index} target={targetSum} />
            ))}
        </tbody>
      </Table>
    </Col>
  );
};

TeamMemberRanking.propTypes = {
  members: PropTypes.array,
};

export default TeamMemberRanking;
