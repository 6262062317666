import React from "react";

export default function ChatReceiveVideo() {
  return (
    <div className="chat-receive-item-container ">
      <div className="chat-send-item with-video align-items-center">
        <video controls>
          <source
            src={
              "https://res.cloudinary.com/tlus-image/video/upload/v1644480112/BLACKPINK_-_Ice_Cream_with_Selena_Gomez_M-V_zhazbt.mp4"
            }
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}
