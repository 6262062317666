import { unwrapResult } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddButton from "../../../components/Button/AddButton";
import { getMyTeam } from "../../SalesTeam/SalesTeamSlice";

const TargetHead = (props) => {
  /// MARK: - Initials
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saleTeams, setSaleTeams] = useState([]);
  const [teamSelect, setTeamSelect] = useState(
    props.teamSelect || {
      value: -1,
      user_name: t("all"),
    }
  );

  /// MARK: - Hooks
  const { current } = useSelector((state) => state.auth);

  /// Get Teams
  useEffect(() => {
    const getSalesTeamAsync = async () => {
      try {
        const action = getMyTeam();
        const resultActioin = await dispatch(action);
        const response = unwrapResult(resultActioin);
        if (response) {
          let salesTeam = (response.data || []).filter(
            (team) => team.status === "1"
          );
          if (current) {
            salesTeam.push({
              value: current.user_id,
              user_name: current.user_name,
            });
          }
          setSaleTeams(salesTeam);
        }
      } catch (error) {
        console.log("Get sale teams failed", error);
      }
    };
    if (current.level == 3) {
      getSalesTeamAsync();
    }
  }, [current]);

  return (
    <>
      <div className="targets-header">
        <span className="fs-16 font-w500 roamie-text-color-primary">
          {t("targets")}
        </span>
        <AddButton text={t("add_target")} onClick={props.onClickAddTarget} />
      </div>
      <div>
        {current.level == 3 ? (
          <div className="col col-md-4 col-lg-4">
            <span className="fs-13 font-w400 op9 roamie-text-color-primary text-truncate">
              {t("saleperson")}
            </span>
            <Dropdown className="roamie-drop-down mg-top-14 h-50">
              <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
                <span className="fs-12 font-w400 roamie-text-color-primary text-dropdown-select text-truncate">
                  {teamSelect.user_name || ""}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="fs-12"
                  onClick={() => {
                    setTeamSelect({
                      value: -1,
                      user_name: t("all"),
                    });
                    if (props.handleFilterSalePerson) {
                      props.handleFilterSalePerson({
                        value: -1,
                        user_name: t("all"),
                      });
                    }
                  }}
                >
                  {t("all")}
                </Dropdown.Item>
                {saleTeams &&
                  saleTeams.length > 0 &&
                  saleTeams.map((team) => (
                    <Dropdown.Item
                      onClick={() => {
                        setTeamSelect(team);
                        if (props.handleFilterSalePerson) {
                          props.handleFilterSalePerson(team);
                        }
                      }}
                      key={team.user_id}
                    >
                      {team.user_name || ""}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
      </div>
    </>
  );
};

TargetHead.propTypes = {
  onClickAddTarget: PropTypes.func,
};

export default TargetHead;
