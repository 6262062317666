const RoleType = {
  salePerson: {
    name: "sale_person",
    value: "seller",
  },
  saleLeader: {
    name: "sale_leader",
    value: "leader",
  },
};

export const rolesType = [RoleType.salePerson, RoleType.saleLeader];

export default RoleType;
