import nodeAxiosClient from "../nodeAxiosClient";

const messageApi = {
  sendMessage: (params) => {
    const url = "/api/message";
    return nodeAxiosClient.post(url, params);
  },
  sendFile: (image, token, onProgress = () => {}, crop) => {
    const url = "/api/upload";
    const data = new FormData();

    data.append("image", image, image.name);
    data.append("crop", crop);

    const config = {
      onUploadProgress: onProgress,
    };

    return nodeAxiosClient.post(url, data, config);
  },
  upLoadFile: (file, token, onProgress = () => {}) => {
    const url = "/api/upload/file";

    const data = new FormData();

    data.append("file", file, file.name);

    const config = {
      onUploadProgress: onProgress,
    };

    return nodeAxiosClient.post(url, data, config);
  },
  postCall: ({ roomID, meetingID }) => {
    const url = "/api/meeting/call";
    return nodeAxiosClient.post(url, { roomID, meetingID });
  },
  getMore: ({ roomID, firstMessageID }) => {
    const url = "/api/messages/more";
    return nodeAxiosClient.post(url, { roomID, firstMessageID });
  },
};

export default messageApi;
