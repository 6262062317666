import PropTypes from "prop-types";
import React from "react";

const AvatarChat = (props) => {
  /// MARK: - Initials;
  const { hasPicture, user, status, avatarType } = props;
  const username =
    (user && user.firstName && user.firstName.substr(0, 1)) ||
    (user && user.email && user.email.substr(0, 1)) ||
    "";

  const getAvatarStatusType = () => {
    if (avatarType === "people-chat") {
      return "status-small";
    } else if (avatarType === "actives-chat") {
      return "status-md";
    }
    return "status-lg";
  };

  return (
    <div className={`wrapper__avatar__chat__container ${props.avatarType}`}>
      {hasPicture ? (
        <img src="" className="avatar__chat-img" />
      ) : (
        <div className="avatar__chat-img d-flex justify-content-center align-items-center bg-dark">
          {<span className="fs-20 text-light">{username}</span>}
        </div>
      )}
      <div className={`${getAvatarStatusType()} ${status}`}></div>
    </div>
  );
};

AvatarChat.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  // urlImageAvatar: PropTypes.string.isRequired,
  avatarType: PropTypes.string,
};

AvatarChat.defaultProps = {
  isOnline: false,
  avatarType: "",
};

export default AvatarChat;
