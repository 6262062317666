import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useGlobal } from "reactn";
import { createGroup } from "../../../../../chat/slices/ChatSlice";
import { setRoom } from "../../../../../chat/slices/IOSlice";

const CreateGroupStep2 = () => {
  /// MARK: - Initials;
  const dispatch = useDispatch();
  const [panel, setPanel] = useGlobal("panel");
  const setNav = useGlobal("nav")[1];
  const [hover, setHover] = useState(false);
  const [error, setError] = useState(false);
  const [title, setTitle] = useGlobal("groupTitle");
  const [newGroupUsers, setNewGroupUsers] = useGlobal("newGroupUsers");

  /// MARK: - Hooks
  const { t } = useTranslation();

  const create = async (e) => {
    e.preventDefault();
    if (!title || title.length === 0) return setError(true);
    setError(null);
    const res = await dispatch(
      createGroup({ people: newGroupUsers, picture: null, title })
    );
    const result = unwrapResult(res);
    await dispatch(setRoom({ room: result.data }));
    setPanel("");
    setNav("rooms");
  };

  const cancelCreateGroup = () => {
    setNewGroupUsers([]);
    setPanel("");
    setNav("rooms");
  };

  return (
    <>
      <div
        className="text-center p-3 bg-primary text-light pointer mt-4"
        onClick={create}
      >
        {t("create_group")}
      </div>
      <div className="group-create-step2">
        {/* <button className="text-primary create-button border border-primary p-1 rounded" onClick={create}>Create Group</button> */}

        {/*<input*/}
        {/*    className="file-input"*/}
        {/*    type="file"*/}
        {/*    ref={fileInput}*/}
        {/*    accept="image/*"*/}
        {/*    onChange={e => changePicture(e.target.files[0])}*/}
        {/*/>*/}
        {/*<div style={{marginTop: 15}} className="picture" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>*/}
        {/*    <GroupPicture picture={groupPicture} />*/}
        {/*    <div className="overlay">*/}
        {/*        <div className="text"><FiEdit2/></div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <input
          className="form-control"
          type="text"
          placeholder={t("group_name")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div style={{ color: "#FF0000" }} hidden={!error} className="fs-13">
          {t("group_name_required")}
        </div>
        <button
          className="ms-1 text-danger create-button"
          onClick={() => cancelCreateGroup()}
        >
          {t("cancel")}
        </button>
      </div>
    </>
  );
};

// CreateGroupStep2.propTypes = {};

export default CreateGroupStep2;
