import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import periodsType from "../../../enum/PeriodsType";
import { useLoading } from "../../components/Loading/LoadingContext";
import { getMyStats } from "../MyProfile/MyProfileSlice";
import HomeCalendar from "./components/HomeCalendar";
import HomeFilter from "./components/HomeFilter";
import HomeStats from "./components/HomeStats";
import HomeSummary from "./components/HomeSummary";
import TeamMemberRanking from "./components/TeamMemberRanking";
import {
  getSummaryActivities,
  getSummaryDeals,
  getTeamMembersRanking,
} from "./HomeSlice";

const Home = () => {
  /// MARK: - Initials;
  const { t, i18n } = useTranslation();
  const { loading, setLoading } = useLoading();
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [summaryDeal, setSummaryDeal] = useState(null);
  const [activitiesObject, setActivitiesObject] = useState(null);
  const [tabs, setTabs] = useState(0);
  const [periods, setPeriods] = useState(periodsType[0]);
  const [teamSelect, setTeamSelect] = useState({
    value: -1,
    user_name: t("all"),
  });
  const [targetSelect, setTargetSelect] = useState({
    value: -1,
    subject: t("all"),
  });
  const [isRefresh, setIsRefresh] = useState(false);
  const [myStats, setMyStats] = useState({});
  const { current } = useSelector((state) => state.auth);

  /// Handle Apply Filter
  const handleApplyFilter = (tabType, periodType, saleTeam, campagin) => {
    if (tabType !== null) {
      setTabs(tabType);
    }
    if (periodType !== null) {
      setPeriods(periodType);
    }
    if (saleTeam !== null) {
      setTeamSelect({
        value: saleTeam.user_id || saleTeam.value,
        user_name: saleTeam.user_name,
      });
    }
    if (campagin !== null) {
      setTargetSelect({
        value: campagin.goal_id || campagin.value,
        subject: campagin.subject,
        ranking: campagin,
      });
    }
    setIsRefresh((preState) => !preState);
  };

  /// Get Team Member Ranking
  useEffect(() => {
    const getTeamMemberRankingAsync = async () => {
      try {
        const role = current && current.level;
        if (role !== 3) {
          return;
        }
        setLoading(true);
        const request = {
          periods: periods.value,
          sale_person: teamSelect.value,
          campaign: targetSelect.value,
        };
        const action = getTeamMembersRanking(request);
        const resultAction = await dispatch(action);
        const members = unwrapResult(resultAction);
        setMembers(members);
      } catch (error) {
        console.log("Get Team Member Ranking Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getTeamMemberRankingAsync();
  }, [isRefresh, current]);

  /// Get Summary Deals
  useEffect(() => {
    const getSummaryDealsAsync = async () => {
      try {
        const role = current && current.level;
        setLoading(true);
        const request = {
          periods: periods.value,
          sale_person: role === 3 ? teamSelect.value : current.user_id,
          campaign: targetSelect.value,
        };
        const action = getSummaryDeals(request);
        const resultAction = await dispatch(action);
        const deals = await unwrapResult(resultAction);
        setSummaryDeal(deals);
      } catch (error) {
        console.log("Get Summary Deals Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getSummaryDealsAsync();
  }, [isRefresh, current]);

  /// Get Activities
  useEffect(() => {
    const getActivitiesAsync = async () => {
      try {
        const role = current && current.level;
        setLoading(true);
        const request = {
          periods: periods.value,
          sale_person: role === 3 ? teamSelect.value : current.user_id,
        };
        const action = getSummaryActivities(request);
        const resultAction = await dispatch(action);
        const response = unwrapResult(resultAction);
        setActivities(response.data || []);
        setActivitiesObject(response);
      } catch (error) {
        console.log("Get Activities Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getActivitiesAsync();
  }, [isRefresh, current]);

  /// Get Stats
  useEffect(() => {
    const getStatsAsync = async () => {
      try {
        setLoading(true);
        const role = current && current.level;
        const request = {
          periods: periods.value,
          sale_person: role === 3 ? teamSelect.value : null,
        };
        const action = getMyStats(request);
        const resultAction = await dispatch(action);
        const myStatsResponse = unwrapResult(resultAction);
        setMyStats(myStatsResponse);
      } catch (error) {
        console.log("Failed to get my stats", error);
      } finally {
        setLoading(false);
      }
    };
    getStatsAsync();
  }, [isRefresh]);

  return (
    <div className="row home-container">
      <div className="col-sm-12 col-md-12 col-lg-8 wrapper__home__container__left">
        <div className="home__container__left">
          <HomeFilter
            onApplyFilter={handleApplyFilter}
            summaryDeal={summaryDeal}
            activitiesObject={activitiesObject}
          />
          {/* Summary Deal */}
          <HomeSummary
            summaryDeal={summaryDeal}
            tabType={tabs}
            summaryActivities={activities}
          />
          {/* Team Member Ranking */}
          <TeamMemberRanking members={members} target={targetSelect} />

          {/* Stats */}
          <HomeStats myStats={myStats} />
        </div>
      </div>

      {/* Calendar, Roamie Point */}
      <div className="col-sm-12 col-lg-4 col-md-12 wrapper__home__container__right">
        <div className="home__container__right">
          <HomeCalendar teamSelect={teamSelect} />
        </div>
        {/* <div
          className="mt-2 home__container__right"
          style={{ padding: "0 !important" }}
        >
          <KPIPointsCollected />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
