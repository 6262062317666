const LeadsType = {
    company: {
      value: 1,
      name: "company",
    },
    person: {
      value: 2,
      name: "person",
    },
  };
  
  export const leadsType = [LeadsType.company, LeadsType.person];
  
  export default LeadsType;
  