import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
/// React router dom
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./chart.css";
/// Css
import "./index.css";
import Footer from "./layouts/Footer";
/// Layout
import Nav from "./layouts/nav";
import CalendarPage from "./pages/Calendar/CalendarPage";
/// Pages
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import ExpiredModal from "./pages/ExpiredRegistration/ExpiredModal";
/// Expired
import ExpiredRegistration from "./pages/ExpiredRegistration/ExpiredRegistration";
/// Dashboard
import Home from "./pages/Home/Home";
/// Leads Management
import LeadsManagement from "./pages/Leads/LeadsManagement";
/// Messages
import Messages from "./pages/Messages/Messages";
/// Messages
import Messenger from "./pages/Messenger/Messenger";
import MyProfile from "./pages/MyProfile/MyProfile";
import SalesTeam from "./pages/SalesTeam/SalesTeam";
/// Service
import Service from "./pages/Service/Service";
/// Settings
import Settings from "./pages/Settings/Settings";
/// Target
import Target from "./pages/Target/Target";
/// Tasks
import Tasks from "./pages/Tasks/Tasks";

const Markup = (props) => {
  const user = useSelector((state) => state.auth.initData);
  const [isExpired, setIsExpired] = useState(false);

  /// Effect handle expired date
  useEffect(() => {
    if (user && user.has_expirate) {
      setIsExpired(user.has_expirate);
    }
  }, [user]);

  const routes = [
    /// Dashboard
    { url: "", component: Home },

    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },

    /// Leads Management
    {
      url: "leads-management",
      component: LeadsManagement,
    },
    /// Sales Team
    {
      url: "sales-team",
      component: SalesTeam,
    },
    /// Calendar
    {
      url: "calendar",
      component: CalendarPage,
    },
    /// My Profile
    {
      url: "my-profile",
      component: MyProfile,
    },
    /// Settings
    {
      url: "settings",
      component: Settings,
    },
    /// Messages
    {
      url: "messages",
      component: Messages,
    },
    /// Messages
    {
      url: "messenger",
      component: Messenger,
    },
    /// Tasks
    {
      url: "tasks",
      component: Tasks,
    },
    /// Target
    {
      url: "targets",
      component: Target,
    },
    /// Athorization code
    {
      url: "callback",
      component: Home,
    },
    /// Expired
    {
      url: "expried",
      component: ExpiredRegistration,
    },
    /// Service
    {
      url: "service",
      component: Service,
    },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");

  return (
    <Router basename="/">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"} ${
          !path ? "right-profile" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
        <ExpiredModal expired={isExpired} />
      </div>
    </Router>
  );
};

export default Markup;
