import Moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { MomentUtil } from "../../../../../utils/moment_util";

const LeadProfileJournalItem = (props) => {
  /// Initials
  const { activity } = props;

  /// Hooks
  const { t, i18n } = useTranslation();

  const getActivityTypeName = (activity) => {
    switch (activity) {
      case 1:
        return t("journal_sms");
      case 2:
        return t("journal_call");
      case 3:
        return t("journal_email");
      case 4:
        return t("journal_appointment");
      case 5:
        return t("journal_task");
    }
  };

  return (
    <div className="row journal-item border-text-input m-1 mb-3">
      <div className="col-4 d-flex align-items-center justify-content-center">
        <span className="text-danger">
          {activity && getActivityTypeName(activity.type)}
        </span>
      </div>
      <div className="col-8 journal-content">
        <div>
          <span className="journal-content__text">
            {activity && activity.description && t(activity.description)}
          </span>
        </div>
        <span className="time mg-top-10">
          {activity &&
            activity.created_at &&
            MomentUtil(activity.created_at, "DD MMM yyyy, HH:mm")}
        </span>
      </div>
    </div>
  );
};

LeadProfileJournalItem.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default LeadProfileJournalItem;
