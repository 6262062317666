import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../../components/Button/AddButton";

const LeadManagementHead = (props) => {
  /// MARK: - Initials;
  const { t } = useTranslation();

  return (
    <div className="leads-management-header">
      <span className="fs-16 font-w500 roamie-text-color-primary">
        {t("leads_management")}
      </span>
      <AddButton text={t("add_leads")} onClick={props.onClickAddLead} />
    </div>
  );
};

LeadManagementHead.propTypes = {
  onClickAddLead: PropTypes.func,
};

LeadManagementHead.defaultProps = {
  onClickAddLead: null,
};

export default LeadManagementHead;
