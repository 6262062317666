import { unwrapResult } from "@reduxjs/toolkit";
import Tabs, { TabPane } from "rc-tabs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "../../../../../node_modules/rc-tabs/assets/index.css";
import LanguageType, { languageType } from "../../../../enum/LanguageType";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { fetch } from "../../Auth/AuthSlide";
import { update } from "../SettingSlice";
import AboutUs from "./AboutUs";
import PasswordForm from "./PasswordForm";
import ProfileForm from "./ProfileForm";

const SettingsTabs = (props) => {
  /// MARK: - Initials
  const { user } = props;
  const [language, setLanguage] = useState(LanguageType.en.value);
  const { t, i18n } = useTranslation();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();

  /// Check language selected
  useEffect(() => {
    const defaultLanguage = user.default_language;
    const language = (defaultLanguage || "").includes("en")
      ? LanguageType.en.value
      : LanguageType.vn.value;
    setLanguage(language);
  }, [user]);

  /// Update language
  const handleUpdateLang = async (value) => {
    i18n.changeLanguage(value);
    try {
      setLoading(true);
      const request = {
        userId: user && user.user_id,
        body: {
          default_language: value,
        },
      };
      const action = update(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        setLanguage(language);
        await dispatch(fetch(user && user.user_id));
      }
    } catch (error) {
      console.log("Failed to update lang", error);
    } finally {
      setLoading(false);
    }
  };

  /// Handle default language
  const checkLanguage = (lang) => {
    const language = languageType.find(
      (item) => item.value === lang || item.sort === lang
    );
    return language.value;
  };

  return (
    <div className="tabs-container ">
      <Tabs
        defaultActiveKey="1"
        className="mx-auto tab-header"
        tabBarGutter={53}
      >
        <TabPane className="mt-3 " tab={t("account_info")} key="1">
          {/* update profile form */}
          <ProfileForm user={user} />
        </TabPane>
        <TabPane className="mt-3" tab={t("change_password")} key="2">
          {/* update password form */}
          <PasswordForm />
        </TabPane>
        <TabPane className="mt-3" tab={t("config")} key="3">
          <div className="col-7 mx-auto ">
            <div className="input-group mb-3 border-text-input">
              <select className="fs-16 form-select form-select-lg bg-transparent">
                <option defaultValue={0}>
                  {t("currency", {
                    currency_code: "USD",
                  })}
                </option>
                <option value="1" className="fs-16">
                  VND
                </option>
                <option value="2" className="fs-16">
                  USD
                </option>
              </select>
            </div>
            <div className="input-group mb-5 border-text-input">
              <select
                className="fs-16 form-select form-select-lg bg-transparent"
                onChange={(e) => handleUpdateLang(e.target.value)}
                // value={language}
              >
                {languageType.map((lang, index) => (
                  <option
                    key={index}
                    value={lang.value}
                    className="fs-16"
                    selected={lang.value.includes(language)}
                  >
                    {t(lang.name)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </TabPane>
        <TabPane className="mt-3" tab={t("about_us")} key="4">
          <AboutUs user={user} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SettingsTabs;
