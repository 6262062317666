import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { search } from "../../../chat/slices/ChatSlice";
import { getRooms } from "../../../chat/slices/IOSlice";
import ChatHeader from "./components/Chat/ChatHeader";
import ChatMessage from "./components/Chat/ChatMessage";
import ChatSendFiles from "./components/Chat/ChatSendFiles";
import ChatTextBox from "./components/Chat/ChatTextBox";
import ChatDetailFile from "./components/Detail/ChatDetailFile";
import ChatDetailInfo from "./components/Detail/ChatDetailInfo";
import ChatDetailNav from "./components/Detail/ChatDetailNav";
import ChatDetailPeople from "./components/Detail/ChatDetailPeople";
import MessageItem from "./components/Messages/MessageItem";
import MessagesActiveChats from "./components/Messages/MessagesActiveChats";
import MessagesHeader from "./components/Messages/MessagesHeader";
import MessagesSearch from "./components/Messages/MessagesSearch";

const Messages = (props) => {
  /// MARK: - Initials;
  const [minimize, setMinimize] = useState(false);
  const [sendFile, setSendFile] = useState(false);
  const { rooms } = useSelector((state) => state.ios);
  const { searchResults } = useSelector((state) => state.chat);
  const { messages } = useSelector((state) => state.ios);
  const dispatch = useDispatch();

  // const [searchResults, setSearchResults] = useState([]);

  /// Handle minimize
  const handleMinimize = () => {
    setMinimize(!minimize);
  };

  const handleSendFile = (bool) => {
    setSendFile(bool);
  };

  useEffect(() => {
    const searchAsync = async () => {
      const searchAction = search({ limit: 30, search: "" });
      await dispatch(searchAction);
    };

    const getRoomsActionAsync = async () => {
      const getRoomsAction = getRooms();
      await dispatch(getRoomsAction);
    };
    searchAsync();
    getRoomsActionAsync();
  }, []);

  const msgs = [...messages].reverse().filter((x) => x !== undefined);

  return (
    <div className="row messages-wrapper__container">
      <div className="wrapper__messages__container col-lg-3">
        <div className="wrapper__messages">
          <MessagesHeader />
          <MessagesActiveChats />
          <MessagesSearch />
          <div className="mg-top-20 messages-list scroll-gray">
            {/*<MessageGroupItem />*/}
            {searchResults &&
              searchResults.map((user, index) => (
                <MessageItem
                  key={user._id}
                  {...user}
                  marginTop={index === 0 ? "mg-top-30" : ""}
                />
              ))}
          </div>
        </div>
      </div>
      {/* Chat */}
      <div
        className={`wrapper__chat__container ${
          minimize ? "col-lg-8" : "col-lg-6"
        }`}
      >
        <div className="wrapper__chat">
          <ChatHeader />
          <div className="chat-box scroll-gray">
            {msgs &&
              msgs.map((msg) => {
                return <ChatMessage key={msg._id} {...msg} />;
              })}
          </div>
          <ChatSendFiles active={sendFile} sendFile={handleSendFile} />
          <ChatTextBox sendFile={handleSendFile} />
        </div>
      </div>
      {/* Detail */}
      <div
        className={`wrapper__detail__container ${
          minimize ? "col-lg-1" : "col-lg-3"
        }`}
      >
        <div className="wrapper__detail">
          <ChatDetailNav minimize={handleMinimize} isMinimize={minimize} />
          <ChatDetailInfo isMinimize={minimize} />
          <ChatDetailPeople isMinimize={minimize} />
          <ChatDetailFile isMinimize={minimize} />
        </div>
      </div>
    </div>
  );
};

Messages.propTypes = {};

export default Messages;
