import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "../chat/slices/ChatSlice";
import ioReducer from "../chat/slices/IOSlice";
import messagesReducer from "../chat/slices/MessageSlice";
import rtcReducer from "../chat/slices/RTCSlice";
import authReducer from "../jsx/pages/Auth/AuthSlide";
import calendarReducer from "../jsx/pages/Calendar/CalendarSlice";
import homeReducer from "../jsx/pages/Home/HomeSlice";
import leadReducer from "../jsx/pages/Leads/LeadSlice";
import myProfileReducer from "../jsx/pages/MyProfile/MyProfileSlice";
import salesTeamReducer from "../jsx/pages/SalesTeam/SalesTeamSlice";
import serviceReducer from "../jsx/pages/Service/ServiceSlice";
import settingReducer from "../jsx/pages/Settings/SettingSlice";
import targetReducer from "../jsx/pages/Target/TargetSlice";
import taskReducer from "../jsx/pages/Tasks/TasksSlice";

const rootReducers = {
  auth: authReducer,
  myProfile: myProfileReducer,
  lead: leadReducer,
  task: taskReducer,
  calendar: calendarReducer,
  setting: settingReducer,
  target: targetReducer,
  salesTeam: salesTeamReducer,
  homeReducer: homeReducer,
  ios: ioReducer,
  chat: chatReducer,
  messages: messagesReducer,
  rtc: rtcReducer,
  service: serviceReducer,
};

const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
