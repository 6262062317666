import React from "react";
import { useField, ErrorMessage } from "formik";

export default function TextField({ holder, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <input
        placeholder={holder}
        className={` ${meta.touched && meta.error && "is-invalid"}`}
        {...field}
        {...props}
      />
      <ErrorMessage component="div" className="error" name={field.name} />
    </>
  );
}
