import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { update } from "../SettingSlice";

export default function PasswordForm() {
  /// MARK: - Initials;
  const { t } = useTranslation();
  const { current } = useSelector((state) => state.auth);
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const formikRef = useRef();

  //handle change password
  const handleChangePassword = async (values) => {
    try {
      const data = {
        userId: current.user_id,
        body: {
          new_password: values.new_password,
          old_password: values.old_password,
        },
      };
      setLoading(true);
      const action = update(data);
      const resultAction = await dispatch(action);
      const updateResponse = unwrapResult(resultAction);
      if (updateResponse.status === 200) {
        enqueueSnackbar(t("update_profile_successful"), {
          persist: false,
          variant: "success",
        });
        formikRef.current.resetForm();
      } else {
        enqueueSnackbar(t(updateResponse.message || ""), {
          persist: false,
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update profile fail", error);
    } finally {
      setLoading(false);
    }
  };
  //password schema
  const passwordSchema = Yup.object({
    old_password: Yup.string().min(6, t("password_length_required")),
    new_password: Yup.string().min(6, t("password_length_required")),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref("new_password")], t("password_not_match"))
      .required(t("confirm_password_length_required")),
  });

  return (
    <Formik
      initialValues={{
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      }}
      validationSchema={passwordSchema}
      onSubmit={handleChangePassword}
      innerRef={formikRef}
    >
      {(formik) => (
        <Form className="col-7 mx-auto">
          {/* Old Password */}
          <div className="input-group mb-3 border-text-input">
            <Field
              type="password"
              className="fs-16 form-control"
              placeholder={t("current_password")}
              name="old_password"
            />
            <ErrorMessage
              className="error"
              component="div"
              name="old_password"
            />
          </div>
          {/* Password */}
          <div className="input-group mb-3 border-text-input">
            <Field
              type="password"
              className="fs-16 form-control"
              placeholder={t("new_password")}
              name="new_password"
            />
            <ErrorMessage
              className="error"
              component="div"
              name="new_password"
            />
          </div>
          {/* Confirm Password */}
          <div className="input-group mb-5 border-text-input">
            <Field
              type="password"
              className="fs-16 form-control"
              placeholder={t("confirm_new_password")}
              name="confirm_new_password"
            />
            <ErrorMessage
              className="error"
              component="div"
              name="confirm_new_password"
            />
          </div>
          <div className="d-grid gap-2 col-5 mx-auto mb-5">
            <button
              className="btn btn-success roamie-text-white-color"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
