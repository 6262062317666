import {
  faBullseye,
  faCalendarAlt,
  faChevronRight,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Field, Form, Formik, yupToFormErrors } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MetricType, { metricsType } from "../../../enum/MetricType";
import SaleType, { salesType } from "../../../enum/SaleType";
import { MomentUtil } from "../../../utils/moment_util";
import { useLoading } from "../../components/Loading/LoadingContext";
import { submitTarget, updateTarget } from "./TargetSlice";
import vi from "date-fns/locale/vi";
import en from "date-fns/locale/en-US";
import { CurrentCountry } from "../../../utils/language_util";
import * as Yup from "yup";

const AddTarget = (props) => {
  /// MARK: -Initials
  const { target } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [metricSelected, setMetricSelected] = useState(MetricType.usd);
  const [saleTypeSelected, setSaleTypeSelected] = useState(SaleType.revenue);

  /// Hooks
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /// UseEffect Load Data
  useEffect(() => {
    if (target) {
      setStartDate(new Date(target.start_date));
      setEndDate(new Date(target.end_date));
      /// Check Metric Selected
      const foundMetric = metricsType.find((metric) => {
        return metric.name == target.metrics;
      });
      if (typeof foundMetric !== "undefined") {
        setMetricSelected(foundMetric);
      }

      /// Check Sale Type Selected
      const foundSaleType = salesType.find((saleType) => {
        return saleType.value == target.sale_type;
      });
      if (typeof foundSaleType !== "undefined") {
        setSaleTypeSelected(foundSaleType);
      }
    }
  }, []);

  /// Handle Validate Metrics
  const handleOnChangeMetrics = (value) => {
    const foundMetric = metricsType.find((metric) => {
      return metric.value == value;
    });
    if (typeof foundMetric !== "undefined") {
      setMetricSelected(foundMetric);
    }
  };

  /// Handle Validate Sales Type
  const handleOnChangeSaleType = (value) => {
    const foundSaleType = salesType.find((saleType) => {
      return saleType.value == value;
    });
    if (typeof foundSaleType !== "undefined") {
      setSaleTypeSelected(foundSaleType);
    }
  };

  /// Handle Submit Add Target
  const handleSubmitAddTarget = async (values) => {
    try {
      setLoading(true);
      const { title, value } = values;
      const request = {
        subject: title,
        description: "",
        value: value,
        metrics: metricSelected.value,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        sale_type: saleTypeSelected.value,
      };
      const action = submitTarget(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message || ""), {
          variant: "success",
        });
        props.isAdd();
        props.onClickCloseDrawer();
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Add Target Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  /// Handle Update Target
  const handleUpdateTarget = async (values) => {
    try {
      setLoading(true);
      const { title, value } = values;
      const data = {
        targetId: target.goal_id,
        request: {
          subject: title,
          description: "",
          value: value,
          metrics: metricSelected.value,
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          sale_type: saleTypeSelected.value,
        },
      };
      const action = updateTarget(data);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message || ""), {
          variant: "success",
        });
        if (response.goal) {
          props.onReloadTarget(response.goal);
        }
        props.onClickCloseDrawer();
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update Target Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="padding-20">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <button onClick={props.onClickCloseDrawer}>
          <FontAwesomeIcon
            icon={faChevronRight}
            size="2x"
            color="#CDD2F3"
            style={{ fontSize: "1.25rem" }}
          />
        </button>
        <span className="fs-16 font-w500 roamie-text-color-primary">
          {target ? t("update_target") : t("add_target")}
        </span>
        <button>
          <FontAwesomeIcon icon={faPen} size="1x" color="#CDD2F3" />
        </button>
      </div>

      {/* Form */}
      <div className="form-add-target mg-top-40">
        <Formik
          initialValues={{
            title: (target && target.subject) || "",
            value: (target && target.value) || "",
            metrics: 1,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required(t("title_is_required")),
            value: Yup.string().required(t("value_is_required"))
              .matches(/^[0-9]+$/, t("must_be_only_digits"))
              .max(10, t("must_be_exactly_9_digits")),
          })}
          onSubmit={(values) => {
            target ? handleUpdateTarget(values) : handleSubmitAddTarget(values);
          }}
        >
          {({ errors, touched, values }) => (
            <Form className="col-10 mx-auto mg-top-40">
              {/* Title */}
              <div className="input-group mb-3 border-text-input d-flex align-items-center justify-content-center roamie-button-background-color">
                <Field
                  type="text"
                  className="form-control text-light placeholder--white-color task-title fs-16 font-w400"
                  placeholder={t("add_a_campaign_title")}
                  name="title"
                />
                <div className="px-3">
                  <FontAwesomeIcon icon={faPen} size="1x" color="white" />
                </div>
                <ErrorMessage component="div" className="error" name="title" />
              </div>

              {/* Value */}
              <div className="input-group mb-3 border-text-input d-flex align-items-center">
                <Field
                  type="text"
                  className="form-control roamie-text-color-primary fs-16 font-w400"
                  placeholder={t("value")}
                  name="value"
                />
                <div className="px-3">
                  <FontAwesomeIcon
                    icon={faBullseye}
                    size="1x"
                    color="#B5BBDE"
                  />
                </div>
                <ErrorMessage component="div" className="error" name="value" />
              </div>

              {/* Start Date */}
              <DatePicker
                className="custom__date__picker px-3 mb-3"
                selected={startDate}
                locale={CurrentCountry().includes("vn") ? vi : en}
                onChange={(date) => {
                  if (endDate && date > endDate) {
                    setStartDate(date);
                    setEndDate(date);
                  } else {
                    setStartDate(date);
                  }
                }}
                showTimeInput={false}
                customInput={
                  <div className="input-group border-text-input d-flex justify-content-between align-items-center">
                    <span>
                      {startDate && MomentUtil(startDate, "DD MMM YYYY")}
                    </span>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="1x"
                      color="#B5BBDE"
                    />
                  </div>
                }
              />

              {/* End Date */}
              <DatePicker
                className="custom__date__picker px-3 mb-3"
                selected={endDate}
                locale={CurrentCountry().includes("vn") ? vi : en}
                onChange={(date) => {
                  if (startDate && startDate > date) {
                    alert(
                      t("the_end_date_cannot_be_earlier_than_the_start_date")
                    );
                  } else {
                    setEndDate(date);
                  }
                }}
                showTimeInput={false}
                customInput={
                  <div className="input-group border-text-input d-flex justify-content-between align-items-center">
                    <span>{endDate && MomentUtil(endDate, "DD MMM YYYY")}</span>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="1x"
                      color="#B5BBDE"
                    />
                  </div>
                }
              />

              {/* Metrics */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <Field
                  as="select"
                  name="metric"
                  validate={handleOnChangeMetrics}
                  className="placeholder-color form-select form-select-lg bg-transparent"
                >
                  {metricsType &&
                    metricsType.length > 0 &&
                    metricsType.map((metric, index) => (
                      <option
                        defaultValue={
                          metricSelected && metricSelected.value == metric.value
                        }
                        value={metric.value}
                        key={index}
                      >
                        {metric.name || ""}
                      </option>
                    ))}
                </Field>
              </div>

              {/* Sale Type */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <Field
                  as="select"
                  name="sale_type"
                  validate={handleOnChangeSaleType}
                  className="placeholder-color form-select form-select-lg bg-transparent"
                >
                  {salesType &&
                    salesType.length > 0 &&
                    salesType.map((saleType, index) => (
                      <option
                        defaultValue={
                          saleTypeSelected &&
                          saleTypeSelected.value == saleType.value
                        }
                        value={saleType.value}
                        key={index}
                      >
                        {t(saleType.name || "")}
                      </option>
                    ))}
                </Field>
              </div>

              {/* Save */}
              <div className="d-grid gap-2 col-6 mx-auto mb-5">
                <button
                  className="btn btn-success roamie-text-white-color"
                  type="submit"
                >
                  {t("save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

AddTarget.propTypes = {
  target: PropTypes.object,
  onClickCloseDrawer: PropTypes.func,
};

export default AddTarget;
