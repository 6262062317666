import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function Interface({
  audio,
  video,
  peer,
  isMaximized,
  isScreen,
}) {
  /// MARK: - Initials;
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!audio) return;
    if (audio) audioRef.current.srcObject = audio;
  }, [audio]);

  useEffect(() => {
    if (!video) return;
    if (video) videoRef.current.srcObject = video;
  }, [video]);

  const renderUerAvatar = () => {
    const username =
      (peer && peer.firstName && peer.firstName.substr(0, 1)) ||
      (peer && peer.email && peer.email.substr(0, 1)) ||
      "";
    return (
      <div
        className="d-flex justify-content-center align-items-center bg-dark rounded-circle"
        style={{ width: "100px", height: "100px" }}
      >
        {<span className="fs-20 text-light">{username || ""}</span>}
      </div>
    );
  };

  return (
    <div className="interface uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-height-1-1">
      {audio && (
        <audio
          ref={audioRef}
          onLoadedMetadata={() => audioRef.current.play()}
          className="remote-audio"
          playsInline
          controls={false}
          hidden={true}
          data-user={peer}
        />
      )}
      {video && (
        <video
          ref={videoRef}
          onLoadedMetadata={() => videoRef.current.play()}
          className="remote-video"
          playsInline
          controls={false}
          hidden={false}
          data-user={peer}
          style={{ objectFit: !isMaximized || isScreen ? "contain" : "cover" }}
        />
      )}
      {!video && (
        <div className="remote-peer">
          <div className="name text-center">
            {(peer && peer.firstName && peer.firstName) || ""}{" "}
            {(peer && peer.lastName && peer.lastName) || ""}
          </div>
          {renderUerAvatar()}
          <div className="status">
            {!video && !audio ? t("no_audio") : t("audio_only")}
          </div>
        </div>
      )}
    </div>
  );
}
