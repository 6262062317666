import { SnackbarProvider } from "notistack";
import { React, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import App from "./App";
import store from "./app/store";
import "./i18nextConf";
import { LoadingProvider } from "./jsx/components/Loading/LoadingContext";
import reportWebVitals from "./reportWebVitals";
import init from "./init";

https: ReactDOM.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <BrowserRouter>
        <LoadingProvider>
          <SimpleReactLightbox>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </SimpleReactLightbox>
        </LoadingProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
reportWebVitals();

init();