import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import taskApi from "../../../api/taskApi";

export const getTasks = createAsyncThunk(
  "/api/getAllTasks",
  async (payload) => {
    const tasksResponse = await taskApi.getTasks(payload);
    return tasksResponse.tasks || [];
  }
);

export const addNewTask = createAsyncThunk(
  "/api/submitTasks",
  async (payload) => {
    const taskResponse = await taskApi.submitTask(payload);
    return taskResponse;
  }
);

export const updateTask = createAsyncThunk(
  "/api/updateTasks",
  async (payload) => {
    const taskResponse = await taskApi.updateTask(payload);
    return taskResponse;
  }
);

export const deleteTask = createAsyncThunk(
  "/api/deleteTasks",
  async (payload) => {
    const deleteResponse = await taskApi.deleteTask(payload);
    return deleteResponse;
  }
);
const taskSlice = createSlice({
  name: "TaskSlice",
  initialState: {
    tasks: [],
  },
  reducers: {},
  extraReducers: {
    [getTasks.fulfilled]: (state, action) => {
      state.tasks = action.payload;
    },
  },
});

const { redecuer } = taskSlice;
export default redecuer;
