import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import iconMessage from "../../../../assets/icons/icon_chat.png";
import iconPhone from "../../../../assets/icons/icon_phone.png";
import iconZalo from "../../../../assets/icons/icon_zalo.png";
import parsePhoneNumber from "libphonenumber-js";

const LeadContactNumberItem = (props) => {
  /// MARK: - Initials;
  const { number } = props;
  const [phoneNumber, setPhoneNumber] = useState(null);

  useEffect(() => {
    const p =
      number && `${number.country_phone_code || ""}${number.number || ""}`;
    const phoneNumber = parsePhoneNumber(p);
    if (phoneNumber) {
      setPhoneNumber(p);
    }
  }, []);

  if (phoneNumber) {
    return (
      <div className="lead-contact-number">
        <div className="lead-contact-number__phone">
          <a href={`tel:${phoneNumber}`} target="_blank">
            <img src={iconPhone} className="icon-size c-pointer" />
          </a>
          <span className="fs-15 font-w500">{phoneNumber}</span>
        </div>
        <div className="lead-contact-number__actions">
          <a
            href={`https://wa.me/${phoneNumber}`}
            target="_blank"
            className="mg-right-10"
          >
            <img src={iconMessage} className="icon-size c-pointer" />
          </a>
          <a href={`https://zalo.me/${phoneNumber}`} target="_blank">
            <img src={iconZalo} className="icon-zalo c-pointer" />
          </a>
        </div>
      </div>
    );
  }
  return <></>;
};

LeadContactNumberItem.propTypes = {
  number: PropTypes.object,
};

export default LeadContactNumberItem;
