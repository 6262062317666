import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import myProfileApi from "../../../api/myProfileApi";

export const getMyStats = createAsyncThunk(
  "/api/get-stats",
  async (payload) => {
    const myStatsResponse = await myProfileApi.getStats(payload);
    return myStatsResponse;
  }
);

const myProfileSlice = createSlice({
  name: "MyProfileSlice",
  initialState: {
    current: {},
    myStats: {},
  },
  reducers: {},
  extraReducers: {
    [getMyStats.fulfilled]: (state, action) => {
      state.myStats = action.payload;
    },
  },
});

const { reducer, actions } = myProfileSlice;
export default reducer;
