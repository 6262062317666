import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { rolesType } from "../../../../enum/RoleType";
import { statusType } from "../../../../enum/StatusType";

const SalesTeamFilter = (props) => {
  /// Initials
  const { searchKey, roleFilter, statusFilter } = props;
  const [statusSelect, setStatus] = useState({ name: "all", value: -1 });
  const [roles, setRoles] = useState({ name: "all", value: -1 });

  /// Hooks
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="row sale-team-filter-container mg-top-40">
        <div className="col col-md-4 col-lg-4">
          <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
            {t("search")}
          </span>
          <div className="h-50 search-sales-team mg-top-14 d-flex justify-content-start align-items-center">
            <i className="fs-13 flaticon-381-search-2 roamie-text-color-primary" />
            <input
              type="text"
              className="form-group placeholder-color fs-13 font-w400 roamie-text-color-primary"
              placeholder={t("search")}
              onChange={(e) => searchKey(e.target.value.trim())}
            />
          </div>
        </div>

        <div className="col col-md-2 col-lg-2">
          <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
            {t("user_type")}
          </span>
          <Dropdown className="roamie-drop-down mg-top-14 h-50">
            <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
              <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
                {t(roles.name)}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setRoles({ name: "all", value: -1 });
                  roleFilter({ name: "all", value: -1 });
                }}
              >
                {t("all")}
              </Dropdown.Item>
              {rolesType &&
                rolesType.map((role, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setRoles(role);
                      roleFilter(role);
                    }}
                  >
                    {t(role.name)}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col col-md-2 col-lg-2">
          <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
            {t("status")}
          </span>
          <Dropdown className="roamie-drop-down mg-top-14 h-50">
            <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
              <span className="fs-13 font-w400 roamie-text-color-primary">
                {t(statusSelect.name)}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setStatus({ name: "all", value: -1 });
                  statusFilter({ name: "all", value: -1 });
                }}
              >
                {t("all")}
              </Dropdown.Item>
              {statusType &&
                statusType.map((status, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setStatus(status);
                      statusFilter(status);
                    }}
                  >
                    {t(status.name)}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

SalesTeamFilter.propTypes = {};

export default SalesTeamFilter;
