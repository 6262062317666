import axiosClient from "./axiosClient";

const settingsApi = {
  userUpdate: (params, userId) => {
    const url = `/api/users/${userId}`;
    return axiosClient.put(url, params);
  },
  avatarUpdate: (params) => {
    const url = "/api/users/change-avatar";
    return axiosClient.post(url, params);
  },
  coverUpate: (params) => {
    const url = "/api/users/change-cover";
    return axiosClient.post(url, params);
  },
  aboutUsUpdate: (params, userId) => {
    const url = `/api/users/${userId}`;
    return axiosClient.put(url, params);
  },
};

export default settingsApi;
