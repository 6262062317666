import React from "react";
import PropTypes from "prop-types";
import ChatMessageAttachTime from "./ChatMessageAttachTime";

const ChatSendItem = (props) => {
  const { author, content, date, isMine } = props;
  return (
    <div className="chat-send-item-container">
      <div className="chat-send-item">
        <span className="fs-14 roamie-text-color-primary">{content}</span>
      </div>
      <img
        src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
        className="chat-send-avatar mg-left-5"
      />
      {/*<ChatMessageAttachTime {...props} />*/}
    </div>
  );
};

ChatSendItem.propTypes = {};

export default ChatSendItem;
