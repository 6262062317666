import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import GlobalImages from "../../../../constants/GlobalImages";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { getMyStats } from "../MyProfileSlice";
import MyProfileStatItem from "./MyProfileStatItem";

const MyProfileStats = (props) => {
  /// Initial
  const [myStats, setMyStats] = useState({});

  /// Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    const getMyStatsAsync = async () => {
      try {
        setLoading(true);
        const action = getMyStats({});
        const resultAction = await dispatch(action);
        const myStatsResponse = unwrapResult(resultAction);
        setMyStats(myStatsResponse);
      } catch (error) {
        console.log("Failed to get my stats", error);
      } finally {
        setLoading(false);
      }
    };
    getMyStatsAsync();
  }, []);

  if (Object.entries(myStats).length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="mg-top-50">
        <h2 id="stats" className="fs-16 font-w500 roamie-text-color-primary">
          {t("stats")}
        </h2>
        <div className="row mg-top-30">
          <MyProfileStatItem
            name={t("deals_closed")}
            value={myStats && myStats.deals && myStats.deals.closed}
            iconName={GlobalImages.iconDeals}
          />
          <MyProfileStatItem
            name={t("deals_closing")}
            value={myStats && myStats.deals && myStats.deals.closing}
            iconName={GlobalImages.iconDeals}
          />
          <MyProfileStatItem
            name={t("person")}
            value={myStats && myStats.leads && myStats.leads.invidid}
            iconName={GlobalImages.iconLeads}
          />
          <MyProfileStatItem
            name={t("company")}
            value={myStats && myStats.leads && myStats.leads.compa}
            iconName={GlobalImages.iconLeads}
          />
          <MyProfileStatItem
            name={t("appointments_made")}
            value={
              myStats &&
              myStats.StatsAppointment &&
              myStats.StatsAppointment.madded
            }
            iconName={GlobalImages.iconAppointments}
          />
          <MyProfileStatItem
            name={t("appointments_empty")}
            value={
              myStats &&
              myStats.StatsAppointment &&
              myStats.StatsAppointment.empty
            }
            iconName={GlobalImages.iconAppointments}
          />
          <MyProfileStatItem
            name={t("tasks_pending")}
            value={myStats && myStats.tasks && myStats.tasks.uncomp}
            iconName={GlobalImages.iconTasks}
          />
          <MyProfileStatItem
            name={t("tasks_completed")}
            value={myStats && myStats.tasks && myStats.tasks.complete}
            iconName={GlobalImages.iconTasks}
          />
        </div>
      </div>
    </>
  );
};

MyProfileStats.propTypes = {};

export default MyProfileStats;
