import React, { useRef } from "react";
import PropTypes from "prop-types";
import AddButton from "../../../components/Button/AddButton";
import { useTranslation } from "react-i18next";
import { importSaleTeam } from "../SalesTeamSlice";
import { useSnackbar } from "notistack";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const SalesTeamHead = (props) => {
  /// Hooks
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, setLoading } = useLoading();
  const dispatch = useDispatch();
  /// Ref
  const refFile = useRef(null);

  /// Handle upload file
  const handleFileUpload = async (e) => {
    let formData = new FormData();
    formData.append("import", e.target.files[0]);
    try {
      setLoading(true);
      const action = importSaleTeam(formData);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t("import_sale_teams_successful"), {
          persist: false,
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
      } else {
        enqueueSnackbar(t("check_your_files_and_try_again"), {
          persist: false,
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Failed to import sale team", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sales-team-head d-flex justify-content-between align-items-center">
      <span className="fs-16 font-w500 roamie-text-color-primary">
        {t("sales_team")}
      </span>
      <div className="d-flex">
        <AddButton text={t("invite")} onClick={props.onClickInvite} />
        <div className="ms-2">
          <AddButton
            text={t("import_sale_teams")}
            onClick={() => refFile.current.click()}
          />
          <input
            hidden
            type={"file"}
            accept=".xlsx"
            ref={refFile}
            onChange={handleFileUpload}
          />
        </div>
      </div>
    </div>
  );
};

SalesTeamHead.propTypes = {
  onClickInvite: PropTypes.func,
};

SalesTeamHead.defaultProps = {
  onClickInvite: null,
};

export default SalesTeamHead;
