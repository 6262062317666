import React from "react";

const SocialButton = (props) => {
  return (
    <div className="social-buttons">
      <a className="btn" style={{ background: `${props.color}` }}>
        <i className={`${props.icon}`}></i>
      </a>
    </div>
  );
};

export default SocialButton;
