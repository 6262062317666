import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AvatarChat from "../AvatarChat";

export default function LittleInterface({ audio, video, peer, isMaximized }) {
  /// MARK: - Initials;
  const audioRef = useRef(null);
  const videoRef = useRef(null);

  /// MARK: - Hooks;
  const { t } = useTranslation();

  useEffect(() => {
    if (!audio) return;
    if (audio) audioRef.current.srcObject = audio;
  }, [audio]);

  useEffect(() => {
    if (!video) return;
    if (video) videoRef.current.srcObject = video;
  }, [video]);

  return (
    <div className="little-interface uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-height-1-1">
      {audio && (
        <audio
          ref={audioRef}
          onLoadedMetadata={() => audioRef.current.play()}
          className="remote-audio"
          playsInline
          controls={false}
          hidden={true}
          data-user={peer}
        />
      )}
      {video && (
        <video
          ref={videoRef}
          onLoadedMetadata={() => videoRef.current.play()}
          className="remote-video"
          playsInline
          controls={false}
          hidden={false}
          data-user={peer}
          style={{ objectFit: isMaximized ? "cover" : "contain" }}
        />
      )}
      {!video && (
        <div className="remote-peer">
          <div className="name text-center">
            {peer.firstName || ""} {peer.lastName || ""}
          </div>
          <AvatarChat user={peer} />
          <div className="status">
            {!video && !audio ? t("no_audio") : t("audio_only")}
          </div>
        </div>
      )}
    </div>
  );
}
