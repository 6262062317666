import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import avatarHolder from "../../../assets/icons/avatar.png";
import { useLoading } from "../../components/Loading/LoadingContext";
import { logout } from "../../pages/Auth/AuthSlide";

const Header = ({ onNote }) => {
  /// Hooks
  const { t } = useTranslation();
  const { initData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { setLoading } = useLoading();

  const handleLogout = async () => {
    setLoading(true);
    await dispatch(logout());
    await history.push("/");
    history.go(0);
    setLoading(false);
  };

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse d-flex justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar mt-4"
                style={{ textTransform: "capitalize" }}
              >
                <span className="welcome-back-title">{t("welcome_back")}</span>
                <span className="welcome-back-name">
                  {((initData && initData.first_name) || "") +
                    " " +
                    ((initData && initData.last_name) || "") +
                    " "}
                  👋
                </span>
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  className="nav-link i-false"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="image-cropper d-flex justify-content-center">
                    <img
                      src={
                        initData && initData.profile_image
                          ? initData.profile_image
                          : avatarHolder
                      }
                      alt="avatar"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <Link
                    to="/my-profile"
                    className="dropdown-item ai-icon d-flex align-items-center"
                  >
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2 mg-left-5">{t("profile")} </span>
                  </Link>
                  <button
                    to=""
                    className="dropdown-item ai-icon align-items-center"
                    onClick={handleLogout}
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ml-2 mg-left-5">{t("logout")}</span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
