import { unwrapResult } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { useSnackbar } from "notistack";
import React from "react";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import setAuthToken from "../../../../chat/setAuthToken";
import { setCurrentUser } from "../../../../chat/slices/ChatSlice";
import { nodeLogin } from "../../../../chat/slices/NodeAuthSlice";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { googleSignIn } from "../AuthSlide";
import SocialButton from "./SocialButton";

export default function SocialGroupButtons() {
  /// MARK: - Initials;
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const setUser = useGlobal("user")[1];

  /// Google Sign In
  const onSuccess = async (response) => {
    try {
      setLoading(true);
      const idToken = response.tokenId;
      const email = response.profileObj.email;
      const familyName = response.profileObj.familyName;
      const giveName = response.profileObj.giveName;
      const action = googleSignIn({
        email: email,
        isMobile: false,
        platform: 3,
        socialAccountType: 3,
        socialToken: idToken,
        userName: `${giveName} ${familyName}`,
      });
      const resultAction = await dispatch(action);
      const userResponse = unwrapResult(resultAction);
      if (userResponse.user_id !== null) {
        enqueueSnackbar(t(userResponse.message || ""), {
          persist: false,
          variant: "success",
        });
        const nodeLoginAction = nodeLogin({ user: userResponse.user });
        const resultAct = await dispatch(nodeLoginAction);
        const res = unwrapResult(resultAct);
        setAuthToken(res.data.token);
        await dispatch(setCurrentUser(jwtDecode(res.data.token)));

        setUser(jwtDecode(res.data.token));
        history.go(0);
      } else {
        enqueueSnackbar(t("something_went_wrong") || "", {
          persist: false,
          variant: "success",
        });
      }
    } catch (error) {
      console.log("Google Sign In Error:", error);
      enqueueSnackbar(error || "", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onFailure = (response) => {
    enqueueSnackbar(t("something_went_wrong"), {
      variant: "error",
    });
    console.log("Failed to google sign in", response);
  };

  return (
    <div className="social-btn-group d-flex justify-content-center">
      <div className="d-flex">
        {/* <SocialButton icon="fa fa-facebook-f" color="#4272CF" /> */}
        {/* <SocialButton icon="fa fa-linkedin" color="#0E76A8" /> */}
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <SocialButton icon="fa fa-google" color="#DD4B39" />
            </button>
          )}
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          scope={
            "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar"
          }
          cookiePolicy={"single_host_origin"}
        />
      </div>
    </div>
  );
}
