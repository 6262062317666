import axiosClient from "./axiosClient";

const salesTeamApi = {
  inviteTeam: (params) => {
    const url = "/api/invite-team";
    return axiosClient.post(url, params);
  },
  getMyTeam: () => {
    const url = "/api/my-team";
    return axiosClient.post(url);
  },
  updateTeam: (params) => {
    const url = "/api/update-team";
    return axiosClient.post(url, params);
  },
  filterMyteam: (params) => {
    const url = "/api/my-team";
    return axiosClient.post(url, params);
  },
  importSaleTeams: (params) => {
    const url = "/api/import-team";
    return axiosClient.post(url, params);
  },
};

export default salesTeamApi;
