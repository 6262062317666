import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import leadApi from "../../../api/leadApi";

export const getLeadsManagement = createAsyncThunk(
  "api/classes/Lead",
  async () => {
    const leadsManagementResponse = await leadApi.getLeadsManagement();
    return leadsManagementResponse.results || [];
  }
);

export const getLeadsPeople = createAsyncThunk(
  "api/classes/Lead/People",
  async (payload) => {
    const leadsPeople = await leadApi.getLeadsPeople(payload);
    return leadsPeople.results || [];
  }
);

export const getLeadsCompany = createAsyncThunk(
  "api/classes/Lead/Company",
  async (payload) => {
    const leadsCompany = await leadApi.getLeadsCompany(payload);
    return leadsCompany.results || [];
  }
);

export const getActivities = createAsyncThunk(
  "api/activities/get_all_activities",
  async (payload) => {
    const { leadId } = payload;
    const activitiesResposne = await leadApi.getActivities(leadId);
    return activitiesResposne.activities || [];
  }
);

export const getNotes = createAsyncThunk(
  "api/classes/Note",
  async (payload) => {
    const { leadId } = payload;
    const notesResponse = await leadApi.getNotes(leadId);
    return notesResponse.notes || [];
  }
);

export const getFiles = createAsyncThunk(
  "api/requests/Lead/getFiles",
  async (payload) => {
    const filesResponse = await leadApi.getFiles(payload);
    return filesResponse.files || [];
  }
);

export const getDeals = createAsyncThunk(
  "api/classes/getDeals",
  async (payload) => {
    const dealsResponse = await leadApi.getDeals(payload);
    return dealsResponse.deals || [];
  }
);

export const addLeadCompany = createAsyncThunk(
  "api/requests/Lead/addLeadCompany",
  async (payload) => {
    const companyResponse = await leadApi.addLeadsManagement(payload);
    return companyResponse;
  }
);

export const addLeadPeople = createAsyncThunk(
  "api/requests/Lead/addLeadPeople",
  async (payload) => {
    const peopleResponse = await leadApi.addLeadsManagement(payload);
    return peopleResponse;
  }
);

export const addNote = createAsyncThunk("api/classes/Note", async (payload) => {
  const response = await leadApi.addNote(payload);
  return response;
});

export const updateNote = createAsyncThunk(
  "api/classes/Note/Update",
  async (payload) => {
    const { noteId, title, content } = payload;
    const request = {
      title: title,
      content: content,
    };
    const response = await leadApi.updateNote(request, noteId);
    return response;
  }
);

export const uploadFile = createAsyncThunk(
  "api/classes/Lead/uploadFile",
  async (payload) => {
    const response = await leadApi.uploadFile(payload);
    return response;
  }
);

export const submitDeal = createAsyncThunk(
  "api/classes/Deal",
  async (payload) => {
    const response = await leadApi.submitDeal(payload);
    return response;
  }
);

export const updateLeadDeal = createAsyncThunk(
  "api/classes/Deal/Update",
  async (payload) => {
    const { deal_id, args } = payload;
    const response = await leadApi.updateDeal(args, deal_id);
    return response;
  }
);

export const deleteLead = createAsyncThunk(
  "api/requests/Lead/deleteLeads",
  async (payload) => {
    const response = await leadApi.deleteLead(payload);
    return response;
  }
);

export const uploadLeadAvatar = createAsyncThunk(
  "api/classes/Lead/uploadImage",
  async (payload) => {
    const response = await leadApi.uploadLeadAvatar(payload);
    return response;
  }
);

export const updateLeadModel = createAsyncThunk(
  "api/classes/Lead/Update",
  async (payload) => {
    const { request, leadId } = payload;
    const response = await leadApi.updateLead(request, leadId);
    return response;
  }
);

export const submitActivity = createAsyncThunk(
  "api/activities/submitActivity",
  async (payload) => {
    const response = await leadApi.submitActivity(payload);
    return response;
  }
);

export const leadFilter = createAsyncThunk(
  "api/classes/Lead",
  async (payload) => {
    const response = await leadApi.filterLead(payload);
    return response;
  }
);
const leadSlice = createSlice({
  name: "LeadSlice",
  initialState: {
    leadsManagement: [],
    activities: [],
    notes: [],
    files: [],
  },
  reducers: {},
  extraReducers: {
    [getLeadsManagement.fulfilled]: (state, action) => {
      state.leadsManagement = action.payload;
    },
    [getActivities.fulfilled]: (state, action) => {
      state.activities = action.payload;
    },
    [getNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
    [getFiles.fulfilled]: (state, action) => {
      state.files = action.payload;
    },
  },
});

const { reducer, actions } = leadSlice;
export default reducer;
