import PropTypes from "prop-types";
import React from "react";
import { Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LeadManagementItem from "./LeadManagementItem";

const LeadManagementTable = (props) => {
  /// Hooks
  const { t } = useTranslation();
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return (
    <Col lg={12}>
      <Table responsive striped>
        <thead className="table-head">
          <tr>
            <th></th>
            <th>
              <span>{t("name")}</span>
            </th>
            {/* <th>
              <span>{t("role")}</span>
            </th> */}
            <th>
              <span>{t("lead_type")}</span>
            </th>
            <th>
              <span>{t("email")}</span>
            </th>
            <th>
              <span>{t("phone_number")}</span>
            </th>
            <th>
              <span>{t("status")}</span>
            </th>
          </tr>
        </thead>
        <tbody id="leads">
          {/* Filter lead with alphabets character */}
          {alphabets.split("").map((char) => {
            return (
              props.leads &&
              props.leads.length > 0 &&
              props.leads
                .filter((lead) =>
                  lead.name.toLowerCase().startsWith(char.toLowerCase())
                )
                .map((lead, index) => (
                  <React.Fragment key={index}>
                    {index === 0 ? (
                      <tr className="spacer">
                        <td className="alphabet-sort">{char}</td>
                      </tr>
                    ) : null}
                    <LeadManagementItem
                      key={lead.objectId}
                      lead={lead}
                      onClick={(lead) => props.onClickLeadProfile(lead)}
                      reload={props.reload}
                    />
                  </React.Fragment>
                ))
            );
          })}
        </tbody>
      </Table>
    </Col>
  );
};

LeadManagementTable.propTypes = {
  onClickLeadProfile: PropTypes.func,
  leads: PropTypes.array,
};

LeadManagementTable.defaultProps = {
  onClickLeadProfile: null,
  leads: [],
};

export default LeadManagementTable;
