import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  var d = new Date();
  const { t } = useTranslation();
  const year = d.getFullYear().toString();
  return (
    <div className="footer">
      <div className="copyright">
        <p className="fs-13 font-w400 roamie-text-color-primary op7 text-center">
          {t("footer_copyright", { date: year })}
        </p>
      </div>
    </div>
  );
};

export default Footer;
