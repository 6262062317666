import Moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MomentUtil } from "../../../../utils/moment_util";
import CalendarEvent from "./CalendarEvent";
import CalendarTasks from "./CalendarTasks";

const CalendarEvents = (props) => {
  /// Initials
  const { appointments, tasks, date, taskClick } = props;
  const [tabs, setTabs] = useState(1);
  let day = props.date.getDate();
  let month = props.date.toLocaleString("default", { month: "long" });
  /// Hooks
  const { t, i18n } = useTranslation();

  /// Tab Filter
  const renderTabFilters = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <h2
          className={`${
            tabs === 1 ? "fs-16 font-w600" : "fs-16 font-w400"
          } roamie-text-color-primary pointer mg-right-10`}
          onClick={() => setTabs(1)}
        >
          {t("all")}
        </h2>
        <h2
          className={`${
            tabs === 2 ? "fs-16 font-w600" : "fs-16 font-w400"
          } roamie-text-color-primary pointer mg-right-10`}
          onClick={() => setTabs(2)}
        >
          {t("tasks")}
        </h2>
        <h2
          className={`${
            tabs === 3 ? "fs-16 font-w600" : "fs-16 font-w400"
          } roamie-text-color-primary pointer mg-right-10`}
          onClick={() => setTabs(3)}
        >
          {t("appointments")}
        </h2>
      </div>
    );
  };

  /// Title Date Selected
  const renderTitleDate = () => {
    return (
      <h1 className="fs-23 font-w500 roamie-text-color-primary">
        {props.date && MomentUtil(props.date, "DD MMM")}
      </h1>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {renderTitleDate()}
        {renderTabFilters()}
      </div>
      <div className="mg-top-40 calendar-events">
        {tabs === 1 || tabs === 3 ? (
          appointments &&
          appointments.length > 0 &&
          appointments.map((appt) => (
            <CalendarEvent
              key={appt.apmt_id}
              appointment={appt}
              onClick={(appointment) => props.onClick(appointment)}
            />
          ))
        ) : (
          <></>
        )}
        {tabs === 1 || tabs === 2 ? (
          tasks &&
          tasks.length > 0 &&
          tasks
            .filter(
              (task) =>
                Moment(date).format("YYYY-MM-DD") ==
                Moment(task.end_date).format("YYYY-MM-DD")
            )
            .map((task) => (
              <CalendarTasks
                key={task.objectId}
                task={task}
                onClick={(task) => taskClick(task)}
              />
            ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

CalendarEvents.propTypes = {
  appointments: PropTypes.array,
  onClick: PropTypes.func,
};

CalendarEvent.defaultProps = {
  appointments: [],
};

export default CalendarEvents;
