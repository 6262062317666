import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LeadStatus = (props) => {
  /// Initials
  const { name } = props;

  /// Hooks
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="lead-status">
        <span className="fs-14 font-w500">{t(name || "")}</span>
      </div>
    </>
  );
};

LeadStatus.propTypes = {
  name: PropTypes.string.isRequired,
};

export default LeadStatus;
