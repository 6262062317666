import React from "react";
import PropTypes from "prop-types";

const ChatSendButton = (props) => {
  return (
    <div className="chat-send-button">
      <i className="fa fa-paper-plane" style={{ color: 'white' }}></i>
    </div>
  );
};

ChatSendButton.propTypes = {};

export default ChatSendButton;
