import StorageKeys from "../constants/StorageKeys";

export const CurrentLanguage = () => {
  const languageKey = localStorage.getItem(StorageKeys.LANGUAGE_KEY) || "vi-VN";
  return languageKey;
};

export const CurrentCountry = () => {
  const currentLanguage = CurrentLanguage();
  if ("vi-VN".includes(currentLanguage)) {
    return "vn";
  }
  return "us";
};
