import React from "react";
import { useTranslation } from "react-i18next";
import { BsChat, BsSearch } from "react-icons/bs";
import { useGlobal } from "reactn";

const MessageMenu = (props) => {
  /// MARK: - Initials;
  const { t } = useTranslation();
  const [nav, setNav] = useGlobal("nav");

  return (
    <div className="mg-top-10 d-flex flex-nowrap justify-content-between message-menu">
      <div
        className={`flex-column d-flex justify-content-between align-items-center mt-1 mb-1 menu-item ${
          nav === "rooms" ? "active" : ""
        }`}
        onClick={() => {
          setNav("rooms");
        }}
      >
        <BsChat size={"17px"} />
        <span className="fs-13 font-w500 roamie-text-color-primary d-flex justify-content-center">
          {t("rooms")}
        </span>
      </div>
      <div
        className={`flex-column d-flex justify-content-between align-items-center mt-1 mb-1 menu-item ${
          nav === "search" ? "active" : ""
        }`}
        onClick={() => {
          setNav("search");
        }}
      >
        <BsSearch size={"17px"} />
        <span className="fs-13 font-w500 roamie-text-color-primary d-flex justify-content-center">
          {t("search_member")}
        </span>
      </div>
      {/* <div className={`flex-column d-flex justify-content-between align-items-center mt-1 mb-1 menu-item ${nav === 'favorites' ? 'active': ''}`} onClick={() => {
            setNav("favorites");
        }}>
            <BsStar size={"17px"} />
            <span className="fs-13 font-w500 roamie-text-color-primary d-flex justify-content-center">Favorites</span>
        </div> */}
      {/* <div className={`flex-column d-flex justify-content-between align-items-center mt-1 mb-1 menu-item ${nav === 'meetings' ? 'active': ''}`} onClick={() => {
            setNav("meetings");
        }}>
            <BsPeople size={"17px"} />
            <span className="fs-13 font-w500 roamie-text-color-primary d-flex justify-content-center" >Meetings</span>
        </div> */}
    </div>
  );
};

MessageMenu.propTypes = {};

export default MessageMenu;
