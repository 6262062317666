import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import avatar from "../../../../assets/images/user.png";
import { MomentUtil } from "../../../../utils/moment_util";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { inviteTeam, updateSaleTeam } from "../SalesTeamSlice";

const SalesTeamItem = (props) => {
  /// MARK: - Initials
  const { team } = props;
  const [teamState, setTeamState] = useState(team);

  /// MARK: - Hooks
  const { t, i18n } = useTranslation();
  const { loading, setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTeamState(team);
  }, [team]);

  /// Handle Update Team
  const handleUpdateTeam = async (role) => {
    try {
      setLoading(true);
      const action = updateSaleTeam({
        user_id: teamState.user_id,
        first_name: teamState.first_name,
        last_name: teamState.last_name,
        role: role,
      });
      const resultAction = await dispatch(action);
      const response = unwrapResult(resultAction);
      if (response.status === 200) {
        teamState.user_role = role == "leader" ? 3 : 4;
        setTeamState(teamState);
        enqueueSnackbar(t("update_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update Sale Team Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  /// Handle resend email
  const handleResendEmail = async () => {
    try {
      setLoading(true);
      const request = {
        name: team && team.user_name,
        email: team && team.email,
        role: team && team.user_role == 3 ? "leader" : "seller",
      };
      const action = inviteTeam(request);
      const resultAction = await dispatch(action);
      const response = unwrapResult(resultAction);
      if (response.status === 200) {
        enqueueSnackbar(t("resend_mail_invite_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Submit Invite Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <tr className="btn-reveal-trigger ">
      <td className="customer_shop_single"></td>
      <td className="py-2">
        <div className="media d-flex align-items-center">
          <div className="avatar avatar-xl mr-2">
            <div className="">
              <img
                className="rounded-circle img-fluid"
                src={avatar}
                width="60"
                alt=""
              />
            </div>
          </div>
          <div className="media-body mg-left-5">
            <h5 className="mb-0 fs--1 sales-team-item text-truncate">
              {teamState && teamState.user_name}
            </h5>
          </div>
        </div>
      </td>

      {/* Email */}
      <td className="py-2">
        <a
          href={`mailto:${teamState && teamState.email}`}
          className="sales-team-item"
        >
          {teamState && teamState.email}
        </a>
      </td>

      {/* Role */}
      <td className="py-2 sales-team-item">
        <Dropdown className="roamie-drop-down mg-top-14 h-50">
          <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
            <span className="fs-15 font-w400 roamie-text-color-primary">
              {teamState && teamState.user_role == 3
                ? t("leader")
                : t("seller")}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              key="leader"
              onClick={() => handleUpdateTeam("leader")}
            >
              {t("leader")}
            </Dropdown.Item>
            <Dropdown.Item
              key="seller"
              onClick={() => handleUpdateTeam("seller")}
            >
              {t("seller")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>

      {/* Status */}
      <td className="py-2 sales-team-item">
        <span className="text-truncate">
          {team && team.status === "0" ? t("inactive") : t("active")}
        </span>
      </td>

      {/* Joined Date */}
      <td className="py-2 sales-team-item px-3">
        <span className="text-truncate">
          {teamState &&
            teamState.joined &&
            MomentUtil(teamState && teamState.joined, "MMM DD, yyyy")}
        </span>
      </td>

      {/* Name Leader */}
      <td className="py-2 sales-team-item">
        <span className="text-truncate">
          {teamState && teamState.leader_name}
        </span>
      </td>

      {/* Resend */}
      <td>
        <div className="lead-status-container">
          {team && team.status == 0 ? (
            <button onClick={handleResendEmail}>
              <div className="arrow-enter-button fs-13">{t("resend")}</div>
            </button>
          ) : (
            <></>
          )}
        </div>
      </td>
    </tr>
  );
};

SalesTeamItem.propTypes = {
  team: PropTypes.object,
};

export default SalesTeamItem;
