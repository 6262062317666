import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AvatarChat from "../AvatarChat";

const MessagesHeader = (props) => {
  /// MARK: - Hooks
  const { t } = useTranslation();

  return (
    <div className="wrapper__messages-header d-flex">
      <AvatarChat isOnline={true} />
      <div className="mg-left-10 d-flex flex-column justify-content-center align-items-start">
        <span className="fs-16 font-w400 roamie-text-color-primary">
          Josh Matl
        </span>
        <span className="fs-11 font-w400 roamie-text-color-primary">
          josh@gmail.com
        </span>
        <Dropdown>
          <Dropdown.Toggle
            className="fs-12 font-w400 mt-1 dropdown-status"
            style={{ color: "green" }}
          >
            {t("online")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <i className="fa fa-cog setting"></i>
    </div>
  );
};

MessagesHeader.propTypes = {};

export default MessagesHeader;
