import { faPhoneAlt, faVideo } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import FlatButtonWithIcon from "../../../../components/Button/FlatButtonWithIcon";

const ChatDetailInfo = (props) => {
  /// Initials
  const { isMinimize } = props;
  return (
    <div className="chat-detail-info-container mg-top-30">
      <div
        className={`d-flex align-items-center ${
          isMinimize ? "justify-content-center" : ""
        }`}
      >
        <img
          src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
          className={`avatar-group mg-right-5 ${
            isMinimize ? "people-chat" : ""
          }`}
        />
        <div
          className={`d-flex flex-column mg-left-5 ${
            isMinimize ? "d-none" : ""
          }`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="fs-15" style={{ color: "#4B5155" }}>
              Family Chat
            </span>
            <i
              className="fa fa-pencil fa-sm p-1 rounded-1"
              style={{ backgroundColor: "#DBE5ED" }}
            ></i>
          </div>
          <span className="fs-12 roamie-text-color-primary">3 members</span>
          <span className="fs-11 roamie-text-color-primary">
            Last message: Today, 09:56
          </span>
        </div>
      </div>
      <div className={`mg-top-10 ${isMinimize ? "" : "d-flex"}`}>
        <FlatButtonWithIcon
          isMinimize={isMinimize}
          icon={faPhoneAlt}
          color={"#76C00D"}
          type="Voice Chat"
        />
        <FlatButtonWithIcon
          isMinimize={isMinimize}
          icon={faVideo}
          color={"#D03E13"}
          type="Video Call"
        />
      </div>
    </div>
  );
};

ChatDetailInfo.propTypes = {};

export default ChatDetailInfo;
