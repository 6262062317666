import React from "react";
import { useTranslation } from "react-i18next";

const MessagesActiveChats = (props) => {
  /// MARK: - Initials;
  const { t } = useTranslation();

  return (
    <div className="mg-top-30 d-flex justify-content-between">
      <span className="fs-14 font-w400 roamie-text-color-primary">
        {t("active_chats")}
      </span>
      <button className="messagess-add">
        <i className="fa fa-plus"></i>
      </button>
    </div>
  );
};

MessagesActiveChats.propTypes = {};

export default MessagesActiveChats;
