import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../../components/Button/AddButton";

const CalendarHead = (props) => {
  /// Hooks
  const { t, i18n } = useTranslation();

  return (
    <div className="add-new-appointment d-flex justify-content-end">
      <AddButton
        text={t("add_new_appoinment")}
        onClick={props.addNewAppointmentClick}
      />
    </div>
  );
};

CalendarHead.propTypes = {
  addNewAppointmentClick: PropTypes.func,
};

CalendarHead.defaultProps = {
  addNewAppointmentClick: null,
};

export default CalendarHead;
