import { faChevronRight, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useLoading } from "../../../../components/Loading/LoadingContext";
import { addNote, updateNote } from "../../LeadSlice";

const AddNote = (props) => {
  /// Initials
  const { lead, note } = props;
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /// Handle Submit Note
  const handleSubmitNote = async (values) => {
    const { title, content } = values;
    try {
      setLoading(true);
      const request = {
        lead_id: lead.objectId,
        title: title,
        content: content,
      };

      const action = addNote(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message), {
          variant: "success",
        });
        props.onReload();
        props.onClose();
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Submit Note Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  /// Handle Update Note
  const handleUpdateNote = async (values) => {
    const { title, content } = values;
    try {
      setLoading(true);
      const request = {
        noteId: note.note_id,
        title: title,
        content: content,
      };
      const action = updateNote(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message), {
          variant: "success",
        });
        props.onReload();
        props.onClose();
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Submit Note Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  /// Validate
  const validateSchema = Yup.object({
    title: Yup.string().required(t("note_title_required")),
    content: Yup.string().required(t("note_content_required")),
  });

  return (
    <div>
      {/* Header */}
      <div className="d-flex justify-content-between padding-20">
        <button onClick={props.onClose}>
          <FontAwesomeIcon icon={faChevronRight} size="1x" color="#CDD2F3" />
        </button>
        <span className="fs-16 font-w500 roamie-text-color-primary">
          {note ? t("update_note") : t("add_note")}
        </span>
        <button></button>
      </div>
      {/* Form */}
      <div className="row mg-top-40">
        <Formik
          initialValues={{
            title: (note && note.title) || "",
            content: (note && note.content) || "",
          }}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            note ? handleUpdateNote(values) : handleSubmitNote(values);
          }}
        >
          {(formik) => (
            <Form className="col-10 mx-auto">
              {/* Title  */}
              <div className="input-group mb-3 border-text-input d-flex align-items-center justify-content-center roamie-button-background-color">
                <Field
                  type="text"
                  className="form-control text-light placeholder--white-color roamie-text-color-primary fs-16 font-w400"
                  placeholder={t("title_note")}
                  name="title"
                />
                <div className="px-3">
                  <FontAwesomeIcon icon={faPen} size="1x" color="white" />
                </div>
                <ErrorMessage component="div" className="error" name="title" />
              </div>

              {/* Note */}
              <div className="d-flex flex-column">
                <div className="input-group mb-3 border-text-input">
                  <Field
                    component="textarea"
                    rows="10"
                    className="form-control placeholder-color roamie-text-color-primary fs-16 font-w400"
                    placeholder={t("note")}
                    name="content"
                    style={{ height: "120px" }}
                  />
                </div>
                <ErrorMessage
                  component="div"
                  className="error"
                  name="content"
                />
              </div>

              {/* Save */}
              <div className="d-grid gap-2 col-6 mx-auto mb-5">
                <button
                  className="btn btn-success roamie-text-white-color"
                  type="submit"
                >
                  {t("save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

AddNote.propTypes = {
  lead: PropTypes.object.isRequired,
  note: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onReload: PropTypes.func,
};

export default AddNote;
