import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import messageApi from "../../../chat/api/messageApi";
import roomApi from "../../../chat/api/roomApi";
import { rtcParams, rtcSetCounterPart } from "../../../chat/slices/RTCSlice";

const FlatButtonWithIcon = (props) => {
  /// Initials
  const { type, isMinimize, icon, color } = props;
  const { roomsWithNewMessages } = useSelector((state) => state.messages);
  const { onlineUsers, room } = useSelector((state) => state.ios);
  let other = {};

  /// Hooks
  const history = useHistory();
  const location = useLocation();
  const user = useGlobal("user")[0];
  const setAudio = useGlobal("audio")[1];
  const setVideo = useGlobal("video")[1];
  const setCallDirection = useGlobal("callDirection")[1];
  const setMeeting = useGlobal("meeting")[1];
  const dispatch = useDispatch();

  if (room && room.people.length !== 0) {
    room.people.forEach((person) => {
      if (user.id !== person._id) other = person;
    });
  }

  if (!other.firstName) {
    other = { ...other, firstName: "Unknown", lastName: "User" };
  }

  const title = (
    room.isGroup ? room.title : `${other.firstName} ${other.lastName}`
  ).substr(0, 22);

  let lastMessage = room.lastMessage;
  let text = "";

  /// Handle popup
  const popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (window.focus) newWindow.focus();
  };

  /// Handle call
  const call = async (callee, isVideo) => {
    await setAudio(true);
    await setVideo(isVideo);
    await setCallDirection("outgoing");
    dispatch(rtcSetCounterPart({ counterpart: callee }));
    try {
      const res = await roomApi.getMeetingRoom({
        startedAsCall: true,
        caller: user.id,
        callee: other._id,
        callToGroup: room.isGroup,
        group: room._id,
      });
      await setMeeting(res.data);
      // popupCenter({url: '/meeting/' + res.data._id, title: 'Call', w: 1400, h: 1000});
      // history.replace('/meeting');
      await messageApi.postCall({ roomID: room._id, meetingID: res.data._id });
      dispatch(rtcParams({ params: res.data._id }));
    } catch (error) {
      console.log("Failed to make a call", error);
    }
  };

  return (
    <div
      className=" mg-top-10 pointer"
      onClick={() => call(other, type.action === "voice" ? false : true)}
    >
      <div
        className={`flat-button-with-icon ${
          isMinimize ? "call-minimize" : "mg-right-10"
        }`}
        style={{ backgroundColor: color }}
      >
        <FontAwesomeIcon icon={icon} size="1x" color={"white"} />
        {isMinimize ? (
          ""
        ) : (
          <span className="mg-left-5 fs-14" style={{ color: "white" }}>
            {type?.name}
          </span>
        )}
      </div>
    </div>
  );
};

FlatButtonWithIcon.propTypes = {};

export default FlatButtonWithIcon;
