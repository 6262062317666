import React from "react";
import { useTranslation } from "react-i18next";

const ChatDetailNav = (props) => {
  /// MARK: - Initials
  const { minimize, isMinimize } = props;

  /// MARK: - Hooks
  const { t } = useTranslation();

  return (
    <div
      className={`chat-detail-nav-container d-flex align-items-center ${
        isMinimize ? "justify-content-center" : "justify-content-start"
      }`}
    >
      <div
        onClick={() => minimize()}
        className={`btn chat-detail-nav-close-button mg-right-5 ${
          isMinimize ? "spin" : ""
        }`}
      >
        <i className="fa fa-arrow-right" style={{ color: "white" }}></i>
      </div>
      <span className="fs-15 font-w400 roamie-text-color-primary">
        {isMinimize ? "" : t("chat_info")}
      </span>
    </div>
  );
};

ChatDetailNav.propTypes = {};

export default ChatDetailNav;
