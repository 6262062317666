import PropTypes from "prop-types";
import React from "react";
import { MomentUtil } from "../../../../../utils/moment_util";

const LeadProfileNotesItem = (props) => {
  /// Initials
  const { note } = props;

  return (
    <div
      className="note-item pointer border-text-input mb-2 p-2"
      onClick={() => props.onClick(note)}
    >
      <div className="row">
        <div className="col-lg-10">
          <span className="text-primary">{note && note.title}</span>
          <p>{note && note.content}</p>
        </div>
      </div>
      <div className="time text-end">
        <span>
          {note &&
            note.created_at &&
            MomentUtil(note.created_at, "DD MMM yyyy, HH:mm")}
        </span>
      </div>
    </div>
  );
};

LeadProfileNotesItem.propTypes = {
  note: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default LeadProfileNotesItem;
