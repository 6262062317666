import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faMinus } from "@fortawesome/free-solid-svg-icons";

const UpDownStatus = (props) => {
  return (
    <div
      className="up_down_status"
      style={{
        backgroundColor: props.backgroundColor,
        border: `2px solid ${props.borderColor}`,
      }}
    >
      <FontAwesomeIcon
        color={props.colorIcon}
        size="1x"
        icon={props.direction === "up" ? faArrowUp : props.direction === "down" ? faArrowDown : faMinus}
      />
    </div>
  );
};

UpDownStatus.propTypes = {
  direction: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  colorIcon: PropTypes.string.isRequired,
};

UpDownStatus.defaultProps = {
  direction: null,
  borderColor: null,
  backgroundColor: null,
  colorIcon: null,
};

export default UpDownStatus;
