import React from "react";
import { Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SalesTeamItem from "./SalesTeamItem";

const SalesTeamTable = (props) => {
  /// MARK: - Initials
  const { teams } = props;

  /// MARK: - Hooks
  const { t, i18n } = useTranslation();

  return (
    <Col lg={12}>
      <Table responsive striped>
        <thead className="table-head">
          <tr>
            <th></th>
            <th>
              <span>{t("name")}</span>
            </th>
            <th>
              <span>{t("email")}</span>
            </th>
            <th>
              <span>{t("user_type")}</span>
            </th>
            <th>
              <span>{t("status")}</span>
            </th>
            <th>
              <span>{t("joined")}</span>
            </th>
            <th>
              <span>{t("leader")}</span>
            </th>
            {/* <th>
              <span>{t("last_active")}</span>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {teams &&
            teams.length > 0 &&
            teams.map((team, index) => (
              <SalesTeamItem key={index} team={team} />
            ))}
        </tbody>
      </Table>
    </Col>
  );
};

SalesTeamTable.propTypes = {};

export default SalesTeamTable;
