import { faPhoneAlt, faVideo } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";
import { MomentUtil } from "../../../../../utils/moment_util";
import FlatButtonWithIcon from "../../../../components/Button/FlatButtonWithIcon";

const ChatDetailInfo = (props) => {
  /// MARK: - Hook
  const { t } = useTranslation();

  /// MARK: - Initials
  const { isMinimize, room } = props;
  const { lastUpdate } = room;
  const user = useGlobal("user")[0];
  let other = {};

  room.people.forEach((person) => {
    if (user.id !== person._id) other = person;
  });

  if (!other.firstName) {
    other = { ...other, firstName: "Unknown", lastName: "User" };
  }
  const title = room.isGroup
    ? room.title
    : `${other.firstName} ${other.lastName}`;

  const members = room && room.people.length;
  const date = lastUpdate ? MomentUtil(lastUpdate, "MMM D") : "";
  const time = lastUpdate ? MomentUtil(lastUpdate, "h:mm A") : "";

  return (
    <div className="chat-detail-info-container mg-top-30">
      <div
        className={`d-flex align-items-center ${
          isMinimize ? "justify-content-center" : ""
        }`}
      >
        <div
          className={`d-flex align-items-center justify-content-center bg-dark avatar-group mg-right-5 text-light ${
            isMinimize ? "people-chat" : ""
          }`}
        >
          <span className={"text-light"}>{title.substr(0, 1)}</span>
        </div>
        <div
          className={`d-flex flex-column mg-left-5 ${
            isMinimize ? "d-none" : ""
          }`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="fs-18" style={{ color: "#4B5155" }}>
              {title || ""}
            </span>
            {/* <i class="fa fa-pencil fa-sm p-1 rounded-1" style={{backgroundColor:"#DBE5ED"}}></i> */}
          </div>
          <span className="fs-14" style={{ color: "#AFBBC6" }}>
            {t("numbers_members", {
              numbers: members || 0,
            })}
          </span>
          <span className="fs-11" style={{ color: "#7B8793" }}>
            {t("last_message", {
              date: date,
            })}
            <br />
            {time}
          </span>
        </div>
      </div>
      <div className={`mg-top-10 ${isMinimize ? "" : "d-flex"}`}>
        <FlatButtonWithIcon
          isMinimize={isMinimize}
          icon={faPhoneAlt}
          color={"#76C00D"}
          type={{ action: "voice", name: t("voice_chat") }}
        />
        <FlatButtonWithIcon
          isMinimize={isMinimize}
          icon={faVideo}
          color={"#D03E13"}
          type={{ action: "video", name: t("video_call") }}
        />
      </div>
    </div>
  );
};

ChatDetailInfo.propTypes = {};

export default ChatDetailInfo;
