import { unwrapResult } from "@reduxjs/toolkit";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CurrentLanguage } from "../../../../utils/language_util";
import { MomentUtil } from "../../../../utils/moment_util";
import ArrowCalendarButton from "../../../components/Button/ArrowCalendarButton";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { getAppointments } from "../../Calendar/CalendarSlice";
import { getTasks } from "../../Tasks/TasksSlice";
import HomeCalendarEventItem from "./HomeCalendarEventItem";
import HomeCalendarTaskItem from "./HomeCalendarTaskItem";

const HomeCalendar = (props) => {
  /// MARK: - Initials;
  const [appointments, setAppointments] = useState([]);
  const [appointmentsFilterd, setAppointmentsFiltered] = useState([]);
  const [appointmentSelected, setAppointmentSelected] = useState({});
  const [taskFillter, setTaskFillter] = useState([]);
  const [tabs, setTabs] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, setLoading } = useLoading();
  const { current } = useSelector((state) => state.auth);
  const { teamSelect } = props;

  /// Day change
  const [date, setDate] = useState(new Date());
  const onDateChange = (newDate) => {
    setDate(newDate);
  };

  /// Moth change
  const [month, setMonth] = useState(new Date());
  const onMonthChange = (newDate) => {
    const date = new Date(newDate);
    setMonth(date);
  };

  /// Check appointments dates
  const checkAppointmentsDates = (dates) => {
    const datesCheck = Moment(dates).format("YYYY-MM-DD");
    const isEvent = appointments.some(
      (item) => Moment(item.start_time).format("YYYY-MM-DD") === datesCheck
    );
    return isEvent;
  };

  /// Check task dates
  const checkTasksDates = (dates) => {
    const datesCheck = Moment(dates).format("YYYY-MM-DD");
    const isTask = taskFillter.some(
      (item) => Moment(item.end_date).format("YYYY-MM-DD") === datesCheck
    );
    return isTask;
  };

  /// Handle On Click Calendar Event
  const handleOnClickCalendarEvent = (appointment) => {
    setAppointmentSelected(appointment);
  };

  /// UseEffect For Change Month
  useEffect(() => {
    const changeMonthAsync = async () => {
      try {
        setLoading(true);
        const firstDay = new Date(
          month.getFullYear(),
          month.getMonth(),
          1
        ).toLocaleDateString();
        const lastDay = new Date(
          month.getFullYear(),
          month.getMonth() + 1,
          1
        ).toLocaleDateString();
        const firstDayFormatter = Moment(firstDay).format("YYYY-MM-DD");
        const lastDayFormatter = Moment(lastDay).format("YYYY-MM-DD");
        const salePersonId = current.level == 3 ? teamSelect.value : null;
        const action = getAppointments({
          firstDayFormatter,
          lastDayFormatter,
          salePersonId,
        });
        const resultAction = await dispatch(action);
        const appointments = unwrapResult(resultAction);
        setAppointments(appointments);
      } catch (error) {
        console.log("Get Appointments Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    changeMonthAsync();
  }, [month, teamSelect]);

  /// UseEffect For Change Day
  useEffect(() => {
    const changeDayAsync = async () => {
      try {
        const dateSelectedFormatted = Moment(Date.parse(date)).format(
          "YYYY-MM-DD"
        );
        const appointmentsClone = [...appointments];
        const appointmentsFiltered = appointmentsClone.filter((appointment) => {
          const startDateAppointmentFormatted = Moment(
            Date.parse(appointment.start_time)
          ).format("YYYY-MM-DD");
          return Moment(dateSelectedFormatted).isSame(
            startDateAppointmentFormatted
          );
        });
        setAppointmentsFiltered(appointmentsFiltered);
      } catch (error) {
        console.log("Get Appointments Error: ", error);
      }
    };
    changeDayAsync();
  }, [date]);

  /// Effect load apointments
  useEffect(() => {
    const getAppoinemntsAsync = async () => {
      try {
        setLoading(true);
        const firstDay = new Date(
          month.getFullYear(),
          month.getMonth(),
          1
        ).toLocaleDateString();
        const lastDay = new Date(
          month.getFullYear(),
          month.getMonth() + 1,
          1
        ).toLocaleDateString();
        const firstDayFormatter = Moment(firstDay).format("YYYY-MM-DD");
        const lastDayFormatter = Moment(lastDay).format("YYYY-MM-DD");
        const salePersonId = current.level == 3 ? teamSelect.value : null;
        const action = getAppointments({
          firstDayFormatter,
          lastDayFormatter,
          salePersonId,
        });
        const resultAction = await dispatch(action);
        const appointments = unwrapResult(resultAction);
        const today = new Date();
        const apptToday = Moment(today).format("YYYY-MM-DD");
        setAppointmentsFiltered(
          appointments.filter((appointment) => {
            const startDateAppointmentFormatted = Moment(
              Date.parse(appointment.start_time)
            ).format("YYYY-MM-DD");
            return Moment(apptToday).isSame(startDateAppointmentFormatted);
          })
        );
      } catch (error) {
        console.log("Get Appointments Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getAppoinemntsAsync();
  }, [teamSelect]);

  /// Effect load task
  useEffect(() => {
    const getTasksAsync = async () => {
      try {
        setLoading(true);
        const salePersonId = current.level == 3 ? teamSelect.value : null;
        const action = getTasks({
          salePersonId,
        });
        const result = await dispatch(action);
        const taskResponse = unwrapResult(result);
        if (taskResponse) {
          setTaskFillter(taskResponse);
        }
      } catch (error) {
        console.log("Get Tasks Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getTasksAsync();
  }, [teamSelect]);

  return (
    <>
      <div className="home-calendar-container">
        <h1 className="text-center fs-15 font-w600 roamie-text-color-primary">
          {t("my_calendar_and_tasks")}
        </h1>
        <Calendar
          className="hide-current"
          nextLabel={<ArrowCalendarButton direction="right" />}
          next2Label={null}
          prevLabel={<ArrowCalendarButton direction="left" />}
          prev2Label={null}
          onChange={onDateChange}
          value={date}
          onActiveStartDateChange={({ activeStartDate }) =>
            onMonthChange(activeStartDate)
          }
          locale={CurrentLanguage()}
          tileClassName={({ date, view }) => {
            if (
              view === "month" &&
              checkAppointmentsDates(date) &&
              checkTasksDates(date)
            ) {
              return "title-event title-task";
            } else if (view === "month" && checkAppointmentsDates(date)) {
              return "title-event-only";
            } else if (view === "month" && checkTasksDates(date)) {
              return "title-task-only";
            }
          }}
        />
        <div className="home-calendar-title mg-top-50">
          <h1 className="fs-23 font-w350 roamie-text-color-primary">
            {date && MomentUtil(date, "DD MMMM")}
          </h1>
          <div className="d-flex justify-content-between align-items-center">
            <h2
              className={`${
                tabs === 1 ? "fs-16 font-w600" : "fs-16 font-w400"
              } roamie-text-color-primary pointer mg-right-10`}
              onClick={() => setTabs(1)}
            >
              {t("all")}
            </h2>
            <h2
              className={`${
                tabs === 2 ? "fs-16 font-w600" : "fs-16 font-w400"
              } roamie-text-color-primary pointer mg-right-10`}
              onClick={() => setTabs(2)}
            >
              {t("tasks")}
            </h2>
            <h2
              className={`${
                tabs === 3 ? "fs-16 font-w600" : "fs-16 font-w400"
              } roamie-text-color-primary pointer mg-right-10`}
              onClick={() => setTabs(3)}
            >
              {t("appointments")}
            </h2>
          </div>
        </div>
        <div className="home-calendar-events mg-top-40 scroll-dark">
          {tabs === 1 || tabs === 3 ? (
            appointmentsFilterd &&
            appointmentsFilterd.length > 0 &&
            appointmentsFilterd.map((appt) => (
              <HomeCalendarEventItem
                key={appt.apmt_id}
                appointment={appt}
                // onClick={(appointment) => props.onClick(appointment)}
              />
            ))
          ) : (
            <></>
          )}
          {tabs === 1 || tabs === 2 ? (
            taskFillter &&
            taskFillter.length > 0 &&
            taskFillter
              .filter(
                (task) =>
                  Moment(date).format("YYYY-MM-DD") ==
                  Moment(task.end_date).format("YYYY-MM-DD")
              )
              .map((task) => (
                <HomeCalendarTaskItem key={task.objectId} task={task} />
              ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

HomeCalendar.propTypes = {};

export default HomeCalendar;
