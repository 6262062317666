import PropTypes from "prop-types";
import React from "react";

const MyProfileStatItem = (props) => {
  return (
    <div className="col-xl-3 col-lg-6 col-sm-6">
      <div className="widget-stat card">
        <div className="card-body p-4">
          <div className="media d-flex justify-content-center text-center flex-column">
            <img
              src={
                require(`../../../../assets/icons/${props.iconName}`).default
              }
              className="icon"
            />
            <div className="media-body roamie-text-regular text-right mg-top-10">
              <p className="mb-1">{props.name}</p>
              <h3 className="roamie-text-primary roamie-text-bold">
                {props.value}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MyProfileStatItem.propTypes = {
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
};

export default MyProfileStatItem;
