import axios from "axios";
import Config from "./config";
import queryString from "query-string";

const nodeAxiosClient = axios.create({
  baseURL: Config.url,
  headers: {
    "Content-Type": "application/json",
    // "Content-Type": "application/x-www-form-urlencoded",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

export default nodeAxiosClient;
