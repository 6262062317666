import React from "react";
import AvatarChat from "../AvatarChat";

const ChatDetailPeopleItem = (props) => {
  /// MARK: - Initials
  const { isMinimize } = props;

  return (
    <div
      className={`chat-detail-people-item d-flex align-items-center 
    ${isMinimize ? "justify-content-center" : "justify-content-start"}`}
    >
      <AvatarChat avatarType="people-chat" />
      <div
        className={`${
          isMinimize ? "d-none" : "d-flex"
        } justify-content-between info`}
      >
        <div className="d-flex flex-column mg-left-10 info">
          <span className="fs-12" style={{ color: "#4B5155" }}>
            Franky Schmidt
          </span>
          <span className="fs-9" style={{ color: "green" }}>
            Online
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <i className="fa fa-microphone-slash mg-right-10"></i>
          <i className="fa fa-times-circle"></i>
        </div>
      </div>
    </div>
  );
};

ChatDetailPeopleItem.propTypes = {};

export default ChatDetailPeopleItem;
