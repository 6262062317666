import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AvatarChat from "../AvatarChat";

const ChatDetailPeopleItem = (props) => {
  /// MARK: - Initials
  const { isMinimize, people } = props;
  const { username } = people;
  const { onlineUsers } = useSelector((state) => state.ios);

  /// MARK: - Hooks
  const { t } = useTranslation();

  const getStatus = () => {
    if (
      onlineUsers.filter((u) => u.id === people._id && u.status === "busy")
        .length > 0
    )
      return "busy";
    if (
      onlineUsers.filter((u) => u.id === people._id && u.status === "online")
        .length > 0
    )
      return "online";
    if (
      onlineUsers.filter((u) => u.id === people._id && u.status === "away")
        .length > 0
    )
      return "away";
    return "offline";
  };

  return (
    <div
      className={`chat-detail-people-item d-flex align-items-center mb-2
    ${isMinimize ? "justify-content-center" : "justify-content-start"}`}
    >
      <AvatarChat avatarType="people-chat" status={getStatus()} user={people} />
      <div
        className={`${
          isMinimize ? "d-none" : "d-flex"
        } justify-content-between info`}
      >
        <div className="d-flex flex-column mg-left-10 info">
          <span className="fs-14" style={{ color: "#4B5155" }}>
            {username}
          </span>
          <span className="fs-9" style={{ color: "green" }}>
            {t(getStatus())}
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {/* <i class="fa fa-microphone-slash mg-right-10 fa-lg"></i>
          <i class="fa fa-times-circle fa-lg"></i> */}
        </div>
      </div>
    </div>
  );
};

ChatDetailPeopleItem.propTypes = {};

export default ChatDetailPeopleItem;
