import {
  faArrowDown,
  faArrowUp,
  faList,
  faPlus,
  faThLarge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddButton from "../../../../components/Button/AddButton";
import Empty from "../../../../components/Empty/Empty";
import { useLoading } from "../../../../components/Loading/LoadingContext";
import { getFiles, uploadFile } from "../../LeadSlice";
import LeadProfileFileItem from "./LeadProfileFileItem";

const LeadProfileFiles = (props) => {
  /// Initials
  const [sort, setSort] = useState(false);
  const [view, setView] = useState(false);
  const [files, setFiles] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const { lead } = props;

  /// Hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { loading, setLoading } = useLoading();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sortByName = () => {
    setSort(!sort);
  };

  const changeView = () => {
    setView(!view);
  };

  /// UseEffect Get Files
  useEffect(() => {
    const getFilesAsync = async () => {
      try {
        const action = getFiles({
          lead_id: lead.objectId,
        });
        const resultAction = await dispatch(action);
        const files = unwrapResult(resultAction);
        setFiles(files);
      } catch (error) {
        console.log("Get Files Error: ", error);
      }
    };
    getFilesAsync();
  }, [needReload]);

  /// Handle Add File
  const handleAddFile = (e) => {
    let formData = new FormData();
    formData.append("fileToUpload", e.target.files[0]);
    handleSubmitFile(formData);
    e.target.value = null;
  };

  /// Handle Submit File
  const handleSubmitFile = async (data) => {
    try {
      setLoading(true);
      data.append("lead_id", lead.objectId);
      const action = uploadFile(data);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message || ""), {
          variant: "success",
        });
        /// Reload
        setNeedReload((preState) => !preState);
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Upload File Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="files-container mg-top-20">
      {/* Add File */}
      <div className="d-flex justify-content-center">
        <label htmlFor="lead_file">
          <AddButton text={t("add_file")} />
          <input
            hidden={true}
            type="file"
            id="lead_file"
            accept="image/*"
            onChange={handleAddFile}
          />
        </label>
      </div>

      <div className="d-flex align-items-center justify-content-between mg-top-20">
        <div className="sort-by-name" onClick={sortByName}>
          <span>
            {t("name") + " "}
            {sort ? (
              <FontAwesomeIcon icon={faArrowDown} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faArrowUp} size="1x" />
            )}
          </span>
        </div>
        <div className="view" onClick={changeView}>
          {view ? (
            <FontAwesomeIcon icon={faList} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faThLarge} size="2x" />
          )}
        </div>
      </div>
      <div className={view ? "file-grid" : ""}>
        {files && files.length == 0 ? (
          <Empty text={t("do_not_have_any_file")} />
        ) : null}
        {files &&
          files.length > 0 &&
          files.map((file) => (
            <LeadProfileFileItem key={file.file_url} view={view} file={file} />
          ))}
      </div>
    </div>
  );
};

LeadProfileFiles.propTypes = {
  lead: PropTypes.object.isRequired,
};

export default LeadProfileFiles;
