import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, Table } from "react-bootstrap";
import MyProfileStatItem from "../../MyProfile/components/MyProfileStatItem";
import GlobalImages from "../../../../constants/GlobalImages";
import { useTranslation } from "react-i18next";

const HomeStats = (props) => {
  /// Initials;
  const { t, i18n } = useTranslation();
  const { myStats } = props;

  if (Object.entries(myStats).length === 0) {
    return <></>;
  }

  return (
    <div>
      <Card.Header>
        <Card.Title className="home-title">{t("stats")}</Card.Title>
      </Card.Header>
      <Row className="mg-top-20">
        <MyProfileStatItem
          name={t("deals_closed")}
          value={myStats.deals.closed}
          iconName={GlobalImages.iconDeals}
        />
        <MyProfileStatItem
          name={t("deals_closing")}
          value={myStats.deals.closing}
          iconName={GlobalImages.iconDeals}
        />
        <MyProfileStatItem
          name={t("person")}
          value={myStats.leads.invidid}
          iconName={GlobalImages.iconLeads}
        />
        <MyProfileStatItem
          name={t("company")}
          value={myStats.leads.compa}
          iconName={GlobalImages.iconLeads}
        />
        <MyProfileStatItem
          name={t("appointments_made")}
          value={myStats.StatsAppointment.madded}
          iconName={GlobalImages.iconAppointments}
        />
        <MyProfileStatItem
          name={t("appointments_empty")}
          value={myStats.StatsAppointment.empty}
          iconName={GlobalImages.iconAppointments}
        />
        <MyProfileStatItem
          name={t("tasks_pending")}
          value={myStats.tasks.uncomp}
          iconName={GlobalImages.iconTasks}
        />
        <MyProfileStatItem
          name={t("tasks_completed")}
          value={myStats.tasks.complete}
          iconName={GlobalImages.iconTasks}
        />
      </Row>
    </div>
  );
};

HomeStats.propTypes = {
  myStats: PropTypes.object,
};

export default HomeStats;
