import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import chatApi from "../api/chatApi";
import groupApi from "../api/groupApi";

export const search = createAsyncThunk("/api/search", async (payload) => {
  const res = await chatApi.search(payload);
  return res;
});

export const createGroup = createAsyncThunk(
  "/api/group/create",
  async (payload) => {
    const res = await groupApi.createGroup(payload);
    return res;
  }
);

export const getMeetings = createAsyncThunk("/api/meeting", async (payload) => {
  const res = await chatApi.meeting(payload);
  return res;
});

const chatSlice = createSlice({
  name: "ChatSlice",
  initialState: {
    searchResults: [],
    searchText: "",
    user: null,
    meetings: [],
  },
  reducers: {
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [search.fulfilled]: (state, action) => {
      state.searchResults = action.payload.data.users;
    },
    [getMeetings.fulfilled]: (state, action) => {
      state.meetings = action.payload.data.meetings;
    },
  },
});

const { reducer, actions } = chatSlice;
export const { updateSearchText, setCurrentUser } = actions;
export default reducer;
