import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MomentUtil } from "../../../../utils/moment_util";
import ArrowEnterButton from "../../../components/Button/ArrowEnterButton";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { checkMetrics } from "../../../components/Metrics/checkMetrics";
import { deleteTarget } from "../TargetSlice";

const TargetItem = (props) => {
  /// Initials
  const { target } = props;

  /// Hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { loading, setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  /// Handle On Click Delete Target
  const handleOnClickDeleteTarget = async () => {
    try {
      setLoading(true);
      const action = deleteTarget({ targetId: target.goal_id });
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message || ""), {
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Delete Target Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <tr className="btn-reveal-trigger ">
        <td className="customer_shop_single">
          <button onClick={handleOnClickDeleteTarget}>
            <FontAwesomeIcon icon={faTrashAlt} size="1x" color={"#E94D64"} />
          </button>
        </td>
        <td className="py-2">
          <h5 className="mb-0 fs--1 target-item">
            {(target && target.subject) || ""}
          </h5>
        </td>
        <td className="py-2 target-item">
          {target &&
            target.start_date &&
            MomentUtil(target.start_date, "DD MMM YYYY")}
        </td>
        <td className="py-2 target-item">
          {target &&
            target.end_date &&
            MomentUtil(target.end_date, "DD MMM YYYY")}
        </td>
        <td className="py-2">
          <span className="target-item">
            {checkMetrics(target && target.metrics)}
          </span>
        </td>
        <td className="py-2">
          <span className="target-item">
            {target && Intl.NumberFormat().format(target.value)}
          </span>
        </td>
        <td>
          <div>
            <ArrowEnterButton onClick={() => props.onClickTargetItem(target)} />
          </div>
        </td>
      </tr>
    </>
  );
};

TargetItem.propTypes = {
  target: PropTypes.object.isRequired,
  onClickTargetItem: PropTypes.func,
};

export default TargetItem;
