import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddButton from "../../../../components/Button/AddButton";
import Empty from "../../../../components/Empty/Empty";
import { getNotes } from "../../LeadSlice";
import AddNote from "../Notes/AddNote";
import LeadProfileNotesItem from "./LeadProfileNotesItem";

const LeadProfileNote = (props) => {
  /// Initials
  const { lead, active } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [noteSelected, setNoteSelected] = useState(null);
  const [needReload, setNeedReload] = useState(false);

  /// Hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  /// UseEffect Get Notes;
  useEffect(() => {
    const getNotesAsync = async () => {
      try {
        const action = getNotes({ leadId: lead.objectId });
        const resultAction = await dispatch(action);
        const notes = unwrapResult(resultAction);
        setNotes(notes);
      } catch (error) {
        console.log("Get Notes Error:", error);
      }
    };
    getNotesAsync();
  }, [needReload]);

  /// Handle On Click Add Note
  const handleOnClickAddNote = () => {
    setNoteSelected(null);
    toggleDrawerAddNote();
  };
  /// Handel lead profile status
  useEffect(() => {
    if (active.name === "no_response" || active.name === "fact_find") {
      handleOnClickAddNote();
    }
  }, [active]);
  /// Handle Open Drawer
  const toggleDrawerAddNote = () => {
    setIsOpen((prevState) => !prevState);
  };

  /// Handle On Click Note
  const handleOnClickNote = (note) => {
    setNoteSelected(note);
    toggleDrawerAddNote();
  };

  return (
    <div className="notes-container mg-top-20">
      <div className="d-flex justify-content-center">
        <AddButton text={t("add_note")} onClick={handleOnClickAddNote} />
      </div>
      <div className="mg-top-20">
        {notes && notes.length == 0 ? (
          <Empty text="do_not_have_any_note" />
        ) : null}
        {notes &&
          notes.length > 0 &&
          notes.map((note) => (
            <LeadProfileNotesItem
              key={note.note_id}
              note={note}
              onClick={handleOnClickNote}
            />
          ))}
      </div>
      <Drawer
        className="lead-drawer"
        variant="temporary"
        {...props}
        open={isOpen}
        anchor={"right"}
        transitionDuration={500}
        onBackdropClick={toggleDrawerAddNote}
      >
        <AddNote
          onClose={toggleDrawerAddNote}
          lead={lead}
          note={noteSelected}
          onReload={() => setNeedReload((preState) => !preState)}
        />
      </Drawer>
    </div>
  );
};

LeadProfileNote.propTypes = {
  lead: PropTypes.object.isRequired,
};

export default LeadProfileNote;
