import React from "react";
import Config from "../../../../../chat/config";

export default function ChatSendVideo(props) {
  const { src } = props;
  return (
    <div className="chat-send-item-container">
      <div className="chat-send-item with-video align-items-center">
        <video controls>
          <source
            src={`https://res.cloudinary.com/tlus-image/video/upload/v1644480112/BLACKPINK_-_Ice_Cream_with_Selena_Gomez_M-V_zhazbt.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}
