import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import StorageKeys from "../../constants/StorageKeys";

import chatUserApi from "../api/chatUserApi";

export const nodeLogin = createAsyncThunk(
  "/api/roamielogin",
  async (payload) => {
    const res = await chatUserApi.login(payload);
    if (res.data.token !== undefined || null) {
      localStorage.setItem(StorageKeys.NODE_TOKEN, res.data.token);
      localStorage.setItem(
        StorageKeys.NODE_USER,
        JSON.stringify(jwtDecode(res.data.token))
      );
    }
    return res;
  }
);

const nodeAuthSlice = createSlice({
  name: "NodeAuthSlice",
  initialState: {
    currentUser: null,
  },
  reducers: {},
  extraReducers: {},
});

const { reducer, actions } = nodeAuthSlice;
export const { setTyping, messagesAddToRoomUnread, messagesRemoveRoomUnread } =
  actions;
export default reducer;
