import React from "react";
import PropTypes from "prop-types";

const ChatReceiveItem = ({author, content, date}) => {
  return (
    <div className="chat-receive-item-container">
      <img
        src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
        className="chat-receiver-avatar mg-right-5"
      />
      <div className="chat-receive-item">
        <span className="fs-14 roamie-text-color-primary">
          {content}
        </span>
      </div>
    </div>
  );
};

ChatReceiveItem.propTypes = {};

export default ChatReceiveItem;
