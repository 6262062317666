import React from "react";
import PropTypes from "prop-types";

const EmailInvite = (props) => {
  return (
    <div className="mb-3 email-invite">
      <span>{props.email}</span>
      <button onClick={() => props.onDelete(props.email)} type="button">
        <span>
          <i className="fa fa-times-circle"></i>
        </span>
      </button>
    </div>
  );
};

EmailInvite.propTypes = {
  email: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EmailInvite;
