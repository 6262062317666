const ActivityType = {
  activitySMS: {
    value: 1,
  },
  activityCall: {
    value: 2,
  },
  activityEmail: {
    value: 3,
  },
  activityAppointment: {
    value: 4,
  },
  activityTask: {
    value: 5,
  },
};

export const getTranslateDescription = (value) => {
  switch (value) {
    case 1:
      return "activity_send_sms";
    case 2:
      return "activity_make_call";
    case 3:
      return "activity_send_email";
    case 4:
      return "activity_setup_appointment";
    case 5:
      return "activity_create_task";
    default:
      break;
  }
};

export default ActivityType;
