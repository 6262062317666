import { unwrapResult } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import periodsType, { getTitlePeriods } from "../../../../enum/PeriodsType";
import UpDownStatus from "../../../components/chatBox/UpDownStatus";
import { getMyTeam } from "../../../pages/SalesTeam/SalesTeamSlice";
import { getTargets } from "../../../pages/Target/TargetSlice";

const HomeFilter = (props) => {
  /// MARK: - Initials;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { summaryDeal, activitiesObject } = props;
  const [tabs, setTabs] = useState(0);
  const [periods, setPeriods] = useState(periodsType[0]);
  const [saleTeams, setSaleTeams] = useState([]);
  const [targets, setTargets] = useState([]);
  const [dealSummary, setDealSummary] = useState(0);
  const [activitiesSummary, setActivitiesSummary] = useState(0);
  const [teamSelect, setTeamSelect] = useState({
    value: -1,
    user_name: t("all"),
  });
  const [targetSelect, setTargetSelect] = useState({
    value: -1,
    subject: t("all"),
  });
  const { initData, current } = useSelector((state) => state.auth);

  /// Get saleteams
  useEffect(() => {
    const getSalesTeamAsync = async () => {
      try {
        const action = getMyTeam();
        const resultActioin = await dispatch(action);
        const response = unwrapResult(resultActioin);
        if (response) {
          let salesTeam = (response.data || []).filter(
            (team) => team.status === "1"
          );
          if (current) {
            salesTeam.push({
              value: current.user_id,
              user_name: current.user_name,
            });
          }
          setSaleTeams(salesTeam);
        }
      } catch (error) {
        console.log("Get sale teams failed", error);
      }
    };
    if (current.level == 3) {
      getSalesTeamAsync();
    }
  }, []);

  /// Get targets
  useEffect(() => {
    const getTargetsAsync = async () => {
      try {
        const action = getTargets({});
        const resultActioin = await dispatch(action);
        const response = unwrapResult(resultActioin);
        if (response) {
          setTargets(response);
        }
      } catch (error) {
        console.log("Get sale teams failed", error);
      }
    };
    getTargetsAsync();
  }, []);

  /// Get summary percent
  useEffect(() => {
    if (tabs === 0) {
      let totalDealValuePre =
        summaryDeal &&
        summaryDeal.periods_pre &&
        summaryDeal.periods_pre.deal_closed_value +
          summaryDeal.periods_pre.deal_closing_value;
      let totalDealValue =
        summaryDeal &&
        summaryDeal.summary
          .map((item) => item.deal_closing_value + item.deal_closed_value) /// return deal
          .reduce((prev, current) => prev + current, 0);
      if (totalDealValuePre === 0) {
        let percenDeal = ((totalDealValue - totalDealValuePre) / 1) * 100;
        setDealSummary(percenDeal);
      } else {
        let percenDeal =
          ((totalDealValue - totalDealValuePre) / totalDealValuePre) * 100;
        setDealSummary(percenDeal);
      }
    } else {
      let totalActivitiesValue =
        activitiesObject &&
        activitiesObject.data.length > 0 &&
        activitiesObject.data
          .map(
            (item) =>
              item.number_of_leads +
              item.number_of_tasks +
              item.number_of_appointment +
              item.number_of_deals
          )
          .reduce((pre, current) => pre + current, 0);
      let totalActivitiesValuePre =
        activitiesObject &&
        activitiesObject.periods_pre &&
        activitiesObject.periods_pre.number_of_leads +
          activitiesObject.periods_pre.number_of_tasks +
          activitiesObject.periods_pre.number_of_appointment +
          activitiesObject.periods_pre.number_of_deals;
      if (totalActivitiesValuePre == 0) {
        let percenActivities =
          ((totalActivitiesValue - totalActivitiesValuePre) / 1) * 100;
        setActivitiesSummary(percenActivities);
      } else {
        let percenActivities =
          ((totalActivitiesValue - totalActivitiesValuePre) /
            totalActivitiesValuePre) *
          100;
        setActivitiesSummary(percenActivities);
      }
    }
  }, [periods, tabs, summaryDeal, activitiesObject]);

  return (
    <>
      <div className="home-filter-container">
        <div className="tabbar-container">
          <div
            onClick={() => {
              setTabs(0);
              props.onApplyFilter(0, null, null, null);
            }}
            className={`tabbar ${tabs === 0 ? "tabbar--actived" : ""}`}
          >
            <span className={`tabbar-title ${tabs === 0 ? "actived" : ""}`}>
              {t("deals")}
            </span>
          </div>
          <div
            onClick={() => {
              setTabs(1);
              props.onApplyFilter(1, null, null, null);
            }}
            className={`tabbar ${tabs === 1 ? "tabbar--actived" : ""}`}
          >
            <span className={`tabbar-title ${tabs === 1 ? "actived" : ""}`}>
              {t("activities")}
            </span>
          </div>
        </div>
        <div className="home-filter">
          <div className="home-filter-icon-container">
            {tabs === 0 ? (
              <UpDownStatus
                direction={
                  dealSummary < 0 ? "down" : dealSummary > 0 ? "up" : "0"
                }
                borderColor={
                  dealSummary < 0
                    ? "#E94D64"
                    : dealSummary > 0
                    ? "#43D133"
                    : "#282A4E"
                }
                backgroundColor={
                  dealSummary < 0
                    ? "#F5D6E0"
                    : dealSummary > 0
                    ? "#D3F1D6"
                    : "#F7F8FF"
                }
                colorIcon={
                  dealSummary < 0
                    ? "#E94D64"
                    : dealSummary > 0
                    ? "#43D133"
                    : "#282A4E"
                }
              />
            ) : (
              <UpDownStatus
                direction={
                  activitiesSummary < 0
                    ? "down"
                    : activitiesSummary > 0
                    ? "up"
                    : "0"
                }
                borderColor={
                  activitiesSummary < 0
                    ? "#E94D64"
                    : activitiesSummary > 0
                    ? "#43D133"
                    : "#282A4E"
                }
                backgroundColor={
                  activitiesSummary < 0
                    ? "#F5D6E0"
                    : activitiesSummary > 0
                    ? "#D3F1D6"
                    : "#F7F8FF"
                }
                colorIcon={
                  activitiesSummary < 0
                    ? "#E94D64"
                    : activitiesSummary > 0
                    ? "#43D133"
                    : "#282A4E"
                }
              />
            )}
            <div>
              <div className="fs-12 font-w400 roamie-text-color-primary">
                {t(getTitlePeriods(periods))}
              </div>
              <div className="fs-16 font-w500 roamie-text-color-primary">
                {`${
                  tabs === 0
                    ? Intl.NumberFormat().format(Math.abs(dealSummary || 0))
                    : Intl.NumberFormat().format(
                        Math.abs(activitiesSummary || 0)
                      )
                }%`}
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="d-flex home-filter-container--dropdown">
        {/* Periods */}
        <div className="col">
          <span className="fs-12 font-w400 op9 roamie-text-color-primary text-truncate">
            {t("periods")}
          </span>
          <Dropdown className="roamie-drop-down mg-top-14 h-50">
            <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
              <span className="fs-12 font-w400 roamie-text-color-primary text-truncate">
                {t(periods && periods.name)}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {periodsType &&
                periodsType.map((period, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setPeriods(period);
                        props.onApplyFilter(
                          tabs,
                          period,
                          teamSelect,
                          targetSelect
                        );
                      }}
                    >
                      {t(period.name)}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Sale Person */}
        {tabs == 0 && initData.user_role === 3 ? (
          <div className="col">
            <span className="fs-12 font-w400 op9 roamie-text-color-primary text-truncate">
              {t("saleperson")}
            </span>
            <Dropdown className="roamie-drop-down mg-top-14 h-50">
              <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
                <span className="fs-12 font-w400 roamie-text-color-primary text-dropdown-select text-truncate">
                  {teamSelect.user_name || ""}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="fs-12"
                  onClick={() => {
                    setTeamSelect({
                      value: -1,
                      user_name: t("all"),
                    });
                    props.onApplyFilter(
                      tabs,
                      null,
                      {
                        value: -1,
                        user_name: t("all"),
                      },
                      targetSelect
                    );
                  }}
                >
                  {t("all")}
                </Dropdown.Item>
                {saleTeams &&
                  saleTeams.length > 0 &&
                  saleTeams.map((team, index) => (
                    <Dropdown.Item
                      onClick={() => {
                        setTeamSelect(team);
                        props.onApplyFilter(tabs, null, team, targetSelect);
                      }}
                      key={index}
                    >
                      {team.user_name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}

        {/* Campaign */}
        <div className="col">
          <span className="fs-12 font-w400 op9 roamie-text-color-primary text-truncate">
            {t("campaign")}
          </span>
          <Dropdown className="roamie-drop-down mg-top-14 h-50">
            <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
              <span className="fs-12 font-w400 roamie-text-color-primary text-dropdown-select text-truncate">
                {targetSelect.subject}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setTargetSelect({
                    value: -1,
                    subject: t("all"),
                  });
                  props.onApplyFilter(tabs, null, null, {
                    value: -1,
                    subject: t("all"),
                  });
                }}
              >
                {t("all")}
              </Dropdown.Item>
              {targets &&
                targets.length > 0 &&
                targets.map((target) => (
                  <Dropdown.Item
                    onClick={() => {
                      setTargetSelect(target);
                      props.onApplyFilter(tabs, null, null, target);
                    }}
                    key={target.goal_id}
                  >
                    {target.subject}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

HomeFilter.propTypes = {
  onApplyFilter: PropTypes.func,
  summaryDeal: PropTypes.object,
  activitiesObject: PropTypes.object,
};

export default HomeFilter;
