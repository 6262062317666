import React from "react";
import PropTypes from "prop-types";
import { useGlobal } from "reactn";
import Moment from "moment";
import ReactImageAppear from "react-image-appear";
import ChatMessageAttachTime from "./ChatMessageAttachTime";
import Config from "../../../../../chat/config";
import ChatReceiveImage from "./ChatReceiveImage";
import ChatReceiveFile from "./ChatReceiveFile";
import ChatReceiveAudio from "./ChatReceiveAudio";
import ChatReceiveVideo from "./ChatReceiveVideo";
import { useTranslation } from "react-i18next";
import { MomentUtil } from "../../../../../utils/moment_util";

const ChatReceiveItemTest = ({ message, previous, next, onOpen, typing }) => {
  /// Initials
  const { author, content, date, showAvatar, isMine, type } = message;
  const username = author
    ? author.firstName?.substr(0, 1) + "" + author.lastName?.substr(0, 1)
    : "";
  let attachPrevious = false,
    attachNext = false;

  /// Hooks
  const { t } = useTranslation();
  if (
    previous &&
    Math.abs(Moment(previous.date).diff(Moment(date), "minutes")) < 3 &&
    author._id === previous.author._id
  )
    attachPrevious = true;

  if (
    next &&
    Math.abs(Moment(next.date).diff(Moment(date), "minutes")) < 3 &&
    author._id === next.author._id
  )
    attachNext = true;

  const Details = ({ side }) => {
    if (!attachPrevious)
      return (
        <div className={`message-details ${side}`}>
          {MomentUtil(date, "MMM DD - h:mm A")}
        </div>
      );
    else return null;
  };

  const PictureOrSpacer = () => {
    if (attachNext) return <div className="chat-spacer mg-left-5"></div>;
    else
      return (
        <div className="chat-receiver-avatar mg-right-5 d-flex justify-content-center align-items-center bg-dark">
          <span className="text-light">{username || "UU"}</span>
        </div>
      );
  };

  const Content = () => {
    switch (type) {
      case "image":
        return <ChatReceiveImage src={content} />;
      case "file":
        return <ChatReceiveFile src={message} />;
      case "video":
        return <ChatReceiveVideo src={message} />;
      case "audio":
        return <ChatReceiveAudio src={message} />;
      default:
        return (
          <div className="chat-receive-item">
            <span className="fs-14 roamie-text-color-primary">
              {typing ? `${t("typing")}...` : content}
            </span>
          </div>
        );
    }
  };

  return (
    <div className="chat-receive-item-container">
      <PictureOrSpacer />
      <div className="chat-item mg-right-5">
        <Content />
        <Details side={isMine ? "right" : "left"} />
      </div>
    </div>
  );
};

ChatReceiveItemTest.propTypes = {};

export default ChatReceiveItemTest;
