import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LeadContactEmailItem = (props) => {
  /// MARK: - Initials;
  const { emailObj } = props;
  const [email, setEmail] = useState("");

  useEffect(() => {
    const e = emailObj && emailObj.email;
    setEmail(e);
  }, []);

  return (
    <div className="d-flex mg-bottom-10">
      <a href={`mailto:${email}`} target="_blank">
        <span className="fs-15 font-w500">{email}</span>
      </a>
    </div>
  );
};

LeadContactEmailItem.propTypes = {
  emailObj: PropTypes.object,
};

export default LeadContactEmailItem;
