import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ServiceTaskItem from "./ServiceTaskItem";

export default function ServiceTasksTable(props) {
  /// Initials
  const { service, leadsSelected, reload } = props;
  const [tasks, setTasks] = useState([]);
  /// Hooks
  const { t } = useTranslation();

  /// Filter lead selected
  useEffect(() => {
    if (leadsSelected && leadsSelected.length > 0) {
      let tasks = [];
      service.forEach((element) => {
        tasks = tasks.concat(element.tasks);
      });
      const leadFilter =
        tasks &&
        tasks.filter((tasks) =>
          leadsSelected.some((lead) => lead.objectId == tasks.lead.objectId)
        );
      setTasks(leadFilter);
    } else if (service && service.length > 0) {
      let tasks = [];
      service.forEach((element) => {
        tasks = tasks.concat(element.tasks);
      });
      setTasks(tasks);
    }
  }, [leadsSelected]);

  return (
    <Table responsive striped>
      <thead className="table-head">
        <tr>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tasks &&
          tasks.length > 0 &&
          tasks.map((task, index) => (
            <ServiceTaskItem key={index} task={task} reload={reload} />
          ))}
      </tbody>
    </Table>
  );
}
