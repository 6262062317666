import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function ChatSendFiles(props) {
  /// Initials
  const { active, sendFile } = props;
  const [over, setOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [send, setSend] = useState(false);
  /// Hook
  const { t, i18n } = useTranslation();
  const input = useRef();
  /// Handle Files upload
  const handleFiles = (values) => {
    setFiles([...files, values[0]]);
  };

  return (
    <>
      {active ? (
        <div
          className={`chat-files-container ${over ? "drop" : ""}`}
          onDrop={(e) => {
            e.preventDefault();
            e.persist();
            setFiles([...files, e.dataTransfer.files[0]]);
            setOver(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setOver(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setOver(false);
          }}
        >
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                sendFile(false);
                setFiles([]);
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
          <input
            ref={input}
            type="file"
            id="fileElem"
            className="d-none"
            accept=".jpg, .pdf, .docx, .mp4, .png"
            onChange={(e) => handleFiles(e.target.files)}
          />
          <div className="chat-file-message mt-5 w-100">
            <FontAwesomeIcon
              className="paper-icon"
              icon={faPaperclip}
              color="white"
            />
            <h3 style={{ color: "#76C00D" }} className="mg-top-50">
              {t("upload_file")}
            </h3>
            <span>{t("drag_file_here_or")}</span>
            <a
              onClick={() => input.current.click()}
              className="pointer"
              style={{ color: "#76C00D" }}
            >
              {" "}
              {t("browse")}
            </a>
            <p>{t("supported_file_types")}: JPG, PNG, DOCX, PDF, MP4</p>
            {send ? (
              <>
                <span className="mg-top-50" style={{ color: "#76C00D" }}>
                  75%
                </span>
                <div className="progress mg-top-10">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: "75%" }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    color={"#76C00D"}
                  ></div>
                </div>
                <p className="mg-top-20">{t("uploading")}</p>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
