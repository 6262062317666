import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import avatarHolder from "../../../../assets/images/user.png";
import { getLeadCategoryType } from "../../../../enum/LeadCategoryType";
import { getLeadStatusType } from "../../../../enum/LeadStatusType";
import ArrowEnterButton from "../../../components/Button/ArrowEnterButton";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { deleteLead } from "../LeadSlice";
import LeadStatus from "./LeadStatus";

const LeadManagementItem = (props) => {
  /// Initials
  const { lead } = props;
  const [name, setName] = useState("");
  const [myContact, setMyContact] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  /// Hooks
  const { t, i18n } = useTranslation();
  const { loading, setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /// UseEffect Load Data
  useEffect(() => {
    if (lead) {
      /// Check name
      const foundName =
        lead.type == 1
          ? lead.name
          : (lead.first_name || "") + " " + (lead.last_name || "");
      setName(foundName);

      /// Check My Contact
      const foundContact = lead.contacts.find((contact) => {
        return (
          typeof contact.objectId === "undefined" || contact.objectId === null
        );
      });
      if (typeof foundContact !== "undefined") {
        setMyContact(foundContact);
        /// Phone Number
        const email =
          (foundContact &&
            foundContact.emails &&
            foundContact.emails.length > 0 &&
            foundContact.emails[0].email) ||
          "";
        setEmail(email);
        /// Phone Number
        const phoneNumber =
          (foundContact &&
            foundContact.numbers &&
            foundContact.numbers.length > 0 &&
            (foundContact.numbers[0].country_phone_code || "") +
              (foundContact.numbers[0].number || "")) ||
          "";
        setPhoneNumber(phoneNumber);
      }
    }
  }, [lead]);

  /// Handle On Click Delete Lead
  const handleOnClickDeleteLead = async () => {
    if (window.confirm(t("are_you_sure"))) {
      try {
        setLoading(true);
        const request = {
          objectId: [lead.objectId],
        };
        const action = deleteLead(request);
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response.status === 200) {
          enqueueSnackbar(t(response.message), {
            variant: "success",
          });
          if (props.reload) {
            props.reload();
          }
        } else {
          enqueueSnackbar(t(response.message || ""), {
            variant: "error",
          });
        }
      } catch (error) {
        console.log("Delete Lead Error: ", error);
      } finally {
        setLoading(false);
      }
    } else {
      return;
    }
  };

  return (
    <>
      <tr className="btn-reveal-trigger ">
        <td className="customer_shop_single">
          <button onClick={handleOnClickDeleteLead}>
            <FontAwesomeIcon icon={faTrashAlt} size="1x" color={"#E94D64"} />
          </button>
        </td>
        <td className="py-2">
          <div className="media d-flex align-items-center">
            <div className="avatar avatar-xl mr-2">
              <img
                className="rounded-circle"
                src={lead && lead.url !== "" ? lead.url : avatarHolder}
                width="60"
                alt=""
                style={{
                  minWidth: "60px",
                  minHeight: "60px",
                  maxWidth: "60px",
                  maxHeight: "60px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="media-body lead-name">
              <h5 className="mb-0 fs--1 lead-item">{name}</h5>
            </div>
          </div>
        </td>
        {/* <td className="py-2 lead-item">Role</td> */}
        <td className="py-2 lead-item">
          {" "}
          {lead && (lead.type == 1 ? t("company") : t("people"))}
        </td>
        <td className="py-2">
          <a
            href={lead.is_view_data ? `mailto:${email}` : null}
            className="lead-item"
          >
            {lead.is_view_data ? email : "***"}
          </a>
        </td>
        <td className="py-2">
          <a
            href={lead.is_view_data ? `tel:${phoneNumber}` : null}
            className="lead-item"
          >
            {lead.is_view_data ? phoneNumber : "***"}
          </a>
        </td>
        <td>
          <div className="lead-status-container">
            {lead && lead.status != 0 && (
              <LeadStatus name={getLeadStatusType(lead.status).name || ""} />
            )}
            {lead && lead.category != 0 && (
              <LeadStatus
                name={getLeadCategoryType(lead.category).name || ""}
              />
            )}
            <ArrowEnterButton onClick={() => props.onClick(lead)} />
          </div>
        </td>
      </tr>
    </>
  );
};

LeadManagementItem.propTypes = {
  onClick: PropTypes.func,
  lead: PropTypes.object.isRequired,
};

LeadManagementItem.defaultProps = {
  onClick: null,
};

export default LeadManagementItem;
