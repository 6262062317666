import React from "react";
import { useDispatch } from "react-redux";
import { search } from "../../../../../chat/slices/ChatSlice";

const MessagesSearch = (props) => {
  /// MARK: - Initials;
  const dispatch = useDispatch();
  const onSearchChange = (e) => {
    dispatch(search({ search: e.target.value }));
  };

  return (
    <div className="mg-top-20 d-flex justify-content-between align-items-center messages-search">
      <input
        type="text"
        className="form-control"
        placeholder="Search people"
        onChange={onSearchChange}
      />
      <i className="fa fa-search" style={{ width: "40px" }}></i>
    </div>
  );
};

MessagesSearch.propTypes = {};

export default MessagesSearch;
