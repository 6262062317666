import React from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import TextField from "../components/TextField";
import SignInDescription from "../components/SignInDescription";
import { resetPassword } from "../AuthSlide";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { useSnackbar } from "notistack";

export default function ResetPassword(props) {
  ///Hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  /// validate
  const validateSchema = Yup.object({
    email: Yup.string().email(t("email_is_invalid")),
  });

  ///handle submit
  const handleOnsubmit = async (values) => {
    try {
      setLoading(true);
      const action = resetPassword(values);
      const resultAction = await dispatch(action);
      const response = unwrapResult(resultAction);
      if (response.status === 200) {
        enqueueSnackbar(t("email_request_password_sent"), {
          persist: false,
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("email_not_found"), {
          persist: false,
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Failed to reset password", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <span className="fs-17 font-w500 roamie-text-color-primary">
        {t("reset_password")}
      </span>
      <div className="d-block">
        <div className="d-flex justify-content-center align-items-center">
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validateSchema}
            onSubmit={handleOnsubmit}
          >
            {(formik) => (
              <Form className="signin-form">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <span
                    role="button"
                    onClick={props.return}
                    className="pt-2 fs-15 font-w500 text-primary mb-2"
                  >
                    {t("return_login")}
                  </span>
                  <div className="input-group mb-4 border-1 min-width200">
                    <span className="input-group-text fa fa-envelope"></span>
                    <TextField
                      className="form-control"
                      holder={t("email")}
                      type="email"
                      name="email"
                    />
                  </div>
                  <div className="blank-space"></div>
                  <button type="submit" className="btn btn-primary btn-login">
                    {t("request_forgot_password")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <SignInDescription register={props.register} />
    </>
  );
}
