import { param } from "jquery";
import axiosClient from "./axiosClient";

const userApi = {
  signIn: (params) => {
    const url = "/api/login";
    return axiosClient.post(url, params);
  },
  googleSignIn: (params) => {
    const url = "/api/signupSocial";
    return axiosClient.post(url, params);
  },
  signUp: (params) => {
    const url = "/api/signup";
    return axiosClient.post(url, params);
  },
  fetchUser: (params) => {
    const url = `/api/users/${params}`;
    return axiosClient.get(url);
  },
  pushFCMToken: (params) => {
    const url = "/api/push/installations";
    return axiosClient.post(url, params);
  },
  resetPassword: (params) => {
    const url = "/api/users/requestResetPassword";
    return axiosClient.post(url, params);
  },
  loginEncrypt: (params) => {
    const url = "/api/login-encrypt";
    return axiosClient.post(url, params);
  },
  deleteMyAccount: (params) => {
    const url = "/api/deleteMyAccount";
    return axiosClient.post(url, params);
  },
};

export default userApi;
