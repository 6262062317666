import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../../../assets/images/user.png";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { fetch } from "../../Auth/AuthSlide";
import { avatarUpdate } from "../SettingSlice";

const AvatarUser = (props) => {
  /// MARK: - Initials;
  const dispatch = useDispatch();
  const [setImage] = useState(false);
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { initData } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  /// Handle Upload Avatar
  const handleUploadvatar = (e) => {
    let formData = new FormData();
    formData.append("fileToUpload", e.target.files[0]);
    handleUpdateAvatar(formData);
  };

  /// Handle Upload Avatar
  const handleUpdateAvatar = async (data) => {
    try {
      setLoading(true);
      const action = avatarUpdate(data);
      const resultAction = await dispatch(action);
      const updateResponse = unwrapResult(resultAction);
      if (updateResponse.status === 200) {
        dispatch(fetch(initData.user_id));
        setImage(true);
        enqueueSnackbar(t("update_profile_successful"), {
          persist: false,
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(updateResponse.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update avatar failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <label htmlFor="avatar" className="d-flex justify-items-center">
        <div
          data-toggle="tooltip"
          data-placement="top"
          title="Change avatar"
          className="wrapper-container__header--avatar"
          style={{
            backgroundImage: `url('${
              (initData && initData.profile_image) || icon
            }')`,
          }}
        />
        <input
          hidden={true}
          type="file"
          id="avatar"
          accept="image/*"
          onChange={handleUploadvatar}
        />
      </label>
    </>
  );
};

export default AvatarUser;
