import axiosClient from "./axiosClient";

const leadApi = {
  getLeadsManagement: () => {
    const url = "api/classes/Lead";
    return axiosClient.get(url);
  },
  getActivities: (leadId) => {
    const url = `/api/activities/get_all_activities?limit=1&lead_id=${leadId}`;
    return axiosClient.get(url);
  },
  getNotes: (leadId) => {
    const url = `/api/classes/Note?limit=100&lead_id=${leadId}`;
    return axiosClient.get(url);
  },
  getFiles: (params) => {
    const url = `/api/requests/Lead/getFiles`;
    return axiosClient.post(url, params);
  },
  getDeals: (params) => {
    const url = "/api/classes/getDeals";
    return axiosClient.post(url, params);
  },
  addLeadsManagement: (params) => {
    const url = "api/requests/Lead/addLeadWithContact";
    return axiosClient.post(url, params);
  },
  getLeadsPeople: (params) => {
    const { type } = params;
    const url = `api/classes/Lead?where={"type": ${type}}`;
    return axiosClient.get(url);
  },
  getLeadsCompany: (params) => {
    const { type } = params;
    const url = `api/classes/Lead?where={"type": ${type}}`;
    return axiosClient.get(url);
  },
  addNote: (params) => {
    const url = "/api/classes/Note";
    return axiosClient.post(url, params);
  },
  updateNote: (params, noteId) => {
    const url = `/api/classes/Note/${noteId}`;
    return axiosClient.put(url, params);
  },
  uploadFile: (params) => {
    const url = "/api/classes/Lead/uploadFile";
    return axiosClient.post(url, params);
  },
  submitDeal: (params) => {
    const url = "/api/classes/Deal";
    return axiosClient.post(url, params);
  },
  updateDeal: (params, dealId) => {
    const url = `/api/classes/Deal/${dealId}`;
    return axiosClient.put(url, params);
  },
  deleteLead: (params) => {
    const url = "/api/requests/Lead/deleteLeads";
    return axiosClient.delete(url, { data: params });
  },
  uploadLeadAvatar: (params) => {
    const url = "/api/classes/Lead/uploadImage";
    return axiosClient.post(url, params);
  },
  updateLead: (params, leadId) => {
    const url = `/api/classes/Lead/${leadId}`;
    return axiosClient.put(url, params);
  },
  submitActivity: (params) => {
    const url = "/api/activities/submitActivity";
    return axiosClient.post(url, params);
  },
  filterLead: (params) => {
    const url = `/api/classes/Lead?fix_where=0&where=${params}`;
    return axiosClient.get(url);
  },
};

export default leadApi;
