import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobal } from "reactn";
import messageApi from "../../../../../chat/api/messageApi";
import { moreMessages } from "../../../../../chat/slices/IOSlice";
import ChatReceiveItemTest from "../Chat/ChatReceiveItemTest";
import ChatSendItemTest from "../Chat/ChatSendItemTest";

const Conversation = (props) => {
  /// Initials
  const { messages } = useSelector((state) => state.ios);
  const user = useGlobal("user")[0];
  const room = useSelector((state) => state.ios.room);
  // const { typing } = useSelector(state => state.messages)
  const [loading, setLoading] = useState(false);
  const chat = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("messages changed");
  }, [messages]);

  const msgs = [...messages]
    .reverse()
    .filter((x) => !_.isEmpty(x) && !_.isEmpty(x.author));

  /// Handle get more message
  const onScroll = async () => {
    if (Math.abs(chat.current.scrollTop + chat.current.scrollHeight) < 700) {
      if (loading) return;
      setLoading(true);
      await messageApi
        .getMore({ roomID: room._id, firstMessageID: messages[0]._id })
        .then((res) => {
          setLoading(false);
          dispatch(moreMessages(res.data));
        })
        .catch((error) => {
          console.log("Failed to load more message", error);
          setLoading(false);
        });
    }
  };

  return (
    <div className="chat-box scroll-gray" ref={chat} onScroll={onScroll}>
      {/* {typing && <span className="fs-13">Typing ...</span>} */}
      {msgs &&
        msgs.map((msg, index) => {
          const isMine = user.id === msg.author._id;
          if (isMine)
            return (
              <ChatSendItemTest
                key={msg._id}
                message={msg}
                previous={msgs[index - 1]}
                next={msgs[index + 1]}
                isMine={isMine}
              />
            );
          else
            return (
              <>
                <ChatReceiveItemTest
                  key={msg._id}
                  message={msg}
                  previous={msgs[index - 1]}
                  next={msgs[index + 1]}
                  isMine={isMine}
                />
              </>
            );
        })}
      {/*<ChatSendItem/>*/}
      {/*<ChatReceiveItem/>*/}
    </div>
  );
};

export default Conversation;
