import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGlobal } from "reactn";
import { getRoom } from "../../../../../chat/slices/IOSlice";
import { MomentUtil } from "../../../../../utils/moment_util";
import AvatarChat from "../AvatarChat";

const Room = ({ room, isFirst }) => {
  /// MARK: - Initials;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roomsWithNewMessages = useSelector(
    (state) => state.messages.roomsWithNewMessages
  );
  const { onlineUsers } = useSelector((state) => state.ios);
  // const {fullName, username, _id, email, marginTop} = props
  // const displayName = _.isEmpty(_.trim(fullName)) ? username: fullName;
  // const {lastMessage} = useSelector(state => state.ios.rooms).filter(x => x._id === _id) || "last msg";
  // const setNav = useGlobal('nav')[1];
  // const [rooms, setRooms] = useGlobal('rooms');
  // const [hover, setHover] = useState(false);
  // const [roomId, setRoomId] = useState(null);

  const user = useGlobal("user")[0];

  let other = {};
  room.people.forEach((person) => {
    if (user.id !== person._id) other = person;
  });

  if (!other.firstName) {
    other = { ...other, firstName: "Unknown", lastName: "User" };
  }
  const title = (
    room.isGroup ? room.title : `${other.firstName} ${other.lastName}`
  ).substr(0, 22);
  let lastMessage = room.lastMessage;
  let text = "";

  if (!lastMessage && room.isGroup) text = t("new_group_created");
  if (!lastMessage && !room.isGroup) text = t("no_message");

  if (!lastMessage) lastMessage = {};

  if (lastMessage.author === user.id && !room.isGroup) text += t("you");

  switch (lastMessage.type) {
    case "file":
      text += t("sent_a_file");
      break;
    case "image":
      text += t("sent_a_picture");
      break;
    default:
      text += lastMessage.content || "";
  }

  const time = lastMessage
    ? MomentUtil(lastMessage.date, "MMM DD - h:mm A")
    : "";

  useEffect(() => {
    // const existed = rooms.find(x => x.id === room.id);
    // setCurrentRoom({...existed});
    // console.log("current room: "+existed);
    // let otherPerson = {}
    // if(existed){
    //     existed.people.forEach(person => {
    //         if (user.id !== person._id)
    //             otherPerson = person;
    //     });
    // }
    // setOther({...otherPerson})
  }, []);

  const getRoomInfo = async (e) => {
    e.preventDefault();
    const { _id } = room;

    await dispatch(getRoom({ id: room._id }));
  };

  const hasNewMessage =
    roomsWithNewMessages && roomsWithNewMessages.includes(room._id);

  const getStatus = () => {
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "busy")
        .length > 0
    )
      return "busy";
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "online")
        .length > 0
    )
      return "online";
    if (
      onlineUsers.filter((u) => u.id === other._id && u.status === "away")
        .length > 0
    )
      return "away";
    return "offline";
  };

  return (
    <div
      className={`message-item d-flex mb-2 ${isFirst ? "mg-top-30" : ""}`}
      style={{ hover: { color: "#000" } }}
      onClick={getRoomInfo}
    >
      <AvatarChat
        status={getStatus()}
        avatarType="actives-chat"
        hasPicture={false}
        user={other}
      />
      <div className="message-item-detail d-flex flex-column justify-content-center mg-left-10">
        <div className="message-item-name">
          <span className="fs-17" style={{ color: "#515671" }}>
            {title}
          </span>
          {!hasNewMessage ? (
            <></>
          ) : (
            <div className="messages-badge mg-right-10">
              <span className="fs-12 font-w600" style={{ color: "white" }}>
                {roomsWithNewMessages &&
                  roomsWithNewMessages.filter((r) => room._id === r).length}
              </span>
            </div>
          )}
        </div>
        <span className="fs-12" style={{ color: "#AFBBC6" }}>
          {text.substr(0, 26)}
          {text.length > 26 && "..."}
        </span>
        <span className="fs-9 mt-1" style={{ color: "#AFBBC6" }}>
          {time}
        </span>
      </div>
    </div>
  );
};

Room.propTypes = {};

export default Room;
