import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PayMentForm from "../../components/PayMent/PayMentForm";
import { logout } from "../Auth/AuthSlide";
import { getPackage } from "../Settings/SettingSlice";

export default function ExpiredModal(props) {
  /// Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  /// Initials
  const { expired } = props;
  const [showPayMent, setShowPayMent] = useState(false);
  const [amount, setAmount] = useState({});

  const handleClose = () => setShowPayMent(false);
  const handleShow = () => setShowPayMent(true);
  const handleLogout = async () => {
    await dispatch(logout());
    history.go(0);
  };

  /// Effect get amount
  useEffect(() => {
    const getAmount = async () => {
      try {
        const action = getPackage();
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response.status === 200) {
          setAmount(response.data[0]);
        } 
      } catch (error) {
        console.log("Failed to get package", error);
      }
    };
    getAmount();
  }, []);
  return (
    <>
      <Modal show={expired} className="payment-modal">
        <Modal.Header closeButton>
          <Modal.Title>{t("expirated")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="expired-wrapper">
            <div className="d-flex justify-content-center">
              <div className="expired-head bg-white d-flex justify-content-center align-items-center">
                <span className="fs-17 roamie-text-color-primary">
                  {t("monthly")}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <span className="fs-48 expired-money">
                {t(`$${(amount && amount.price_usd) || 0}`)}
                <span className="fs-17">{"/" + t("month")}</span>
              </span>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <div className="roamie-text-color-primary">
                <p>
                  {t(
                    "1. A Personal Assistant to remind you of upcoming appointments and tasks"
                  )}
                </p>
                <p>
                  {t(
                    "2. A Sales Assistant that manages your leads throughout the sales cycle"
                  )}
                </p>
                <p>
                  {t(
                    "3. A Sales Advisor that keeps you up to date with sales progress and targets"
                  )}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowPayMent(true)}>
            {t("pay")}
          </Button>
          <Button variant="dark" className="text-black" onClick={handleLogout}>
            {t("logout")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPayMent} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("pay_for_month")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayMentForm cancel={handleShow} type={2} />
        </Modal.Body>
      </Modal>
    </>
  );
}
