import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const LeadProfileInfo = (props) => {
  /// Hooks
  const { t, i18n } = useTranslation();

  return (
    <div className="lead_profile_info mg-top-20">
      <div className="d-flex justify-content-start align-items-start">
        <div>
          <i className={props.icon} style={{ color: "#10193D" }}></i>
        </div>
        <div className="d-flex flex-column mg-left-10">
          <span className="fs-15 font-w400 roamie-text-color-primary">
            {props.title}
          </span>
          <hr className="lead_profile_info-dividier" />
          <span className="fs-16 font-w500 roamie-text-color-primary">
            {props.content}
          </span>
        </div>
      </div>
    </div>
  );
};

LeadProfileInfo.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LeadProfileInfo;
