import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import icon from "../../../../assets/icons/check-mark.png";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { signUp } from "../AuthSlide";
import SignInDescription from "../components/SignInDescription";
import SignUpDescription from "../components/SignUpDescription";
import SocialGroupButtons from "../components/SocialGroupButtons";
import TextField from "../components/TextField";

const SignUp = (props) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  /// Active password
  const [active, setActive] = useState(false);
  //validate Yup
  const validateSchema = Yup.object({
    email: Yup.string()
      .email(t("email_is_invalid"))
      .required(t("email_required")),

    password: Yup.string()
      .required(t("password_required"))
      .min(6, t("password_length_required")),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], t("password_not_match"))
      .required(t("confirm_password_required"))
      .min(6, t("confirm_password_length_required")),
  });

  //submit signup
  const handleSubmitSignUp = async (values) => {
    try {
      setLoading(true);
      const data = { email: values.email, password: values.password };
      const action = signUp(data);
      const resultAction = await dispatch(action);
      const userSignUpResponse = unwrapResult(resultAction);
      if (userSignUpResponse.status === 200) {
        setSuccess(!success);
      } else {
        enqueueSnackbar(t("email_is_already_existed"), {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(t("email_is_already_existed"), {
        variant: "error",
      });
      console.log("Faile to sign up:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="signup-container mt-2">
      <span className="fs-17 font-w500 roamie-text-color-primary">
        {t("register_to_roamie")}
      </span>
      <div
        className={`d-flex justify-content-center d-${
          success ? "block" : "none"
        }`}
      >
        <div className="signup-form__success">
          <img className="mt-5" src={icon} alt="icon" />
          <p>
            {t("we_sent_an_activation_email")}
            <br />
            {t("please_confirm_your_email")}
          </p>
          <button onClick={props.login} className="btn btn-primary mt-5">
            {t("login")}
          </button>
        </div>
      </div>
      <div className={success ? "d-none" : "d-block"}>
        <SocialGroupButtons />
        <div className="d-flex justify-content-center">
          <Formik
            initialValues={{ email: "", password: "", confirmPassword: "" }}
            validationSchema={validateSchema}
            onSubmit={(values) => {
              handleSubmitSignUp(values);
            }}
          >
            {(formik) => (
              <Form className="signup-form ">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <span className="pt-2 pt-2 fs-16 font-w500 roamie-text-color-primary op2 mb-2">
                    {t("or")}
                  </span>
                  <div className="input-group mb-4 border-1 min-width200">
                    <span className="input-group-text fa fa-envelope"></span>
                    <TextField
                      className="form-control"
                      holder={t("email")}
                      type="email"
                      name="email"
                    />
                  </div>
                  <div className="input-group mb-4 min-width200">
                    <span className="input-group-text fa fa-lock border border-start-2"></span>
                    <TextField
                      className="form-control"
                      holder={t("password")}
                      type={active ? "text" : "password"}
                      name="password"
                    />
                    <span
                      onClick={() => {
                        setActive(!active);
                      }}
                      className={`pointer input-group-text fa fa-eye${
                        active ? "" : "-slash"
                      }`}
                    ></span>
                  </div>
                  <div className="input-group mb-4 min-width200">
                    <span className="input-group-text fa fa-lock border border-start-2"></span>
                    <TextField
                      className="form-control"
                      holder={t("confirm_password")}
                      type={active ? "text" : "password"}
                      name="confirmPassword"
                    />
                    <span
                      onClick={() => {
                        setActive(!active);
                      }}
                      className={`pointer input-group-text fa fa-eye${
                        active ? "" : "-slash"
                      }`}
                    ></span>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary min-width200"
                  >
                    {t("register")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {success ? (
        <SignInDescription register={props.login} />
      ) : (
        <SignUpDescription login={props.login} />
      )}
    </div>
  );
};

export default SignUp;
