import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../components/Empty/Empty";
import { useLoading } from "../../components/Loading/LoadingContext";
import { getMyTeam } from "../SalesTeam/SalesTeamSlice";
import AddTask from "./AddTask";
import Task from "./components/Task";
import TaskHead from "./components/TaskHead";
import { getTasks } from "./TasksSlice";

const Tasks = (props) => {
  /// MARK: - Initials
  const { t, i18n } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [taskSelected, setTaskSelected] = useState({});
  const [taskReaload, setTaskReaload] = useState(false);
  const dispatch = useDispatch();
  const { loading, setLoading } = useLoading();
  const [saleTeams, setSaleTeams] = useState([]);
  const [teamSelect, setTeamSelect] = useState({
    value: -1,
    user_name: t("all"),
  });
  const { current } = useSelector((state) => state.auth);

  /// Drawer Add Task
  const toggleDrawerAddTask = () => {
    setIsOpen((prevState) => !prevState);
  };

  /// Filter task type
  const [type, setType] = useState(0);
  const handleChangeType = (value) => {
    setType(value);
  };

  /// Get Teams
  useEffect(() => {
    const getSalesTeamAsync = async () => {
      try {
        const action = getMyTeam();
        const resultActioin = await dispatch(action);
        const response = unwrapResult(resultActioin);
        if (response) {
          let salesTeam = (response.data || []).filter(
            (team) => team.status === "1"
          );
          if (current) {
            salesTeam.push({
              value: current.user_id,
              user_name: current.user_name,
            });
          }
          setSaleTeams(salesTeam);
        }
      } catch (error) {
        console.log("Get sale teams failed", error);
      }
    };
    if (current.level == 3) {
      getSalesTeamAsync();
    }
  }, []);

  /// UseEffect
  useEffect(() => {
    const getTasksAsync = async () => {
      try {
        setLoading(true);
        const salePersonId = current.level == 3 ? teamSelect.value : null;
        const action = getTasks({ salePersonId });
        const resultAction = await dispatch(action);
        const tasks = unwrapResult(resultAction);
        setTasks(tasks);
      } catch (error) {
        console.log("Get Tasks Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getTasksAsync();
  }, [taskReaload, teamSelect]);

  /// Handle On Click Task
  const handleOnClickTask = (task) => {
    setTaskSelected(task);
    toggleDrawerAddTask();
  };

  const handleOpenDrawerAddTask = () => {
    setTaskSelected(null);
    toggleDrawerAddTask();
  };

  ///Handle task reload
  const handleTaskReload = () => {
    setTaskReaload((preState) => !preState);
  };

  return (
    <>
      <div className="wrapper-tasks-container">
        {/* Title */}
        <div className="mt-2 mb-2">
          <TaskHead
            type={type}
            changeType={handleChangeType}
            onClick={handleOpenDrawerAddTask}
          />
        </div>

        {/* Filter */}
        <div className="mb-2">
          {current.level == 3 ? (
            <div className="col col-md-4 col-lg-4">
              <span className="fs-13 font-w400 op9 roamie-text-color-primary text-truncate">
                {t("saleperson")}
              </span>
              <Dropdown className="roamie-drop-down mg-top-14 h-50">
                <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
                  <span className="fs-12 font-w400 roamie-text-color-primary text-dropdown-select text-truncate">
                    {teamSelect.user_name || ""}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="fs-12"
                    onClick={() => {
                      setTeamSelect({
                        value: -1,
                        user_name: t("all"),
                      });
                    }}
                  >
                    {t("all")}
                  </Dropdown.Item>
                  {saleTeams &&
                    saleTeams.length > 0 &&
                    saleTeams.map((team) => (
                      <Dropdown.Item
                        onClick={() => {
                          setTeamSelect({
                            value: team.user_id || team.value,
                            user_name: team.user_name,
                          });
                        }}
                        key={team.user_id}
                      >
                        {team.user_name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
        </div>

        {/* Tasks */}
        <div>
          {tasks &&
          tasks.filter((task) => task.task_type === type).length == 0 ? (
            <Empty text={t("do_not_have_any_tasks")} />
          ) : null}
          {tasks &&
            tasks.length > 0 &&
            tasks.map((task) => {
              if (task.task_type === type) {
                return (
                  <Task
                    key={task.objectId}
                    task={task}
                    onClick={handleOnClickTask}
                    reload={handleTaskReload}
                  />
                );
              }
            })}
        </div>

        {/* Drawer */}
        <Drawer
          className="lead-drawer"
          variant="temporary"
          open={isOpen}
          anchor={"right"}
          onBackdropClick={toggleDrawerAddTask}
        >
          <AddTask
            reload={handleTaskReload}
            onClickCloseDrawer={toggleDrawerAddTask}
            task={taskSelected}
          />
        </Drawer>
      </div>
    </>
  );
};

Tasks.propTypes = {};

export default Tasks;
