import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGlobal } from "reactn";
import messageApi from "../../../../../chat/api/messageApi";
import { addMessage } from "../../../../../chat/slices/IOSlice";
import { sendMessage } from "../../../../../chat/slices/MessageSlice";

const fileSuport = ["docx", "pdf"];
const imageSuport = ["jpg", "png"];
const videoSuport = ["mp4"];
const audioSuport = ["mp3"];

export default function ChatSendFiles(props) {
  /// Initials
  const { active, sendFile } = props;
  const [over, setOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [send, setSend] = useState(false);
  const { room } = useSelector((state) => state.ios);
  const { user } = useSelector((state) => state.chat);

  /// Hook
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const input = useRef();
  const ref = useGlobal("ref")[0];
  /// Handle Files upload
  const handleFiles = async (file) => {
    let tmpRefs = [];
    for (let i = 0; i < file.length; i++) {
      const fileType = file[i].name.slice(
        ((file[i].name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      if (fileSuport.includes(fileType)) {
        /// Handle send file
        for (let i = 0; i < file.length; i++) {
          if (file[i].size / (1024 * 1024) > 10)
            return alert("File exceeds 10MB limit!");
        }
        for (let i = 0; i < file.length; i++) {
          const fileSend = file[i];
          tmpRefs.push(ref + i);
          const res = await messageApi.upLoadFile(fileSend, ref + i);
          var msg = {
            roomID: room._id,
            authorID: user.id,
            content: res.data.file.shieldedID,
            type: "file",
            fileID: res.data.file._id,
          };
          let newMessage = {
            _id: Math.random(),
            author: { ...user, _id: user.id },
            content: res.data.file.shieldedID,
            type: "file",
            date: moment(),
            file: res.data.file,
          };
          await dispatch(sendMessage(msg));
          await dispatch(addMessage({ message: newMessage }));
        }
      } else if (imageSuport.includes(fileType)) {
        /// Handle send image
        const image = file[i];
        tmpRefs.push(ref + i);
        const res = await messageApi.sendFile(image, ref + i);
        var msg = {
          roomID: room._id,
          authorID: user.id,
          content: res.data.image.shieldedID,
          contentType: "text",
          type: "image",
        };
        let newMessage = {
          _id: Math.random(),
          author: { ...user, _id: user.id },
          content: res.data.image.shieldedID,
          type: "image",
          date: moment(),
        };
        await dispatch(sendMessage(msg));
        await dispatch(addMessage({ message: newMessage }));
      } else if (videoSuport.includes(fileType)) {
        /// Handle send video
        alert("video");
      } else if (audioSuport.includes(fileType)) {
        /// Handle send audio
        alert("audio");
      } else {
        alert("file not support");
      }
    }
  };
  return (
    <>
      {active ? (
        <div
          className={`chat-files-container ${over ? "drop" : ""}`}
          onDrop={(e) => {
            e.preventDefault();
            e.persist();
            setFiles([...files, e.dataTransfer.files[0]]);
            setOver(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setOver(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setOver(false);
          }}
        >
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                sendFile(false);
                setFiles([]);
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
          <input
            ref={input}
            type="file"
            id="fileElem"
            className="d-none"
            onChange={(e) => handleFiles(e.target.files)}
          />
          <div className="chat-file-message mt-5 w-100">
            <FontAwesomeIcon
              className="paper-icon"
              icon={faPaperclip}
              color="white"
            />
            <h3 style={{ color: "#76C00D" }} className="mg-top-50">
              {t("upload_file")}
            </h3>
            <span>{t("drag_file_here_or")}</span>
            <a
              onClick={() => input.current.click()}
              className="pointer"
              style={{ color: "#76C00D" }}
            >
              {" "}
              {t("browse")}
            </a>
            <p>{t("supported_file_types")}: JPG, PNG, DOCX, PDF, MP4</p>
            {send ? (
              <>
                <span className="mg-top-50" style={{ color: "#76C00D" }}>
                  75%
                </span>
                <div className="progress mg-top-10">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: "75%" }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    color={"#76C00D"}
                  ></div>
                </div>
                <p className="mg-top-20">{t("uploading")}</p>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
