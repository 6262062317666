import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homeApi from "../../../api/homeApi";
import userApi from "../../../api/userApi";

export const getTeamMembersRanking = createAsyncThunk(
  "/api/get-team-member-ranking",
  async (payload) => {
    const response = await homeApi.getTeamMembersRanking(payload);
    return response.data || [];
  }
);

export const getSummaryActivities = createAsyncThunk(
  "/api/get-summary-activities",
  async (payload) => {
    const response = await homeApi.getSummaryActivities(payload);
    return response;
  }
);

export const getSummaryActivitiesPercent = createAsyncThunk(
  "/api/get-summary-activities",
  async (payload) => {
    const response = await homeApi.getSummaryActivities(payload);
    return response || [];
  }
);
export const getSummaryDeals = createAsyncThunk(
  "api/get-summary-deals",
  async (payload) => {
    const response = await homeApi.getSummaryDeals(payload);
    return response.data || [];
  }
);

export const getSummaryPercent = createAsyncThunk(
  "api/get-summary-deals",
  async (params) => {
    const response = await homeApi.getSummaryDealsPercent(params);
    return response.data || [];
  }
);

export const getUserInfo = createAsyncThunk(
  "get-user-info",
  async (payload) => {
    const response = await userApi.fetchUser(payload);
    return response;
  }
);

const homeSlice = createSlice({
  name: "HomeSlice",
  initialState: {
    userInfo: {},
  },
  reducers: {},
  extraReducers: {
    [getUserInfo.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

const { reducer, actions } = homeSlice;
export default reducer;
