import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MomentUtil } from "../../../../../utils/moment_util";
import { useLoading } from "../../../../components/Loading/LoadingContext";
import { addNewTask } from "../../../Tasks/TasksSlice";
import vi from "date-fns/locale/vi";
import en from "date-fns/locale/en-US";
import { CurrentCountry } from "../../../../../utils/language_util";

export default function TaskModal(props) {
  /// Initials
  const [renewalDate, setRenewal] = useState(null);
  const [referalDate, setReferal] = useState(null);
  const [upSellDate, setUpSell] = useState(null);
  const [date, setDate] = useState([]);
  const { show, set, lead, close } = props;

  /// Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  /// Handle Modal
  const handleClose = () => {
    set(false);
    close();
  };

  /// Handle reset
  const handleReset = () => {
    setRenewal(null);
    setReferal(null);
    setUpSell(null);
    setDate([]);
  };

  /// Set date array
  const handleSetDate = (datePick, type) => {
    const item = {
      date: datePick.toISOString(),
      type: type,
    };
    setDate([...date, item]);
  };
  const handleSave = async () => {
    setLoading(true);
    if (date && date.length > 0) {
      date.map(async (item) => {
        try {
          const request = {
            tasks: [
              {
                objectId: null,
                title: t(item.type),
                end_date: item.date,
                reminder: {
                  reminder_time_type: 1,
                },
                task_type: 0,
                lead: {
                  objectId: lead && lead.objectId,
                },
                description: t(item.type),
                lead_id: lead && lead.objectId,
              },
            ],
          };
          const action = addNewTask(request);
          const resultAction = await dispatch(action);
          const response = unwrapResult(resultAction);
          if (response.status === 200) {
            enqueueSnackbar(`${t(response.message || "")}`, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(t(response.message || ""), {
              variant: "error",
            });
          }
        } catch (error) {
          console.log("Add New Task Error:", error);
        } finally {
          setLoading(false);
        }
      });
      set(false);
      close();
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("service")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DatePicker
          className="custom__date__picker px-3 mb-3"
          onChange={(date) => {
            setRenewal(date);
            handleSetDate(date, "renewal");
          }}
          locale={CurrentCountry().includes("vn") ? vi : en}
          showTimeInput
          customInput={
            <div className="input-group border-text-input d-flex justify-content-between align-items-center">
              <span>
                {renewalDate
                  ? renewalDate && MomentUtil(renewalDate, "DD MMM yyyy, HH:mm")
                  : t("renewal")}
              </span>
              <FontAwesomeIcon icon={faCalendarAlt} size="1x" color="#B5BBDE" />
            </div>
          }
        />
        <DatePicker
          className="custom__date__picker px-3 mb-3"
          onChange={(date) => {
            setReferal(date);
            handleSetDate(date, "referal");
          }}
          locale={CurrentCountry().includes("vn") ? vi : en}
          showTimeInput
          customInput={
            <div className="input-group border-text-input d-flex justify-content-between align-items-center">
              <span>
                {referalDate
                  ? referalDate && MomentUtil(referalDate, "DD MMM yyyy, HH:mm")
                  : t("referal")}
              </span>
              <FontAwesomeIcon icon={faCalendarAlt} size="1x" color="#B5BBDE" />
            </div>
          }
        />
        <DatePicker
          className="custom__date__picker px-3 mb-3"
          onChange={(date) => {
            setUpSell(date);
            handleSetDate(date, "up_sell");
          }}
          locale={CurrentCountry().includes("vn") ? vi : en}
          showTimeInput
          customInput={
            <div className="input-group border-text-input d-flex justify-content-between align-items-center">
              <span>
                {upSellDate
                  ? upSellDate && MomentUtil(upSellDate, "DD MMM yyyy, HH:mm")
                  : t("up_sell")}
              </span>
              <FontAwesomeIcon icon={faCalendarAlt} size="1x" color="#B5BBDE" />
            </div>
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger text-light" onClick={handleSave}>
          {t("save")}
        </button>
        <button className="btn btn-primary text-light" onClick={handleReset}>
          {t("reset")}
        </button>
        <button className="btn btn-light" onClick={handleClose}>
          {t("cancel")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
