import {
  faCalendarAlt,
  faChevronRight,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import en from "date-fns/locale/en-US";
import vi from "date-fns/locale/vi";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ActivityType, {
  getTranslateDescription
} from "../../../enum/ActivityType";
import ReminderType, { reminderTypes } from "../../../enum/ReminderType";
import { CurrentCountry } from "../../../utils/language_util";
import { MomentUtil } from "../../../utils/moment_util";
import { useLoading } from "../../components/Loading/LoadingContext";
import { getLeadsManagement, submitActivity } from "../Leads/LeadSlice";
import { addNewTask, updateTask } from "./TasksSlice";

const AddTask = (props) => {
  /// MARK: -Initials
  const { leadProfile } = props;
  const [taskDate, setTaskDate] = useState(new Date());
  const [reminderType, setReminderType] = useState(ReminderType.atTime);
  const [leadSelected, setLeadSelected] = useState(null);
  const [leads, setLeads] = useState([]);
  const [taskSelected, setTaskSelected] = useState(props.task);

  /// Hooks
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const authState = useSelector((state) => state.auth);

  /// Handle On Change Reminder Type
  const handleOnChangeReminderType = (event) => {
    const reminderType = reminderTypes.find(function (element) {
      return element.value == event.target.value;
    });
    setReminderType(reminderType);
  };

  /// Handle On Change Lead
  const handleOnChangeLead = (event) => {
    let value = event.target.value;
    if (value === -1) {
      return;
    }
    const lead = leads.find(function (element) {
      return element.objectId == value;
    });
    setLeadSelected(lead);
  };

  /// Valiedate Form
  const validateSchema = Yup.object({
    title: Yup.string().required(t("task_title_required")),
    lead: Yup.number()
      .positive(t("lead_is_required"))
      .required(t("lead_is_required")),
  });

  /// Add New Task
  const handleAddNewTask = async (values) => {
    try {
      setLoading(true);
      const { title, description } = values;
      const request = {
        tasks: [
          {
            objectId: null,
            title: title,
            end_date: taskDate.toISOString(),
            reminder: {
              reminder_time_type: reminderType.value,
            },
            task_type: 0,
            lead: {
              objectId: leadSelected.objectId,
            },
            description: description,
            lead_id: leadSelected.objectId,
          },
        ],
      };
      const action = addNewTask(request);
      const resultAction = await dispatch(action);
      const response = unwrapResult(resultAction);
      if (response.status === 200) {
        await handleSubmitActivity();
        enqueueSnackbar(`${t(response.message || "")}`, {
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
        if (props.onClickCloseDrawer) {
          props.onClickCloseDrawer();
        }
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Add New Task Error:", error);
    } finally {
      setLoading(false);
    }
  };

  /// Update Task
  const handleUpdateTask = async (values) => {
    try {
      setLoading(true);
      const { title, description } = values;
      const request = {
        tasks: [
          {
            objectId: taskSelected && taskSelected.objectId,
            title: title,
            end_date: taskDate.toISOString(),
            reminder: {
              reminder_time_type: reminderType.value,
            },
            task_type: 0,
            lead: {
              objectId: leadSelected && leadSelected.objectId,
            },
            description: description,
            lead_id: leadSelected && leadSelected.objectId,
          },
        ],
      };
      const action = updateTask(request);
      const resultAction = await dispatch(action);
      const response = unwrapResult(resultAction);
      if (response.status === 200) {
        enqueueSnackbar(`${t(response.message || "")}`, {
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
        if (props.onClickCloseDrawer) {
          props.onClickCloseDrawer();
        }
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update Task Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  /// Handle Submit Activity
  const handleSubmitActivity = async () => {
    try {
      const leadId = leadSelected.objectId;
      const leadName =
        leadSelected.type === 1
          ? leadSelected.companyName || ""
          : (leadSelected.first_name || "") +
            " " +
            (leadSelected.last_name || "");
      const request = {
        lead_id: leadId,
        type: ActivityType.activityTask.value,
        description: t(
          getTranslateDescription(ActivityType.activityTask.value),
          {
            sender:
              (authState.first_name || "") + " " + (authState.last_name || ""),
            receiver: leadName,
          }
        ),
      };
      const action = submitActivity(request);
      await dispatch(action);
    } catch (error) {
      console.log("Submit Activity Error: ", error);
    }
  };

  /// UseEffect Leads
  useEffect(() => {
    async function fetchData() {
      try {
        const resultAction = await dispatch(getLeadsManagement());
        const leads = unwrapResult(resultAction);
        setLeads(leads);
        if (taskSelected) {
          /// Check Leads Selected
          const lead = leads.find(function (element) {
            return element.objectId == taskSelected.lead.objectId;
          });
          setLeadSelected(lead);

          /// Check Tasks Selected
          const reminderType =
            reminderTypes.find(function (element) {
              return element.value == taskSelected.reminder.reminder_time_type;
            }) || ReminderType.atTime;
          setReminderType(reminderType);

          /// Task Date
          setTaskDate(new Date(taskSelected.end_date));
        }
        if (leadProfile) {
          setLeadSelected(leadProfile);
        }
      } catch (error) {
        console.log("Get Leads Error: ", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="wrapper__container-add-task padding-20">
      {/* Header */}
      <div className="wrapper__container-header">
        <button onClick={props.onClickCloseDrawer}>
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ fontSize: "1.25rem" }}
            color="#CDD2F3"
          />
        </button>
        <span className="fs-16 font-w600 roamie-text-color-primary">
          {taskSelected ? t("update_task") : t("create_new_task")}
        </span>
      </div>

      {/* Form */}
      <div className="wrapper__container-form">
        <Formik
          initialValues={{
            title: (taskSelected && taskSelected.title) || "",
            lead: -1,
            description: (taskSelected && taskSelected.description) || "",
          }}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            taskSelected ? handleUpdateTask(values) : handleAddNewTask(values);
          }}
        >
          {({ errors, setFieldValue }) => (
            <Form className="col-10 mx-auto mg-top-40">
              {/* Task Title  */}
              <div className="input-group mb-3 border-text-input d-flex align-items-center justify-content-center roamie-button-background-color">
                <Field
                  type="text"
                  className="form-control placeholder--white-color task-title fs-14 font-w400"
                  placeholder={t("task_title")}
                  name="title"
                />
                <div className="px-3">
                  <FontAwesomeIcon icon={faPen} size="1x" color="white" />
                </div>
                <ErrorMessage component="div" className="error" name="title" />
              </div>

              {/* Description */}
              <div className="input-group mb-3 border-text-input">
                <Field
                  type="text"
                  className="form-control fs-14 font-w400"
                  placeholder={t("task_description")}
                  name="description"
                />
              </div>

              {/* Date Picker */}
              <DatePicker
                className="custom__date__picker px-3 mb-3"
                selected={taskDate}
                onChange={(date) => setTaskDate(date)}
                showTimeInput
                locale={CurrentCountry().includes("vn") ? vi : en}
                customInput={
                  <div className="fs-14 input-group border-text-input d-flex justify-content-between align-items-center">
                    <span>
                      {taskDate && MomentUtil(taskDate, "DD MMM yyyy, HH:mm")}
                    </span>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="1x"
                      color="#B5BBDE"
                    />
                  </div>
                }
              />

              {/* Reminder Type */}
              <div className="fs-14 input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <select
                  value={reminderType.value}
                  onChange={handleOnChangeReminderType}
                  className="placeholder-color form-select bg-transparent"
                >
                  {reminderTypes &&
                    reminderTypes.length > 0 &&
                    reminderTypes.map((reminderType, index) => (
                      <option
                        defaultValue={
                          taskSelected &&
                          taskSelected.reminder.reminder_time_type ==
                            reminderType.value
                        }
                        value={reminderType.value}
                        key={index}
                      >
                        {t(reminderType.title)}
                      </option>
                    ))}
                </select>
              </div>

              {/* Lead */}
              <div className="fs-14 input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <select
                  value={leadSelected && leadSelected.objectId}
                  onChange={(option) => {
                    setFieldValue("lead", option.target.value);
                    handleOnChangeLead(option);
                  }}
                  className="placeholder-color form-select bg-transparent"
                  name="lead"
                >
                  <option defaultValue={-1} value={-1}>
                    {t("add_leads")}
                  </option>
                  {leads &&
                    leads.length > 0 &&
                    leads.map((lead, index) => (
                      <option
                        defaultValue={
                          leadProfile
                            ? leadProfile.objectId == lead.objectId
                            : taskSelected &&
                              taskSelected.lead.objectId == lead.objectId
                        }
                        value={lead.objectId}
                        key={index}
                      >
                        {lead && lead.type == 1
                          ? lead.name
                          : lead.first_name + " " + lead.last_name}
                      </option>
                    ))}
                </select>

                {errors.lead ? (
                  <div>
                    <ErrorMessage
                      className="error error__select"
                      component="div"
                      name="lead"
                    />
                  </div>
                ) : null}
              </div>

              {/* Save */}
              <div className="d-grid gap-2 col-6 mx-auto mb-5">
                <button
                  className="btn btn-success roamie-text-white-color"
                  type="submit"
                >
                  {taskSelected ? t("update") : t("save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

AddTask.propTypes = {
  onClickCloseDrawer: PropTypes.func.isRequired,
  task: PropTypes.object,
};

AddTask.defaultValue = {
  leadProfile: null,
};

export default AddTask;
