import {
  faChevronDown,
  faChevronRight,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import Tabs, { TabPane } from "rc-tabs";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import avatarHolder from "../../../assets/icons/avatar.png";
import icon1 from "../../../assets/icons/ic_circle_blue.png";
import icon4 from "../../../assets/icons/ic_circle_green.png";
import icon3 from "../../../assets/icons/ic_circle_pink.png";
import icon2 from "../../../assets/icons/ic_circle_yellow.png";
import LeadStatusType from "../../../enum/LeadStatusType";
import { MomentUtil } from "../../../utils/moment_util";
import { useLoading } from "../../components/Loading/LoadingContext";
import AddNewAppointment from "../Calendar/AddNewAppointment";
import AddTask from "../Tasks/AddTask";
import LeadContactEmailItem from "./components/LeadContactEmailItem";
import LeadContactNumberItem from "./components/LeadContactNumberItem";
import LeadProfileInfo from "./components/LeadProfileInfo";
import LeadStatusAndCategory from "./components/LeadStatusAndCategory/LeadStatusAndCategory";
import LeadProfileDeals from "./components/ProfileDrawer/LeadProfileDeals";
import LeadProfileFiles from "./components/ProfileDrawer/LeadProfileFiles";
import LeadProfileJournal from "./components/ProfileDrawer/LeadProfileJournal";
import LeadProfileNote from "./components/ProfileDrawer/LeadProfileNote";
import { updateLeadModel } from "./LeadSlice";

const color = ["#4D77E9", "#F4D63C", "#F259F2", "#43D133"];
const icon = [icon1, icon2, icon3, icon4];

const LeadProfile = (props) => {
  /// MARK: - Initials
  const [lead, setLead] = useState(props.lead);
  const [myContact, setMyContact] = useState(null);
  const [name, setName] = useState("");
  const [isCompany, setIsCompany] = useState(false);
  const [tags, setTags] = useState("");
  const [toggleLeadStatusLeads, setToggleLeadStatusLeads] = useState(false);
  const [toogleLeadStatusApproached, setToggleLeadStatusApproached] =
    useState(false);
  const [toggleLeadStatusWinning, setToggleLeadStatusWinning] = useState(false);
  const [toggleLeadStatusService, setToggleLeadStatusService] = useState(false);
  const [active, setActive] = useState({ name: "" });
  const [taskOpen, setTaskOpen] = useState(false);
  const [apptOpen, setAppointOpen] = useState(false);
  const taskArray = [
    "call_later",
    "email_latter",
    "renewal",
    "testimonial",
    "referral",
    "up_sell",
    "not_now",
  ];
  const { enqueueSnackbar } = useSnackbar();
  const { loading, setLoading } = useLoading();
  const dispatch = useDispatch();

  /// Handle toggle status
  const handAddToggle = (category) => {
    const { name } = { ...category };
    setActive({ name });
  };

  /// Drawer Add Task
  const handleOpenDrawerAddTask = () => {
    toggleDrawerAddTask();
  };

  /// Drawer Add Appointments
  const handleOpenDrawerAddAppointment = () => {
    toggleDrawerAddNewAppointment();
  };

  /// Toggle task
  const toggleDrawerAddTask = () => {
    setTaskOpen((prevState) => !prevState);
  };

  /// Toggle appointments
  const toggleDrawerAddNewAppointment = () => {
    setAppointOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (taskArray.includes(active.name)) {
      handleOpenDrawerAddTask();
    }
    if (active.name === "appointment") {
      handleOpenDrawerAddAppointment();
    }
  }, [active]);

  /// Load Data
  useEffect(() => {
    if (lead) {
      /// Check My Contact
      const foundContact = lead.contacts.find((contact) => {
        return typeof contact.objectId === "undefined";
      });
      if (typeof foundContact !== "undefined") {
        setMyContact(foundContact);
      }
      /// Check Name
      const name =
        lead.type == 1
          ? lead.name
          : (lead.first_name || "") + " " + (lead.last_name || "");
      setName(name);
      /// Check Is Comapny
      setIsCompany(lead.type == 1);
      /// Check Hash Tags
      const hashTags = lead.tags
        .map((tag) => {
          return `#${tag}`;
        })
        .join(",");
      setTags(hashTags);
    }
  }, []);

  /// MARK: - Hooks
  const { t, i18n } = useTranslation();

  /// Tabs
  const callback = function (key) {};

  /// Collapse
  const handleToggleLeadStatusLeads = () => {
    setToggleLeadStatusLeads((preState) => !preState);
  };

  const handleToggleLeadApproached = () => {
    if (lead.status == 0 && lead.category == 0) {
      enqueueSnackbar(t("please_choose_a_leads_status_first"), {
        persist: false,
        variant: "error",
      });
      return;
    }
    setToggleLeadStatusApproached((preState) => !preState);
  };

  const handleToggleLeadWinning = () => {
    if (lead.status == 0 && lead.category == 0) {
      enqueueSnackbar(t("please_choose_a_leads_status_first"), {
        persist: false,
        variant: "error",
      });
      return;
    }
    setToggleLeadStatusWinning((preState) => !preState);
  };

  const handleToggleLeadService = () => {
    // if (!lead.has_closed) {
    //   enqueueSnackbar(t("please_add_a_deal_first"), {
    //     persist: false,
    //     variant: "error",
    //   });
    //   return;
    // }
    setToggleLeadStatusService((preState) => !preState);
  };

  /// Handle On Click Category
  const handleOnClickCategory = (leadCategory, leadStatus) => {
    handAddToggle(leadCategory);
    const leadClone = { ...lead };
    leadClone.category = leadCategory.value;
    leadClone.status = leadStatus.value;
    const selectedRecords = [...(leadClone.selectionRecord || [])];
    const newSelectedRecords = {
      status: leadClone.status,
      category: leadClone.category,
    };
    const foundIndex = selectedRecords.findIndex((record) => {
      return record.status == newSelectedRecords.status;
    });
    if (foundIndex != -1) {
      selectedRecords[foundIndex] = newSelectedRecords;
    } else {
      selectedRecords.push(newSelectedRecords);
    }
    leadClone.selection_record = selectedRecords;
    const request = {
      leadId: leadClone.objectId,
      request: leadClone,
    };
    updateLead(request);
  };

  /// Update Lead
  const updateLead = async (request) => {
    try {
      setLoading(true);
      const action = updateLeadModel(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        const leadRequest = request.request;
        if (leadRequest) {
          setLead(leadRequest);
        }
        enqueueSnackbar(t(response.message || ""), {
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update Lead Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lead-wrapper__container">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <button onClick={props.onClickCloseDrawer}>
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ fontSize: "1.25rem" }}
            color="#CDD2F3"
          />
        </button>
        <span className="fs-16 font-w500 roamie-text-color-primary">
          {name}
        </span>
        {lead.is_view_data ? (
          <button onClick={() => props.onClickLeadEdit(lead)}>
            <FontAwesomeIcon icon={faPen} size="1x" color="#CDD2F3" />
          </button>
        ) : (
          <button></button>
        )}
      </div>

      {/* Bio */}
      <div className="wrapper__container-bio mg-top-40 d-flex justify-content-center flex-column align-items-center">
        <img
          src={lead && lead.url !== "" ? lead.url : avatarHolder}
          className="img-fluid rounded-circle lead_avatar"
          alt="profile"
        />
        <span className="mt-3 fs-16 font-w400 roamie-text-color-primary">
          {lead.is_view_data
            ? myContact &&
              myContact.emails &&
              myContact.emails.length > 0 &&
              myContact.emails[0].email
            : "***"}
        </span>
        <span className="fs-20 font-w400 roamie-text-color-primary">
          {name}
        </span>
      </div>

      {/* Tabs */}
      <div className="wrapper__container-tabs mg-top-40">
        <Tabs defaultActiveKey="1" onChange={callback} tabBarGutter={30}>
          {/* Profile */}
          <TabPane tab={t("profile")} key="1">
            <div>
              {!isCompany ? (
                <LeadProfileInfo
                  icon="fa fa-birthday-cake fa-1x"
                  title={t("birthday")}
                  content={
                    lead &&
                    lead.birthday &&
                    MomentUtil(lead.birthday, "DD MMM YYYY")
                  }
                />
              ) : null}
              <LeadProfileInfo
                icon="fa fa-location-arrow fa-1x"
                title={t("address")}
                content={
                  // lead.addressList ? lead && lead.addressList.address : ""
                  (lead &&
                    lead.addressList &&
                    lead.addressList.length > 0 &&
                    lead.addressList[0].address) ||
                  ""
                }
              />
              {isCompany ? (
                <LeadProfileInfo
                  icon="fa fa-building fa-1x"
                  title={t("company")}
                  content={name}
                />
              ) : null}
              <LeadProfileInfo
                icon="fa fa-hashtag fa-1x"
                title={t("hash_tags")}
                content={tags}
              />
            </div>
            <div className="mg-top-40">
              {/* Leads */}
              <div className="lead_collapse">
                <div
                  onClick={handleToggleLeadStatusLeads}
                  className="d-flex justify-content-between align-items-center pointer lead_collapse--items"
                >
                  <span className="fs-15 font-w500 roamie-text-color-primary">
                    {t("leads")}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="1x"
                    color="#10193D"
                  />
                </div>
                {/* Status */}
                <Collapse in={toggleLeadStatusLeads}>
                  <div>
                    {LeadStatusType &&
                      LeadStatusType.leads.categories.map(
                        (leadCategory, index) => (
                          <LeadStatusAndCategory
                            key={index}
                            leadCategory={leadCategory}
                            leadStatusType={LeadStatusType.leads}
                            onClick={handleOnClickCategory}
                            lead={lead}
                            color={color[index]}
                            icon={icon[index]}
                          />
                        )
                      )}
                  </div>
                </Collapse>
              </div>

              {/* Approached */}
              <div className="lead_collapse mg-top-20">
                <div
                  onClick={handleToggleLeadApproached}
                  className="d-flex justify-content-between align-items-center pointer lead_collapse--items"
                >
                  <span className="fs-15 font-w500 roamie-text-color-primary">
                    {t("approached")}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="1x"
                    color="#10193D"
                  />
                </div>
                <Collapse in={toogleLeadStatusApproached}>
                  <div className="mg-top-20">
                    {/* Numbers */}
                    {lead &&
                      lead.contacts &&
                      lead.contacts.map((contact, index) => {
                        const numbers = contact.numbers || [];
                        if (numbers.length === 0) {
                          return <></>;
                        }
                        return numbers.map((number) => {
                          return lead.is_view_data ? (
                            <LeadContactNumberItem number={number} />
                          ) : (
                            <></>
                          );
                        });
                      })}
                    {/* Emails */}
                    {lead &&
                      lead.contacts &&
                      lead.contacts.map((contact, index) => {
                        const emails = contact.emails || [];
                        if (emails.length === 0) {
                          return <></>;
                        }
                        return emails.map((email) => {
                          return lead.is_view_data ? (
                            <LeadContactEmailItem emailObj={email} />
                          ) : (
                            <></>
                          );
                        });
                      })}
                    {/* Category Approached */}
                    {LeadStatusType &&
                      LeadStatusType.approached.categories.map(
                        (leadCategory, index) => (
                          <LeadStatusAndCategory
                            leadCategory={leadCategory}
                            leadStatusType={LeadStatusType.approached}
                            onClick={handleOnClickCategory}
                            lead={lead}
                            color={color[index]}
                            key={index}
                            icon={icon[index]}
                          />
                        )
                      )}
                  </div>
                </Collapse>
              </div>

              {/* Winning */}
              <div className="lead_collapse mg-top-20">
                <div
                  onClick={handleToggleLeadWinning}
                  className="d-flex justify-content-between align-items-center pointer lead_collapse--items"
                >
                  <span className="fs-15 font-w500 roamie-text-color-primary">
                    {t("winning")}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="1x"
                    color="#10193D"
                  />
                </div>
                <Collapse in={toggleLeadStatusWinning}>
                  <div className="mg-top-20">
                    {LeadStatusType &&
                      LeadStatusType.winning.categories.map(
                        (leadCategory, index) => (
                          <LeadStatusAndCategory
                            leadCategory={leadCategory}
                            leadStatusType={LeadStatusType.winning}
                            onClick={handleOnClickCategory}
                            lead={lead}
                            color={color[index]}
                            key={index}
                            icon={icon[index]}
                          />
                        )
                      )}
                  </div>
                </Collapse>
              </div>

              {/* Service */}
              <div className="lead_collapse mg-top-20">
                <div
                  onClick={handleToggleLeadService}
                  className="d-flex justify-content-between align-items-center pointer lead_collapse--items"
                >
                  <span className="fs-15 font-w500 roamie-text-color-primary">
                    {t("service")}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="1x"
                    color="#10193D"
                  />
                </div>
                <Collapse in={toggleLeadStatusService}>
                  <div className="mg-top-20">
                    {LeadStatusType &&
                      LeadStatusType.service.categories.map(
                        (leadCategory, index) => (
                          <LeadStatusAndCategory
                            leadCategory={leadCategory}
                            leadStatusType={LeadStatusType.service}
                            onClick={handleOnClickCategory}
                            lead={lead}
                            color={color[index]}
                            key={index}
                            icon={icon[index]}
                          />
                        )
                      )}
                  </div>
                </Collapse>
              </div>
            </div>
          </TabPane>

          {/* Journal */}
          <TabPane tab={t("journal")} key="2">
            <LeadProfileJournal lead={lead} />
          </TabPane>

          {/* Notes */}
          <TabPane forceRender={true} tab={t("notes")} key="3">
            <LeadProfileNote active={active} lead={lead} />
          </TabPane>

          {/* Files */}
          <TabPane forceRender={true} tab={t("files")} key="4">
            <LeadProfileFiles lead={lead} />
          </TabPane>

          {/* Deals */}
          <TabPane forceRender={true} tab={t("deals")} key="5">
            <LeadProfileDeals active={active} lead={lead} />
          </TabPane>
        </Tabs>

        {/*Task drawer */}
        <Drawer
          className="lead-drawer"
          variant="temporary"
          open={taskOpen}
          anchor={"right"}
          onBackdropClick={toggleDrawerAddTask}
        >
          <AddTask
            onClickCloseDrawer={toggleDrawerAddTask}
            leadProfile={lead}
          />
        </Drawer>

        {/*Appointments drawer */}
        <Drawer
          className="lead-drawer"
          variant="temporary"
          open={apptOpen}
          anchor={"right"}
          onBackdropClick={toggleDrawerAddNewAppointment}
        >
          <AddNewAppointment
            onClickCloseDrawer={toggleDrawerAddNewAppointment}
          />
        </Drawer>
      </div>
    </div>
  );
};

LeadProfile.propTypes = {
  onClickCloseDrawer: PropTypes.func,
  lead: PropTypes.object,
  onClickLeadEdit: PropTypes.func,
};

LeadProfile.defaultProps = {
  onClickCloseDrawer: null,
};

export default LeadProfile;
