import * as mediasoup from "mediasoup-client";
import React, { useEffect, useRef, useState } from "react";
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronUp,
  FiColumns,
  FiGrid,
  FiMaximize,
  FiMenu,
  FiMic,
  FiMicOff,
  FiMinimize,
  FiPhoneOff,
  FiVideo,
  FiVideoOff,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getGlobal, useGlobal } from "reactn";
import chatApi from "../../../../../chat/api/chatApi";
import {
  rtcConsumers,
  rtcLeave,
  rtcParams,
  rtcProducers,
  rtcRoomID,
} from "../../../../../chat/slices/RTCSlice";
import AddPeers from "./AddPeers";
import Join from "./Join";
import LittleStreams from "./LittleStreams";
import Ringing from "./Ringing";
import Streams from "./Streams";

let transport;
let videoProducer;
let screenProducer;
let audioProducer;

export default function Meeting() {
  /// MARK: - Initials
  const [device, setDevice] = useState(null);
  const { io } = useSelector((state) => state.ios);
  const [streams, setStreams] = useGlobal("streams");
  const [localStream, setLocalStream] = useGlobal("localStream");
  const [video, setVideo] = useGlobal("video");
  const [audio, setAudio] = useGlobal("audio");
  const [isScreen, setScreen] = useGlobal("screen");
  const [audioStream, setAudioStream] = useGlobal("audioStream");
  const [videoStream, setVideoStream] = useGlobal("videoStream");
  const [screenStream, setScreenStream] = useGlobal("screenStream");
  const [callStatus, setCallStatus] = useGlobal("callStatus");
  const callDirection = useGlobal("callDirection")[0];
  const [joined, setJoined] = useGlobal("joined");
  const [isMaximized, setMaximized] = useState(true);
  const [isGrid, setGrid] = useState(true);
  const [topBar, setTopBar] = useState(true);
  const [screenSharing, setScreenSharing] = useGlobal("screensharing");
  const [acccepted, setAccepted] = useGlobal("accepted");
  const [showPanel, setShowPanel] = useGlobal("showPanel");
  const [over, setOver] = useGlobal("over");
  const [meeting, setMeeting] = useGlobal("meetingID");
  const [addPeers, setAddPeers] = useState(false);
  const {
    producers,
    lastLeave,
    lastLeaveType,
    increment,
    closingState,
    closed,
    answerIncrement,
    answerData,
  } = useSelector((state) => state.rtc);
  const counterpart = useSelector((state) => state.rtc.counterpart) || {};
  const meetingID = useSelector((state) => state.rtc.params);
  const room = useSelector((state) => state.ios.room);
  const params = useParams();
  const roomID = meetingID;
  const dispatch = useDispatch();
  const history = useHistory();

  const getAudio = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setAudioStream(stream);
    return stream;
  };

  const getVideo = async () => {
    const stream = navigator.mediaDevices.getUserMedia({ video: true });
    setVideoStream(stream);
    return stream;
  };

  const getScreen = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
    });
    setScreenStream(stream);
    return stream;
  };

  const produceAudio = async (stream) => {
    let useStream = stream || audioStream;
    setAudio(true);
    try {
      const track = useStream.getAudioTracks()[0];
      const params = { track };
      audioProducer = await transport.produce(params);
    } catch (err) {
      console.log("getusermedia produce failed", err);
      setAudio(false);
    }
  };

  const produceVideo = async (stream) => {
    let useStream = stream || videoStream;
    setVideo(true);
    try {
      const track = useStream.getVideoTracks()[0];
      const params = { track, appData: { isScreen: false } };
      await setLocalStream(useStream);
      videoProducer = await transport.produce(params);
    } catch (err) {
      console.log("getusermedia produce failed", err);
      setVideo(false);
    }
  };

  const produceScreen = async (stream) => {
    try {
      const track = stream.getVideoTracks()[0];
      const params = { track, appData: { isScreen: true } };
      await setLocalStream(stream);
      screenProducer = await transport.produce(params);
      await setScreen(true);
    } catch (err) {
      console.log("getusermedia produce failed", err);
    }
  };

  const stopScreen = async () => {
    try {
      if (localStream) localStream.getVideoTracks()[0].stop();
      await io.request("remove", { producerID: screenProducer.id, roomID });
      screenProducer.close();
      screenProducer = null;
      await setScreen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const stopVideo = async () => {
    try {
      if (localStream) localStream.getVideoTracks()[0].stop();
      await io.request("remove", { producerID: videoProducer.id, roomID });
      videoProducer.close();
      videoProducer = null;
      await setVideo(false);
    } catch (e) {
      console.log(e);
    }
  };

  const stopAudio = async () => {
    try {
      await io.request("remove", { producerID: audioProducer.id, roomID });
      audioProducer.close();
      audioProducer = null;
      await setAudio(false);
    } catch (e) {
      console.log(e);
    }
  };

  const init = async () => {
    await setCallStatus("in-call");
    await setShowPanel(false);
    await setOver(true);

    window.consumers = [];
    await setStreams([]);

    dispatch(rtcRoomID(roomID));

    const { producers, consumers, peers } = await io.request("join", {
      roomID,
    });
    dispatch(rtcConsumers({ consumers, peers }));

    const routerRtpCapabilities = await io.request("getRouterRtpCapabilities");
    let device = new mediasoup.Device();

    await device.load({ routerRtpCapabilities });

    setDevice(device);

    await subscribe(device);

    dispatch(rtcProducers({ producers: producers || [] }));

    const data = await io.request("createProducerTransport", {
      forceTcp: false,
      rtpCapabilities: device.rtpCapabilities,
      roomID,
    });

    if (data.error) {
      console.error(data.error);
      return;
    }

    transport = device.createSendTransport(data);
    transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
      io.request("connectProducerTransport", { dtlsParameters })
        .then(callback)
        .catch(errback);
    });

    transport.on(
      "produce",
      async ({ kind, rtpParameters, appData }, callback, errback) => {
        try {
          const { id } = await io.request("produce", {
            transportId: transport.id,
            kind,
            rtpParameters,
            roomID,
            isScreen: appData && appData.isScreen,
          });
          callback({ id });
        } catch (err) {
          errback(err);
        }
      }
    );

    transport.on("connectionstatechange", (state) => {
      switch (state) {
        case "connecting":
          break;

        case "connected":
          // document.querySelector('#local_video').srcObject = stream;
          break;

        case "failed":
          transport.close();
          break;

        default:
          break;
      }
    });

    await produceAudio();
    await produceVideo();
  };

  const subscribe = async (device, socketID) => {
    const data = await io.request("createConsumerTransport", {
      forceTcp: false,
      roomID,
      socketID: socketID,
    });

    if (data.error) {
      console.error(data.error);
      return;
    }

    const transport = device.createRecvTransport(data);
    transport.on("connect", ({ dtlsParameters }, callback, errback) => {
      io.request("connectConsumerTransport", {
        transportId: transport.id,
        dtlsParameters,
        socketID: socketID,
      })
        .then(callback)
        .catch(errback);
    });

    transport.on("connectionstatechange", async (state) => {
      switch (state) {
        case "connecting":
          break;

        case "connected":
          // document.querySelector('#remote_video').srcObject = await stream;
          for (let producer of producers) {
            await io.request("resume", { producerID: producer.producerID });
          }
          break;

        case "failed":
          transport.close();
          break;

        default:
          break;
      }
    });

    window.transport = transport;
  };

  const consume = async (transport, producer) => {
    try {
      const { rtpCapabilities } = device;
      const data = await io.request("consume", {
        rtpCapabilities,
        socketID: producer.socketID,
        roomID,
        producerID: producer.producerID,
      });
      const { producerId, id, kind, rtpParameters } = data;

      let codecOptions = {};

      const consumer = await transport.consume({
        id,
        producerId,
        kind,
        rtpParameters,
        codecOptions,
      });
      consumer.on("producerclose", () => {
        console.log("associated producer closed so consumer closed");
      });
      consumer.on("close", () => {
        console.log("consumer closed");
      });
      const stream = new MediaStream();
      stream.addTrack(consumer.track);
      stream.isVideo = kind === "video";
      return stream;
    } catch (e) {
      console.log("consume error: ", e);
    }
  };

  const close = async () => {
    /// Close Stream Video
    try {
      localStream.getVideoTracks()[0].stop();
    } catch (e) {
      console.log("Local stream close error: ", e);
    }
    await setStreams([]);

    /// Close Transport
    try {
      transport.close();
    } catch (e) {
      console.log("Close call error: ", e);
    }

    try {
      await io.request("leave", { roomID });
    } catch (e) {
      console.log("Leave error: ", e);
    }
    await chatApi.postClose({
      roomID,
      userID: counterpart._id,
      isGroup: (room && room.isGroup) || false,
    });
    dispatch(rtcParams({ params: null }));
    setJoined(false);
    setShowPanel(true);
    setCallStatus(null);
    // dispatch(rtcLeave());
    console.log("close action meeting");
  };

  useEffect(() => {
    const init = async () => {
      !window.consumers && (window.consumers = []);
      let newStreams = [];
      for (let producer of producers) {
        if (
          !window.consumers.includes(producer.producerID) &&
          producer.roomID === roomID
        ) {
          window.consumers.push(producer.producerID);

          const stream = await consume(window.transport, producer);

          stream.producerID = producer.producerID;
          stream.socketID = producer.socketID;
          stream.userID = producer.userID;

          newStreams.push(stream);

          io.request("resume", {
            producerID: producer.producerID,
            meetingID: roomID,
          });
        }
      }
      setStreams([...getGlobal().streams, ...newStreams]);
    };
    init();
  }, [producers]);

  useEffect(() => {
    if (!answerData) return;
    if (
      callDirection === "outgoing" &&
      callStatus !== "in-call" &&
      answerData.meetingID === roomID
    ) {
      setJoined(true);
      init();
    }
  }, [answerIncrement, answerData]);

  useEffect(() => {
    if (acccepted) {
      setAccepted(false).then(() => {
        setJoined(true);
        init();
      });
    }
  }, [acccepted]);

  useEffect(() => {
    setMeeting(roomID);
    return () => {
      if (getGlobal().callStatus !== "in-call") {
        try {
          if (getGlobal().audioStream)
            getGlobal()
              .audioStream.getTracks()
              .forEach((track) => track.stop());
        } catch (e) {}
        try {
          if (getGlobal().videoStream)
            getGlobal()
              .videoStream.getTracks()
              .forEach((track) => track.stop());
        } catch (e) {}
      }
    };
  }, []);

  useEffect(() => {
    if (closingState && joined) close();
  }, [closingState]);

  useEffect(() => {
    if (lastLeaveType === "leave") {
      setStreams(getGlobal().streams.filter((s) => s.socketID !== lastLeave));
    } else {
      setStreams(getGlobal().streams.filter((s) => s.producerID !== lastLeave));
    }
  }, [lastLeave, lastLeaveType, setStreams, increment]);

  if (callDirection === "incoming" && !joined) {
    return (
      <div
        className="content uk-flex uk-flex-column uk-flex-center uk-flex-middle"
        style={{ background: "black", height: "100vh" }}
      >
        <Ringing
          incoming={true}
          meetingID={roomID}
          onJoin={() => {
            setJoined(true);
            init();
          }}
        />
      </div>
    );
  }

  if (callDirection === "outgoing" && !joined) {
    return (
      <div
        className="content uk-flex uk-flex-column uk-flex-center uk-flex-middle"
        style={{ background: "black", height: "100vh" }}
      >
        <Ringing incoming={false} meetingID={roomID} />
      </div>
    );
  }

  if (!joined) {
    return (
      <div
        className="content uk-flex uk-flex-column uk-flex-center uk-flex-middle"
        style={{ background: "black", height: "100vh" }}
      >
        <Join
          onJoin={() => {
            setJoined(true);
            init();
          }}
        />
      </div>
    );
  }

  // const notAvailable = name => {
  //     addToast(`This feature is not available yet.`, {
  //         appearance: 'warning',
  //         autoDismiss: true,
  //     })
  // };

  const TopBar = ({ localStream }) => {
    const localVideoRef = useRef(null);

    useEffect(() => {
      if (!localStream) return;
      localVideoRef.current.srcObject = localStream;
    }, [localStream]);

    return (
      <div className="meeting-top-controls">
        <div
          className="panel-control"
          onClick={() => {
            setShowPanel(!showPanel);
            setOver(showPanel);
          }}
        >
          {showPanel ? <FiChevronLeft /> : <FiMenu />}
        </div>
        <LittleStreams streams={streams} />
        <div className="videos" style={{ flexGrow: 0 }}>
          <video
            hidden={!video && !isScreen}
            className="video"
            onLoadedMetadata={() => localVideoRef.current.play()}
            ref={localVideoRef}
            style={{ objectFit: "cover", background: "black", zIndex: 1000 }}
            playsInline
          />
        </div>
        <div className="panel-control" onClick={() => setTopBar(!topBar)}>
          {topBar ? <FiChevronDown /> : <FiChevronUp />}
        </div>
      </div>
    );
  };

  const TopBarTransparent = ({ localStream }) => {
    const localVideoRef = useRef(null);

    useEffect(() => {
      if (!localVideoRef) return;
      localVideoRef.current.srcObject = localStream;
    }, [localVideoRef]);

    return (
      <div className="meeting-top-controls-transparent">
        <div
          className="panel-control"
          onClick={() => {
            setShowPanel(!showPanel);
            setOver(showPanel);
          }}
        >
          {showPanel ? <FiChevronLeft /> : <FiMenu />}
        </div>
        <div
          className="videos"
          style={{ flexGrow: 0, minWidth: video || isScreen ? 137 : 0 }}
        >
          <video
            hidden={!video && !isScreen}
            className="video"
            onLoadedMetadata={() => localVideoRef.current.play()}
            ref={localVideoRef}
            style={{ objectFit: "cover", background: "black", zIndex: 1000 }}
            playsInline
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className="meeting-main uk-flex uk-flex-column"
      style={{ height: "100vh" }}
    >
      {isGrid && <TopBarTransparent localStream={localStream} />}
      {!isGrid && topBar && <TopBar localStream={localStream} />}
      <Streams
        isGrid={isGrid}
        streams={streams}
        localStream={localStream}
        isVideo={video}
        isScreen={isScreen}
        isMaximized={isMaximized}
      >
        <div
          className="meeting-controls"
          style={{ bottom: topBar || isGrid ? 0 : 95 }}
        >
          <div
            className="control"
            onClick={() =>
              video
                ? stopVideo()
                : getVideo().then((stream) => produceVideo(stream))
            }
          >
            {video ? <FiVideo /> : <FiVideoOff />}
          </div>
          <div
            className="control"
            onClick={() =>
              audio
                ? stopAudio()
                : getAudio().then((stream) => produceAudio(stream))
            }
          >
            {audio ? <FiMic /> : <FiMicOff />}
          </div>
          {/* <div
            className="control"
            onClick={() =>
              isScreen
                ? stopScreen()
                : getScreen().then((stream) => produceScreen(stream))
            }
          >
            {isScreen ? <FiXOctagon /> : <FiMonitor />}
          </div> */}
          {/* Close */}
          <div className="close" onClick={close}>
            <FiPhoneOff />
          </div>
          {/* Add Peer */}
          {/* <div className="control" onClick={() => setAddPeers(true)}>
            <FiUserPlus />
          </div> */}
          {/* Max */}
          <div className="control" onClick={() => setMaximized(!isMaximized)}>
            {isMaximized ? <FiMaximize /> : <FiMinimize />}
          </div>
          {/* Grid */}
          <div className="control" onClick={() => setGrid(!isGrid)}>
            {isGrid ? <FiGrid /> : <FiColumns />}
          </div>
        </div>
      </Streams>
      {!isGrid && !topBar && <TopBar localStream={localStream} />}
      {addPeers && <AddPeers onClose={() => setAddPeers(false)} />}
    </div>
  );
}
