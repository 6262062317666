import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AddButton = (props) => {
  return (
    <div className="add-button" onClick={props.onClick}>
      <FontAwesomeIcon icon={faPlus} color="white" className='fs-12' />
      <span className="fs-12 font-w500 roamie-text-white-color mg-left-5">
        {props.text}
      </span>
    </div>
  );
};

AddButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string
};

AddButton.defaultProps = {
  onClick: null,
  type: null
};

export default AddButton;
