import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import paymentApi from "../../../api/paymentApi";
import settingsApi from "../../../api/settingsApi";
import StorageKeys from "../../../constants/StorageKeys";

export const update = createAsyncThunk("/api/user/", async (payload) => {
  const { userId, body } = payload;
  for (let item in body) {
    if (body[item] === "" || body[item] === null || body[item] === undefined) {
      delete body[item];
    }
  }
  const updateResponse = await settingsApi.userUpdate(body, userId);
  if (updateResponse.user !== undefined || null) {
    localStorage.setItem(StorageKeys.USER, JSON.stringify(updateResponse.user));
  }
  return updateResponse;
});

export const avatarUpdate = createAsyncThunk(
  "/api/users/change-avatar",
  async (payload) => {
    const updateResponse = await settingsApi.avatarUpdate(payload);
    return updateResponse;
  }
);
export const coverUpate = createAsyncThunk(
  "/api/users/change-cover",
  async (payload) => {
    const updateResponse = await settingsApi.coverUpate(payload);
    return updateResponse
  }
)
export const aboutUsUpdate = createAsyncThunk(
  "/api/user/update-aboutus",
  async (payload) => {
    const updateResponse = await settingsApi.aboutUsUpdate(payload.about, payload.id)
    return updateResponse
  }
)

/// Payment action
export const getAmount = createAsyncThunk(
  "/api/setting/amount-update-role",
  async () => {
    const result = await paymentApi.getAmount();
    return result;
  }
)

export const payRequest = createAsyncThunk(
  "/api/users/update-role/braintree",
  async (payload) => {
    const response = await paymentApi.paymentRequest(payload);
    return response;
  }
)

export const getPackage = createAsyncThunk(
  "/api/package/get-all",
  async () => {
    const response = await paymentApi.getPackage();
    return response;
  }
)

export const payForMonth = createAsyncThunk(
  "/api/package/get-all",
  async (payload) => {
    const response = await paymentApi.paymentExpried(payload);
    return response;
  }
)
const settingSlice = createSlice({
  name: "SettingSlice",
  initialState: {
    avatar: {},
    cover: {},
    about: {}
  },
  reducers: {},
  extraReducers: {
    [update.fulfilled]: (state, action) => {
      state.current = action.payload;
    },
    [avatarUpdate.fulfilled]: (state, action) => {
      state.avatar = action.payload.data.profile_image;
    },
    [coverUpate.fulfilled]: (state, action) => {
      state.cover = action.payload.data.profile_cover;
    },
    [aboutUsUpdate.fulfilled]: (state, action) => {
      state.about = action.payload.data
    }
  },
});

const { reducer, actions } = settingSlice;
export default reducer;
