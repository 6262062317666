import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { MomentUtil } from "../../../../utils/moment_util";

const CalendarEvent = (props) => {
  /// Initials;
  const { appointment } = props;

  /// Hooks
  const { t, i18n } = useTranslation();

  const getHoursDiff = () => {
    const endDate = Moment(appointment.end_time);
    const startDate = Moment(appointment.start_time);
    return endDate.diff(startDate, "hours");
  };

  return (
    <div className="d-flex align-items-center">
      <h1 className="fs-20 font-w400 roamie-text-color-second col-lg-1">
        {Moment(appointment.start_time).format("hh:mm")}
        <br />
        {appointment.start_time && MomentUtil(appointment.start_time, "a")}
      </h1>
      <div
        className="calendar-event"
        onClick={() => props.onClick(appointment)}
      >
        <span className="fs-17 font-w400 roamie-text-white-color">
          {appointment.title}
        </span>
        <span className="fs-15 font-w400 op8 roamie-text-white-color">
          {getHoursDiff()} {t("hours")}
        </span>
        <div>
          <FontAwesomeIcon icon={faMapMarkerAlt} color="#fff" />
          <span className="fs-15 font-w400 roamie-text-white-color mg-left-10">
            {appointment.location.address}
          </span>
        </div>
      </div>
    </div>
  );
};

CalendarEvent.propTypes = {
  appointment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default CalendarEvent;
