import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const ActivitiesChart = (props) => {
  /// Initials
  const { activities } = props;
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState([]);
  const { t, i18n } = useTranslation();

  /// Hooks
  useEffect(() => {
    /// Categories
    const categories =
      (activities &&
        activities.length > 0 &&
        activities.map((activity) => activity.category)) ||
      [];
    setCategories(categories);
    /// Data
    const values =
      (activities &&
        activities.length > 0 &&
        activities.map(
          (activity) =>
            activity.number_of_leads +
            activity.number_of_tasks +
            activity.number_of_appointment +
            activity.number_of_deals
        )) ||
      [];
    setValues(values);
  }, [activities]);
  /// Checl deal item
  const checkChartDealItem = (item, index) => {
    const itemCheck = activities[index > 0 ? index - 1 : 0];
    if (itemCheck.number_of_deals < item.number_of_deals) {
      return {
        ...item,
        status: "increase",
      };
    } else if (itemCheck.number_of_deals > item.number_of_deals) {
      return {
        ...item,
        status: "decrease",
      };
    }
    return {
      ...item,
      status: "constant",
    };
  };

  /// Checl task item
  const checkChartTaskItem = (item, index) => {
    const itemCheck = activities[index > 0 ? index - 1 : 0];
    if (itemCheck.number_of_tasks < item.number_of_tasks) {
      return {
        ...item,
        status: "increase",
      };
    } else if (itemCheck.number_of_tasks > item.number_of_tasks) {
      return {
        ...item,
        status: "decrease",
      };
    }
    return {
      ...item,
      status: "constant",
    };
  };

  /// Checl appt item
  const checkChartApptItem = (item, index) => {
    const itemCheck = activities[index > 0 ? index - 1 : 0];
    if (itemCheck.number_of_appointment < item.number_of_appointment) {
      return {
        ...item,
        status: "increase",
      };
    } else if (itemCheck.number_of_appointment > item.number_of_appointment) {
      return {
        ...item,
        status: "decrease",
      };
    }
    return {
      ...item,
      status: "constant",
    };
  };

  /// Checl lead item
  const checkChartLeadItem = (item, index) => {
    const itemCheck = activities[index > 0 ? index - 1 : 0];
    if (itemCheck.number_of_leads < item.number_of_leads) {
      return {
        ...item,
        status: "increase",
      };
    } else if (itemCheck.number_of_leads > item.number_of_leads) {
      return {
        ...item,
        status: "decrease",
      };
    }
    return {
      ...item,
      status: "constant",
    };
  };

  /// Check status tool tip
  const checkStatus = (status, type) => {
    switch (status) {
      case "increase":
        if (type === "text") {
          return "text-success";
        } else if (type === "arrow") {
          return "arrow-up text-success";
        } else {
          return "border-success bg-success-fix";
        }
      case "decrease":
        if (type === "text") {
          return "text-warning";
        } else if (type === "arrow") {
          return "arrow-down text-warning";
        } else {
          return "border-warning bg-warning-fix";
        }
      case "constant":
        if (type === "text") {
          return "text-light";
        } else if (type === "arrow") {
          return "text-light";
        } else {
          return "border-light bg-light-fix";
        }
      default:
        break;
    }
  };

  const state = {
    series: [
      {
        name: "",
        data: values,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        group: "social",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [6],
        colors: ["#3b4cb8"],
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
        markers: {
          fillColors: ["#3b4cb8"],
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
        },
      },
      markers: {
        size: 10,
        border: 8,
        strokeWidth: 8,
        colors: ["#3b4cb8"],
        hover: {
          size: 8,
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: "#10193d",
            fontSize: "13px",
            fontFamily: "Poppins",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#10193d",
            fontSize: "14.5px",
            fontFamily: "Poppins",
            fontWeight: 500,
            cssClass: "apexcharts-yaxis-label",
          },
          formatter: function (value) {
            var val = Math.abs(value);
            if (val >= 1000) {
              val = (val / 1000).toFixed(0) + "k";
            }
            return val;
          },
        },
      },
      fill: {
        colors: ["#3b4cb8"],
        opacity: 0.07,
      },
      grid: {
        strokeDashArray: 7,
        borderColor: "#e2e5f5",
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const activity = activities[dataPointIndex];
          const deal = checkChartDealItem(
            activities[dataPointIndex],
            dataPointIndex
          );
          const lead = checkChartLeadItem(
            activities[dataPointIndex],
            dataPointIndex
          );
          const appt = checkChartApptItem(
            activities[dataPointIndex],
            dataPointIndex
          );
          const task = checkChartTaskItem(
            activities[dataPointIndex],
            dataPointIndex
          );
          const category = categories[dataPointIndex];
          return (
            '<div class="chart-tooltip">' +
            '<div class="chart-tooltip__header p-2">' +
            `${category}` +
            "</div>" +
            '<div class="chart-tooltip__body p-2">' +
            '<div class="d-flex align-items-center">' +
            `<i class="${
              dataPointIndex === 0 ? "" : "" + checkStatus(lead.status, "text")
            }"></i>` +
            '<span class="ms-2 w-fix">' +
            `${t("leads_activities_chart")}` +
            "</span>" +
            `<div class="border ms-1 p-1 rounded 
            ${
              dataPointIndex === 0
                ? "border-light bg-light-fix"
                : checkStatus(lead.status, null)
            }}">` +
            '<span class="ms-2">' +
            `${Intl.NumberFormat().format(lead.number_of_leads || 0)}` +
            "</span>" +
            `<i class="ms-1 
            ${
              dataPointIndex === 0
                ? "text-light"
                : checkStatus(lead.status, "arrow")
            }"></i>` +
            "</div>" +
            "</div>" +
            '<div class="d-flex align-items-center mt-1">' +
            `<i class="${
              dataPointIndex === 0 ? "" : "" + checkStatus(task.status, "text")
            }"></i>` +
            '<span class="ms-2 w-fix">' +
            `${t("tasks")}` +
            "</span>" +
            `<div class="border ms-1 p-1 rounded 
            ${
              dataPointIndex === 0
                ? "border-light bg-light-fix"
                : checkStatus(task.status, null)
            }}">` +
            '<span class="ms-2">' +
            `${Intl.NumberFormat().format(task.number_of_tasks || 0)}` +
            "</span>" +
            `<i class="ms-1 
            ${
              dataPointIndex === 0
                ? "text-light"
                : checkStatus(task.status, "arrow")
            }"></i>` +
            "</div>" +
            "</div>" +
            '<div class="d-flex align-items-center  mt-1">' +
            `<i class="${
              dataPointIndex === 0 ? "" : "" + checkStatus(appt.status, "text")
            }"></i>` +
            '<span class="ms-2 w-fix">' +
            `${t("appointments")}` +
            "</span>" +
            `<div class="border ms-1 p-1 rounded 
            ${
              dataPointIndex === 0
                ? "border-light bg-light-fix"
                : checkStatus(appt.status, null)
            }}">` +
            '<span class="ms-2">' +
            `${Intl.NumberFormat().format(appt.number_of_appointment || 0)}` +
            "</span>" +
            `<i class="ms-1 
            ${
              dataPointIndex === 0
                ? "text-light"
                : checkStatus(appt.status, "arrow")
            }"></i>` +
            "</div>" +
            "</div>" +
            '<div class="d-flex align-items-center mt-1">' +
            `<i class="${
              dataPointIndex === 0 ? "" : "" + checkStatus(deal.status, "text")
            }"></i>` +
            '<span class="ms-2 w-fix">' +
            `${t("deals")}` +
            "</span>" +
            `<div class="border ms-1 p-1 rounded 
            ${
              dataPointIndex === 0
                ? "border-light bg-light-fix"
                : checkStatus(deal.status, null)
            }}">` +
            '<span class="ms-2">' +
            `${Intl.NumberFormat().format(deal.number_of_deals || 0)}` +
            "</span>" +
            `<i class="ms-1 
            ${
              dataPointIndex === 0
                ? "text-light"
                : checkStatus(deal.status, "arrow")
            }"></i>` +
            "</div>" +
            "</div>" +
            "</div>" +
            "</div>"
          );
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        height={350}
      />
    </div>
  );
};

ActivitiesChart.propTypes = {
  activities: PropTypes.array,
};

export default ActivitiesChart;
