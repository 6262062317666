import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Moment from "moment";
import { MomentUtil } from "../../../../../utils/moment_util";

const LeadProfileFileItem = (props) => {
  /// Initials
  const { file } = props;

  /// Hooks
  const { t, i18n } = useTranslation();

  return (
    <div className={`${props.view ? "file-item-grid" : "file-item"} mt-2`}>
      <div
        className="file-img"
        style={{
          backgroundImage: `url(${file && file.file_url})`,
        }}
      ></div>
      <div className="file-name">
        <span>{file && file.file_name}</span>
        <br />
        <span className="time">
          {file &&
            file.created_at &&
            MomentUtil(file.created_at, "MMMM DD, YYYY")}
        </span>
      </div>
    </div>
  );
};

LeadProfileFileItem.propTypes = {
  file: PropTypes.object.isRequired,
};

export default LeadProfileFileItem;
