import Moment from "moment";
import React from "react";
import IconLeads from "../../../../assets/icons/icon_leads.png";
import { MomentUtil } from "../../../../utils/moment_util";

export default function HomeCalendarTaskItem(props) {
  /// Initials
  const { task } = props;

  return (
    <>
      <div className="home-calendar-task">
        <h1 className="fs-20 font-w400 roamie-text-color-primary col-lg-1">
          {Moment(task && task.end_date).format("hh:mm")}
          <br />
          {task && task.end_date && MomentUtil(task.end_date, "a")}
        </h1>
        <div className="home-calendar-task-card">
          <span className="fs-17 font-w400 roamie-text-white-color">
            {(task && task.title) || ""}
          </span>
          <span className="fs-15 font-w400 op8 roamie-text-white-color">
            {(task && task.description) || ""}
          </span>
          <div>
            <img src={IconLeads} style={{ width: "20px", height: "20px" }} />
            <span className="fs-15 font-w400 op8 roamie-text-white-color mg-left-10">
              {(task && task.lead && task.lead.name) || ""}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
