import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useGlobal } from "reactn";
import { search } from "../../../../../chat/slices/ChatSlice";
import UserItem from "./UserItem";

const CreateGroup = (props) => {
  /// MARK: - Initials;
  const { t } = useTranslation();
  const [nav, setNav] = useGlobal("nav");
  const user = useGlobal("user")[0];
  const [panel, setPanel] = useGlobal("panel");
  const searchText = useGlobal("search")[0];
  // const [newGroupUsers, setNewGroupUsers] = useState([]);
  const [newGroupUsers, setNewGroupUsers] = useGlobal("newGroupUsers");
  const [searchResults, setSearchResults] = useGlobal("searchResults");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const createGroupAsync = async () => {
      const rs = await dispatch(search({ search: "" }));
      const final = unwrapResult(rs);
      setSearchResults(final.data.users);
    };
    createGroupAsync();
  }, [setSearchResults]);

  useEffect(() => {
    resetState();
    setNewGroupUsers([user.id]);
  }, [user]);

  const resetState = () => {
    setNewGroupUsers([]);
  };

  const onSelect = (id) => {
    if (newGroupUsers.includes(id)) {
      setNewGroupUsers(newGroupUsers.filter((u) => u !== id));
    } else {
      setError(false);
      setNewGroupUsers([...newGroupUsers, id]);
    }
  };

  const searchResultsList =
    searchResults &&
    searchResults.map((user) => (
      <UserItem
        key={user._id}
        user={user}
        selected={newGroupUsers.includes(user._id)}
        onSelect={() => onSelect(user._id)}
      />
      // const searchResultsList = searchResults.map(user => <User key={user._id} selectedUser={user} />);
    ));

  const Notice = ({ text }) => <div className="notice">{text}</div>;

  const createGroup = (e) => {
    e.preventDefault();
    if (newGroupUsers.length > 1) setPanel("CreateGroupStep2");
    else setError(true);
  };

  const cancelCreateGroup = (e) => {
    e.preventDefault();
    resetState();
    setNav("rooms");
  };

  return (
    <div className="create-group mt-4">
      {newGroupUsers.length > 1 ? (
        <div
          className="text-center p-3 bg-primary text-light pointer"
          onClick={createGroup}
        >
          {t("create_group")}
        </div>
      ) : (
        <></>
      )}

      <div
        className="selection-text error"
        hidden={newGroupUsers.length <= 1 || !error}
      >
        {t("you_must_select_some_member")}
      </div>
      <div
        className="selection-text mt-2 mb-2"
        style={{ cursor: "pointer" }}
        hidden={newGroupUsers.length <= 1}
      >
        {t("user_selected", {
          numbers_user: newGroupUsers.length - 1,
        })}{" "}
        -{" "}
        <a className="text-danger" onClick={() => setNewGroupUsers([user.id])}>
          {t("cancel")}
        </a>
      </div>
      <div className="rooms">
        {searchResultsList}
        {(!searchResultsList || searchResults.length === 0) &&
          searchText.length != 0 && (
            <Notice
              text={t("there_are_no_users_available_for", {
                search: `"${searchText}"`,
              })}
            />
          )}
      </div>
    </div>
  );
};

// CreateGroup.propTypes = {};

export default CreateGroup;
