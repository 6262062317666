import axiosClient from "./axiosClient";

const homeApi = {
  getTeamMembersRanking: (params) => {
    const url = "/api/get-team-member-ranking";
    return axiosClient.post(url, params);
  },
  getSummaryDeals: (params) => {
    const url = "api/get-summary-deals";
    return axiosClient.post(url, params);
  },
  getSummaryDealsPercent: (params) => {
    const url = "api/get-summary-deals";
    return axiosClient.post(url, params);
  },
  getSummaryActivities: (params) => {
    const url = "api/get-summary-activities";
    return axiosClient.post(url, params);
  },
};

export default homeApi;
