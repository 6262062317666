import React from "react";
import PropTypes from "prop-types";
import ChatMessageAttachTime from "./ChatMessageAttachTime";
import Moment from "moment";
import { useGlobal } from "reactn";
import Config from "../../../../../chat/config";
import ChatSendImage from "./ChatSendImage";
import ChatSendFile from "./ChatSendFile";
import ChatSendVideo from "./ChatSendVideo";
import ChatSendAudio from "./ChatSendAudio";
import { MomentUtil } from "../../../../../utils/moment_util";

const ChatSendItemTest = ({ message, previous, next, onOpen }) => {
  const { author, content, date, showAvatar, isMine, type } = message;
  const username =
    author &&
    (author.firstName?.substr(0, 1) || "U") +
      "" +
      (author.lastName?.substr(0, 1) || "U");

  let attachPrevious = false,
    attachNext = false;

  if (
    previous &&
    Math.abs(Moment(previous.date).diff(Moment(date), "minutes")) < 3 &&
    author._id === previous.author._id
  )
    attachPrevious = true;

  if (
    next &&
    Math.abs(Moment(next.date).diff(Moment(date), "minutes")) < 3 &&
    author._id === next.author._id
  )
    attachNext = true;

  const Details = ({ side }) => {
    if (!attachPrevious)
      return (
        <div className={`message-details ${side}`}>
          {date && MomentUtil(date, "MMM DD - h:mm A")}
        </div>
      );
    else return null;
  };

  const PictureOrSpacer = () => {
    if (attachNext) return <div className="chat-spacer mg-left-5"></div>;
    else
      return (
        <div className="chat-send-avatar mg-right-5 d-flex justify-content-center align-items-center bg-dark">
          <span className="text-light">{username}</span>
        </div>
      );
  };

  const Content = () => {
    switch (type) {
      case "image":
        return <ChatSendImage src={content} />;
      case "file":
        return <ChatSendFile src={message} />;
      case "video":
        return <ChatSendVideo src={message} />;
      case "audio":
        return <ChatSendAudio src={message} />;
      default:
        return (
          <div className="chat-send-item">
            <span className="fs-14 roamie-text-color-primary">{content}</span>
          </div>
        );
    }
  };
  return (
    <div className="chat-send-item-container">
      <div className="chat-item mg-right-5">
        <Content />
        <Details side={isMine ? "right" : "left"} />
      </div>

      <PictureOrSpacer />

      {/*<div className="chat-spacer mg-left-5"></div>*/}
      {/*<ChatMessageAttachTime {...props} />*/}
    </div>
  );
};

ChatSendItemTest.propTypes = {};

export default ChatSendItemTest;
