import React from "react";
import PropTypes from "prop-types";

const ChatHeader = (props) => {
  return (
    <div className="chat-header roamie-text-color-primary">
      <span className="fs-15">Famlily Chat</span>
    </div>
  );
};

ChatHeader.propTypes = {};

export default ChatHeader;
