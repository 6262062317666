import Moment from "moment";
import "moment/locale/es";
import "moment/locale/vi";
import { CurrentLanguage } from "./language_util";

export const MomentUtil = (date, format) => {
  /// MARK: - Initials;
  const languageKey = CurrentLanguage();
  var locale = "en";
  if ("vi-VN".includes(languageKey)) {
    locale = "vi";
  }
  const momentDate = Moment(date);
  if (!momentDate.isValid()) {
    return "";
  }
  return momentDate.locale(locale).format(format);
};
