import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { MomentUtil } from "../../../../utils/moment_util";

const HomeCalendarEventItem = (props) => {
  /// MARK: - Initials;
  const { appointment } = props;

  /// MARK: - Hooks
  const { t } = useTranslation();

  const getHoursDiff = () => {
    const endDate = Moment(appointment.end_time);
    const startDate = Moment(appointment.start_time);
    return endDate.diff(startDate, "hours");
  };

  return (
    <>
      <div className="home-calendar-event">
        <h1 className="fs-20 font-w400 roamie-text-color-primary col-lg-1">
          {Moment(appointment.start_time).format("hh:mm")}
          <br />
          {appointment.start_time && MomentUtil(appointment.start_time, "a")}
        </h1>
        <div
          className="home-calendar-event-card"
          // onClick={() => props.onClick(appointment)}
        >
          <span className="fs-17 font-w400 roamie-text-white-color">
            {appointment.title}
          </span>
          <span className="fs-15 font-w400 op8 roamie-text-white-color">
            {getHoursDiff()} {t("hours")}
          </span>
          <div>
            <FontAwesomeIcon icon={faMapMarkerAlt} color="#fff" />
            <span className="fs-15 font-w400 op8 roamie-text-white-color mg-left-10">
              {appointment.location.address}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

HomeCalendarEventItem.propTypes = {};

export default HomeCalendarEventItem;
