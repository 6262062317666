import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MomentUtil } from "../../../../utils/moment_util";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { deleteTask, updateTask } from "../TasksSlice";

const Task = (props) => {
  /// Initials
  const { task } = props;
  const isOverDue = Moment(task.end_date).isBefore(new Date());
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);

  /// Hooks
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /// UseEffect Load Data
  useEffect(() => {
    /// Check Task Completed
    const isTaskCompleted = task.task_type == 1;
    setIsTaskCompleted(isTaskCompleted);
  }, []);
  /// Handle Update Task
  const handleUpdateTaskCompleted = async (event) => {
    try {
      setLoading(true);
      const value = event.target.checked;
      task.task_type = 1;
      task.lead_id = task && task.lead.objectId;
      const request = {
        tasks: [task],
      };
      const action = updateTask(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message || ""), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  /// Handle Update Task
  const handleUpdateTaskUnCompleted = async (event) => {
    try {
      setLoading(true);
      const value = event.target.checked;
      task.task_type = 0;
      task.lead_id = task && task.lead.objectId;
      const request = {
        tasks: [task],
      };
      const action = updateTask(request);
      const result = await dispatch(action);
      const response = unwrapResult(result);
      if (response.status === 200) {
        enqueueSnackbar(t(response.message || ""), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  /// Handle delete task
  const handleDeleteTask = async () => {
    if (window.confirm(t("are_you_sure"))) {
      try {
        setLoading(true);
        const request = {
          objectId: task && task.objectId,
        };
        const action = deleteTask(request);
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response && response.status === 200) {
          enqueueSnackbar(t(response.message || ""), {
            variant: "success",
          });
          if (props.reload) {
            props.reload();
          }
        } else {
          enqueueSnackbar(t(response.message || ""), {
            variant: "error",
          });
        }
      } catch (error) {
        console.log("Failed to detele task", error);
      } finally {
        setLoading(false);
      }
    } else {
      return;
    }
  };
  return (
    <div className="row">
      <div
        className={`wrapper__task mb-3 card card-body ${
          task && task.task_type === 1 ? "col-11" : ""
        }`}
      >
        {!isTaskCompleted && (
          <label className="pointer">
            <input type="checkbox" onChange={handleUpdateTaskCompleted} />
            <span className="mg-left-10">{t("pending")}</span>
          </label>
        )}
        {isTaskCompleted && (
          <label className="pointer">
            <input type="checkbox" onChange={handleUpdateTaskUnCompleted} />
            <span className="mg-left-10">{t("completed")}</span>
          </label>
        )}
        <button onClick={() => props.onClick(task)}>
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center date__task">
              <p className="fs-24 text-primary mb-0 mt-2">
                {task && task.end_date && MomentUtil(task.end_date, "DD")}
              </p>
              <span className="fs-14 text-primary">
                {task && task.end_date && MomentUtil(task.end_date, "ddd")}
              </span>
            </div>
            <div className="mg-left-10 info__task d-flex justify-content-center align-items-start flex-column">
              <span className="fs-20 mb-1">{task.title}</span>
              <span className="fs-400 mb-1">{task.description}</span>
              <span className="fs-400 mb-1 text-primary">
                {task.lead && task.lead.name}
              </span>
              <span className="fs-400 mb-1">
                {Moment(task.end_date).locale("en").format("MM-YYYY, HH:mm")}
              </span>
              <div className="task__status pending">
                {!isTaskCompleted && (
                  <span>{isOverDue ? t("overdue") : t("pending")}</span>
                )}
                {isTaskCompleted && <span>{t("completed")}</span>}
              </div>
            </div>
          </div>
        </button>
      </div>
      {task && task.task_type === 1 ? (
        <div className="col-1 d-flex align-items-center justify-content-center">
          <FontAwesomeIcon
            className="pointer"
            icon={faTrashAlt}
            size="2x"
            color={"#E94D64"}
            onClick={handleDeleteTask}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

Task.propTypes = {
  task: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

Task.defaultProps = {
  onClick: () => {},
};

export default Task;
