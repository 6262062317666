import React from "react";
import { useTranslation } from "react-i18next";
import empty from "../../../assets/images/empty_image.png";

export default function Empty(props) {
  /// Hooks
  const { t } = useTranslation();

  return (
    <div className="empty-container d-flex justify-content-center col-12 mg-top-20">
      <div className="">
        <div className="empty-item__img d-flex justify-content-center">
          <img src={empty} width='90' alt="icon"/>
        </div>
        <div className="empty-item__text mt-4 d-flex justify-content-center">
          <span className='roamie-text-color-second font-w500 fs-15'>{t(props.text)}</span>
        </div>
      </div>
    </div>
  );
}
