import jwtDecode from "jwt-decode";
import IO from "socket.io-client";
import store from "../app/store";
import messageSound from "../assets/audio/message.mp3";
import Config from "./config";
import {
  addMessage,
  getOnlineUser,
  getRooms,
  initializeIO,
  refreshMeetings,
  setRooms
} from "./slices/IOSlice";
import { messagesAddToRoomUnread } from "./slices/MessageSlice";
import {
  rtcAnswer,
  rtcCall,
  rtcClose,
  rtcConsumers,
  rtcLeave,
  rtcNewPeer,
  rtcProducer,
  rtcResetProducers,
  rtcSetCounterPart,
  rtcUpdateCalling
} from "./slices/RTCSlice";

const socketPromise = require("./socket.io-promise").promise;

const InitIO = (token) => (dispatch) => {
  /// MARK: - Initials;
  const user = jwtDecode(token);

  let io = IO((Config.socketUrl || "") + "/");
  io.request = socketPromise(io);
  io.on("connect", () => {
    io.emit("authenticate", { token });
    console.log("IO connected");
  });

  io.on("authenticated", () => {
    console.log("IO authenticated");
    // const action = getRooms({ limit: 30 });
    // dispatch(action).then(rs => {
    //     console.log((rs.payload.data));
    // });
    dispatch(initializeIO({ io }));
  });

  /// Message In
  io.on("message-in", (data) => {
    const { room, message } = data;
    const currentRoom = store.getState().ios.room;

    const audio = document.createElement("audio");
    audio.style.display = "none";
    audio.src = messageSound;
    audio.autoplay = true;
    audio.onended = () => audio.remove();
    document.body.appendChild(audio);

    if (!currentRoom || currentRoom._id !== room._id) {
      // store.dispatch({type: Actions.MESSAGES_ADD_ROOM_UNREAD, roomID: room._id});
      dispatch(messagesAddToRoomUnread({ roomID: room._id }));
    }

    if (!currentRoom) return;
    if (currentRoom._id === room._id) store.dispatch(addMessage({ message }));

    const action = getRooms({ limit: 30 });
    dispatch(action).then((rs) => {
      store.dispatch(setRooms({ rooms: rs.payload.data.rooms }));
    });
    // getRooms().then(res => store.dispatch(setRooms({rooms: res.data.rooms}))).catch(err => console.log(err));
  });

  /// New Producer
  io.on("newProducer", (data) => {
    console.log("newProducer", data);
    if (data.socketID !== io.id) store.dispatch(rtcProducer(data));
  });

  /// Leave
  io.on("leave", (data) => {
    console.log("leave", data);
    let producers = store.getState().rtc.producers;
    producers = producers.filter(
      (producer) => producer.socketID !== data.socketID
    );
    console.log("producers after leave", producers);
    // dispatch(rtcParams({ params: null }));
    store.dispatch(rtcResetProducers({ producers, socketID: data.socketID }));
  });

  /// Consumers
  io.on("consumers", (data) => {
    console.log("consumers", data);
    store.dispatch(rtcConsumers({ consumers: data }));
  });

  /// New Peer
  io.on("newPeer", (data) => {
    console.log("newPeer", data);
    store.dispatch(rtcNewPeer({ data: data }));
  });

  /// Call
  io.on("call", (data) => {
    console.log("call", data);
    if (store.getState().rtc.isCalling) {
      io.emit("call-busy", {
        user_id: user.id,
        room_id: data.roomID,
      });
      return;
    }
    store.dispatch(rtcSetCounterPart({ counterpart: data.counterpart }));
    store.dispatch(rtcCall(data));
  });

  io.on("call-busy", (data) => {});

  /// Close
  io.on("close", async (data) => {
    console.log("close", data);
    const isGroup = data.isGroup || false;
    if (!isGroup) {
      store.dispatch(rtcClose(data));
      store.dispatch(rtcLeave());
    } else {
      // const getMeetingsAsnyc = async () => {
      //   const resultAction = await dispatch(getMeetings());
      //   const meetings = unwrapResult(resultAction);
      //   return meetings;
      // };
      // const { meetingID } = store.getState().rtc.callData;
      // const response = await getMeetingsAsnyc();
      // const meetings = response && response.data.meetings;
      // const meeting = meetings.find((meeting) => meeting._id == meetingID);
      // if (meeting) {
      //   const isStillMeeting = meeting.peers.length > 0;
      //   store.dispatch(
      //     rtcUpdateStillMetting({ isStillMeeting: isStillMeeting })
      //   );
      // }
    }
  });

  /// Answer
  io.on("answer", (data) => {
    console.log("Answer: ", data);
    store.dispatch(rtcAnswer(data));
    store.dispatch(rtcUpdateCalling({ isCalling: true }));
  });

  /// Remove
  io.on("remove", (data) => {
    console.log("remove", data.producerID);
    let producers = store.getState().rtc.producers;
    producers = producers.filter(
      (producer) => producer.producerID !== data.producerID
    );
    console.log("producers after remove", producers);
    store.dispatch(
      rtcResetProducers({
        producers,
        socketID: data.socketID,
        lastLeaveType: "remove",
        producerID: data.producerID,
      })
    );
  });

  /// Online users
  io.on("onlineUsers", (data) => {
    dispatch(getOnlineUser(data));
  });

  /// Refresh Meetings
  io.on("refresh-meetings", (data) => {
    dispatch(refreshMeetings({ timestamp: data.timestamp }));
  });

  /// User Deleted
  // io.on('user-deleted', async () => {
  //     localStorage.removeItem('token');
  //     await setGlobal({
  //         token: null,
  //         user: {},
  //     });
  // })

  /// Typing
  // io.on('typing', data => {
  //     if (store.getState().ios.room && data.roomID === store.getState().ios.room._id) {
  //         if (data.isTyping) {
  //             clearTimeout(window.typingTimeout)
  //             window.typingTimeout = setTimeout(() => {
  //                 dispatch(setIsTyping(false));
  //             }, 10000);
  //         }
  //         else {
  //             clearTimeout(window.typingTimeout);
  //         }
  //         dispatch(setIsTyping(data.isTyping));
  //     }
  // })

  io.on("disconnected", () => {});

  io.on("create-group", (data) => {
    console.log("Create Group");
    const action = getRooms({ limit: 30 });
    dispatch(action).then((rs) => {
      store.dispatch(setRooms({ rooms: rs.payload.data.rooms }));
    });
  });

  window.onbeforeunload = function () {
    io.emit("leave", { socketID: io.id, roomID: store.getState().rtc.roomID });
    return;
  };
};

export default InitIO;
