import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRoom } from "../../../../../chat/slices/IOSlice";
import AvatarChat from "../AvatarChat";

const MessageItem = (props) => {
  // MARK: - Initials;
  const dispatch = useDispatch();
  const { fullName, username, _id, email, marginTop } = props;
  const displayName = _.isEmpty(_.trim(fullName)) ? username : fullName;
  const { lastMessage } =
    useSelector((state) => state.ios.rooms).filter((x) => x._id === _id) ||
    "last msg";

  const chat = async () => {
    const resultAct = await dispatch(createRoom({ counterpart: _id }));
    const res = unwrapResult(resultAct);
    const room = res.data.room;
    // await dispatch(setRoom(room));
    // await dispatch(setMessages(room.messages));
    // setNav('rooms');
    // const target = `/room/${res.data.room._id}`;
    // dispatch({type: Actions.SET_ROOM, room: res.data.room});
    // dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
    // if (location.pathname !== target) history.replace(target);
  };

  return (
    <div
      className={`message-item d-flex ` + marginTop}
      onClick={chat}
      style={{ hover: { color: "#000" } }}
    >
      <AvatarChat avatarType="actives-chat" />
      <div className="message-item-detail d-flex flex-column justify-content-center mg-left-10">
        <div className="message-item-name">
          <span className="fs-17" style={{ color: "#515671" }}>
            {displayName}
          </span>
          <span className="fs-11 roamie-text-color-primary">01.02.21</span>
        </div>
        <span className="fs-12" style={{ color: "#AFBBC6" }}>
          {lastMessage}
        </span>
      </div>
    </div>
  );
};

MessageItem.propTypes = {};

export default MessageItem;
