import React from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineGroupAdd } from "react-icons/md";
import { useGlobal } from "reactn";

const MessagesActiveChats = (props) => {
  /// MARK: - Initials;
  const { t } = useTranslation();
  const [nav, setNav] = useGlobal("nav");

  return (
    <div className="mg-top-30 d-flex justify-content-between">
      <span className="fs-15 font-w400 roamie-text-color-primary">
        {t("create_group")}
      </span>
      <button
        className="messagess-add"
        onClick={() => {
          // alert("new group");
          setNav("createGroup");
        }}
      >
        {/*<i className="fa fa-plus"></i>*/}
        <MdOutlineGroupAdd size={"20px"} />
      </button>
    </div>
  );
};

MessagesActiveChats.propTypes = {};

export default MessagesActiveChats;
