import axiosClient from "./axiosClient";

const myProfileApi = {
  getStats: (params) => {
    const { sale_person, periods } = params;
    let p = "";
    if (periods !== undefined) {
      p = periods.toString();
    }
    const url = `/api/get-stats?periods=${p}&sale_person=${sale_person || ""}`;
    return axiosClient.get(url);
  },
};

export default myProfileApi;
