import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import salesTeamApi from "../../../api/salesTeamApi";

export const inviteTeam = createAsyncThunk(
  "api/invite-team",
  async (payload) => {
    const response = await salesTeamApi.inviteTeam(payload);
    return response;
  }
);

export const getMyTeam = createAsyncThunk("api/my-team", async (payload) => {
  const response = await salesTeamApi.getMyTeam();
  return response;
});

export const updateSaleTeam = createAsyncThunk(
  "api/update-team",
  async (payload) => {
    const response = await salesTeamApi.updateTeam(payload);
    return response;
  }
);

export const searchMyTeam = createAsyncThunk("api/search/my-team", async (payload) => {
  const response = await salesTeamApi.filterMyteam(payload);
  return response;
});

export const importSaleTeam = createAsyncThunk("api/import-team", async (payload) => {
  const response = await salesTeamApi.importSaleTeams(payload);
  return response;
});
const salesTeamSlice = createSlice({
  name: "SalesTeamSlice",
  initialState: {},
  reducers: {},
  extraReducers: {},
});

const { reducer, actions } = salesTeamSlice;
export default reducer;
