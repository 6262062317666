import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  deleteConversation,
  getRooms,
} from "../../../../../chat/slices/IOSlice";
import ChatDetailPeopleItem from "./ChatDetailPeopleItem";

const ChatDetailPeople = (props) => {
  /// Initials
  const { isMinimize, room } = props;
  const [expland, setExpland] = useState(true);

  /// Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /// Handle delete conversation
  const remove = async () => {
    if (
      window.confirm(
        "Are you sure you want to remove this room? All associated messages will be deleted, both for you and other members."
      )
    ) {
      try {
        const resDelete = await dispatch(deleteConversation(room._id));
        const resultDelete = unwrapResult(resDelete);
        if (resultDelete) {
          dispatch(getRooms());
        }
        // popup('Room has been deleted.', 'success');
        // getRooms().then(res => dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
        // if (currentRoom && room._id === currentRoom._id) history.replace('/');
      } catch (error) {
        console.log("Error while removing room. Please retry!", error);
      }
    }
  };

  return (
    <div className="mg-top-20">
      <span
        className={`d-flex justify-content-${
          isMinimize ? "center" : "between"
        }`}
        style={{ color: "#4B5155" }}
      >
        <span className="fs-14 font-w500">{t("members")}</span>
        <div className={isMinimize ? "d-none" : ""}>
          <FontAwesomeIcon
            onClick={() => setExpland(!expland)}
            className="p-2 rounded-1 chat-feature-icon"
            icon={expland ? faSortDown : faSortUp}
            style={{ backgroundColor: "#DBE5ED" }}
          />

          {/* <Dropdown>
            <Dropdown.Toggle className="chat-detail-more">
              <FontAwesomeIcon className="p-2 rounded-1 chat-feature-icon ms-2" icon={faSortDown} style={{ backgroundColor: "#DBE5ED" }} />
            </Dropdown.Toggle>

             <Dropdown.Menu>
              <Dropdown.Item onClick={remove}>Delete conversation</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </span>
      <div
        className={`chat-detail-people-container ${
          isMinimize ? "scroll-hide" : "scroll-gray"
        } ${expland ? "" : "d-none"}`}
      >
        <div className="chat-detail-peoples">
          {room &&
            room.people.length > 0 &&
            room.people.map((p) => (
              <ChatDetailPeopleItem
                key={p._id}
                isMinimize={isMinimize}
                people={p}
              />
            ))}
          {/*<ChatDetailPeopleItem isMinimize={isMinimize}/>*/}
        </div>
      </div>
    </div>
  );
};

ChatDetailPeople.propTypes = {};

export default ChatDetailPeople;
