import React from "react";
import iconFile from "../../../../../assets/icons/icon_file.png";
import Config from "../../../../../chat/config";
export default function ChatSendFile(props) {
  const { src } = props;
  return (
    <div className="chat-send-item-container">
      <div className="chat-send-item with-file align-items-center">
        <img src={iconFile} />
        <a
          className="fs-14 roamie-text-color-primary mg-left-5"
          href={`${Config.url || ""}/api/files/${src.content}`}
          download={src.file ? src.file.name : "File"}
        >
          <div className="content-name">
            {src.file ? src.file.name : "File"}
          </div>
        </a>
      </div>
    </div>
  );
}
