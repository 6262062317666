import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userApi from "../../../api/userApi";
import StorageKeys from "../../../constants/StorageKeys";
import { signOutFromGoogle } from "../../../services/GoogleService";

export const signIn = createAsyncThunk("/api/login", async (payload) => {
  const userResponse = await userApi.signIn(payload);
  if (userResponse.user !== undefined || null) {
    localStorage.setItem(StorageKeys.USER, JSON.stringify(userResponse.user));
  }
  return userResponse;
});

export const googleSignIn = createAsyncThunk(
  "/api/signupSocial",
  async (payload) => {
    const userResponse = await userApi.googleSignIn(payload);
    if (userResponse.user !== undefined || null) {
      localStorage.setItem(StorageKeys.USER, JSON.stringify(userResponse.user));
    }
    return userResponse;
  }
);

export const signUp = createAsyncThunk("/api/sigup", async (payload) => {
  const userSignUpResponse = await userApi.signUp(payload);
  return userSignUpResponse;
});

export const fetch = createAsyncThunk("/api/users", async (payload) => {
  const userData = await userApi.fetchUser(payload);
  if (userData && userData.user && userData.user.default_language) {
    localStorage.setItem(
      StorageKeys.LANGUAGE_KEY,
      userData.user.default_language
    );
  }
  return userData.user;
});

export const pushFcmToken = createAsyncThunk(
  "/api/push/installations",
  async (payload) => {
    const response = await userApi.pushFCMToken(payload);
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "/api/users/requestResetPassword",
  async (payload) => {
    const response = await userApi.resetPassword(payload);
    return response;
  }
);

export const loginEncrypt = createAsyncThunk(
  "/api/login-encrypt",
  async (payload) => {
    const response = await userApi.loginEncrypt(payload);
    if (response.user !== undefined || null) {
      localStorage.setItem(StorageKeys.USER, JSON.stringify(response.user));
    }
    return response;
  }
);

export const reloadUserInfoState = createAsyncThunk(
  "reload_user_info",
  async () => {
    const userResponse =
      JSON.parse(localStorage.getItem(StorageKeys.USER)) || {};
    return userResponse;
  }
);

export const deleteMyAccount = createAsyncThunk(
  "delete_my_account",
  async (payload) => {
    const response = await userApi.deleteMyAccount(payload);
    return response;
  }
);

const authSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    current: JSON.parse(localStorage.getItem(StorageKeys.USER)) || {},
    languageKey: localStorage.getItem(StorageKeys.LANGUAGE_KEY) || "vi-VN",
    initData: {},
  },

  reducers: {
    logout: (state) => {
      signOutFromGoogle();
      localStorage.removeItem(StorageKeys.USER);
      localStorage.removeItem(StorageKeys.NODE_USER);
      localStorage.removeItem(StorageKeys.NODE_TOKEN);
    },
  },
  extraReducers: {
    [signIn.fulfilled]: (state, action) => {
      state.current = action.payload.user;
    },
    [googleSignIn.fulfilled]: (state, action) => {
      state.current = action.payload.user;
    },
    [signUp.fulfilled]: (state, action) => {
      state.current = action.payload.user;
    },
    [fetch.fulfilled]: (state, action) => {
      state.initData = action.payload;
    },
    [loginEncrypt.fulfilled]: (state, action) => {
      state.current = action.payload.user;
    },
    [reloadUserInfoState.fulfilled]: (state, action) => {
      state.current = action.payload.user;
    },
  },
});

const { reducer, actions } = authSlice;
export const { logout } = actions;
export default reducer;
