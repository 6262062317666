import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { leadsType } from "../../../../enum/LeadType";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getMyTeam } from "../../SalesTeam/SalesTeamSlice";

const all = {
  value: 0,
  name: "all",
};

const LeadManagementFilter = (props) => {
  /// MARK: - Initials
  const { t } = useTranslation();
  const { search, typeFilter } = props;
  const [typeSelected, setTypeSelected] = useState(all);
  const dispatch = useDispatch();
  const [saleTeams, setSaleTeams] = useState([]);
  const [teamSelect, setTeamSelect] = useState(
    props.teamSelect || {
      value: -1,
      user_name: t("all"),
    }
  );

  /// MARK: - Hooks
  const { current } = useSelector((state) => state.auth);

  /// Get Teams
  useEffect(() => {
    const getSalesTeamAsync = async () => {
      try {
        const action = getMyTeam();
        const resultActioin = await dispatch(action);
        const response = unwrapResult(resultActioin);
        if (response) {
          let salesTeam = (response.data || []).filter(
            (team) => team.status === "1"
          );
          if (current) {
            salesTeam.push({
              value: current.user_id,
              user_name: current.user_name,
            });
          }
          setSaleTeams(salesTeam);
        }
      } catch (error) {
        console.log("Get sale teams failed", error);
      }
    };
    if (current.level == 3) {
      getSalesTeamAsync();
    }
  }, []);

  return (
    <>
      <div className="d-flex lead-management-filter-container mt-2">
        <div className="col col-md-4 col-lg-4">
          <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
            {t("search")}
          </span>
          <div className="h-50 search-leads mg-top-14">
            <i className="flaticon-381-search-2 roamie-text-color-primary" />
            <input
              type="text"
              className="form-group placeholder-color fs-13 font-w400 roamie-text-color-primary"
              placeholder={t("search")}
              onKeyUp={(e) => search(e.target.value)}
            />
          </div>
        </div>

        {/* Lead Type */}
        <div className="col col-md-4 col-lg-4">
          <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
            {t("leads_type")}
          </span>
          <Dropdown className="roamie-drop-down mg-top-14 h-50">
            <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
              <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
                {t(typeSelected.name)}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setTypeSelected(all);
                  typeFilter(all);
                }}
              >
                {t("all")}
              </Dropdown.Item>
              {leadsType.map((type, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    setTypeSelected(type);
                    typeFilter(type);
                  }}
                >
                  {t(type.name)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Sale Person  */}
        {current.level == 3 ? (
          <div className="col col-md-4 col-lg-4">
            <span className="fs-13 font-w400 roamie-text-color-primary text-truncate">
              {t("saleperson")}
            </span>
            <Dropdown className="roamie-drop-down mg-top-14 h-50">
              <Dropdown.Toggle variant="" className="roamie-drop-down-toggle">
                <span className="fs-13 font-w400 roamie-text-color-primary text-dropdown-select text-truncate">
                  {teamSelect.user_name || ""}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="fs-12"
                  onClick={() => {
                    setTeamSelect({
                      value: -1,
                      user_name: t("all"),
                    });
                    if (props.handleFilterSalePerson) {
                      props.handleFilterSalePerson({
                        value: -1,
                        user_name: t("all"),
                      });
                    }
                  }}
                >
                  {t("all")}
                </Dropdown.Item>
                {saleTeams &&
                  saleTeams.length > 0 &&
                  saleTeams.map((team) => (
                    <Dropdown.Item
                      onClick={() => {
                        setTeamSelect(team);
                        if (props.handleFilterSalePerson) {
                          props.handleFilterSalePerson(team);
                        }
                      }}
                      key={team.user_id}
                    >
                      {team.user_name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
      </div>
    </>
  );
};

LeadManagementFilter.propTypes = {};

export default LeadManagementFilter;
