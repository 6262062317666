import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { CurrentCountry } from "../../../../utils/language_util";
import { nFormatter } from "../../../../utils/number_util";

const DealsChart = (props) => {
  /// Initials
  const { summaryDeal } = props;
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState([]);
  const { t, i18n } = useTranslation();
  
  /// Hooks
  useEffect(() => {
    /// Categories
    const categories =
      (summaryDeal &&
        summaryDeal.summary.length > 0 &&
        summaryDeal.summary.map((summary) => summary.category)) ||
      [];
    setCategories(categories);
    /// Data
    const values =
      (summaryDeal &&
        summaryDeal.summary.length > 0 &&
        summaryDeal.summary.map(
          (summary) => summary.deal_closing_value + summary.deal_closed_value
        )) ||
      [];
    setValues(values);
  }, [summaryDeal]);

  /// Check chart closed items
  const checkChartClosedItem = (item, index) => {
    const itemCheck =
      summaryDeal && summaryDeal.summary[index > 0 ? index - 1 : 0];
    if (itemCheck.deal_closed_value < item.deal_closed_value) {
      return {
        ...item,
        closed: "increase",
      };
    } else if (itemCheck.deal_closed_value > item.deal_closed_value) {
      return {
        ...item,
        closed: "decrease",
      };
    }
    return {
      ...item,
      closed: "constant",
    };
  };
  /// Check chart closing items
  const checkChartClosingItem = (item, index) => {
    const itemCheck =
      summaryDeal && summaryDeal.summary[index > 0 ? index - 1 : 0];
    if (itemCheck.deal_closing_value < item.deal_closing_value) {
      return {
        ...item,
        closing: "increase",
      };
    } else if (itemCheck.deal_closing_value > item.deal_closing_value) {
      return {
        ...item,
        closing: "decrease",
      };
    }
    return {
      ...item,
      closing: "constant",
    };
  };
  /// Check status tool tip
  const checkStatus = (status, type) => {
    switch (status) {
      case "increase":
        if (type === "text") {
          return "text-success";
        } else if (type === "arrow") {
          return "arrow-up text-success";
        } else {
          return "border-success bg-success-fix";
        }
      case "decrease":
        if (type === "text") {
          return "text-warning";
        } else if (type === "arrow") {
          return "arrow-down text-warning";
        } else {
          return "border-warning bg-warning-fix";
        }
      case "constant":
        if (type === "text") {
          return "text-light";
        } else if (type === "arrow") {
          return "text-light";
        } else {
          return "border-light bg-light-fix";
        }
      default:
        break;
    }
  };
  const state = {
    series: [
      {
        name: "",
        data: values,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        group: "social",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [6],
        colors: ["#3b4cb8"],
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
        markers: {
          fillColors: ["#3b4cb8"],
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
        },
      },
      markers: {
        size: 10,
        border: 8,
        strokeWidth: 8,
        colors: ["#3b4cb8"],
        hover: {
          size: 8,
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: "#10193d",
            fontSize: "13px",
            fontFamily: "Poppins",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        CSSRuleList: {},
      },
      yaxis: {
        labels: {
          style: {
            colors: "#10193d",
            fontSize: "14.5px",
            fontFamily: "Poppins",
            fontWeight: 500,
            cssClass: "apexcharts-yaxis-label",
          },
          formatter: function (value) {
            if (CurrentCountry().includes("vn")) {
              var val = Math.abs(value);
              return `${t("amount_chart", {
                amount: Intl.NumberFormat().format(val),
              })}`;
            } else {
              var val = Math.abs(value);
              return `$${nFormatter(val, 1)}`;
            }
          },
        },
      },
      fill: {
        colors: ["#3b4cb8"],
        opacity: 0.07,
      },
      grid: {
        strokeDashArray: 7,
        borderColor: "#e2e5f5",
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const summary = summaryDeal && summaryDeal.summary[dataPointIndex];
          const closed = checkChartClosedItem(
            summaryDeal && summaryDeal.summary[dataPointIndex],
            dataPointIndex
          );
          const closing = checkChartClosingItem(
            summaryDeal && summaryDeal.summary[dataPointIndex],
            dataPointIndex
          );
          const category = categories && categories[dataPointIndex];
          return (
            '<div class="chart-tooltip">' +
            '<div class="chart-tooltip__header p-2">' +
            `${category || ""}` +
            "</div>" +
            '<div class="chart-tooltip__body p-2">' +
            '<div class="d-flex align-items-center">' +
            `<i class="${
              dataPointIndex === 0
                ? ""
                : "" + checkStatus(closed.closed, "text")
            }"></i>` +
            '<span class="ms-2 w-fix">' +
            `${t("closed_deals")}` +
            "</span>" +
            `<div class="border ms-1 p-1 rounded 
            ${
              dataPointIndex === 0
                ? "border-light bg-light-fix"
                : checkStatus(closed.closed, null)
            }">` +
            '<span class="ms-2">' +
            `${Intl.NumberFormat().format(
              (closed && closed.deal_closed_value) || 0
            )}` +
            "</span>" +
            `<i class="ms-1 
            ${
              dataPointIndex === 0
                ? "text-light"
                : checkStatus(closed.closed, "arrow")
            }"></i>` +
            "</div>" +
            "</div>" +
            '<div class="d-flex align-items-center mt-1">' +
            `<i class="${
              dataPointIndex === 0
                ? ""
                : "" + checkStatus(closing.closing, "text")
            }"></i>` +
            '<span class="ms-2 w-fix">' +
            `${t("closing_deals")}` +
            "</span>" +
            `<div class="border ms-1 p-1 rounded 
            ${
              dataPointIndex === 0
                ? "border-light bg-light-fix"
                : checkStatus(closing.closing, null)
            }">` +
            '<span class="ms-2">' +
            `${Intl.NumberFormat().format(
              (closing && closing.deal_closing_value) || 0
            )}` +
            "</span>" +
            `<i class="ms-1 
            ${
              dataPointIndex === 0
                ? "text-light"
                : checkStatus(closing.closing, "arrow")
            }"></i>` +
            "</div>" +
            "</div>" +
            "</div>" +
            "</div>"
          );
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={state && state.options}
        series={state && state.series}
        type="area"
        height={350}
      />
    </div>
  );
};

DealsChart.propTypes = {
  summaryDeal: PropTypes.object,
};

export default DealsChart;
