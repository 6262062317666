import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../components/Empty/Empty";
import { useLoading } from "../../components/Loading/LoadingContext";
import AddLead from "./components/AddLead";
import LeadManagementFilter from "./components/LeadManagementFilter";
import LeadManagementHead from "./components/LeadManagementHead";
import LeadManagementTable from "./components/LeadManagementTable";
import LeadProfile from "./LeadProfile";
import { getLeadsManagement, leadFilter } from "./LeadSlice";

const all = {
  value: 0,
  name: "all",
};
const LeadsManagement = (props) => {
  /// MARK: - Initials
  const [leads, setLeads] = useState([]);
  const [leadSelected, setLeadSelected] = useState(null);
  const [leadReload, setLeadReload] = useState(false);
  const [test, setTest] = useState("");
  const [leadType, setLeadType] = useState(all);
  const [searchKey, setSearchKey] = useState("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, setLoading } = useLoading();
  const { t } = useTranslation();
  const [teamSelect, setTeamSelect] = useState({
    value: -1,
    user_name: t("all"),
  });
  const { current } = useSelector((state) => state.auth);

  /// Add Lead
  const [showAddLead, setShowAddLead] = useState(false);
  const onClickAddLead = () => {
    setLeadSelected(null);
    setShowAddLead(true);
  };

  /// Drawer
  const toggleDrawer = () => {
    setLeadSelected(null);
    setShowAddLead(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawerAddNewAppointment = () => {
    setIsOpen((prevState) => !prevState);
  };

  /// Handle Open Drawer Lead Profile
  const handleOpenDrawerLeadProfile = (lead) => {
    setLeadSelected(lead);
    toggleDrawerAddNewAppointment();
  };

  /// Handle Edit Lead
  const handleLeadEdit = (lead) => {
    setLeadSelected(lead);
    toggleDrawerAddNewAppointment();
    setShowAddLead(true);
  };

  /// Handle Open Drawer Add Lead
  const handleOpenDrawerAddLead = () => {
    setLeadSelected(null);
    toggleDrawerAddNewAppointment();
  };

  /// Handle reload lead
  const handleReloadLead = () => {
    setLeadReload(!leadReload);
  };

  /// Get Leads
  useEffect(() => {
    const getLeadsAsync = async () => {
      try {
        setLoading(true);
        const action = getLeadsManagement();
        const resultAction = await dispatch(action);
        const leads = unwrapResult(resultAction);
        setLeads(leads);
      } catch (error) {
        console.log("Get Leads Error Management:", error);
      } finally {
        setLoading(false);
      }
    };
    getLeadsAsync();
  }, []);

  /// Effect set lead selected
  useEffect(() => {
    if (leadSelected && leadSelected.objeactId) {
      let lead = {};
      lead = leads.find((item) => item.objeactId == leadSelected.objeactId);
      setLeadSelected(lead);
    }
  }, [leads]);

  /// Effect lead filter
  useEffect(() => {
    const leadFilterAsync = async () => {
      try {
        setLoading(true);
        const request = `{
          "search" :"${searchKey}",
          "type" : ${leadType.value},
          "sale_person": ${current.level == 3 ? teamSelect.value : null}
        }`;
        const action = leadFilter(request);
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response.status === 200) {
          setLeads(response && response.results);
        } else {
          enqueueSnackbar(t(response.message || ""), {
            variant: "error",
          });
        }
      } catch (error) {
        console.log("Failed to search lead ", error);
      } finally {
        setLoading(false);
      }
    };
    leadFilterAsync();
  }, [searchKey, leadType, teamSelect, leadReload]);

  /// Handle Filter SalePerson
  const handleFilterSalePerson = (salePerson) => {
    if (salePerson) {
      setTeamSelect({
        value: salePerson.user_id || salePerson.value,
        user_name: salePerson.user_name,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="leads-management-container">
          <LeadManagementHead onClickAddLead={onClickAddLead} />
          <LeadManagementFilter
            search={setSearchKey}
            typeFilter={setLeadType}
            teamSelect={teamSelect}
            handleFilterSalePerson={handleFilterSalePerson}
          />
          {leads && leads.length == 0 && (
            <div className="mg-top-20">
              <Empty text={t("do_not_have_any_leads")} />
            </div>
          )}
          {leads && leads.length > 0 && (
            <LeadManagementTable
              leads={leads}
              onClickLeadProfile={handleOpenDrawerLeadProfile}
              reload={handleReloadLead}
            />
          )}
          {/* Drawer */}
          <Drawer
            className="lead-drawer"
            variant="temporary"
            open={isOpen}
            anchor={"right"}
            onBackdropClick={handleOpenDrawerLeadProfile}
          >
            <LeadProfile
              onClickCloseDrawer={handleOpenDrawerLeadProfile}
              lead={leadSelected}
              onClickLeadEdit={handleLeadEdit}
              reload={handleReloadLead}
            />
          </Drawer>
        </div>
        <AddLead
          open={showAddLead}
          onClose={toggleDrawer}
          reload={handleReloadLead}
          lead={leadSelected}
          onAddSuccess={toggleDrawer}
        />
      </div>
    </>
  );
};

LeadsManagement.propTypes = {};

export default LeadsManagement;
