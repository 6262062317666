import React from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import AvatarChat from "../AvatarChat";

const UserItem = ({ user, selected, onSelect }) => {
  /// MARK: - Initials;
  const { _id, username, email, fullName, firstName, lastName } = user;
  // const dispatch = useDispatch();
  // const {fullName, username, _id, email, marginTop} = props
  // const displayName = _.isEmpty(_.trim(fullName)) ? username: fullName;
  // const {lastMessage} = useSelector(state => state.ios.rooms).filter(x => x._id === _id) || "last msg";

  const chat = async () => {
    // const resultAct = await dispatch(createRoom({counterpart: _id}));
    // const res = unwrapResult(resultAct);
    // const room = res.data.room;
    // await dispatch(setRoom(room));
    // await dispatch(setMessages(room.messages));
    // setNav('rooms');
    // const target = `/room/${res.data.room._id}`;
    // dispatch({type: Actions.SET_ROOM, room: res.data.room});
    // dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
    // if (location.pathname !== target) history.replace(target);
  };

  return (
    <div
      className={`message-item d-flex user-item mb-2`}
      style={{ hover: { color: "#000" } }}
      onClick={onSelect}
    >
      <AvatarChat avatarType="actives-chat" status="offline" user={user} />
      <div
        className={`message-item-detail d-flex flex-column justify-content-center mg-left-10 ${
          selected ? "active" : ""
        }`}
      >
        <span className="fs-17" style={{ color: "#515671" }}>
          {fullName || ""}
        </span>
        <span
          className="fs-12 mb-1 text-truncate"
          style={{ color: selected ? "#da7d02" : "#AFBBC6" }}
        >
          @{username}
        </span>
        {selected ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
      </div>
    </div>
  );
};

UserItem.propTypes = {};

export default UserItem;
