import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { MomentUtil } from "../../../../../utils/moment_util";

const LeadProfileDealItem = (props) => {
  /// Initials
  const { deal, index, type } = props;

  /// Hooks
  const { t, i18n } = useTranslation();

  return (
    <div
      className="row journal-item ph-2 border-text-input m-1 mb-2 pointer"
      onClick={() => props.onClick(deal)}
    >
      <div className="col-6 p-2">
        <div>
          {<span>{t("lead_deal", { deal_index: `${index + 1}` })}</span>}
        </div>
        <p className="time">{deal && deal.description}</p>
        <div>
          <span className="text-danger">{type}</span>
        </div>
        <div className="time">
          <span>
            {deal &&
              deal.close_date &&
              MomentUtil(deal.close_date, "DD MMM yyyy, HH:mm")}
          </span>
        </div>
      </div>
      <div className="col-6 p-2 d-flex justify-content-between flex-column">
        <div className="time d-flex justify-content-end align-items-center">
          <FontAwesomeIcon icon={faVolumeUp} size="1x" />
          <span className="mg-left-10">{deal && deal.target_name}</span>
        </div>
        <div className="text-end">
          <span>
            <sup>$</sup>
            {Intl.NumberFormat().format(deal.value || 0)}
          </span>
        </div>
      </div>
    </div>
  );
};

LeadProfileDealItem.propTypes = {
  deal: PropTypes.object.isRequired,
  index: PropTypes.number,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default LeadProfileDealItem;
