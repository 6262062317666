import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ServiceDealItem from "./ServiceDealItem";

export default function ServiceDealsTable(props) {
  /// Initials
  const { service, leadsSelected, reload } = props;
  const [deals, setDeals] = useState([]);
  /// Hooks
  const { t } = useTranslation();

  /// Filter lead selected
  useEffect(() => {
    if (leadsSelected && leadsSelected.length > 0) {
      let deals = [];
      service.forEach((element) => {
        deals = deals.concat(element.deals);
      });
      const leadFilter =
        deals &&
        deals.filter((deal) =>
          leadsSelected.some((lead) => lead.objectId == deal.lead_id)
        );
      setDeals(leadFilter);
    } else if (service && service.length > 0) {
      let deals = [];
      service.forEach((element) => {
        deals = deals.concat(element.deals);
      });
      setDeals(deals);
    }
  }, [leadsSelected]);
  return (
    <Table responsive striped>
      <thead className="table-head">
        <tr>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {deals &&
          deals.length > 0 &&
          deals
            .filter((deal) => deal.deal_type === 2)
            .map((deal, index) => (
              <ServiceDealItem key={index} deal={deal} reload={reload} />
            ))}
      </tbody>
    </Table>
  );
}
