import jwtDecode from "jwt-decode";
import { setGlobal } from "reactn";

const init = async () => {
  let token = localStorage.getItem("NODE_TOKEN");
  let userString = localStorage.getItem("NODE_USER");
  let user = userString ? JSON.parse(userString) : null;

  const state = {
    version: "1.0",
    entryPath: window.location.pathname,
    token,
    user: user || (token ? jwtDecode(token) : {}),
    rooms: [],
    // searchResults: [],
    // searchResults: [{
    //     _id: uuidv4(),
    //     firstName: 'aa',
    //     lastName: 'bb'
    // }, {
    //     _id: 2,
    //     firstName: 'dd',
    //     lastName: 'ee'
    // }, {
    //     _id: 3,
    //     firstName: 'ff',
    //     lastName: 'ggg'
    // }, {
    //     _id: 4,
    //     firstName: 'tt',
    //     lastName: 'ww'
    // }],
    favorites: [],
    meetings: [],
    nav: "rooms",
    search: "",
    over: null,
    isPicker: false,
    messages: [],
    streams: [],
    inCall: false,
    video: true,
    audio: true,
    audioStream: null,
    videoStream: null,
    screenStream: null,
    callStatus: null,
    counterpart: null,
    callDirection: null,
    meeting: null,
    showPanel: true,
    panel: "standard",
    newGroupUsers: [],
  };

  setGlobal(state).then(() => {}, state);
};

export default init;
