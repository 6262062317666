import axiosClient from "./axiosClient";

const targetApi = {
  getTargets: (params) => {
    const { salePersonId } = params;
    let url = "/api/classes/Target";
    if (salePersonId) {
      url = `/api/classes/Target?sale_person=${salePersonId || ""}`;
    }
    return axiosClient.get(url);
  },
  submitTarget: (params) => {
    const url = "/api/classes/Target";
    return axiosClient.post(url, params);
  },
  updateTarget: (params, targetId) => {
    const url = `/api/classes/Target/${targetId}`;
    return axiosClient.put(url, params);
  },
  deleteTarget: (targetId) => {
    const url = `/api/classes/Target/${targetId}`;
    return axiosClient.delete(url);
  },
  getDeal: (params) => {
    const url = `/api/classes/getDeals`;
    return axiosClient.post(url, params);
  },
};

export default targetApi;
