import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/icons/logo.png";
import { useLoading } from "../../components/Loading/LoadingContext";
import { loginEncrypt } from "./AuthSlide";
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";

const type = { background: "#41539f", color: "white", borderRadius: "10px" };

const Auth = (props) => {
  /// MARK: - Initials;
  const [authType, setAuthType] = useState(true);
  const { encrypt, handleLoginCrypto } = props;
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  /// Handle Auth Type
  const handleAuthType = () => {
    setAuthType(!authType);
  };

  /// Effect login encrypt
  useEffect(() => {
    if (encrypt) {
      const loginByEncrypt = async () => {
        try {
          setLoading(true);
          const request = {
            hash: encrypt,
          };
          const action = loginEncrypt(request);
          const result = await dispatch(action);
          const respone = unwrapResult(result);
          if (respone.status === 200) {
            enqueueSnackbar(t("login_success"), {
              persist: false,
              variant: "success",
            });
            await history.push("/");
            history.go(0);
          } else {
            enqueueSnackbar(t("something_went_wrong"), {
              persist: false,
              variant: "error",
            });
          }
        } catch (error) {
          console.log("Login failed", error);
          enqueueSnackbar(t("something_went_wrong"), {
            variant: "error",
          });
        } finally {
          setLoading(false);
        }
      };
      loginByEncrypt();
    }
  }, []);

  return (
    <div className="auth-wrapper">
      <img src={logo} alt="logo" />

      {/* Login and Register button */}
      <div className="auth-btn">
        <div className="auth-btn__signin d-flex align-items-center">
          <button
            onClick={handleAuthType}
            className="btn btn-light"
            style={authType ? type : { padding: "10px" }}
          >
            {t("login")}
          </button>
        </div>
        <div className="auth-btn__signup d-flex align-items-center">
          <button
            onClick={handleAuthType}
            className="btn btn-light"
            style={authType ? { padding: "10px" } : type}
          >
            {t("register")}
          </button>
        </div>
      </div>

      <div>
        {authType ? (
          <SignIn register={handleAuthType} />
        ) : (
          <SignUp login={handleAuthType} />
        )}
      </div>
    </div>
  );
};

Auth.propTypes = {};
export default Auth;
