const MetricType = {
  usd: {
    value: 1,
    name: "USD",
  },
  vnd: {
    value: 2,
    name: "VND",
  },
};

export const metricsType = [MetricType.usd, MetricType.vnd];

export default MetricType;
