import React, { useEffect, useState } from "react";
import avatarHolder from "../../../../assets/images/user.png";

export default function ServiceLeadItem(props) {
  /// Initials
  const { lead, select, leadsSelected } = props;
  const [name, setName] = useState("");
  const [myContact, setMyContact] = useState(null);
  /// Effect load data
  useEffect(() => {
    if (lead) {
      /// Check name
      const foundName =
        lead.type == 1
          ? lead.name
          : (lead.first_name || "") + " " + (lead.last_name || "");
      setName(foundName);

      /// Check My Contact
      const foundContact = lead && lead.contacts && lead.contacts.find((contact) => {
        return typeof contact.objectId === "undefined";
      })
      if (typeof foundContact !== "undefined") {
        setMyContact(foundContact);
      }
    }
  }, [lead]);

  /// Handle select leads
  const handleSelecteLeads = (e) => {
    let leads = [...leadsSelected]
    if (e.target.checked) {
      leads = [...leadsSelected,lead]
    } else {
      leads.splice(leadsSelected.indexOf(lead.objectId),1) 
    } 
    select(leads)
  }
  return (
    <tr className="service-lead-item">
      <td width={10}>
        <input type={"checkbox"} onChange={(e) => handleSelecteLeads(e)} />
      </td>
      <td className="d-flex align-items-center">
        <img
          src={lead && lead.url ? lead.url : avatarHolder}
          className="rounded-circle"
          alt=""
          style={{
            minWidth: "60px",
            minHeight: "60px",
            maxWidth: "60px",
            maxHeight: "60px",
            objectFit: "cover",
          }}
        />
        <div className="ms-3 media-body">
          <h5 className="mb-0 fs--1">{name}</h5>
        </div>
      </td>
      <td>
        <h5 className="mb-0 fs--1">
          {myContact &&
            myContact.emails &&
            myContact.emails.length > 0 &&
            myContact.emails[0].email}
        </h5>
      </td>
    </tr>
  );
}
