import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const rtcSlice = createSlice({
  name: "RTCSlice",
  initialState: {
    producers: [],
    lastLeave: null,
    roomID: null,
    consumers: [],
    consumersTimestamp: null,
    peers: {},
    increment: 0,
    callIncrement: 0,
    callData: null,
    answerIncrement: 0,
    answerData: 0,
    lastLeaveType: "leave",
    counterpart: null,
    closingState: false,
    closed: true,
    params: null,
    isCalling: false,
    isStillMetting: false,

  },
  reducers: {
    rtcRoomID: (state, action) => {
      state.roomID = action.payload;
      state.closed = false;
    },
    rtcConsumers: (state, action) => {
      if (
        state.consumersTimestamp &&
        moment(state.consumersTimestamp).isAfter(
          moment(action.payload.consumers.timestamp)
        )
      )
      return state;
      state.consumers = action.payload.consumers.content;
      state.peers = action.payload.peers || state.peers;
      state.consumersTimestamp = action.payload.consumers.timestamp;
      state.closed = false;
    },
    rtcProducer: (state, action) => {
      state.producers = [...state.producers, action.payload];
      state.closed = false;
    },
    rtcProducers: (state, action) => {
      state.producers = [...state.producers, ...action.payload.producers];
      state.closed = false;
    },
    rtcLeave: (state, action) => {
      state.producers = [];
      state.lastLeave = null;
      state.roomID = null;
      state.consumers = [];
      state.consumersTimestamp = null;
      state.peers = {};
      state.increment = 0;
      state.callIncrement = 0;
      state.callData = null;
      state.answerIncrement = 0;
      state.answerData = 0;
      state.lastLeaveType = "leave";
      state.counterpart = null;
      state.closingState = false;
      state.closed = true;
      state.params = null;
    },
    rtcResetProducers: (state, action) => {
      state.producers = [...action.payload.producers];
      state.lastLeave = action.payload.producerID || action.payload.socketID;
      state.lastLeaveType = action.payload.lastLeaveType || "leave";
      state.increment = state.increment + 1;
    },
    rtcClose: (state, action) => {
      state.closingState = !state.closed;
    },
    rtcAnswer: (state, action) => {
      state.answerIncrement = state.answerIncrement + 1;
      state.answerData = action.payload;
      state.closed = false;
      state.closingState = false;
    },
    rtcCall: (state, action) => {
      state.callIncrement = state.callIncrement + 1;
      state.callData = action.payload;
      state.closed = false;
      state.closingState = false;
    },
    rtcSetCounterPart: (state, action) => {
      state.counterpart = action.payload.counterpart;
      state.closed = false;
    },
    rtcNewPeer: (state, action) => {
      state.peers = {
        ...state.peers,
        [action.payload.data.socketID]: action.payload.data,
      };
    },
    rtcParams: (state, action) => {
      state.params = action.payload.params;
    },
    rtcUpdateCalling: (state, action) => {
      state.isCalling = action.payload.isCalling;
    },
    rtcUpdateStillMetting: (state, action) => {
      state.isStillMetting = action.payload.isStillMetting;
    },
  },
  extraReducers: {},
});

const { reducer, actions } = rtcSlice;

export const {
  rtcRoomID,
  rtcConsumers,
  rtcProducer,
  rtcProducers,
  rtcLeave,
  rtcResetProducers,
  rtcClose,
  rtcAnswer,
  rtcCall,
  rtcSetCounterPart,
  rtcNewPeer,
  rtcParams,
  rtcUpdateCalling,
  rtcUpdateStillMetting,
} = actions;

export default reducer;
