import React from "react";
import { useTranslation } from "react-i18next";

const HeaderRanking = (props) => {
  /// Hooks
  const { t, i18n } = useTranslation();

  return (
    <>
      <thead>
        <tr>
          <th>
            <span className="text-truncate">{t("rank")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("user_name")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("leads")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("appts")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("tasks")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("deals")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("deals_closing")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("deals_closed")}</span>
          </th>
          <th>
            <span className="text-truncate">{t("percent_target_hit")}</span>
          </th>
        </tr>
      </thead>
    </>
  );
};

HeaderRanking.propTypes = {};

export default HeaderRanking;
