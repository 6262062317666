import React from "react";
import PropTypes from "prop-types";
import { useGlobal } from "reactn";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ChatHeader = ({ room }) => {
  /// MARK: - Initials;
  const isStillMetting =
    useSelector((state) => state.rtc.isStillMetting) || false;
  const user = useGlobal("user")[0];
  const { t } = useTranslation();

  let other = {};

  room.people.forEach((person) => {
    if (user.id !== person._id) other = person;
  });

  if (!other.firstName) {
    other = { ...other, firstName: "Unknown", lastName: "User" };
  }

  const title = room.isGroup
    ? room.title
    : `${other.firstName} ${other.lastName}`;

  /// Handle Join Meeting
  const handleJoinMeeting = async () => {};

  return (
    <div className="chat-header d-flex justify-content-between">
      <span className="fs-18">{title}</span>
      {isStillMetting ? (
        <button onClick={handleJoinMeeting}>{t("join_meeting")}</button>
      ) : null}
    </div>
  );
};

ChatHeader.propTypes = {};

export default ChatHeader;
