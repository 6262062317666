import Picker from "emoji-picker-react";
import Moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import paperclip from "../../../../../assets/icons/icon_paperclip.png";
import smile from "../../../../../assets/icons/icon_smile.png";
import { addMessage } from "../../../../../chat/slices/IOSlice";
import { sendMessage } from "../../../../../chat/slices/MessageSlice";
import ChatSendButton from "./ChatSendButton";

const ChatTextBox = (props) => {
  /// MARK: - Initials
  const { sendFile } = props;
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [message, setMessageForm] = useState("");
  const { room } = useSelector((state) => state.ios);
  const { user } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const [isDisable, setIsDisable] = useState(false);

  /// MARK: - Hook
  const ref = useRef(null);
  const onEmojiClick = (event, emojiObject) => {
    const cursor = ref.current.selectionStart;
    const text =
      message.slice(0, cursor) + emojiObject.emoji + message.slice(cursor);
    setMessageForm(text);
    /// For the new cursor
    const newCursor = cursor + emojiObject.emoji.length;
    setTimeout(() => ref.current.setSelectionRange(newCursor, newCursor), 10);
  };

  const handleSendMessage = async () => {
    if (message.length === 0) return;

    // setIsDisable(true);
    var msg = {
      roomID: room._id,
      authorID: user.id,
      content: message,
      contentType: "text",
    };
    await dispatch(sendMessage(msg));

    let newMessage = {
      _id: Math.random(),
      author: { ...user, _id: user.id },
      content: message,
      type: "text",
      date: Moment(),
    };
    await dispatch(addMessage({ message: newMessage }));

    // sendMessage({roomID: room._id, authorID: user.id, content: text, contentType: 'text'}).then(() => {
    //     getRooms().then(res => dispatch({
    //         type: Actions.SET_ROOMS,
    //         rooms: res.data.rooms
    //     })).catch(err => console.log(err));
    // });
    // let newMessage = {
    //     _id: Math.random(), author: {...user, _id: user.id}, content: text, type: 'text', date: moment()
    // };
    // dispatch({type: Actions.MESSAGE, message: newMessage});
    // setText('');
    // showPicker(false);

    setMessageForm("");
    setChosenEmoji(false);
    // setIsDisable(false);
  };

  const handleKeyPress = (event) => {
    setChosenEmoji(false);
    if (event.key === "Enter") handleSendMessage();
  };

  return (
    <div className="chat-text-box d-flex justify-content-between align-items-center">
      {chosenEmoji ? (
        <div className="emoji-picker">
          <Picker
            onEmojiClick={onEmojiClick}
            disableAutoFocus={true}
            groupNames={{ smileys_people: "PEOPLE" }}
            native
          />
        </div>
      ) : (
        ""
      )}
      <input
        ref={ref}
        type="text"
        className="form-control"
        placeholder="Enter your message here"
        value={message}
        onChange={(e) => setMessageForm(e.target.value)}
        onKeyPress={handleKeyPress}
        disabled={isDisable}
      />
      <div className="d-flex justify-content-between align-items-center px-2">
        <button
          className="mg-right-10"
          onClick={() => setChosenEmoji(!chosenEmoji)}
        >
          <span className="suffix">{<img src={smile} />}</span>
        </button>
        <button className="mg-right-10" onClick={() => sendFile(true)}>
          <span className="suffix">{<img src={paperclip} />}</span>
        </button>
        <ChatSendButton />
      </div>
    </div>
  );
};

ChatTextBox.propTypes = {};

export default ChatTextBox;
