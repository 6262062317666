import LeadCategoryType from "./LeadCategoryType";

const LeadStatusType = {
  leads: {
    value: 1,
    name: "leads",
    categories: [
      LeadCategoryType.cold,
      LeadCategoryType.warm,
      LeadCategoryType.hot,
      LeadCategoryType.supperHot,
    ],
  },
  approached: {
    value: 2,
    name: "approached",
    categories: [
      LeadCategoryType.noResponse,
      LeadCategoryType.callLater,
      LeadCategoryType.emailLater,
      LeadCategoryType.appointment,
    ],
  },
  winning: {
    value: 3,
    name: "winning",
    categories: [
      LeadCategoryType.factFind,
      LeadCategoryType.closing,
      LeadCategoryType.closed,
      LeadCategoryType.notNow,
    ],
  },
  service: {
    value: 4,
    name: "service",
    categories: [
      LeadCategoryType.renewal,
      LeadCategoryType.testimonial,
      LeadCategoryType.upSell,
    ],
  },
};

export const getLeadStatusType = (value) => {
  switch (value) {
    case 1:
      return LeadStatusType.leads;
    case 2:
      return LeadStatusType.approached;
    case 3:
      return LeadStatusType.winning;
    case 4:
      return LeadStatusType.service;
    case 5:
      return LeadStatusType.notNow;
    case 6:
      return LeadStatusType.lost;
    case 7:
      return LeadStatusType.all;
    default:
      break;
  }
};

export default LeadStatusType;
