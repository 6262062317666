import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form, Field } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { aboutUsUpdate } from "../SettingSlice";
import { useSelector } from "react-redux";

export default function AboutUs(props) {
  /// Initials
  const { user } = props;
  const { initData } = useSelector((state) => state.auth);
  /// Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  /// Update about us
  const handleSubmit = async (values) => {
    try {
      const request = {
        id: user && user.user_id,
        about: {
          about: values.about,
        },
      };
      setLoading(true);
      const action = aboutUsUpdate(request);
      const result = await dispatch(action);
      const updateResponse = unwrapResult(result);
      if (updateResponse.status === 200) {
        enqueueSnackbar(t("update_profile_successful"), {
          persist: false,
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(updateResponse.message || ""), {
          variant: "error",
        });
      }
    } catch (err) {
      console.log("Failed to update about us", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="about_us">
      <Formik
        initialValues={{
          about: (initData && initData.about) || "",
        }}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Field
              name="about"
              as="textarea"
              className="fs-16 form-control fs-4 border-text-input mb-3"
              rows={3}
              placeholder={t("about_us")}
            />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-success roamie-text-white-color"
                type="submit"
              >
                {t("save")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
