import React, { useState } from "react";
import ReactImageAppear from "react-image-appear";
import { Lightbox } from "react-modal-image";
import Config from "../../../../../chat/config";

export default function ChatReceiveImage(props) {
  const { src } = props;
  const [open, setOpen] = useState(null);
  return (
    <div className="chat-receive-item-container" onClick={() => setOpen(1)}>
      {open && (
        <Lightbox
          medium={`${Config.url || ""}/api/images/${src}/1024`}
          large={`${Config.url || ""}/api/images/${src}/2048`}
          hideDownload={false}
          onClose={() => setOpen(null)}
        />
      )}
      <div className="chat-receive-item with-image">
        <ReactImageAppear
          src={`${Config.url || ""}/api/images/${src}/512`}
          animationDuration="0.2s"
        />
      </div>
    </div>
  );
}
