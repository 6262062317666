import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik } from "formik";
// import nodeLogin from '../../../../chat/login';
import jwtDecode from "jwt-decode";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import * as Yup from "yup";
import setAuthToken from "../../../../chat/setAuthToken";
import { setCurrentUser } from "../../../../chat/slices/ChatSlice";
import { nodeLogin } from "../../../../chat/slices/NodeAuthSlice";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { signIn } from "../AuthSlide";
import SignInDescription from "../components/SignInDescription";
import SocialGroupButtons from "../components/SocialGroupButtons";
import TextField from "../components/TextField";
import ResetPassword from "./ResetPassword";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const setUser = useGlobal("user")[1];

  /// Active password
  const [active, setActive] = useState(false);
  /// Reset password
  const [reset, setReset] = useState(false);
  const handleResetPassword = () => {
    setReset(true);
  };

  /// Validate Schema
  const validateSchema = Yup.object({
    email: Yup.string()
      .email(t("email_is_invalid"))
      .required(t("email_required")),
    password: Yup.string()
      .required(t("password_required"))
      .min(6, t("password_length_required")),
  });

  /// handle submit action
  const handleSubmitSignIn = async (values) => {
    try {
      setLoading(true);
      const action = signIn(values);
      const resultAction = await dispatch(action);
      const userResponse = unwrapResult(resultAction);
      if (userResponse.user) {
        ///
        enqueueSnackbar(t("login_success"), {
          persist: false,
          variant: "success",
        });

        const nodeLoginAction = nodeLogin({ user: userResponse.user });
        const resultAct = await dispatch(nodeLoginAction);
        const res = unwrapResult(resultAct);
        setAuthToken(res.data.token);
        await dispatch(setCurrentUser(jwtDecode(res.data.token)));

        setUser(jwtDecode(res.data.token));
        history.go(0);
      } else {
        enqueueSnackbar(t("invalid_email_or_password"), {
          persist: false,
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Faile to sign in:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signin-container mt-2">
      {reset ? (
        <ResetPassword
          return={() => setReset(false)}
          register={props.register}
        />
      ) : (
        <>
          <span className="fs-17 font-w500 roamie-text-color-primary">
            {t("login_to_roamie")}
          </span>
          <div className="d-block">
            <SocialGroupButtons />
            <div className="d-flex justify-content-center align-items-center">
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validateSchema}
                onSubmit={handleSubmitSignIn}
              >
                {(formik) => (
                  <Form className="signin-form">
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <span className="pt-2 fs-16 font-w500 roamie-text-color-primary op2 mb-2">
                        {t("or")}
                      </span>
                      <div className="input-group mb-4 border-1 min-width200">
                        <span className="input-group-text fa fa-envelope"></span>
                        <TextField
                          className="form-control"
                          holder={t("email")}
                          type="email"
                          name="email"
                        />
                      </div>
                      <div className="input-group mb-4 min-width200">
                        <span className="input-group-text fa fa-lock border border-start-2"></span>
                        <TextField
                          className="form-control"
                          holder={t("password")}
                          type={active ? "text" : "password"}
                          name="password"
                        />
                        <span
                          onClick={() => {
                            setActive(!active);
                          }}
                          className={`pointer input-group-text fa fa-eye${
                            active ? "" : "-slash"
                          }`}
                        ></span>
                      </div>
                      <div className="blank-space">
                        <a
                          role="button"
                          tabIndex="0"
                          className="text-primary"
                          onClick={handleResetPassword}
                        >
                          {t("forgot_password")}
                        </a>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-login"
                      >
                        {t("login")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <SignInDescription register={props.register} />
        </>
      )}
    </div>
  );
};
export default SignIn;
