import React from "react";
import Tabs, { TabPane } from "rc-tabs";
import { useTranslation } from "react-i18next";

export default function MyProfileTab() {
  /// Hooks
  const { t } = useTranslation();

  /// Handle tabs clicks
  const handleTabsClick = (key) => {
    switch (key) {
      case "1":
        break;
      case "2":
        document.getElementById("stats").scrollIntoView({ behavior: "smooth" });
        break;
      case "3":
        document
          .getElementById("activities")
          .scrollIntoView({ behavior: "smooth" });
        break;
      case "4":
        document.getElementById("chart").scrollIntoView({ behavior: "smooth" });
        break;
      case "5":
        document.getElementById("chart").scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };
  return (
    <div className="my-profiles-tabs">
      <Tabs
        defaultActiveKey="1"
        className="mx-auto"
        tabBarGutter={53}
        onTabClick={(key) => handleTabsClick(key)}
      >
        <TabPane tab={t("about")} key="1"></TabPane>
        <TabPane tab={t("stats")} key="2"></TabPane>
        {/* <TabPane tab={t("activities")} key="3"></TabPane>
        <TabPane tab={t("sales_targets_involved")} key="4"></TabPane>
        <TabPane tab={t("team_chart")} key="5"></TabPane> */}
      </Tabs>
    </div>
  );
}
