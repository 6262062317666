import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobal } from "reactn";
import { getMeetings, search } from "../../../../../chat/slices/ChatSlice";
import { getRooms } from "../../../../../chat/slices/IOSlice";
import MeetingItem from "../Components/MeetingItem";
import Room from "../Components/Room";
import User from "../Components/User";
import CreateGroup from "./CreateGroup";

const Panel = (props) => {
  /// MARK: - Initials;
  const [nav, setNav] = useGlobal("nav");
  // const [searchResults, setSearchResults] = useGlobal('searchResults');
  // const [rooms, setRooms] = useGlobal('rooms');
  const { searchResults, meetings } = useSelector((state) => state.chat);
  const { rooms } = useSelector((state) => state.ios);
  const dispatch = useDispatch();

  useEffect(() => {
    const searchAsync = async () => {
      const searchAction = search({ limit: 30, search: "" });
      await dispatch(searchAction);
    };

    const getRoomsActionsAsync = async () => {
      const getRoomsAction = getRooms();
      await dispatch(getRoomsAction);
    };

    const getMeetingRoomAsync = async () => {
      const getMeetingRoom = getMeetings();
      await dispatch(getMeetingRoom);
    };

    searchAsync();
    getRoomsActionsAsync();
    getMeetingRoomAsync();
    // search().then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
  }, []);

  const roomsList = (rooms || []).map((room, index) => (
    <Room key={room._id} room={room} isFirst={index === 0} />
  ));

  const searchResultsList = (searchResults || []).map((user, index) => (
    <User key={user._id} selectedUser={user} isFirst={index === 0} />
  ));

  const meetingList = (meetings || []).map((meeting, index) => (
    <MeetingItem key={meeting._id} meeting={meeting} isFirst={index === 0} />
  ));

  return (
    <>
      {nav === "rooms" && roomsList}
      {nav === "search" && searchResultsList}
      {nav === "favorites" && <span>this is favorites</span>}
      {/* {nav === 'meetings' && meetingList} */}
      {nav === "createGroup" && <CreateGroup />}
    </>
  );
};

// Panel.propTypes = {};

export default Panel;
