import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGlobal } from "reactn";
import AvatarChat from "../AvatarChat";

const MessagesHeader = (props) => {
  /// MARK: - Initials
  const { initData } = useSelector((state) => state.auth);
  const { onlineUsers } = useSelector((state) => state.ios);
  const [user, setUser] = useGlobal("user");
  const { t } = useTranslation();

  /// Check user status
  const getStatus = () => {
    if (
      onlineUsers.filter((u) => u.id === user.id && u.status === "busy")
        .length > 0
    )
      return "busy";
    if (
      onlineUsers.filter((u) => u.id === user.id && u.status === "online")
        .length > 0
    )
      return "online";
    if (
      onlineUsers.filter((u) => u.id === user.id && u.status === "away")
        .length > 0
    )
      return "away";
    return "offline";
  };

  return (
    <div className="wrapper__messages-header d-flex">
      <AvatarChat status={getStatus()} user={user} />
      <div className="mg-left-10 d-flex flex-column justify-content-center align-items-start">
        <span className="fs-15 font-w400 roamie-text-color-primary">
          {(initData?.last_name || "No") +
            " " +
            (initData?.first_name || "Name")}
        </span>
        <span className="fs-12 font-w400 roamie-text-color-third text-truncate">
          {initData.email || ""}
        </span>
        <span
          className="fs-12 font-w400 mt-1 dropdown-status"
          style={{ color: "green" }}
        >
          {t(getStatus())}
        </span>
      </div>
      {/* <i className="fa fa-cog setting"></i> */}
    </div>
  );
};

MessagesHeader.propTypes = {};

export default MessagesHeader;
