import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const MyProfileAbout = (props) => {
  const { t } = useTranslation()
  const {initData} = useSelector(state => state.auth)
  return (
    <div id='about'>
      <span className="fs-17 font-w400 roamie-text-color-primary">
        {initData && initData.about ||
       <li><Link to="settings" spy="true">{t('update_about')}  <i className="fa fa-arrow-right"></i></Link></li>}
      </span>
    </div>
  );
};

MyProfileAbout.propTypes = {};

export default MyProfileAbout;
