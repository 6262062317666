import React from "react";
import { useSelector } from "react-redux";
import ChatDetailInfo from "./ChatDetailInfo";
import ChatDetailNav from "./ChatDetailNav";
import ChatDetailPeople from "./ChatDetailPeople";


const ChatDetail = (props) => {
  const room = useSelector((state) => state.ios.room);
  /// Initials
  const { isMinimize, minimize } = props;
  return (
    <>
      <ChatDetailNav minimize={minimize} isMinimize={isMinimize} />
      <ChatDetailInfo isMinimize={isMinimize} room={room} />
      <ChatDetailPeople isMinimize={isMinimize} room={room} />
      {/* <ChatDetailFile isMinimize={isMinimize}/> */}
    </>
  );
};

ChatDetail.propTypes = {};

export default ChatDetail;
