import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useGlobal } from "reactn";
import { createRoom } from "../../../../../chat/slices/IOSlice";
import AvatarChat from "../AvatarChat";

const User = ({ selectedUser, selected, onSelect, isFirst }) => {
  // _id(pin):"61bc7f8af8f80ac0c4da0060"
  // tagLine(pin):"New RoamieChat User"
  // username(pin):"admin"
  // email(pin):"di4ldev@gmail.com"
  // firstName(pin):"tuan"
  // lastName(pin):"lm"
  // fullName(pin):"tuan lm"
  const { _id, username, email, fullName } = selectedUser;
  const dispatch = useDispatch();
  // const {fullName, username, _id, email, marginTop} = props
  // const displayName = _.isEmpty(_.trim(fullName)) ? username: fullName;
  // const {lastMessage} = useSelector(state => state.ios.rooms).filter(x => x._id === _id) || "last msg";
  const setNav = useGlobal("nav")[1];
  // const [rooms, setRooms] = useGlobal('rooms');
  const [hover, setHover] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const user = useGlobal("user")[0];

  const chat = async () => {
    // setNav("rooms");
    await dispatch(createRoom({ counterpart: _id }));
    // const id = uuidv4();
    // setRoomId(id);
    // alert(id);
    // setRooms([...rooms, {id, people: [{_id: user.id}, selectedUser]}])

    // const target = `/room/${res.data.room._id}`;
    // dispatch({type: Actions.SET_ROOM, room: res.data.room});
    // dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
    // if (location.pathname !== target) history.replace(target);
  };

  return (
    <div
      className={`message-item d-flex mb-2 user-item ${
        isFirst ? "mg-top-30" : ""
      }`}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{ hover: { color: "#000" } }}
      onClick={chat}
    >
      <AvatarChat
        avatarType="actives-chat"
        status="offline"
        user={selectedUser}
      />
      <div
        className={`message-item-detail d-flex flex-column justify-content-center mg-left-10 ${
          selected ? "active" : ""
        }`}
      >
        <span className="fs-17" style={{ color: "#515671" }}>
          {fullName}
        </span>
        <span className="fs-12 text-truncate" style={{ color: "#AFBBC6" }}>
          @{username}
        </span>
      </div>
    </div>
  );
};

User.propTypes = {};

export default User;
