import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cover from "../../../../assets/images/cover_image.png";
import { useLoading } from "../../../components/Loading/LoadingContext";
import { fetch } from "../../Auth/AuthSlide";
import { coverUpate } from "../SettingSlice";

const CoverImage = (props) => {
  /// Hook
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const { initData } = useSelector((state) => state.auth);

  /// Handel Upload Cover Image
  const handleUploadCover = (e) => {
    let formData = new FormData();
    formData.append("fileToUpload", e.target.files[0]);
    handleUpdateCover(formData);
  };

  /// Handle Update Cover
  const handleUpdateCover = async (data) => {
    try {
      setLoading(true);
      const action = coverUpate(data);
      const resultAction = await dispatch(action);
      const updateResponse = unwrapResult(resultAction);
      if (updateResponse.status === 200) {
        dispatch(fetch(initData.user_id));
        enqueueSnackbar(t("update_profile_successful"), {
          persist: false,
          variant: "success",
        });
      } else {
        enqueueSnackbar(t(updateResponse.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Update avatar failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <label htmlFor="cover" className="d-flex justify-items-center">
        <div
          data-toggle="tooltip"
          data-placement="top"
          title="Change cover image"
          className="wrapper-container__header--coverImage"
          style={{
            backgroundImage: `url('${
              (initData && initData.profile_cover) || cover
            }')`,
          }}
        >
          <input
            hidden={true}
            type="file"
            id="cover"
            accept="image/*"
            onChange={handleUploadCover}
          />
        </div>
      </label>
    </>
  );
};

export default CoverImage;
