import { Drawer } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import closedIcon from "../../../../assets/icons/icon_closed.png";
import { MomentUtil } from "../../../../utils/moment_util";
import AddTask from "../../Tasks/AddTask";

export default function ServiceTaskItem(props) {
  /// MARK: -Initials
  const { task, reload } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [taskSelected, setTaskSelected] = useState({});

  /// MARK: - Hooks
  const { t } = useTranslation();

  /// Drawer Task
  const toggleDrawerAddTask = () => {
    setIsOpen((prevState) => !prevState);
  };
  /// Handle On Click Task
  const handleOnClickTask = () => {
    setTaskSelected(task);
    toggleDrawerAddTask();
  };

  ///Handle task reload
  const handleTaskReload = () => {
    reload();
  };

  /// Effect load task
  return (
    <>
      <tr className="pointer" onClick={handleOnClickTask}>
        <td width={50}>
          <img
            src={closedIcon}
            className=""
            alt=""
            style={{
              minWidth: "60px",
              minHeight: "60px",
              maxWidth: "60px",
              maxHeight: "60px",
              objectFit: "cover",
            }}
          />
        </td>
        <td>
          <h4>{task && task.title}</h4>
          <h5>{task && task.description}</h5>
          <span>
            {task && task.reminder.reminder_time_type === 1
              ? t("completed")
              : t("pending")}
          </span>
        </td>
        <td>
          <h4></h4>
          <span></span>
          <br />
          <span>
            {task && task.end_date && MomentUtil(task.end_date, "MM/DD/YYYY")}
          </span>
        </td>
      </tr>
      <Drawer
        className="lead-drawer"
        variant="temporary"
        open={isOpen}
        anchor={"right"}
        onBackdropClick={toggleDrawerAddTask}
      >
        <AddTask
          reload={handleTaskReload}
          onClickCloseDrawer={toggleDrawerAddTask}
          task={taskSelected}
        />
      </Drawer>
    </>
  );
}
