import nodeAxiosClient from "../nodeAxiosClient";

const chatApi = {
  search: (params) => {
    const url = "/api/search";
    return nodeAxiosClient.post(url, params);
  },
  meeting: (params) => {
    const url = "/api/meeting/list";
    return nodeAxiosClient.post(url, params);
  },
  typing: (room, isTyping) => (dispatch) => {
    const url = "/api/typing";
    return nodeAxiosClient
      .post(url, { room, isTyping })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  },
  postClose: ({ meetingID, userID, isGroup, room }) => {
    const url = "/api/meeting/close";
    return nodeAxiosClient.post(url, { meetingID, userID, isGroup, room });
  },
  postAnswer: ({ userID, meetingID, answer }) => {
    const url = "/api/meeting/answer";
    return nodeAxiosClient.post(url, { userID, meetingID, answer });
  },
  postAdd: ({ userID, meetingID }) => {
    const url = "/api/meeting/add";
    return nodeAxiosClient.post(url, { userID, meetingID });
  },
};

export default chatApi;
