const LanguageType = {
  en: {
    value: "en-US",
    name: "english",
    sort: "en",
  },
  vn: {
    value: "vi-VN",
    name: "vietnamese",
    sort: "vi",
  },
};

export const languageType = [LanguageType.vn, LanguageType.en];
export default LanguageType;
