import React, { useState } from "react";
import { FiPhoneCall, FiPlusCircle } from "react-icons/fi";

export default function User({ user, selected, onSelect }) {
  const [hover, setHover] = useState(false);
  const title = `${user.firstName} ${user.lastName}`.substr(0, 22);

  return (
    <div
      className="room uk-flex"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onSelect}
    >
      <div>{/* <AvatarChat user={user} /> */}</div>
      <div className="text ms-3">
        <div className="title">
          {title}
          {title.length > 22 && "..."}
        </div>
      </div>
      <div className="controls">
        <div className={`date${selected ? " selected" : ""}`}>
          @{user.username}
        </div>
      </div>
      <div className="controls">
        <div className={`button${selected ? " selected" : ""}`}>
          {selected ? <FiPhoneCall /> : <FiPlusCircle />}
        </div>
      </div>
    </div>
  );
}
