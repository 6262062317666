import React, { useState } from "react";
import { useSelector } from "react-redux";

import AvatarUser from "./AvatarUser";
import CoverImage from "./CovertImage";

const SettingsHeader = (props) => {
  return (
    <>
      <div className="wrapper-container d-flex justify-items-center">
        <div className="wrapper-container__header">
          <CoverImage url={props.coverImage} />
          <AvatarUser url={props.avatar} />
        </div>
      </div>
    </>
  );
};

export default SettingsHeader;
