import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

const ArrowEnterButton = (props) => {
  /// Initials
  const { onClick } = props;

  return (
    <>
      <button onClick={onClick}>
        <div className="arrow-enter-button">
          <FontAwesomeIcon icon={faChevronRight} color="white" />
        </div>
      </button>
    </>
  );
};

ArrowEnterButton.propTypes = {
  onClick: PropTypes.func,
};

ArrowEnterButton.defaultProps = {
  onClick: null,
};

export default ArrowEnterButton;
