import React from "react";
import PropTypes from "prop-types";
import ChatReceiveItem from "./ChatReceiveItem";
import ChatSendItem from "./ChatSendItem";

import { useSelector, useDispatch } from "react-redux";

const ChatMessage = (props ) => {
    const { author, content, date } = props;
    const { user} = useSelector(state => state.chat);
    const isMine = user.id === author._id;

    if (isMine)
        return <ChatSendItem {...props} isMine={isMine} />
    else
        return <ChatReceiveItem  {...props} isMine={isMine} />
};

ChatMessage.propTypes = {};

export default ChatMessage;
