import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

const ArrowCalendarButton = (props) => {
  return (
    <div>
      <FontAwesomeIcon
        className="arrow__calendar__button"
        icon={props.direction === "left" ? faChevronLeft : faChevronRight}
        size="6x"
      />
    </div>
  );
};

ArrowCalendarButton.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default ArrowCalendarButton;
