import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CurrentCountry } from "../../../../utils/language_util";
import parsePhoneNumber from "libphonenumber-js";

export default function PhoneUser(props) {
  /// MARK: - Initials;
  const [phoneNumber, setPhone] = useState("");
  const [countryCode, setCountry] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const numbers = props.user && props.user.numbers;
    const countryPhoneCode = numbers.country_phone_code;
    const number = numbers.number;
    const phoneNumber = parsePhoneNumber(`${countryPhoneCode}${number}`);
    if (phoneNumber) {
      setPhone(phoneNumber.nationalNumber);
      setCountry(phoneNumber.countryCallingCode);
    }
  }, []);

  const handleNumber = async (phone, country) => {
    const phoneNumber = parsePhoneNumber(`+${phone}`);
    if (phoneNumber) {
      setPhone(phoneNumber.nationalNumber);
      setCountry(`+${phoneNumber.countryCallingCode}`);
      props.form.setFieldValue("phone_number", phoneNumber.nationalNumber);
      props.form.setFieldValue(
        "country_phone_code",
        `+${phoneNumber.countryCallingCode}`
      );
    }
  };

  return (
    <PhoneInput
      value={`${countryCode}${phoneNumber}`}
      placeholder={t("phone_number")}
      onChange={(phone, country) => {
        handleNumber(phone, country);
      }}
      country={CurrentCountry()}
      inputClass="input-phone border-0 fs-16"
      aria-describedby="basic-addon2"
    />
  );
}
