import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../../components/Button/AddButton";

const TaskHead = (props) => {
  /// Hooks
  const { t } = useTranslation();

  return (
    <div className="task__head d-flex justify-content-between align-items-center">
      <span className="fs-16 font-w500 roamie-text-color-primary">
        {t("tasks")}
      </span>
      <div>
        {/* Tabs */}
        <div className="d-flex btn-changes">
          <div className="d-flex justify-content-end col-lg-6 p-0">
            <div className="d-flex align-items-center">
              <a
                onClick={() => props.changeType(0)}
                className={`btn text-truncate ${
                  props.type === 0 ? "btn-primary" : "btn-light pt-2 pb-2"
                }`}
              >
                {t("pending")}
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-start col-lg-6 p-0">
            <div className="d-flex align-items-center">
              <a
                onClick={() => props.changeType(1)}
                className={`btn text-truncate ${
                  props.type === 1 ? "btn-primary" : "btn-light pt-2 pb-2"
                }`}
              >
                {t("completed")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <AddButton text={t("add_task")} onClick={props.onClick} />
    </div>
  );
};

TaskHead.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TaskHead;
