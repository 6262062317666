import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import SliderDrawer from "../../../components/Drawer/SliderDrawer";
import LeadCompany from "./AddLead/LeadCompany";
import LeadPeople from "./AddLead/LeadPeople";

const AddLead = (props) => {
  /// MARK: - Initials
  const [active, setActive] = useState(0);
  const { lead } = props;
  const { t } = useTranslation();

  /// UseEffect Check Tab
  useEffect(() => {
    if (lead) {
      setActive(lead.type === 1 ? 0 : 1);
    }
  }, [lead]);

  /// Handle Change Tabs
  const handleSetActive = useCallback((i) => setActive(i), []);

  /// Render Tab Component
  const renderTabs = () => {
    const renderTabCompany = () => {
      return (
        <div
          className={`item-group ${active === 0 ? "active" : ""}`}
          onClick={() => handleSetActive(0)}
        >
          {t("company")}
        </div>
      );
    };

    const renderTabPeople = () => {
      return (
        <div
          className={`item-group ${active === 1 ? "active" : ""}`}
          onClick={() => handleSetActive(1)}
        >
          {t("person")}
        </div>
      );
    };

    if (lead && lead.type) {
      if (lead.type === 1) {
        return <div className="select-group">{renderTabCompany()}</div>;
      } else {
        return <div className="select-group">{renderTabPeople()}</div>;
      }
    }
    return (
      <div className="select-group">
        {renderTabCompany()} {renderTabPeople()}
      </div>
    );
  };

  return (
    <SliderDrawer
      {...props}
      title={props.lead ? t("update_lead") : t("add_leads")}
    >
      <div className="drawer-add">
        {renderTabs()}
        {active === 0 ? (
          <LeadCompany
            reload={props.reload}
            lead={lead && lead.type == 1 ? lead : null}
            onAddSuccess={props.onAddSuccess}
          />
        ) : (
          <LeadPeople
            reload={props.reload}
            lead={lead && lead.type == 2 ? lead : null}
            onAddSuccess={props.onAddSuccess}
          />
        )}
      </div>
    </SliderDrawer>
  );
};

AddLead.propTypes = {
  lead: PropTypes.object,
  onAddSuccess: PropTypes.func,
};

export default AddLead;
