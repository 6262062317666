import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import messageApi from "../api/messageApi";

export const sendMessage = createAsyncThunk("/api/message", async (payload) => {
  const res = await messageApi.sendMessage(payload);
  return res;
});

// export const setIsTyping = createAsyncThunk(
//   "/api/message/typing",
//   async (payload) => {
//     const res = await payload;
//     return res;
//   }
// );
const messageSlice = createSlice({
  name: "MessageSlice",
  initialState: {
    roomsWithNewMessages: [],
    // typing: null,
  },
  reducers: {
    // setTyping: (state, action) => {
    //     state.typing = action.payload.typing
    // },
    messagesAddToRoomUnread: (state, action) => {
      console.log("messagesAddToRoomUnread " + JSON.stringify(action));
      state.roomsWithNewMessages.push(action.payload.roomID);
    },
    messagesRemoveRoomUnread: (state, action) => {
      state.roomsWithNewMessages = state.roomsWithNewMessages.filter(
        (r) => r !== action.payload.roomID
      );
    },
  },
  extraReducers: {},
});

const { reducer, actions } = messageSlice;
export const { messagesAddToRoomUnread, messagesRemoveRoomUnread } = actions;
export default reducer;
