import React from "react";
import PropTypes from "prop-types";

const ChatDetailFileItem = (props) => {
  /// Initials
  const { isMinimize } = props
  return (
    <div className={`chat-file-item d-flex mg-bottom-5 align-items-center 
    ${isMinimize ? "justify-content-center" : "justify-content-start"}`}>
      <div className="icon-container">
        <i className="fa fa-image"></i>
      </div>
      <div className={`${isMinimize ? 'd-none' : 'd-flex'} info justify-content-between`}>
        <div className="d-flex mg-left-10 flex-column justify-content-center align-items-start">
          <span className="fs-9 font-w500" style={{ color: "#7B8793" }}>
            Documents
          </span>
          <span className="fs-14 font-w500" style={{ color: "#7B8793" }}>
            8 files
          </span>
        </div>
        <div className="d-flex align-items-center">
          <span className="fs-9 font-w500" style={{ color: "#7B8793" }}>
            2.45 MB
          </span>
          <i
            className="fa fa-chevron-right mg-left-10"
            style={{ color: "#AFBBC6" }}
          ></i>
        </div>
      </div>
    </div>
  );
};

ChatDetailFileItem.propTypes = {};

export default ChatDetailFileItem;
