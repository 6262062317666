import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../components/Empty/Empty";
import { useLoading } from "../../components/Loading/LoadingContext";
import AddTarget from "./AddTarget";
import TargetHead from "./components/TargetHead";
import TargetTable from "./components/TargetTable";
import TargetView from "./components/TargetView";
import { getTargets } from "./TargetSlice";

const Target = (props) => {
  /// MARK: - Initials
  const { current } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const [targets, setTargets] = useState([]);
  const [targetSelected, setTargetSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [targetReload, setTargetReload] = useState(false);
  const [isView, setIsView] = useState(false);
  const [teamSelect, setTeamSelect] = useState({
    value: -1,
    user_name: t("all"),
  });

  /// MARK: - Hooks
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, setLoading } = useLoading();

  /// UseEffect Get Targets
  useEffect(() => {
    const getTargetsAsync = async () => {
      try {
        setLoading(true);
        const salePersonId = teamSelect.value;
        const action = getTargets(current.level == 3 ? { salePersonId } : {});
        const result = await dispatch(action);
        const targets = unwrapResult(result);
        setTargets(targets);
      } catch (error) {
        console.log("Get Target Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getTargetsAsync();
  }, [targetReload, isOpen, teamSelect]);

  /// Handle On Click Add Target
  const handleOnClickAddTarget = () => {
    setTargetSelected(null);
    toggleDrawerAddTarget();
  };

  /// Handle Toggle Add Target
  const toggleDrawerAddTarget = () => {
    setIsOpen((prevState) => !prevState);
  };
  /// Handle Toggle Deal View
  const toggleDrawerDeals = () => {
    setIsView((prevState) => !prevState);
  };

  /// Handle On Click Target
  const handleOnClickTarget = (target) => {
    setTargetSelected(target);
    toggleDrawerAddTarget();
  };

  /// Handle On Click View Deals
  const handleOnClickView = (target) => {
    setTargetSelected(target);
    toggleDrawerDeals();
  };

  /// Handle Reload Target
  const handleReloadTarget = (target) => {
    setTargetSelected(target);
  };

  /// handle reload target
  const handleReload = () => {
    setTargetReload((preState) => !preState);
  };

  /// Handle Filter SalePerson
  const handleFilterSalePerson = (salePerson) => {
    if (salePerson) {
      setTeamSelect({
        value: salePerson.user_id || salePerson.value,
        user_name: salePerson.user_name,
      });
    }
  };

  return (
    <div className="row">
      <div className="wrapper__container__targets">
        <TargetHead
          onClickAddTarget={handleOnClickAddTarget}
          teamSelect={teamSelect}
          handleFilterSalePerson={handleFilterSalePerson}
        />
        {targets && targets.length == 0 ? (
          <Empty text={t("do_not_have_any_targets")} />
        ) : null}
        {targets && targets.length > 0 && (
          <TargetTable
            targets={targets}
            onClickTargetItem={handleOnClickView}
            reload={handleReload}
          />
        )}
      </div>

      <Drawer
        className="lead-drawer"
        variant="temporary"
        open={isOpen}
        anchor={"right"}
        onBackdropClick={toggleDrawerAddTarget}
      >
        <AddTarget
          onClickCloseDrawer={toggleDrawerAddTarget}
          onReloadTarget={handleReloadTarget}
          target={targetSelected}
          isAdd={handleReload}
        />
      </Drawer>

      {/* Deal view drawer */}
      <Drawer
        className="lead-drawer"
        variant="temporary"
        open={isView}
        anchor={"right"}
        onBackdropClick={toggleDrawerDeals}
      >
        <TargetView
          target={targetSelected}
          onClickCloseDrawer={toggleDrawerDeals}
          onClickTargetUpdate={handleOnClickTarget}
        />
      </Drawer>
    </div>
  );
};

Target.propTypes = {};

export default Target;
