import React from "react";
import PropTypes from "prop-types";

const ChatSendButton = (props) => {
  const { handleClick } = props;

  return (
    <div
      className="chat-send-button"
      style={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <i class="fa fa-paper-plane" style={{ color: "white" }}></i>
    </div>
  );
};

ChatSendButton.propTypes = {};

export default ChatSendButton;
