import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LeadStatusAndCategory = (props) => {
  /// Initials;
  const { leadCategory, leadStatusType, lead } = props;
  const [isSelected, setIsSelected] = useState(false);
  /// Hooks
  const { t, i18n } = useTranslation();

  /// UseEffect Load Data
  useEffect(() => {
    if (
      lead.selection_record ? lead && lead.selection_record.length > 0 : false
    ) {
      const foundRecordSelected = lead.selection_record.find((record) => {
        return record.category == leadCategory.value;
      });
      if (foundRecordSelected) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [lead]);

  return (
    <div className="mb-2 d-flex align-items-center">
      <img src={props.icon} className="lead-category-icon" />
      <div
        onClick={() => {
          props.onClick(leadCategory, leadStatusType);
        }}
        className="lead-category-name mg-left-10 pointer"
        style={{
          border: `2px solid ${props.color}`,
          backgroundColor: isSelected ? "#41539F" : "transparent",
          color: isSelected ? "white" : "#10193d",
        }}
      >
        <span>{t(leadCategory.name)}</span>
      </div>
    </div>
  );
};

LeadStatusAndCategory.propTypes = {
  lead: PropTypes.object,
  leadCategory: PropTypes.object.isRequired,
  leadStatusType: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default LeadStatusAndCategory;
