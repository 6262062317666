import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGlobal, useGlobal } from "reactn";
import logo from "../../../assets/icons/logo.png";
import { search } from "../../../chat/slices/ChatSlice";
import { rtcParams } from "../../../chat/slices/RTCSlice";
import ChatHeader from "./components/Chat/ChatHeader";
import ChatSendFiles from "./components/Chat/ChatSendFiles";
import ChatTextBox from "./components/Chat/ChatTextBox";
import Conversation from "./components/Conversation/Conversation";
import ChatDetail from "./components/Detail/ChatDetail";
import CreateGroup from "./components/Group/CreateGroup";
import CreateGroupStep2 from "./components/Group/CreateGroupStep2";
import Panel from "./components/Group/Panel";
import Meeting from "./components/Meeting/Meeting";
import MessageMenu from "./components/Messages/MessageMenu";
import MessagesActiveChats from "./components/Messages/MessagesActiveChats";
import MessagesHeader from "./components/Messages/MessagesHeader";
import MessagesSearch from "./components/Messages/MessagesSearch";

const Messenger = (props) => {
  /// MARK: - Initials;
  const [nav, setNav] = useGlobal("nav");
  const [minimize, setMinimize] = useState(false);
  const [sendFile, setSendFile] = useState(false);
  const dispatch = useDispatch();
  const [panel, setPanel] = useGlobal("panel");
  const room = useSelector((state) => state.ios.room);
  const callIncrement = useSelector((state) => state.rtc.callIncrement);
  const callData = useSelector((state) => state.rtc.callData);
  const params = useSelector((state) => state.rtc.params);
  const history = useHistory();
  const [searchResults, setSearchResults] = useState([]);
  const { t } = useTranslation();

  /// Handle minimize
  const handleMinimize = () => {
    setMinimize(!minimize);
  };

  /// Handle Send File
  const handleSendFile = (bool, files) => {
    setSendFile(bool);
  };

  /// Handle popup
  const popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (window.focus) newWindow.focus();
  };

  useEffect(() => {
    const searchAsync = async () => {
      // const searchAction = search({limit: 30, search: ""});
      // const getRoomsAction = getRooms();

      // getRooms().then(res => dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
      const searchRs = await dispatch(search({ limit: 30, search: "" }));
      // setSearchResults(searchRs.data.users)
      // search().then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
      // getFavorites().then(res => setFavorites(res.data.favorites)).catch(err => console.log(err));
      // getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));

      // await dispatch(searchAction);
      // await dispatch(getRoomsAction);
      setNav("rooms");
      setPanel("");
      console.log("search result call");
    };
    searchAsync();
  }, [setSearchResults]);

  useEffect(() => {
    if (!callData) return;
    setGlobal({
      audio: true,
      video: false,
      callDirection: "incoming",
      meeting: { _id: callData.meetingID },
    }).then(() => {
      // popupCenter({url: '/meeting/' + callData.meetingID, title: 'Call incoming', w: 1400, h: 1000})
      dispatch(rtcParams({ params: callData.meetingID }));
    });
  }, [callIncrement, callData]);

  // useEffect(() => {
  //     console.log(location.pathname)
  //     if (location.pathname !== '/') setOver(true);
  // }, [location]);

  const getPanel = () => {
    switch (panel) {
      case "createGroup":
        return <CreateGroup />;
      case "CreateGroupStep2":
        return <CreateGroupStep2 />;
      default:
        return <Panel />;
    }
  };

  return (
    <div className="row messages-wrapper__container">
      <div className="wrapper__messages__container col-lg-3">
        <div className="wrapper__messages">
          <div className="panel">
            <MessagesHeader />
            <MessagesActiveChats />
            <MessageMenu />
            <MessagesSearch />
          </div>
          <div className="mg-top-30 messages-list">{getPanel()}</div>
        </div>
      </div>

      {/* Chat */}
      <div
        className={`wrapper__chat__container ${
          minimize ? "col-lg-8" : "col-lg-6"
        }`}
      >
        <div className="wrapper__chat">
          {room ? (
            <>
              <ChatHeader room={room} />
              <Conversation />
              <ChatSendFiles active={sendFile} sendFile={handleSendFile} />
              <ChatTextBox sendFile={handleSendFile} />
            </>
          ) : (
            <div className="chat-none">
              <img src={logo} />
              <span className="text-primary">
                {t("search_for_someone_to_start_a_conversation")}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Detail */}
      <div
        className={`wrapper__detail__container ${
          minimize ? "col-lg-1" : "col-lg-3"
        }`}
      >
        <div className="wrapper__detail">
          {room ? (
            <ChatDetail minimize={handleMinimize} isMinimize={minimize} />
          ) : (
            <div className="text-center">
              <p className="text-primary">{t("welcome_to_roamie_chat")}</p>
              <br />
              <p className="fs-14 fw-400">{t("roamie_chat_slogan")}</p>
            </div>
          )}
        </div>
      </div>
      {params !== null && (
        <Modal show={true} className="meeting-modal">
          <Modal.Body>
            <Meeting />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

Messenger.propTypes = {};

export default Messenger;
