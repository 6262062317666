import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PayMentForm from "../../components/PayMent/PayMentForm";
import { getPackage } from "../Settings/SettingSlice";
import ExpiredModal from "./ExpiredModal";

export default function ExpiredRegistration() {
  /// Initials
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState({});

  /// Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const getPackageAsync = async () => {
      try {
        const action = getPackage();
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response.status === 200) {
          setAmount(response.data[0]);
        }
      } catch (error) {
        console.log("Failed to get package", error);
      }
    };
    getPackageAsync();
  }, []);
  /// Handle modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="expired-wrapper">
      <ExpiredModal />
      <div className="d-flex justify-content-center mt-3">
        <div className="expired-head bg-white d-flex justify-content-center align-items-center">
          <span className="fs-17 roamie-text-color-primary">
            {t("monthly")}
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <span className="fs-48 expired-money">
          {t(`$${amount && amount.price_usd}`)}
          <span className="fs-17">{"/" + t("month")}</span>
        </span>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <div className="roamie-text-color-primary">
          <p>
            {t(
              "1. A Personal Assistant to remind you of upcoming appointments and tasks"
            )}
          </p>
          <p>
            {t(
              "2. A Sales Assistant that manages your leads throughout the sales cycle"
            )}
          </p>
          <p>
            {t(
              "3. A Sales Advisor that keeps you up to date with sales progress and targets"
            )}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <button className="btn btn-pay" onClick={handleShow}>
          {t("pay")}
        </button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("pay_for_month")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayMentForm cancel={handleShow} type={2} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
