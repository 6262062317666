import { Drawer } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import championsIcon from "../../../../assets/icons/icon_championships.png";
import AddDeal from "../../Leads/components/Deals/AddDeal";
export default function ServiceDealItem(props) {
  /// Initials
  const { deal, reload } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [radio, setRadio] = useState(null);
  const [dealSelected, setDealSelected] = useState(null);

  /// Hooks
  const { t } = useTranslation();

  /// Handle Open Drawer
  const toggleDrawerAddNote = () => {
    setIsOpen((prevState) => !prevState);
  };
  /// Handle On Click Deal
  const handleOnClickDeal = () => {
    setDealSelected(deal);
    toggleDrawerAddNote();
  };
  return (
    <>
      <tr className="pointer" onClick={() => handleOnClickDeal()}>
        <td width={50}>
          <img
            src={championsIcon}
            className=""
            alt=""
            style={{
              minWidth: "60px",
              minHeight: "60px",
              maxWidth: "60px",
              maxHeight: "60px",
              objectFit: "cover",
            }}
          />
        </td>
        <td>
          <h4>{t("deal")}</h4>
          <h5>{deal && deal.description}</h5>
          <span>{t("closed")}</span>
        </td>
        <td>
          <h4>
            {deal && deal.value && Intl.NumberFormat().format(deal.value)}
          </h4>
          <span></span>
          <br />
          <span>{moment(deal && deal.close_date).format("MM/DD/YYYY")}</span>
        </td>
      </tr>

      <Drawer
        className="lead-drawer"
        variant="temporary"
        open={isOpen}
        anchor={"right"}
        onBackdropClick={toggleDrawerAddNote}
      >
        <AddDeal
          radio={radio}
          onClose={toggleDrawerAddNote}
          onClick={handleOnClickDeal}
          deal={dealSelected}
          reload={reload}
        />
      </Drawer>
    </>
  );
}
