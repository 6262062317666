import { Drawer } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Empty from "../../components/Empty/Empty";
import { useLoading } from "../../components/Loading/LoadingContext";
import SalesTeamFilter from "./components/SalesTeamFilter";
import SalesTeamHead from "./components/SalesTeamHead";
import SalesTeamTable from "./components/SalesTeamTable";
import InviteTeam from "./InviteTeam";
import { getMyTeam, searchMyTeam } from "./SalesTeamSlice";

const SalesTeam = (props) => {
  /// Initials
  const [teams, setTeams] = useState([]);
  const [saleReload, setReload] = useState(false);
  const { initData } = useSelector((state) => state.auth);
  const [roleSelect, setRoleSelect] = useState({ name: "all", value: -1 });
  const [statusSelect, setStatusSelect] = useState({ name: "all", value: -1 });
  const [searchKey, setSearchKey] = useState("");

  /// Drawer
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawerInvite = () => {
    setIsOpen((prevState) => !prevState);
  };

  /// Hooks
  const { t, i18n } = useTranslation();
  const { loading, setLoading } = useLoading();
  const dispatch = useDispatch();
  const history = useHistory();

  /// Handle reload
  const handleSaleReload = () => {
    setReload(!saleReload);
  };

  /// UseEffect Get My Team
  useEffect(() => {
    const getMyTeamAsync = async () => {
      try {
        setLoading(true);
        const resultAction = await dispatch(getMyTeam());
        const response = unwrapResult(resultAction);
        if (response.status === 200) {
          setTeams(response.data);
        }
      } catch (error) {
        console.log("Get My Team Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    getMyTeamAsync();
  }, [saleReload]);

  /// UseEffect Filter My Team
  useEffect(() => {
    const filterMyTeamAsync = async () => {
      const request = {
        keyword: searchKey === "" ? "" : searchKey,
        user_type:
          roleSelect && roleSelect.value === -1 ? "" : roleSelect.value,
        status:
          statusSelect && statusSelect.value === -1 ? "" : statusSelect.value,
      };
      try {
        const action = searchMyTeam(request);
        const result = await dispatch(action);
        const response = unwrapResult(result);
        if (response && response.status === 200) {
          setTeams(response.data);
        }
      } catch (error) {
        console.log("Failed to filter", error);
      }
    };
    filterMyTeamAsync();
  }, [searchKey, roleSelect, statusSelect]);

  ///
  useEffect(() => {
    if (initData && initData.user_role === 4) {
      history.push("/");
      history.go(0);
    }
  }, [initData]);

  return (
    <>
      <div className="row">
        <div className="sales-team-container">
          <SalesTeamHead
            onClickInvite={toggleDrawerInvite}
            reload={handleSaleReload}
          />
          <SalesTeamFilter
            searchKey={setSearchKey}
            roleFilter={setRoleSelect}
            statusFilter={setStatusSelect}
          />
          {teams && teams.length == 0 ? (
            <div className="mg-top-20">
              <Empty text={t("do_not_have_any_members")} />
            </div>
          ) : null}
          {teams && teams.length > 0 ? <SalesTeamTable teams={teams} /> : null}

          {/* Drawer */}
          <Drawer
            className="lead-drawer"
            variant="temporary"
            open={isOpen}
            anchor={"right"}
            transitionDuration={500}
            onBackdropClick={toggleDrawerInvite}
          >
            <InviteTeam
              onClickCloseDrawer={toggleDrawerInvite}
              reload={handleSaleReload}
            />
          </Drawer>
        </div>
      </div>
    </>
  );
};

SalesTeam.propTypes = {};

export default SalesTeam;
