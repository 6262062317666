import nodeAxiosClient from "../nodeAxiosClient";

const chatUserApi = {
  login: (params) => {
    const url = "/api/roamielogin";
    return nodeAxiosClient.post(url, params);
  },
};

export default chatUserApi;
