import nodeAxiosClient from "../nodeAxiosClient";

const roomApi = {
  getRooms: (params) => {
    const url = "/api/rooms/list";
    return nodeAxiosClient.post(url, params);
  },
  createRoom: (params) => {
    const url = "/api/room/create";
    return nodeAxiosClient.post(url, params);
  },
  getRoom: (params) => {
    const url = "/api/room/join";
    return nodeAxiosClient.post(url, params);
  },
  getMeetingRoom: (params) => {
    const url = "/api/meeting/get";
    return nodeAxiosClient.post(url, params);
  },
  deleteRoom: (id) => {
    const url = `/api/room/remove`;
    return nodeAxiosClient.post(url, id);
  },
};

export default roomApi;
