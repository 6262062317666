import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import serviceApi from "../../../api/serviceApi"

export const getService = createAsyncThunk(
    "/api/getService",
    async () => {
        const response = await serviceApi.getService()
        return response;
    }
)
const serviceSlice = createSlice({
    name: "SettingSlice",
    initialState: {},
    reducers: {},
    extraReducers: {},
});

const { reducer, actions } = serviceSlice;
export default reducer;
