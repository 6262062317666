import {
  faCalendarAlt,
  faChevronRight,
  faEnvelope,
  faMapMarkerAlt,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import en from "date-fns/locale/en-US";
import vi from "date-fns/locale/vi";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ActivityType, {
  getTranslateDescription,
} from "../../../enum/ActivityType";
import ReminderType, { reminderTypes } from "../../../enum/ReminderType";
import {
  checkSignInStatus,
  initClient,
  publishTheCalenderEvent,
  signInToGoogle,
  updateCalendarEvent,
} from "../../../services/GoogleService";
import { CurrentCountry } from "../../../utils/language_util";
import { MomentUtil } from "../../../utils/moment_util";
import LeadSelected from "../../components/Lead/LeadSelected";
import { useLoading } from "../../components/Loading/LoadingContext";
import { getLeadsManagement, submitActivity } from "../Leads/LeadSlice";
import { submitAppointment, updateAppointment } from "./CalendarSlice";
import EmailInvite from "./components/EmailInvite";

const AddNewAppointment = (props) => {
  /// Initials
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Moment().add(60, "m").toDate());
  const [allDay, setAllDay] = useState(false);
  const [googleSycing, setGoogleSyncing] = useState(false);
  const [reminderType, setReminderType] = useState(ReminderType.atTime);
  const [leads, setLeads] = useState([]);
  const [leadsSelected, setLeadsSelected] = useState([]);
  const [emailsInvite, setEmailsInvite] = useState([]);
  const appointmentSelected = props.appointment;

  /// Hooks
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const authState = useSelector((state) => state.auth);

  /// UseEffect Google Calendar
  useEffect(() => {
    initClient((success) => {});
  }, []);

  /// Get Auth Google
  const getAuthToGoogle = async () => {
    const status = await checkSignInStatus();
    if (!status) {
      await signInToGoogle();
    }
  };

  /// Handle On Change All Day
  const handleOnChangeAllDay = (event) => {
    if (event.target.checked) {
      const today = new Date();
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        8,
        0,
        0
      );
      const endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        18,
        0,
        0
      );
      setStartDate(startDate);
      setEndDate(endDate);
    }
    setAllDay(event.target.checked);
  };

  /// Handle On Change Google Syncing
  const handleOnChangeGoogleSyncing = (event) => {
    setGoogleSyncing(event.target.checked);
  };

  /// Handle On Change Reminder Type
  const handleOnChangeReminderType = (event) => {
    const reminderType = reminderTypes.find(function (element) {
      return element.value.toString() === event.target.value.toString();
    });
    setReminderType(reminderType);
  };

  /// validate select
  // const validateSelect = (values) => {
  //   let errorMessage;
  //   if (values == -1 || values == "") {
  //     errorMessage = t("leads_required");
  //   } else {
  //     const foundLeadSelected = leadsSelected.find((element) => {
  //       return element.objectId == values;
  //     });
  //     if (typeof foundLeadSelected === "undefined") {
  //       const lead = leads.find((element) => {
  //         return element.objectId == values;
  //       });
  //       leadsSelected.push(lead);
  //       setLeadsSelected([...leadsSelected]);
  //     }
  //   }
  //   return errorMessage;
  // };

  /// Handle On Change Lead
  const handleOnChangeLead = (event) => {
    let value = event.target.value;
    if (value == -1) {
      return;
    }
    const foundLeadSelected = leadsSelected.find((element) => {
      return element.objectId.toString() === value.toString();
    });
    if (typeof foundLeadSelected === "undefined") {
      const lead = leads.find((element) => {
        return element.objectId.toString() === value.toString();
      });
      leadsSelected.push(lead);
      setLeadsSelected([...leadsSelected]);
    }
  };

  const handleOnDeleteLead = (lead) => {
    const objectId = lead.objectId;
    const leadsSelectedClone = [...leadsSelected];
    const newLeads = leadsSelectedClone.filter((element) => {
      return element.objectId.toString() !== objectId.toString();
    });
    setLeadsSelected(newLeads);
  };

  const handleAddEmail = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const emailClones = [...emailsInvite];
      const email = e.target.value;
      emailClones.push(email);
      setEmailsInvite([...emailClones]);
    }
  };

  const handleOnDeleteEmail = (email) => {
    const emailsInviteClone = [...emailsInvite];
    const newEmailsInvite = emailsInviteClone.filter((element) => {
      return element.toString() !== email.toString();
    });
    setEmailsInvite(newEmailsInvite);
  };

  /// Add Appointment
  const handleAddAppointment = async (values) => {
    try {
      setLoading(true);
      const { title, location, description } = values;
      var googleCalendarID = null;
      if (googleSycing) {
        await getAuthToGoogle();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var event = {
          summary: title,
          location: location,
          description: description,
          start: {
            dateTime: Moment(startDate),
            timeZone: timeZone,
          },
          end: {
            dateTime: Moment(endDate),
            timeZone: timeZone,
          },
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: reminderType.reminderMinutes },
            ],
          },
        };
        const resultGoogleCalendar = await publishTheCalenderEvent(event);
        googleCalendarID = resultGoogleCalendar.id;
      }
      const request = {
        title: title,
        type: 1,
        location: {
          address: location,
          lat: null,
          long: null,
        },
        is_all_day: allDay,
        start_time: startDate.toISOString(),
        end_time: endDate.toISOString(),
        reminder: {
          reminder_time_type: reminderType.value,
        },
        description: description,
        is_synced_phone_calendar: false,
        is_synced_google_calendar: googleSycing,
        list_invited_emails: emailsInvite,
        list_leads: leadsSelected.map((element) => element.objectId),
        id_in_phone_calendar: null,
        id_in_google_calendar: googleCalendarID,
      };
      const action = submitAppointment(request);
      const actionResult = await dispatch(action);
      const response = unwrapResult(actionResult);
      if (response.status === 200) {
        handleSubmitActivity();
        enqueueSnackbar(t(`${response.message}`), {
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
        if (props.onClickCloseDrawer) {
          props.onClickCloseDrawer();
        }
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Submit Appointment Error:", error);
    } finally {
      setLoading(false);
    }
  };

  /// Update Appointment
  const handleUpdateAppointment = async (values) => {
    try {
      setLoading(true);
      const { title, location, description, email } = values;
      const { id_in_google_calendar } = appointmentSelected;
      /// Update Google API
      if (id_in_google_calendar) {
        await getAuthToGoogle();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var event = {
          summary: title,
          location: location,
          description: description,
          start: {
            dateTime: Moment(startDate),
            timeZone: timeZone,
          },
          end: {
            dateTime: Moment(endDate),
            timeZone: timeZone,
          },
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: reminderType.reminderMinutes },
            ],
          },
        };
        await updateCalendarEvent(
          event,
          appointmentSelected.id_in_google_calendar
        );
      }
      /// Submit API
      const request = {
        apmt_id: appointmentSelected.apmt_id,
        title: title,
        type: 1,
        location: {
          address: location,
          lat: null,
          long: null,
        },
        is_all_day: allDay,
        start_time: startDate.toISOString(),
        end_time: endDate.toISOString(),
        reminder: {
          reminder_time_type: reminderType.value,
        },
        description: description,
        is_synced_phone_calendar: false,
        is_synced_google_calendar: googleSycing,
        list_invited_emails: emailsInvite,
        list_leads: leadsSelected.map((element) => element.objectId),
        id_in_phone_calendar: null,
        id_in_google_calendar: id_in_google_calendar,
      };
      const action = updateAppointment(request);
      const actionResult = await dispatch(action);
      const response = unwrapResult(actionResult);
      if (response.status === 200) {
        enqueueSnackbar(t(`${response.message}`), {
          variant: "success",
        });
        if (props.reload) {
          props.reload();
        }
        if (props.onClickCloseDrawer) {
          props.onClickCloseDrawer();
        }
      } else {
        enqueueSnackbar(t(response.message || ""), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log("Submit Appointment Error:", error);
    } finally {
      setLoading(false);
    }
  };

  /// UseEffect Get Leads
  useEffect(() => {
    const getLeads = async () => {
      try {
        const action = getLeadsManagement();
        const resultAction = await dispatch(action);
        const leads = unwrapResult(resultAction);
        setLeads(leads);
        /// Load Appointment
        if (appointmentSelected) {
          /// Start Date
          setStartDate(new Date(appointmentSelected.start_time));
          /// End Date
          setEndDate(new Date(appointmentSelected.end_time));
          /// Reminder Type
          const reminderType =
            reminderTypes.find(function (element) {
              return (
                element.value.toString() ===
                appointmentSelected.reminder.reminder_time_type.toString()
              );
            }) || ReminderType.atTime;
          setReminderType(reminderType);
          /// Email Invites
          setEmailsInvite(appointmentSelected.list_invited_emails);
          /// Set All day
          setAllDay(appointmentSelected.is_all_day);
          /// Set Google Syncing
          setGoogleSyncing(appointmentSelected.is_synced_google_calendar);
          /// Leads
          const leadsSelected = leads.filter((lead) =>
            appointmentSelected.list_leads.includes(lead.objectId)
          );
          setLeadsSelected(leadsSelected);
        }
      } catch (error) {
        console.log("Get Leads Error: ", error);
      }
    };
    getLeads();
  }, []);

  /// Validate Form
  const validateSchema = Yup.object({
    title: Yup.string().required(t("appointment_title_required")),
    location: Yup.string().required(t("location_required")),
    email: Yup.string().email(t("email_is_invalid")),
    // end_date: Yup.date()
    //   .required("end_date_is_required")
    //   .min(startDate, "end_date_must_be_later_than_today"),
  });

  /// Handle Submit Activity
  const handleSubmitActivity = async () => {
    try {
      for (const leadSelected of leadsSelected) {
        const leadId = leadSelected.objectId;
        const leadName =
          leadSelected.type === 1
            ? leadSelected.companyName || ""
            : (leadSelected.first_name || "") +
              " " +
              (leadSelected.last_name || "");
        const request = {
          lead_id: leadId,
          type: ActivityType.activityAppointment.value,
          description: t(
            getTranslateDescription(ActivityType.activityAppointment.value),
            {
              sender:
                (authState.first_name || "") +
                " " +
                (authState.last_name || ""),
              receiver: leadName,
            }
          ),
        };
        const action = submitActivity(request);
        const response = await dispatch(action);
      }
    } catch (error) {
      console.log("Submit Activity Error: ", error);
    }
  };

  return (
    <div className="calendar-wrapper__container">
      {/* Header */}
      <div className="wrapper__container-header mg-top-20">
        <button onClick={props.onClickCloseDrawer}>
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ fontSize: "1.25rem" }}
            color="#CDD2F3"
          />
        </button>
        <span className="fs-16 font-w500 roamie-text-color-primary">
          {appointmentSelected
            ? t("update_appointment")
            : t("create_new_appointment")}
        </span>
      </div>

      {/* Body */}
      <div className="wrapper__container-form">
        <Formik
          initialValues={{
            title: (appointmentSelected && appointmentSelected.title) || "",
            location:
              (appointmentSelected && appointmentSelected.location.address) ||
              "",
            description:
              (appointmentSelected && appointmentSelected.description) || "",
          }}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            appointmentSelected
              ? handleUpdateAppointment(values)
              : handleAddAppointment(values);
          }}
        >
          {(formik) => (
            <Form className="col-10 mx-auto">
              {/* Appointment Title  */}
              <div className="input-group mb-3 border-text-input d-flex align-items-center justify-content-center roamie-button-background-color">
                <Field
                  type="text"
                  className="form-control placeholder--white-color appointmant-title fs-14 font-w400"
                  placeholder={t("appointment_title")}
                  name="title"
                />
                <ErrorMessage className="error" component="div" name="title" />
                <div className="px-3">
                  <FontAwesomeIcon icon={faPen} size="1x" color="white" />
                </div>
              </div>
              {/* Appointment Description */}
              <div className="input-group mb-3 border-text-input">
                <Field
                  type="text"
                  className="form-control fs-14 font-w400"
                  placeholder={t("appointment_description")}
                  name="description"
                />
              </div>
              {/* Location */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <Field
                  type="text"
                  className="form-control fs-14 font-w400"
                  placeholder={t("location")}
                  name="location"
                />
                <div className="px-3">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="1x"
                    color="#B5BBDE"
                  />
                </div>
                <ErrorMessage
                  className="error"
                  component="div"
                  name="location"
                />
              </div>

              {/* Start Date */}
              <DatePicker
                className="custom__date__picker px-3 mb-3"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  if (date.getTime() > endDate.getTime()) {
                    setEndDate(new Moment(date).add(60, "m").toDate());
                  }
                }}
                showTimeInput
                locale={CurrentCountry().includes("vn") ? vi : en}
                customInput={
                  <div className="fs-14 input-group border-text-input d-flex justify-content-between align-items-center">
                    <span>
                      {startDate && MomentUtil(startDate, "DD MMM yyyy, HH:mm")}
                    </span>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="1x"
                      color="#B5BBDE"
                    />
                  </div>
                }
              />

              {/* End Date  */}
              <DatePicker
                className="fs-14 custom__date__picker px-3 mb-3"
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
                showTimeInput
                locale={CurrentCountry().includes("vn") ? vi : en}
                minDate={startDate}
                customInput={
                  <div className="input-group border-text-input d-flex justify-content-between align-items-center">
                    <span>
                      {endDate && MomentUtil(endDate, "DD MMM yyyy, HH:mm")}
                    </span>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="1x"
                      color="#B5BBDE"
                    />
                  </div>
                }
              />

              {/* Reminder Type */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <select
                  value={reminderType.value}
                  onChange={handleOnChangeReminderType}
                  className="placeholder-color form-select bg-transparent"
                >
                  {reminderTypes &&
                    reminderTypes.length > 0 &&
                    reminderTypes.map((reminderType, index) => (
                      <option value={reminderType.value} key={index}>
                        {t(reminderType.title)}
                      </option>
                    ))}
                </select>
              </div>
              {/* Leads */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <select
                  onChange={handleOnChangeLead}
                  className="placeholder-color form-select bg-transparent"
                  name="lead"
                >
                  <option defaultValue value={-1}>
                    {t("add_person")}
                  </option>
                  {leads &&
                    leads.length > 0 &&
                    leads.map((lead, index) => (
                      <option value={lead.objectId} key={index}>
                        {lead && lead.type === 1
                          ? lead.name || ""
                          : (lead.first_name || "") +
                            " " +
                            (lead.last_name || "")}
                      </option>
                    ))}
                </select>
                <ErrorMessage
                  className="error error__select"
                  component="div"
                  name="lead"
                />
              </div>
              {/* Leads Selected */}
              {leadsSelected &&
                leadsSelected.length > 0 &&
                leadsSelected.map((lead) => (
                  <LeadSelected
                    key={lead.objectId}
                    lead={lead}
                    onDelete={handleOnDeleteLead}
                  />
                ))}
              {/* Invite By Email */}
              <div className="input-group mb-3 border-text-input d-flex justify-content-center align-items-center">
                <Field
                  type="text"
                  name="email"
                  className="form-control fs-14 font-w400"
                  placeholder={t("invite_by_email")}
                  onKeyDown={handleAddEmail}
                />
                <div className="px-3">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="1x"
                    color="#B5BBDE"
                  />
                  <ErrorMessage
                    className="error"
                    component="div"
                    name="email"
                  />
                </div>
              </div>
              {/* Emails Invite */}
              {emailsInvite &&
                emailsInvite.length > 0 &&
                emailsInvite.map((email) => (
                  <EmailInvite email={email} onDelete={handleOnDeleteEmail} />
                ))}
              {/* All Day */}
              <div className="mb-3 d-flex justify-content-between">
                <span className="fs-16 font-w400 roamie-text-color-primary">
                  {t("all_day")}
                </span>
                <Switch
                  checked={allDay}
                  onChange={handleOnChangeAllDay}
                  color="default"
                />
              </div>
              {/* Google Syncing */}
              <div className="mb-3 d-flex justify-content-between">
                <span className="fs-16 font-w400 roamie-text-color-primary">
                  {t("google_syncing")}
                </span>
                <Switch
                  checked={googleSycing}
                  onChange={handleOnChangeGoogleSyncing}
                  color="default"
                />
              </div>
              {/* Save */}
              <div className="d-grid gap-2 col-6 mx-auto mb-5">
                <button
                  className="btn btn-success roamie-text-white-color"
                  type="submit"
                >
                  {appointmentSelected ? t("update") : t("save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

AddNewAppointment.propTypes = {
  onClickCloseDrawer: PropTypes.func,
  appointment: PropTypes.object,
};

AddNewAppointment.defaultProps = {
  onClickCloseDrawer: null,
};

export default AddNewAppointment;
