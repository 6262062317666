/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
/// Link
import { Link } from "react-router-dom";
import IconCalendarActive from "../../../assets/icons/icons-active/icon_calendar_active.png";
import IconChatActive from "../../../assets/icons/icons-active/icon_chat_active.png";
import IconSalesTeamActive from "../../../assets/icons/icons-active/icon_customers_active.png";
import IconHomeActive from "../../../assets/icons/icons-active/icon_home_active.png";
import IconLeadsActive from "../../../assets/icons/icons-active/icon_leads_active.png";
import IconSettingsActive from "../../../assets/icons/icons-active/icon_setting_active.png";
import IconTargetActive from "../../../assets/icons/icons-active/icon_target_active.png";
import IconTasksActive from "../../../assets/icons/icons-active/icon_task_active.png";
import IconCalendar from "../../../assets/icons/icon_calendar.png";
import IconChat from "../../../assets/icons/icon_chat.png";
import IconSalesTeam from "../../../assets/icons/icon_customers.png";
/// Icons
import IconHome from "../../../assets/icons/icon_home.png";
import IconLeads from "../../../assets/icons/icon_leads.png";
import IconService from "../../../assets/icons/icon_service.png";
import IconServiceActive from "../../../assets/icons/icon_service_active.png";
import IconSettings from "../../../assets/icons/icon_setting.png";
import IconTarget from "../../../assets/icons/icon_target.png";
import IconTasks from "../../../assets/icons/icon_task.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

export default function SideBar(props) {
  const { t } = useTranslation();
  const [path, setPath] = useState(window.location.pathname);
  const { initData } = useSelector((state) => state.auth);
  const [data, setData] = useState(initData);
  /// Path
  let paths = path;
  paths = path.substring(paths.indexOf("/") + 1);

  /// Active menu
  let home = ["", "callback"],
    leadsManagement = ["leads-management"],
    salesTeam = ["sales-team"],
    calendar = ["calendar"],
    settings = ["settings"],
    chat = ["messages"],
    messenger = ["messenger"],
    tasks = ["tasks"],
    targets = ["targets"],
    service = ["service"];

  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);

  useEffect(() => {
    if (initData) {
      setData(initData);
    }
  }, [initData]);

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <div className="top-menu">
            {/* Home */}
            {home.includes(paths)}
            <li
              className={`${home.includes(paths) ? "mm-active" : ""}`}
              onClick={() => setPath("")}
            >
              <Link className="ai-icon" to="/" aria-expanded="false">
                <div className="side-menu">
                  {home.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconHomeActive}
                      width="19"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="icon-menu"
                      src={IconHome}
                      width="19"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">{t("home")}</span>
                </div>
              </Link>
            </li>

            {/* Targets */}
            <li
              className={`${targets.includes(paths) ? "mm-active" : ""}`}
              onClick={() => setPath("targets")}
            >
              <Link className="ai-icon" to="/targets" aria-expanded="false">
                <div className="side-menu">
                  {targets.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconTargetActive}
                      width="19"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="icon-menu"
                      src={IconTarget}
                      width="19"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">{t("target")}</span>
                </div>
              </Link>
            </li>

            {/* Lead Management */}
            <li
              className={`${
                leadsManagement.includes(paths) ? "mm-active" : ""
              }`}
              onClick={() => setPath("leads-management")}
            >
              <Link
                className="ai-icon"
                to="/leads-management"
                aria-expanded="false"
              >
                <div className="side-menu">
                  {leadsManagement.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconLeadsActive}
                      width="19"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="icon-menu"
                      src={IconLeads}
                      width="19"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">{t("leads_management")}</span>
                </div>
              </Link>
            </li>

            {/* Sales Team */}
            {data && data.user_role == 3 ? (
              <li
                className={`${salesTeam.includes(paths) ? "mm-active" : ""}`}
                onClick={() => setPath("sales-team")}
              >
                <Link
                  className="ai-icon"
                  to="/sales-team"
                  aria-expanded="false"
                >
                  <div className="side-menu">
                    {salesTeam.includes(paths) ? (
                      <img
                        className="icon-menu"
                        src={IconSalesTeamActive}
                        width="19"
                        alt="icon"
                      />
                    ) : (
                      <img
                        className="icon-menu"
                        src={IconSalesTeam}
                        width="19"
                        alt="icon"
                      />
                    )}
                    <span className="nav-text">{t("sales_team")}</span>
                  </div>
                </Link>
              </li>
            ) : (
              <></>
            )}

            {/* Calendar */}
            <li
              className={`${calendar.includes(paths) ? "mm-active" : ""}`}
              onClick={() => setPath("calendar")}
            >
              <Link className="ai-icon" to="/calendar" aria-expanded="false">
                <div className="side-menu">
                  {calendar.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconCalendarActive}
                      width="19"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="icon-menu"
                      src={IconCalendar}
                      width="19"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">{t("calendar")}</span>
                </div>
              </Link>
            </li>

            {/* Tasks */}
            <li
              className={`${tasks.includes(paths) ? "mm-active" : ""}`}
              onClick={() => setPath("tasks")}
            >
              <Link className="ai-icon" to="/tasks" aria-expanded="false">
                <div className="side-menu">
                  {tasks.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconTasksActive}
                      width="19"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="icon-menu"
                      src={IconTasks}
                      width="19"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">{t("task")}</span>
                </div>
              </Link>
            </li>

            {/* Service */}
            <li
              className={`${service.includes(paths) ? "mm-active" : ""}`}
              onClick={() => setPath("service")}
            >
              <Link className="ai-icon" to="/service" aria-expanded="false">
                <div className="side-menu">
                  {service.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconServiceActive}
                      width="19"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="icon-menu"
                      src={IconService}
                      width="19"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">{t("service")}</span>
                </div>
              </Link>
            </li>

            {/* Chat */}
            <li
              className={`${messenger.includes(paths) ? "mm-active" : ""}`}
              onClick={() => setPath("messenger")}
            >
              <Link className="ai-icon" to="/messenger" aria-expanded="false">
                <div className="side-menu">
                  {messenger.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconChatActive}
                      width="19"
                    />
                  ) : (
                    <img className="icon-menu" src={IconChat} width="19" />
                  )}
                  <span className="nav-text">{t("messages")}</span>
                </div>
              </Link>
            </li>
          </div>

          <div className="bottom-menu">
            <li
              className={`${settings.includes(paths) ? "mm-active" : ""}`}
              onClick={() => setPath("settings")}
            >
              <Link className="ai-icon" to="/settings" aria-expanded="false">
                <div className="side-menu">
                  {settings.includes(paths) ? (
                    <img
                      className="icon-menu"
                      src={IconSettingsActive}
                      width="19"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="icon-menu"
                      src={IconSettings}
                      width="19"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">{t("settings")}</span>
                </div>
              </Link>
            </li>
          </div>
        </MM>
      </PerfectScrollbar>
    </div>
  );
}
