const PeriodsType = {
  daily: {
    name: "daily",
    value: 0,
  },
  weekly: {
    name: "weekly",
    value: 1,
  },
  monthly: {
    name: "monthly",
    value: 2,
  },
  year: {
    name: "year",
    value: 3,
  },
};

export const getTitlePeriods = (period) => {
  switch (period) {
    case PeriodsType.daily:
      return "daily";
    case PeriodsType.weekly:
      return "weekly";
    case PeriodsType.monthly:
      return "monthly";
    case PeriodsType.year:
      return "year";
    default:
      break;
  }
};

const periodsType = [
  PeriodsType.daily,
  PeriodsType.weekly,
  PeriodsType.monthly,
  PeriodsType.year,
];

export default periodsType;
